import React, { useCallback, useRef, useState } from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import NoteForm from './NoteForm';

const AnnotationForm = ({
    activeEmployee,
    annotationType,
    cancelButtonAction,
    handleAddNote,
    handleRefreshSavedNote,
    handleUpdateNote,
    gridInstance = null,
    isNoteReadOnly,
    isNewNote,
    listInstance = null,
    noteData,
    noteSectionLabel = 'Note',
    objectId,
    popupWidth = 400,
    setIsShowAnnotationForm,
    showTitleRow = false,
    showAnnotationForm = false,
    showAttachmentForm = false,
    showNoteForm = false,
    target = '',
    useTimeEntryForm = false,
    useShading = true
}) => {
    // eslint-disable-next-line no-unused-vars
    const [ noteTitle, setNoteTitle ]= useState('Annotations');
    const annotationPopupRef = useRef();

    const onHidden = useCallback(e => {
        setIsShowAnnotationForm(false);
    }, [setIsShowAnnotationForm]);

    return (
        <>
            <Popup
                ref={annotationPopupRef}
                visible={showAnnotationForm}
                dragEnabled={false}
                showTitle={showTitleRow}
                title={noteTitle}
                height={275}
                width={popupWidth}
                hideOnOutsideClick={true}
                shadingColor={useShading ? null : 'rgba(0,0,0,.1)'}
                onHidden={onHidden}
            >
                <Position
                    offset={!useTimeEntryForm ? '0 140' : '0 0'}
                    at={useTimeEntryForm ? 'center' : 'bottom'}
                    my="center"
                    of={target}
                    collision="fit"
                />
                {
                    showNoteForm ? 
                        <NoteForm 
                            activeEmployee={activeEmployee}
                            annotationType={annotationType}
                            cancelButtonAction={cancelButtonAction}
                            handleAddNote={handleAddNote}
                            handleUpdateNote={handleUpdateNote}
                            isNewNote={isNewNote}
                            noteData={noteData}
                            noteSectionLabel={noteSectionLabel}
                            objectId={objectId}
                            isNoteReadOnly={isNoteReadOnly}
                            setIsShowAnnotationForm={setIsShowAnnotationForm}
                            useTimeEntryForm={useTimeEntryForm}
                            gridInstance={gridInstance}
                            listInstance={listInstance}
                            popupRef={annotationPopupRef}
                            handleRefreshSavedNote={handleRefreshSavedNote}
                        />
                        : null
                }
                {
                    showAttachmentForm ? 
                        <div><p>Attachment Form</p></div>
                        : null
                }
            </Popup>
        </>
    );    
};

export default AnnotationForm;
