import { Flex, Image, Link, useAuthenticator, useTheme } from '@aws-amplify/ui-react';

export function SignInFooter() {
    const { toForgotPassword } = useAuthenticator();
    const { tokens } = useTheme();

    return (
        <>
            <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
                <Link onClick={toForgotPassword}>Reset your password</Link>
            </Flex>
            <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
                <Image
                    src="/assets/images/WSStamp.png"
                    alt="WennSoft"
                    width={48}
                    height={48}
                />
            </Flex>
        </>
    );
}
