import { cloneDeep, isEmpty } from 'lodash';
import { differenceInMinutes, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';
import { toHoursAndMinutes } from './timeHelpers';

export function createTimesheetGridDataSet(daysOfPeriod, timesheetData) {
    let entries = [];
    if (isEmpty(timesheetData)) return [];

    timesheetData.timeTransactions.forEach(t => {
        const entry = entries.find(e => e.transactionType === t.transactionType && e.activityId.id === t.activityId.id && e.costcodeId?.id === t.costcodeId?.id && e.paycodeId.id === t.paycodeId.id && e.equipmentId?.id === t.equipmentId?.id && e.status === t.status);
        if (!entry) {
            const newRecord = t;
            const dowIndex = daysOfPeriod.findIndex(d => t.transactionFormattedDate === d.formattedDate);
            if (dowIndex !== -1) {
                newRecord[daysOfPeriod[0].formattedDate] = null;
                newRecord[daysOfPeriod[1].formattedDate] = null;
                newRecord[daysOfPeriod[2].formattedDate] = null;
                newRecord[daysOfPeriod[3].formattedDate] = null;
                newRecord[daysOfPeriod[4].formattedDate] = null;
                newRecord[daysOfPeriod[5].formattedDate] = null;
                newRecord[daysOfPeriod[6].formattedDate] = null;
                newRecord[daysOfPeriod[dowIndex].formattedDate] = t.hours;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
            }
            entries.push(newRecord);
        }
        else {
            const dowIndex = daysOfPeriod.findIndex(d => t.transactionFormattedDate === d.formattedDate);
            if (dowIndex !== -1) {

                if (entry[daysOfPeriod[dowIndex].formattedDate]) {
                    entry[daysOfPeriod[dowIndex].formattedDate] += t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                }
                else {
                    entry[daysOfPeriod[dowIndex].formattedDate] = t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                }
            }
        }
    });

    return entries;
}

export function splitPayPeriodTransactions(transaction) {

    const currentTransaction = cloneDeep(transaction);
    const futureTransaction = cloneDeep(transaction);

    const newTimeInOutTime = setHours(setMinutes(setSeconds(setMilliseconds(new Date(currentTransaction.timeOut), 0), 0), 0), 0);

    currentTransaction.timeIn = new Date(currentTransaction.timeIn);
    currentTransaction.timeOut = newTimeInOutTime;
    currentTransaction.hours = Number(toHoursAndMinutes(Math.round(differenceInMinutes(currentTransaction.timeOut, currentTransaction.timeIn))));

    futureTransaction.timeIn = newTimeInOutTime;
    futureTransaction.hours = Number(toHoursAndMinutes(Math.round(differenceInMinutes(futureTransaction.timeOut, futureTransaction.timeIn))));
    futureTransaction.timesheetId = null;
    futureTransaction.id = null;
    futureTransaction.transactionDate = newTimeInOutTime;

    return {
        currentTransaction,
        futureTransaction
    };
}

export function prepareTimeEntriesForSave(transactions, activeEmployee) {

    const entries = [];

    transactions.forEach(trx => {
        const ttx = {
            id:                       trx.id ? trx.id : null,
            timesheetId:              trx.timesheetId ? trx.timesheetId : null,
            employeeId:               trx.employeeId?.id ? trx.employeeId.id : activeEmployee.id,
            activityId:               trx.activityId.id,
            costcodeId:               trx.costcodeId?.id ? trx.costcodeId.id : null,
            paycodeId:                trx.paycodeId.id,
            equipmentId:              trx.equipment?.id ? trx.equipmentId.id : null,
            shiftCodeId:              trx.shiftCodeId?.id ? trx.shiftCodeId.id : null,
            status:                   trx.status,
            statusComment:            trx.statusComment ? trx.statusComment : null,
            description:              trx.description ? trx.description : null,
            transactionType:          trx.transactionType,
            transactionDate:          trx.transactionDate,
            timeIn:                   trx.timeIn ? trx.timeIn : null,
            timeOut:                  trx.timeOut ? trx.timeOut : null,
            hours:                    trx.hours ? trx.hours : null,
            acknowledgementTimestamp: trx.acknowledgementTimestamp,
            notes:                    trx.notes
        };
        entries.push(ttx);
    });

    return entries;
}
