import React from 'react';
import { connect } from 'react-redux';
import { func, string } from 'prop-types';
import template from 'url-template';

import { get, has } from 'lodash';

import awsHeader from '../../helpers/awsAuth';
import config from '../../awsConfig';

// import { getEntityGroup } from '../../helpers/entityGroups';

// import * as ArchitectActions from '../../actions/architectActions';
// import * as ApplicationMessageActions from '../../actions/applicationMessageActions';
// import * as IotHubActions from '../../actions/iotHubActions';
// import * as ReportingActions from '../../actions/reportingActions';
// import * as ServiceManagementActions from '../../actions/serviceManagementActions';
import * as WebSocketActions from '../../actions/webSocketActions';
// import * as WizardActions from '../../actions/wizardActions';
// import * as dashboardActions from '../../actions/dashboardActions';

const AGENT_SOCKET_URL = template.parse(
    'wss://{host}{/path}{?companyId,sessionId,idToken}'
);

function buildAgentUrl({ companyId, host, idToken, instanceId, path }) {
    if (!companyId || !host || !idToken || !instanceId) {
        return null;
    }
    return AGENT_SOCKET_URL.expand({
        companyId,
        host,
        idToken,
        path,
        sessionId: instanceId
    });
}

class WebSocketConnection extends React.Component {
    static defaultProps = {
        connectSocket:    () => {},
        disconnectSocket: () => {},
        host:             config.key2act.applications.socket.host,
        path:             config.key2act.applications.socket.path,
        sendPacket:       () => {}
    };

    static propTypes = {
        companyId:        string,
        connectSocket:    func,
        disconnectSocket: func,
        host:             string.isRequired,
        idToken:          string.isRequired,
        instanceId:       string.isRequired,
        path:             string.isRequired,
        sendPacket:       func
    };

    componentDidMount() {
        this.connectAgentSocket();
        this.connectCoreSocket();
    }

    componentDidUpdate(prevProps) {
        const prevUrl = buildAgentUrl(prevProps);
        const currentUrl = buildAgentUrl(this.props);

        if (prevProps.companyId !== this.props.companyId) {
            this.connectCoreSocket();
        }

        if (prevUrl === currentUrl) {
            return;
        }

        if (prevUrl) {
            this.props.disconnectSocket();
        }

        this.connectAgentSocket(currentUrl);
    }

    componentWillUnmount() {
        this.props.disconnectSocket();
    }

    connectAgentSocket = (url) => {
        url || (url = buildAgentUrl(this.props));

        if (!url) {
            return;
        }

        this.props.connectSocket(url);
    };

    connectCoreSocket = () => {
        if (!this.props.companyId) return;

        const coreHost = config.key2act.applications.websocket.host;
        const corePath = config.key2act.applications.websocket.path;
        const coreUrl = `${coreHost}/${corePath}?companyId=${this.props.companyId}`;

        const startWebSocket = async () => {
            const header = await awsHeader();
            const rawToken = header.headers.Authorization;
            const token = rawToken.replace('Bearer ', '');

            const authPacket = {
                'authToken': token,
                'companyId': this.props.companyId
            };

            let ws = new WebSocket(coreUrl);

            // Event Listeners
            ws.onopen = () => {
                ws.send(JSON.stringify(authPacket));
            };

            ws.onmessage = evt => {
                const message = JSON.parse(evt.data);
                const dispatchType = get(message, 'dispatchType', null);
                const authSuccessProp = has(message, 'authSuccess');
                const payload = get(message, 'payload', {});

                if (authSuccessProp) {
                    const isSuccessful = get(message, 'authSuccess');

                    if (isSuccessful) {
                        // eslint-disable-next-line no-console
                        console.log('Websocket connection successful.');
                        return;
                    }

                    // eslint-disable-next-line no-console
                    console.log('Websocket connection failed.');
                    return;
                }

                switch (dispatchType) {
                    case 'EntityUpdate':
                        this.signalEntityUpdate(payload);
                        break;
                    case 'WorkerProcessStatus':
                        this.signalWorkerProcessStatus(payload);
                        break;
                    case 'WorkerProcessError':
                        this.signalWorkerProcessError(payload);
                        break;
                    default:
                        break;
                }
            };

            ws.onclose = e => {
                // AWS will close the websocket every 10 minutes. This code will reset the
                // ws connection and restart it after 1 second.
                ws = null;

                setTimeout(startWebSocket, 1000);
            };

            ws.onerror = err => {
                // eslint-disable-next-line no-console
                console.error('A websocket error has occurred: ', err);
            };
        };

        startWebSocket();
    };

    signalEntityUpdate = payload => {
        // const { enqueueSnackbar } = this.props;
        const timestamp = new Date();

        // const entity = get(payload, 'entity', null);
        // const action = get(payload, 'operation', null);
        // const entityGroup = getEntityGroup(entity);

        const cacheTarget = {
            // target:    entityGroup ? entityGroup : 'Global',
            timestamp: timestamp.getTime()
        };

        sessionStorage.setItem('apiCacheFlushTarget', JSON.stringify(cacheTarget));

        // let entityId, entityPayload, parentId;

        switch (cacheTarget.target) {
            // case 'Global':
            //     enqueueSnackbar('Database update detected. Refresh BOB at your convenience.', 'info');
            //     break;
            // case 'Admin':
            //     break;
            // case 'Architect':
            //     entityId = get(payload, 'entityId', null);
            //     parentId = get(payload, 'parentId', null);

            //     this.props.architectActionRouter(entity, entityId, action, parentId);
            //     break;
            // case 'IoTHub':
            //     break;
            // case 'Reporting':
            //     entityId = get(payload, 'entityId', null);

            //     this.props.reportingActionRouter(entity, entityId, action);
            //     break;
            // case 'Rules':
            //     break;
            // case 'ServiceManagement':
            //     entityId = get(payload, 'entityId', null);
            //     entityPayload = get(payload, 'entityPayload', null);
            //     parentId = get(payload, 'parentId', null);

            //     this.props.serviceManagementActionRouter(entity, entityId, entityPayload, action, parentId);
            //     break;
            // case 'Subscriptions':
            //     break;
            default:
                break;
        }
    };

    signalWorkerProcessStatus = async payload => {
        //const { enqueueSnackbar } = this.props;
        const entity = get(payload, 'processType', null);
        // const userId = get(payload, 'userId', null);

        // let processId;
        // let equipmentProcessIds;
        // let sensorProcessIds;
        // let profileObjectProcessIds;
        switch (entity) {
            // case 'EntityOptimizationExportCreate':
            //     processId = get(payload, 'processId', null);

            //     if (processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchOptimizationReportData(payload);
            //         enqueueSnackbar('Your Optimization Report is ready to download.', 'info');
            //     }
            //     break;
            // case 'InspectionReportCreate':
            //     processId = get(payload, 'processId', null);

            //     if (processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.updateInspectionReport(payload);
            //         enqueueSnackbar('Your Inspection Report is ready to download.', 'info');
            //     }
            //     break;
            // case 'SitePerformance':
            //     processId = get(payload, 'processId', null);

            //     this.props.updateWatchDogReport(payload);

            //     if (processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         enqueueSnackbar('Your Watchdog Report is ready to download.', 'info');
            //     }
            //     break;
            // case 'CustomerSupportReportCreate':
            //     processId = get(payload, 'processId', null);
            //     if (processId && processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchExportedFileUrl(payload);
            //     }
            //     break;
            // case 'DashboardExportCreate':
            //     processId = get(payload, 'processId');

            //     if (processId && processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchExportedFileUrl(payload);
            //     }

            //     break;
            // case 'EquipmentFaultExportCreate':
            //     processId = get(payload, 'processId');

            //     if (processId && processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchExportedFileUrl(payload);
            //     }

            //     break;
            // case 'SensorDataWithScoreExportCreate':

            //     processId = get(payload, 'processId');

            //     if (processId && processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchExportedFileUrl(payload, true);
            //     }

            //     break;
            // case 'SensorDataExportCreate':
            //     processId = get(payload, 'processId');

            //     if (processId && processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchExportedFileUrl(payload);
            //     }

            //     break;
            // case  'HealthCheckExportCreate':
            //     processId = get(payload, 'processId');


            //     if (processId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchExportedFileUrl(payload);
            //     }

            //     break;
            // case 'EquipmentBulkCreate':
            //     processId = get(payload, 'processId');
            //     equipmentProcessIds = this.props.equipmentProcessIds;

            //     if (processId && equipmentProcessIds.includes(processId) && this.props.currentUser && this.props.currentUser.id === userId) {
            //         const currentData = this.props.equipmentProcessData;
            //         const newData = [{ processId: processId, data: payload.result.equipmentMutationResults  }];

            //         this.props.setEquipmentProcessData([...currentData, ...newData]);
            //     }
            //     break;
            // case 'SensorBulkCreate':
            //     processId = get(payload, 'processId', '');
            //     sensorProcessIds = this.props.sensorProcessIds;

            //     if (processId && sensorProcessIds.includes(processId) && this.props.currentUser && this.props.currentUser.id === userId) {
            //         const currentData = this.props.sensorProcessData;
            //         const newData = [{ processId: processId, data: payload.result.sensorMutationResults }];

            //         this.props.setSensorProcessData([...currentData, ...newData]);
            //     }
            //     break;
            // case 'ProfileObjectBulkCreate':
            //     processId = get(payload, 'processId');
            //     profileObjectProcessIds = this.props.profileObjectProcessIds;

            //     if (processId && profileObjectProcessIds.includes(processId) && this.props.currentUser && this.props.currentUser.id === userId) {
            //         const currentData = this.props.profileObjectProcessData;
            //         const newData = [{ processId: processId, data: payload.result.profileObjectMutationResults }];

            //         this.props.setProfileObjectProcessData([...currentData, ...newData]);
            //     }
            //     break;
            // case 'EquipmentCountExport':
            //     processId = get(payload, 'processId');
            //     if (processId === this.props.storedProcessId && this.props.currentUser && this.props.currentUser.id === userId) {
            //         this.props.fetchBillingMetricReport(payload);
            //         enqueueSnackbar('Your Billing Metrics Report is ready to download.', 'info');
            //     }
            //     break;
            default:
                break;
        }
    };

    signalWorkerProcessError = payload => {
        // stub to expand worker process error functionality
    };

    render() {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        session:     state.auth.session,
        webSocket:   state.webSocket
    };
};

const mapDispatchToProps = {
    ...WebSocketActions
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketConnection);
