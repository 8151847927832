import {
    FETCH_CONFIGURATION_DATA,
    FETCH_USER_SETTINGS
}
    from '../actions/actionTypes';

const initialState = {
    allConfigurations: [],
    timeUserSettings:  []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONFIGURATION_DATA:
            return {
                ...state,
                allConfigurations: action.payload
            };
        case FETCH_USER_SETTINGS:
            return {
                ...state,
                timeUserSettings: action.payload
            };
        default:
            return state;
    }
};
