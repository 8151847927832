import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataGrid, {
    Button as GridButton,
    Column,
    Editing,
    LoadPanel,
    Lookup,
    NumericRule,
    RangeRule,
    RequiredRule,
    Summary,
    TotalItem,
    ColumnFixing
} from 'devextreme-react/data-grid';
import { Tooltip } from 'devextreme-react/tooltip';

import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import { Button } from 'devextreme-react/button';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import { cloneDeep, isEmpty, isNumber, orderBy } from 'lodash';

import ActivityLookup from '../lookups/activityLookup';
import CostCodeLookup from '../lookups/costcodeLookup';
import PayCodeLookup from '../lookups/paycodeLookup';
import LoadingMask from '../loadingMask/loadingMask';
import TimeCellContextMenu from '../timeCellContextMenu/timeCellContextMenu';
import ConfirmationDialog from '../confirmationDialog/confirmationDialog';
import AnnotationForm from '../annotations/AnnotationForm';

import { format, isEqual, isValid, parseISO } from 'date-fns';

import * as AnnotationTypes from '../../data/annotationTypes';
import * as AnnotationActions from '../../actions/annotationActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as JobActions from '../../actions/jobActions';
import * as WorkOrderActions from '../../actions/workOrderActions';
import * as TimeActions from '../../actions/timeActions';

import './TimeEntryTable.scss';

const defaultNoteData = {
    annotationType: null,
    isAttachment:   false,
    attachment:     null,
    subject:        null,
    isVisible:      true,
    noteText:       'Default Note'
};

const TimeEntryTable = ({
    activeCompany,
    activeEmployee,
    currentTimesheet,
    currentUser,
    currentWeekEndingDate,
    deleteAnnotation,
    deleteTimeTransactions,
    employeePayCodes,
    fetchEmployeeTimesheet,
    fetchJobCostCodes,
    fetchWorkOrderCostCodes,
    insertTimeSheetTransaction,
    insertEmployeeTimeSheet,
    isInEditMode,
    isInRefresh,
    isSaveValid,
    jobList,
    periodDays,
    saveIsValid,
    setIsInEditMode,
    setIsSaveValid,
    setTimesheetGridInstance,
    timesheetData,
    timesheetGridRef,
    timesheetStartDate,
    unbilledActivities,
    updateEmployeeTimeSheet,
    workOrderCostCodes,
    workOrderList,
    workOrders,
}) => {
    const { t } = useTranslation();
    const gridRef = useRef();

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams({});

    const [newActivityType, setNewActivityType] = useState();
    const [ isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [ isDeleteNoteConfirmationVisible, setIsDeleteNoteConfirmationVisible ] = useState(false);
    const [ isShowAnnotationForm, setIsShowAnnotationForm] = useState(false);
    const [ isNoteReadOnly, setIsNoteReadOnly] = useState(false);
    const [ deleteConfirmationTitle, setDeleteConfirmationTitle] = useState('Delete Transaction');
    const [ deleteConfirmationData, setDeleteConfirmationData] = useState();
    const [ deleteTransactions, setDeleteTransactions ] = useState([]);
    const [ selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [ noteSectionLabel, setNoteSectionLabel] = useState('Note');
    const [ annotationTarget, setAnnotationTarget ] = useState(null);
    const [ noteData, setNoteData ] = useState(defaultNoteData);
    const [ noteToDelete, setNoteToDelete] = useState();
    const [ isNewNote, setIsNewNote ] = useState(false);

    useEffect(() => {
        setTimesheetGridInstance(gridRef);
        config({
            floatingActionButtonConfig: {
                shading: true
            }
        });
        repaintFloatingActionButton();
    }, []);

    useEffect(() => {
        setIsShowAnnotationForm(false);
        setSearchParams({companyId: activeCompany.id});
    }, [activeCompany.id]);

    const employeePayCodeLookup = useMemo(() => {
        return employeePayCodes.filter(pc => pc.payType !== 5);
    }, [employeePayCodes]);

    const workOrderLaborCostCodes = useMemo(() => {
        return orderBy(workOrderCostCodes.filter(cc => cc.costType.toLowerCase() === 'labor'), 'description', 'asc');
    }, [workOrderCostCodes]);

    const gridData = useMemo(() => {
        return timesheetData;
        // const gridStore = new CustomStore({
        //     key:      'id',
        //     laodMode: 'raw',
        //     load:     () => {
        //         return timesheetData;
        //     },
        //     insert: () => {}
        // });
        // return gridStore;
    }, [timesheetData]);

    const defaultPayCode = useMemo(() => {
        if (employeePayCodes.length === 1) {
            return employeePayCodes[0].paycodeId;
        }
        return null;
    }, [employeePayCodeLookup]);

    const hasAnnotations = cellData => {
        if (!cellData.rowType === 'data' || !cellData.value) return false;
        if (!isValid(new Date(cellData.column.dataField))) return false;


        let annotationCount = 0;

        const noteColumn = cellData.column.dataField + '_notes';
        if (cellData.data[noteColumn]) {
            annotationCount = cellData.data[noteColumn].length;
        }

        return annotationCount !== 0;
    };

    const isEditingAllowed = e => {
        return !e.row.isNewRow && !e.row.isEditing && e.row.data.status.toUpperCase() === 'OPEN';
    };

    const isDeletingAllowed = e => {
        return !e.row.isNewRow && !e.row.isEditing && e.row.data.status.toUpperCase() === 'OPEN';
    };

    const renderActivityCell = useCallback((cellData) => {
        const { row } = cellData;
        return (
            <>
                { row.data.transactionType.toUpperCase() !== 'UNBILLED' ?
                    <>
                        <div>{`${row.data.activityName} - ${row.data.activityDescription}`}</div>
                        <div style={{ fontSize: '11px' }}>{`${row.data.activityClientName} - ${row.data.activitySiteName}`}</div>
                    </>
                    :
                    <div>{row.data.activityName}</div>
                }
            </>
        );
    }, []);

    const renderCostCodeCell = useCallback((cellData) => {
        const { row } = cellData;
        return (
            <>
                { row.data.transactionType.toUpperCase() === 'JOB' ?
                    <>
                        <div>{row.data.costcodeAlias}</div>
                        <div style={{ fontSize: '11px' }}>{row.data.costcodeDescription}</div>
                    </>
                    : row.data.transactionType.toUpperCase() === 'SERVICE' ?
                        <div>{row.data.costcodeDescription}</div>
                        :
                        <div />
                }
            </>
        );
    }, []);

    const renderPayCodeCell = useCallback((cellData) => {
        const { row } = cellData;
        return (
            <>
                <div>{`${row.data.paycodeName ? row.data.paycodeName : ''}`}</div>
                <div style={{ fontSize: '11px' }}>{row.data?.paycodeDescription}</div>
            </>
        );
    }, []);

    const onCellPrepared = useCallback((e) => {
        let hasError = false;
        if (e.rowType === 'data' && e.cellElement)
        {
            const cellId = `cell-${e.columnIndex}-${e.rowIndex}`;
            e.cellElement.id = cellId;
            const transaction = currentTimesheet.timeTransactions?.find(tt => tt.id === e.data[e.column.dataField + '_id']);
            if (transaction) {
                hasError = transaction.status.toLowerCase() === 'error' || transaction.status.toLowerCase() === 'rejected';
            }
        }

        if (e.rowType === 'data' && isValid(new Date(e.column.dataField))) {
            if (hasError && e.data[e.column.dataField] !== null) {
                if (e.cellElement && e.cellElement.classList) {
                    e.cellElement.classList.add('transaction-error');
                }
            }
            else if (e.cellElement && e.cellElement.classList) {
                e.cellElement.classList.remove('transaction-error');
            }
        }
    }, [currentTimesheet.timeTransactions]);

    const onCellRender = useCallback((cellData) => {
        let hasError = false;
        let statusComment = '';
        const transaction = currentTimesheet.timeTransactions?.find(tt => tt.id === cellData.data[cellData.column.dataField + '_id']);
        if (transaction) {
            hasError = transaction.status.toLowerCase() === 'error' || transaction.status.toLowerCase() === 'rejected';
            statusComment = transaction.statusComment;
        }
        const cellId = `cell-${cellData.columnIndex}-${cellData.rowIndex}`;
        const buttonCellId = `timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
        const cellAttributes = {
            id: buttonCellId
        };

        const hasAnnotation = hasAnnotations(cellData);
        const tooltipHeaderMessage = cellData.data.status.toLowerCase() === 'error' ? 'Error in time entry' : cellData.data.status.toLowerCase() === 'rejected' ? 'Time entry rejected' : '';

        return (
            <div className={'time-cell-container'}>
                <div className={'time-cell'}>
                    {cellData.value ? parseFloat(cellData.value).toFixed(2) : ''}
                    <Button
                        elementAttr={cellAttributes}
                        hoverStateEnabled={false}
                        sytlingMode="text"
                        icon="fa-solid fa-ellipsis-vertical"
                        width={10}
                    >
                        <TimeCellContextMenu
                            cellId={buttonCellId}
                            cellData={cellData}
                            handleAddNote={handleAddNote}
                            handleUpdateNote={handleUpdateNote}
                            handleDeleteNote={handleDeleteNote}
                            handleViewNote={handleViewNote}
                            handleCellAddTime={handleCellAddTime}
                            handleCellEditTime={handleCellEditTime}
                            handleCellDeleteTime={handleCellDeleteTime}
                            useTimeEntryForm={false}
                        />
                    </Button>
                </div>
                { hasError ?
                    <Tooltip
                        target={`#${cellId}`}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        position="bottom"
                        hideOnOutsideClick={false}
                    >
                        <div className="tooltip-error">
                            <div className="tooltip-error-header"><i className="fa-solid fa-triangle-exclamation" style={{color: '#ff0000', paddingRight: '5px'}} />{tooltipHeaderMessage}</div>
                            <div className="tooltip-error-text">{statusComment}</div>
                        </div>
                    </Tooltip>
                    : null}
                { hasAnnotation ?
                    <div className="annotation-triangle" />
                    : null}
            </div>
        );
    }, [currentTimesheet.timeTransactions]);

    const totalCellRender = useCallback((row) => {
        const { data } = row;
        let totalHours = 0;

        Object.keys(data).forEach((key) => {
            if (isValid(new Date(key))) {
                totalHours += data[key];
            }
        });

        return <div>{parseFloat(totalHours).toFixed(2)}</div>;
    }, []);

    const handleAddNote = async (cellData) => {
        const { data } = cellData;
        const transDate = parseISO(cellData.column.dataField);
        const cellId = `#timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
        const transactionId = data[cellData.column.dataField + '_id'];
        setSelectedTransactionId(transactionId);
        setAnnotationTarget(cellId);
        const noteLabel = `Note for ${format(transDate, 'PPP')}`;
        setNoteSectionLabel(noteLabel);
        setIsNoteReadOnly(false);
        setIsNewNote(true);

        const transactionNoteData = {
            id:             null,
            annotationType: 'TimeTransaction',
            isAttachemnt:   false,
            attachment:     null,
            subject:        null,
            noteText:       null
        };
        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    };

    const handleUpdateNote = async (cellData) => {
        const { data } = cellData;
        const cellId = `#timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
        setAnnotationTarget(cellId);
        const noteLabel = `Note for ${format(parseISO(cellData.column.dataField), 'PPP')}`;
        setNoteSectionLabel(noteLabel);
        const transactionId = data[cellData.column.dataField + '_id'];
        setSelectedTransactionId(transactionId);
        setIsNoteReadOnly(false);
        setIsNewNote(false);
        let transactionNoteData = {};
        const noteColumn = cellData.column.dataField + '_notes';
        if (data[noteColumn] && data[noteColumn].length !== 0) {
            transactionNoteData.id = data[noteColumn][0].id;
            transactionNoteData.annotationType = 'TimeTransaction';
            transactionNoteData.isAttachment = false;
            transactionNoteData.subject = data[noteColumn][0].subject;
            transactionNoteData.noteText = data[noteColumn][0].noteText;
        }
        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    };

    const handleViewNote = async (cellData) => {
        const { data } = cellData;
        const cellId = `#timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
        setAnnotationTarget(cellId);
        const noteLabel = `Note for ${format(parseISO(cellData.column.dataField), 'PPP')}`;
        setNoteSectionLabel(noteLabel);
        const transactionId = data[cellData.column.dataField + '_id'];
        setSelectedTransactionId(transactionId);
        setIsNoteReadOnly(true);

        let transactionNoteData = {};
        const noteColumn = cellData.column.dataField + '_notes';
        if (data[noteColumn] && data[noteColumn].length !== 0) {
            transactionNoteData.id = data[noteColumn][0].id;
            transactionNoteData.annotationType = 'TimeTransaction';
            transactionNoteData.isAttachment = false;
            transactionNoteData.subject = data[noteColumn][0].subject;
            transactionNoteData.noteText = data[noteColumn][0].noteText;
        }
        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    };

    const renderNoteDeleteContent = () => {
        return (
            <>
                <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                    <div>
                        <div className="confirmation-dialog-header">
                            Are you sure you want to delete the note for:
                        </div>
                        <div className="confirmation-note-message" style={{width: '100%'}}>
                            {`${format(parseISO(deleteConfirmationData.column.dataField), 'PPPP')}`}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleDeleteNote = async (cellData) => {
        const { data } = cellData;
        const noteColumn = cellData.column.dataField + '_notes';
        setNoteToDelete(data[noteColumn][0]);
        const title = `Delete note for ${format(parseISO(cellData.column.dataField), 'PPP')}`;
        setDeleteConfirmationData(cellData);
        setDeleteConfirmationTitle(title);
        setIsDeleteNoteConfirmationVisible(true);

    };

    const handleCellAddTime = (cellData) => {
        gridRef.current.instance.editRow(cellData.rowIndex);
        gridRef.current.instance.focus(gridRef.current.instance.getCellElement(cellData.rowIndex, cellData.columnIndex));
    };

    const handleCellEditTime = (cellData) => {
        gridRef.current.instance.editRow(cellData.rowIndex);
        gridRef.current.instance.focus(gridRef.current.instance.getCellElement(cellData.rowIndex, cellData.columnIndex));
    };

    const handleRowDelete = useCallback((e) => {
        const { row } = e;

        setDeleteConfirmationTitle('Delete Time Entries');

        setDeleteConfirmationData({type: 'row', data: row.data});

        switch (row.data.transactionType.toUpperCase()) {
            case 'JOB':
                setDeleteTransactions(buildDeleteList(row.data));
                break;
            case 'SERVICE':
                setDeleteTransactions(buildDeleteList(row.data));
                break;
            case 'UNBILLED':
                setDeleteTransactions(buildDeleteList(row.data));
                break;
            default:
        }

        setIsDeleteConfirmationVisible(true);
    });

    const renderTimeDeleteContent = (e) => {
        if (deleteConfirmationData.type === 'row') {
            const data = deleteConfirmationData.data;
            return buildRowDeleteContent(data);
        }
        else {
            return buildDayDeleteContent(deleteConfirmationData);
        }
    };

    const buildRowDeleteContent = (data) => {
        const transType = data.transactionType.toUpperCase();

        let label = 'Job';
        switch (transType) {
            case 'JOB':
            case 'SERVICE':
                label = transType === 'JOB' ? 'Job' : 'Service Call';
                return (
                    <>
                        <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                            <div>
                                <div className="confirmation-dialog-header">
                                    Are you sure you want to delete entries for:
                                </div>
                                <div className="confirmation-delete-container">
                                    <div className="confirmation-activity-label">
                                        {label}:
                                    </div>
                                    <div className="confirmation-activity-value">
                                        {data.activityName}
                                    </div>
                                    <div className="confirmation-costcode-label">
                                        Cost Code:
                                    </div>
                                    <div className="confirmation-costcode-value">
                                        {data.costcodeAlias} - {data.costcodeDescription}
                                    </div>
                                    <div className="confirmation-paycode-label">
                                        Pay Code:
                                    </div>
                                    <div className="confirmation-paycode-value">
                                        {data.paycodeDescription}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 'UNBILLED':
                return (
                    <>
                        <div className="dialog-message" style={{textAlign: 'top'}}>
                            <div>
                                <div className="confirmation-dialog-header">
                                    Are you sure you want to delete entries for:
                                </div>
                                <div className="confirmation-delete-container">
                                    <div className="confirmation-activity-label">
                                        Unbilled Activity:
                                    </div>
                                    <div className="confirmation-activity-value">
                                        {data.activityName}
                                    </div>
                                    <div className="confirmation-costcode-label">
                                        Pay Code:
                                    </div>
                                    <div className="confirmation-costcode-value">
                                        {data.paycodeDescription}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            default:
                return (
                    <p>Are you sure you want to delete time entries?</p>
                );
        }
    };

    const buildDayDeleteContent = (data) => {
        const hasNotes = data.data.data[data.data.column.dataField + '_notes'];
        return (
            <>
                <div>
                    <div className="confirmation-dialog-header">
                        Are you sure you want to delete the time entry on
                    </div>
                    <div className="confirmation-costcode-value">
                        {`${format(parseISO(data.data.column.dataField), 'PPPP')} for ${data.data.value} hour(s)?`}
                    </div>
                    {
                        hasNotes && hasNotes.length !== 0 ?
                            <div className="confirmation-costcode-value" style={{paddingTop: '10px'}}>
                                This entry has an associated note. Deleting this entry will also delete this note.
                            </div>
                            :
                            null
                    }
                </div>
            </>
        );
    };

    const buildDeleteList = data => {
        const transactionsToDelete = [];

        Object.keys(data).forEach(key => {
            let transDate;

            try {
                transDate = periodDays.find(d => d.formattedDate === key).date;
            }
            catch {
                // do nothing;
            }

            if (isValid(transDate)) {
                if (data[`${key}_id`]) {
                    transactionsToDelete.push({id: data[`${key}_id`], formattedDate: key});
                }
            }
        });

        return transactionsToDelete;
    };

    const handleDeleteNoteConfirmation = async () => {
        if (noteToDelete) {
            const deleted = await deleteAnnotation(activeCompany.id, noteToDelete.id, noteToDelete.isAttachment, t);
            if (deleted) {
                const options = {
                    includeexternalorigindetails: true,
                    companyid:                    activeCompany.id,
                    requesterId:                  currentUser.id
                };
                await fetchEmployeeTimesheet([activeEmployee.id], timesheetStartDate, options);
            }
        }


        setIsDeleteNoteConfirmationVisible(false);
    };

    const handleCellDeleteTime = useCallback((cellData) => {
        const { column, data } = cellData;
        const trxId = data[`${column.dataField}_id`];
        setDeleteConfirmationTitle('Delete Time Entry');
        setDeleteConfirmationData({type: 'single', data: cellData});
        setDeleteTransactions([{id: trxId, formattedDate: column.dataField}]);
        setIsDeleteConfirmationVisible(true);
    }, []);

    const handleDeleteConfirmation = async () => {
        if (deleteTransactions.length !== 0) {
            const promises = [];
            deleteTransactions.forEach(async trx => {
                promises.push(deleteTimeTransactions({id: trx.id, formattedDate: trx.formattedDate}, t));
            });

            await Promise.all(promises.map(p => p))
                .then(async result => {
                    const options = {
                        includeexternalorigindetails: true,
                        companyid:                    activeCompany.id,
                        requesterid:                  currentUser.id
                    };
                    await fetchEmployeeTimesheet([activeEmployee.id], timesheetStartDate, options);
                });

        }

        setDeleteTransactions([]);
        setDeleteConfirmationData();
        setIsDeleteConfirmationVisible(false);
    };

    const handleDeleteDeny =() => {
        setDeleteTransactions([]);
        setIsDeleteConfirmationVisible(false);
        setIsDeleteNoteConfirmationVisible(false);
    };

    const handleAnnotationCancel = () => {
        setIsShowAnnotationForm(false);
        setIsNoteReadOnly(false);
    };

    const customizeTotalFooter = (data) => {
        return data.value ? parseFloat(data.value).toFixed(2) : '';
    };

    const calculateCustomTotalHoursSummary = useCallback((options) => {
        if (options.summaryProcess === 'start') {
            options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
            const { value } = options;

            Object.keys(value).forEach((key) => {
                if (isValid(new Date(key))) {
                    options.totalValue += value[key];
                }
            });
        }
    }, []);

    const renderTotalHoursText = () => {
        return 'Total Hours: ';
    };

    const onNewJobActivityClick = async () => {
        await setNewActivityType('JOB');
        setIsInEditMode(true);
        gridRef.current.instance.addRow();
    };

    const onNewServiceActivityClick = async () => {
        await setNewActivityType('SERVICE');
        setIsInEditMode(true);
        gridRef.current.instance.addRow();
    };

    const onNewUnbilledActivityClick = async () => {
        await setNewActivityType('UNBILLED');
        setIsInEditMode(true);
        gridRef.current.instance.addRow();
    };

    const onInitNewRow = useCallback((e) => {
        e.data.transactionType = newActivityType;
        e.data.status = 'OPEN';
        if (defaultPayCode)
            e.data.paycodeId = {id: defaultPayCode};
    }, [newActivityType, defaultPayCode]);

    const onEditingStart = useCallback((e) => {
        const init = async() => {
            await setIsInEditMode(true);
            const { data } = e;
            switch (data.transactionType.toUpperCase()) {
                case 'JOB':
                    await setNewActivityType('JOB');
                    break;
                case 'SERVICE':
                    await setNewActivityType('SERVICE');
                    break;
                case 'UNBILLED':
                    await setNewActivityType('UNBILLED');
                    break;
                default:
                    await setNewActivityType('');
            }
        };
        init();
    }, [setNewActivityType, setIsInEditMode]);

    const onEditorPreparing = useCallback((e) => {
        const init = async () => {
            if (e.editorName === 'dxNumberBox') {
                e.editorOptions.showSpinButtons = true;
                e.editorOptions.validationMessageMode = 'always';
                e.editorOptions.validationMessagePosition = 'bottom';
            }
        };
        init();
    }, []);

    const onRowPrepared = useCallback((e) => {
        if (e.rowType === 'header') {
            const { cells } = e;
            cells.forEach(cell => {
                if (isValid(parseISO(cell.column.dataField))) {
                    const locale = navigator.language;
                    const transDate = parseISO(cell.column.dataField);
                    const formattedDate = transDate.toLocaleString(locale, {
                        month: '2-digit',
                        day:   '2-digit'
                    });
                    cell.cellElement.className = 'date-header-cell';
                    cell.cellElement.innerHTML = `<div>${format(transDate, 'E')}<br />${formattedDate}<div>`;
                }
                else if (cell.column.name === 'totals') {
                    cell.cellElement.className = 'date-header-cell';
                }
            });
        }
        else if (e.rowType === 'totalFooter')
        {
            const { cells } = e;
            cells.forEach(cell => {
                if (isValid(parseISO(cell.column.dataField))) {
                    cell.cellElement.className = 'total-cell';
                    const subTotal = currentTimesheet?.subTotals?.find(s => s.formattedDate === cell.column.dataField);
                    const innerText = getSubTotalDisplay(subTotal);
                    if (innerText !== '')
                    {
                        const innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
                        cell.cellElement.innerHTML = innerHTML;
                    }
                }
                else if (cell.column.dataField === 'paycodeId.id') {
                    cell.cellElement.className = 'paycode-total-cell';
                    const innerText = `<div class='subtotal-container'><div class='regular-hours'>Regular Hours:</div><div class='other-hours'>Other Hours:</div></div>`;
                    const innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
                    cell.cellElement.innerHTML = innerHTML;
                }
                else if (cell.column.name === 'totals') {
                    cell.cellElement.className = 'total-cell';
                    const innerText = getTotalHoursDisplay(currentTimesheet?.subTotals);
                    const innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
                    cell.cellElement.innerHTML = innerHTML;
                }
                else if (cell.column.name === 'commandColumn') {
                    let totalHours = 0;
                    currentTimesheet?.subTotals?.forEach(s => {
                        totalHours += s.regularHours + s.otherHours;
                    });
                    totalHours = parseFloat(totalHours).toFixed(2);
                    if (totalHours === 0)
                        totalHours = 0.00;

                    cell.cellElement.className += ' total-cell';
                    const innerText = `<div class='subtotal-container'><div class='regular-hours'>Total Hrs</div><div>${totalHours}</div></div>`;
                    cell.cellElement.innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
                }
            });
        }

    }, [currentTimesheet.subTotals]);

    const onRowInserting = useCallback((e) => {
        const init = async () => {
            let result;
            const timesheet = {
                timesheetId:                 currentTimesheet.id,
                timesheetStartDate:          currentTimesheet.timesheetStartDate ? currentTimesheet.timesheetStartDate : timesheetStartDate,
                timesheetFormattedStartDate: currentTimesheet.timesheetFormattedStartDate,
                employeeId:                  currentTimesheet.employeeId,
                transactions:                []
            };

            // must have at least 1 day of time entered.
            Object.keys(e.data).forEach(key => {
                let transDate;
                try {
                    transDate = periodDays.find(d => d.formattedDate === key).date;
                }
                catch (err) {
                    // do nothing
                }

                if (isValid(transDate) && isNumber(e.data[key])) {
                    let record = currentTimesheet.timeTransactions.find(t =>
                        t.transactionType.toUpperCase() === e.data.transactionType.toUpperCase() &&
                        t.activityId.id === e.data.activityId.id &&
                        t.costcodeId?.id === e.data.costcodeId?.id &&
                        t.paycodeId.id === e.data.paycodeId.id &&
                        t.transactionFormattedDate ===key &&
                        t.status.toUpperCase() === e.data.status.toUpperCase()
                    );

                    if (record) {
                        record.activityId = e.data.activityId.id;
                        record.costcodeId = e.data.costcodeId?.id ? e.data.costcodeId.id : null;
                        record.paycodeId = e.data.paycodeId.id;
                        record.equipmentId = e.data.equipmentId?.id ? e.data.equipmentId.id : null;
                        record.employeeId = activeEmployee.id;
                        record.hours += e.data[key];

                        timesheet.transactions.push(record);
                    }
                    else {
                        const timesheetRecord = {
                            transactionId:            null,
                            timesheetId:              e.data.timesheetId ? e.data.timesheetId : timesheet.timesheetId,
                            transactionType:          e.data.transactionType,
                            activityId:               e.data.activityId.id,
                            costcodeId:               e.data.costcodeId?.id ? e.data.costcodeId.id : null,
                            paycodeId:                e.data.paycodeId.id,
                            equipmentId:              e.data.equipment?.id ? e.data.equipment.id : null,
                            hours:                    e.data[key],
                            transactionDate:          key,
                            transactionFormattedDate: key,
                            status:                   e.data.status,
                            statusComment:            e.data.statusComment ? e.data.statusComment : null,
                            description:              e.data.description ? e.data.description : null,
                            timeIn:                   e.data.timeIn ? e.data.timeIn : null,
                            timeOut:                  e.data.timeOut ? e.data.timeOut : null
                        };
                        timesheet.transactions.push(timesheetRecord);
                    }
                }
            });

            if (!isEmpty(timesheet.transactions)) {
                result = await insertTimeSheetTransaction(activeEmployee.id, timesheet, t);
            }
            setIsInEditMode(false);
            return !result.success;
        };
        e.cancel = init();
        setIsInEditMode(false);
    });

    const onRowUpdating = useCallback((e) => {
        const init = async () => {
            const originalTransactions = cloneDeep(currentTimesheet.timeTransactions);

            const {newData, oldData} = e;

            let result;
            const timesheet = {
                timesheetId:                 currentTimesheet.id,
                timesheetStartDate:          currentTimesheet.timesheetStartDate ? currentTimesheet.timesheetStartDate : timesheetStartDate,
                timesheetFormattedStartDate: currentTimesheet.timesheetFormattedStartDate,
                employeeId:                  currentTimesheet.employeeId,
                transactions:                []
            };

            const isActivityUpdated = Object.prototype.hasOwnProperty.call(newData, 'activityId');
            const isCostCodeUpdated = Object.prototype.hasOwnProperty.call(newData, 'costcodeId');
            const isPaycodeUpdated = Object.prototype.hasOwnProperty.call(newData, 'paycodeId');
            const isEquipmentUpdated = Object.prototype.hasOwnProperty.call(newData,'equipmentId');

            if (isActivityUpdated || isCostCodeUpdated || isPaycodeUpdated || isEquipmentUpdated) {
                // changed one of the key segments
                const transactions = originalTransactions.filter(t =>
                    t.transactionType.toUpperCase() === oldData.transactionType.toUpperCase() &&
                    t.activityId.id === oldData.activityId.id &&
                    t.costcodeId?.id === oldData.costcodeId?.id &&
                    t.paycodeId.id === oldData.paycodeId.id &&
                    t.status.toUpperCase() === oldData.status.toUpperCase()
                );
                if (transactions) {
                    transactions.forEach(t => {
                        // update the key segments since at least one has changed
                        t.activityId = isActivityUpdated ? newData.activityId.id : t.activityId ? t.activityId.id : null;
                        t.costcodeId = isCostCodeUpdated ? newData.costcodeId?.id : t.costcodeId ? t.costcodeId.id : null;
                        t.paycodeId = isPaycodeUpdated ? newData.paycodeId.id : t.paycodeId ? t.paycodeId.id : null;
                        t.equipmentId = isEquipmentUpdated ? newData.equipmentId.id : t.equipmentId ? t.equipmentId.id : null;
                        t.employeeId = oldData.employeeId.id;
                        if (t.status.toLowerCase() === 'error' || t.status.toLowerCase() === 'rejected') {
                            t.status = 'OPEN';
                            t.statusComment = '';
                        }
                    });
                    timesheet.transactions = updateRowValues(newData, oldData, transactions);
                }
            }
            else {
                // do the hour changes only here
                let updatedTransactionsList = [];

                Object.keys(newData).forEach(key => {
                    let transDate;
                    let record;
                    try {
                        transDate = periodDays.find(d => d.formattedDate === key).date;
                    }
                    catch (err) {
                        // do nothing
                    }
                    if (isValid(transDate) && isNumber(newData[key])) {
                        record = originalTransactions.find(t =>
                            t.transactionType.toUpperCase() === oldData.transactionType.toUpperCase() &&
                            t.activityId.id === oldData.activityId.id &&
                            t.costcodeId?.id === oldData.costcodeId?.id &&
                            t.paycodeId.id === oldData.paycodeId.id &&
                            t.transactionFormattedDate === key &&
                            t.status.toUpperCase() === oldData.status.toUpperCase()
                        );
                        if (record) {
                            record.activityId = record.activityId.id;
                            record.costcodeId = record.costcodeId && record.costcodeId.id ? record.costcodeId.id : null;
                            record.paycodeId = record.paycodeId.id;
                            record.equipmentId = record.equipmentId && record.equipmentId.id ? record.equipmentId.id : null;
                            record.employeeId = record.employeeId.id;
                            record.hours = newData[key];
                            if (record.status.toLowerCase() === 'error' || record.status.toLowerCase() === 'rejected') {
                                record.status = 'OPEN';
                                record.statusComment = '';
                            }
                            updatedTransactionsList.push(record);
                        }
                        else {
                            record = {
                                id:              null,
                                timesheetId:     oldData.timesheetId,
                                transactionType: oldData.transactionType,
                                transactionDate: key,
                                status:          'OPEN',
                                activityId:      oldData.activityId ? oldData.activityId.id : null,
                                costcodeId:      oldData.costcodeId ? oldData.costcodeId.id : null,
                                paycodeId:       oldData.paycodeId ? oldData.paycodeId.id : null,
                                equipmentId:     oldData.equipmentId ? oldData.equipmentId.id : null,
                                employeeId:      activeEmployee.id,
                                hours:           newData[key]
                            };
                            updatedTransactionsList.push(record);
                        }
                    }
                });

                if (!isEmpty(updatedTransactionsList)) {
                    timesheet.transactions = updatedTransactionsList;
                }
            }

            if (!isEmpty(timesheet.transactions)) {
                result = await insertTimeSheetTransaction(activeEmployee.id, timesheet, t);
                setIsInEditMode(false);
            }
            return !result.success;
        };
        e.cancel = init();
        setIsInEditMode(false);
    });

    function updateRowValues(newData, oldData, originalTransactions) {
        Object.keys(newData).forEach(key => {
            let transDate;
            let record;
            try {
                transDate = periodDays.find(d => d.formattedDate === key).date;
            }
            catch (err) {
                // do nothing
            }
            if (isValid(transDate) && isNumber(newData[key])) {
                const original = currentTimesheet.timeTransactions.find(t =>
                    t.transactionType.toUpperCase() === oldData.transactionType.toUpperCase() &&
                    t.activityId.id === oldData.activityId.id &&
                    t.costcodeId?.id === oldData.costcodeId?.id &&
                    t.paycodeId.id === oldData.paycodeId.id &&
                    isEqual(new Date(t.transactionDate), transDate) &&
                    t.status.toUpperCase() === oldData.status.toUpperCase()
                );

                if (original) {
                    record = originalTransactions.find(o => o.id === original.id);
                }

                if (record) {
                    // these checks are like this because of the new records vs udpated records on the grid have different values for the record
                    record.activityId = newData.activityId ? newData.activityId.id : record.activityId && record.activityId.id ? record.activityId.id : record.activityId ? record.activityId : null;
                    record.costcodeId = newData.costcodeId ? newData.costcodeId.id : record.costcodeId && record.costcodeId.id ? record.costcodeId.id : record.costcodeId ? record.costcodeId : null;
                    record.paycodeId = newData.paycodeId ? newData.paycodeId.id : record.paycodeId && record.paycodeId.id ? record.paycodeId.id : record.paycodeId ? record.paycodeId : null;
                    record.equipmentId = newData.equipmentId ? newData.equipmentId.id : record.equipmentId && record.equipmentId.id ? record.equipmentId.id : record.equipmentId ? record.equipmentId : null;
                    record.employeeId = activeEmployee.id;
                    record[key] = newData[key];
                    record.hours = newData[key];
                    if (record.status.toLowerCase() === 'error' || record.status.toLowerCase() === 'rejected') {
                        record.status = 'OPEN';
                        record.statusComment = '';
                    }
                }
                else {
                    const timesheetRecord = {
                        id:              null,
                        timesheetId:     currentTimesheet?.id,
                        transactionType: newData.transactionType ? newData.transactionType : oldData.transactionType,
                        activityId:      newData.activityId?.id ? newData.actvityId.id : oldData.activityId.id,
                        costcodeId:      newData.costcodeId?.id ? newData.costcodeId.id : oldData.costcodeId?.id ? oldData.costcodeId.id : null,
                        paycodeId:       newData.paycodeId?.id ? newData.paycodeId.id : oldData.paycodeId.id,
                        equipmentId:     newData.equipmentId?.id ? newData.equipmentId.id : oldData.equipmentId?.id ? oldData.equipmentId.id : null,
                        hours:           newData[key],
                        transactionDate: transDate,
                        status:          newData.status ? newData.status : oldData.status,
                        statusComment:   newData.statusComment ? newData.statusComment : oldData.statusComment ? oldData.statusComment : null,
                        description:     newData.description ? newData.description : oldData.description ? oldData.description : null,
                        timeIn:          newData.timeIn ? newData.timeIn : oldData.timeIn ? oldData.timeIn : null,
                        timeOut:         newData.timeOut ? newData.timeOut : oldData.timeOut ? oldData.timeOut : null
                    };
                    originalTransactions.push(timesheetRecord);
                }
            }
        });
        return originalTransactions;
    }

    const onRowValidating = useCallback(async (e) => {
        e.promise = validateRowEntry(e, periodDays, timesheetData)
            .then(result => {
                e.isValid = result.isValid;
                e.errorText = result.errorText;
            });
    }, [periodDays, timesheetData]);

    const validateRowEntry = (e, periodDays, timeTransactions) => {
        let result = {errorText: '', isValid: true};

        const promise = new Promise((resolve, reject) => {
            const isNewRecord = !e.oldData;
            if (!e.isValid) {
                result.isValid = false;
                result.errorText = 'Please fix validation errors';
            }
            if (e.isValid) {
                // check that at least one day has hours entered
                let hasHours = false;
                let exceeds24Hours = false;
                let periodDaysHours = cloneDeep(periodDays).map(pd => {
                    return {
                        formattedDate:  pd.formattedDate,
                        exceeds24Hours: false
                    };
                });
                let hasHoursChanged = false;
                Object.keys(e.newData).forEach(key => {
                    if (isValid(new Date(key))) {
                        hasHoursChanged = true;
                    }
                });

                if (hasHoursChanged) {
                    Object.keys(e.newData).forEach(key => {
                        const transactionId = e.key[`${key}_id`];
                        if (!hasHours) {
                            let transDate;
                            try {
                                transDate = periodDays.find(d => d.formattedDate === key).date;
                            }
                            catch (err) {
                                // do nothing
                            }
                            if (isValid(transDate)) {
                                hasHours = true;
                                const transactions = cloneDeep(timeTransactions).filter(t =>
                                    t.transactionFormattedDate === key && t.id !== transactionId
                                );
                                if (transactions) {
                                    let totalHours = e.newData[key];
                                    transactions.forEach(t => {
                                        totalHours += t.hours;
                                    });
                                    exceeds24Hours = totalHours > 24;
                                    if (exceeds24Hours) {
                                        const record = periodDaysHours.find(p => p.formattedDate === key);
                                        if (record) {
                                            record.exceeds24Hours = true;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }

                if ((hasHoursChanged && !hasHours) || (isNewRecord && !hasHours)) {
                    result.isValid = false;
                    result.errorText = 'Please enter hours for at least 1 day of the period';
                }
                if (exceeds24Hours) {
                    result.isValid = false;
                    const badDays = periodDaysHours.filter(pd => pd.exceeds24Hours);
                    badDays.forEach(d => {
                        const message = `Total hours for ${format(new Date(d.formattedDate), 'PPPP')} exceeds 24 hours`;
                        result.errorText = message;
                    });
                }
            }
            setIsSaveValid(result.isValid);
            resolve(result);
        });
        return promise;
    };

    const getSubTotalDisplay = (subTotal) => {
        if (subTotal) {
            let regularHours = subTotal.regularHours ? parseFloat(subTotal.regularHours).toFixed(2) : 0.00;
            let otherHours = subTotal.otherHours ? parseFloat(subTotal.otherHours).toFixed(2) : 0.00;
            if (regularHours === 0) {
                regularHours = '0.00';
            }

            if (otherHours === 0) {
                otherHours = '0.00';
            }

            const innerHTML = `<div class='subtotal-container'><div class='regular-hours'>${regularHours}</div><div class='other-hours'>${otherHours}</div></div>`;
            return innerHTML;
        }
        else {
            return '';
        }
    };

    const getTotalHoursDisplay = (subTotals) => {
        let regularTotal = 0;
        let otherTotal = 0;

        subTotals?.forEach(t => {
            regularTotal += t.regularHours;
            otherTotal += t.otherHours;
        });

        if (regularTotal === 0) {
            regularTotal = '0.00';
        }
        else {
            regularTotal = parseFloat(regularTotal).toFixed(2);
        }

        if (otherTotal === 0) {
            otherTotal = '0.00';
        }
        else {
            otherTotal = parseFloat(otherTotal).toFixed(2);
        }

        const innerHTML = `<div class='subtotal-container'><div class='regular-hours'>${regularTotal}</div><div class='other-hours'>${otherTotal}</div></div>`;
        return innerHTML;
    };

    const setJobActivityCellValue = useCallback((rowData, value) => {
        rowData.activityId = { id: value };

        const ccValue = gridRef.current.instance.cellValue(0, 'costcodeId.id');

        if (value) {
            return fetchJobCostCodes(value).then(codes => {
                if (codes.length === 1) {
                    rowData.costcodeId = {id: codes[0].id};
                }
                else {
                    if (ccValue)
                        rowData.costcodeId = null;
                }
            });
        }
        else {
            if (ccValue)
                rowData.costcodeId = null;
        }
    }, [fetchJobCostCodes]);

    const setServiceActivityCellValue = useCallback((rowData, value) => {
        rowData.activityId = { id: value };

        const ccValue = gridRef.current.instance.cellValue(0, 'costcodeId.id');

        if (value) {
            if (workOrderLaborCostCodes.length === 1) {
                rowData.costcodeId = { id: workOrderLaborCostCodes[0].id };
            }
            else {
                if (ccValue)
                    rowData.costcodeId = null;
            }
        }
        else {
            if (ccValue)
                rowData.costcodeId = null;
        }
    });

    const setUnbilledActivityCellValue = useCallback((rowData, value) => {
        rowData.activityId = { id: value };
        rowData.costcodeId = null;
    });

    function setCostCodeCellValue(rowData, value) {
        this.defaultSetCellValue(rowData, value);
    }

    function setPayCodeCellValue(rowData, value) {
        this.defaultSetCellValue(rowData, value);
    }

    function fetchActivityLookups(options) {
        if (options.data && options.data.transactionType.toUpperCase() === 'JOB') {
            const jobStore = new CustomStore({
                key:      'id',
                loadMode: 'raw',
                load:     () => {
                    return jobList;
                }
            });
            return jobStore;
        }
        else if (options.data && options.data.transactionType.toUpperCase() === 'SERVICE') {
            const serviceStore = new CustomStore({
                key:      'id',
                loadMode: 'raw',
                load:     () => {
                    return workOrderList;
                }
            });
            return serviceStore;
        }
        else if (options.data && options.data.transactionType.toUpperCase() === 'UNBILLED') {
            const unbilledActivitiesStore = new CustomStore({
                key:      'id',
                loadMode: 'raw',
                load:     () => {
                    return unbilledActivities;
                }
            });
            return unbilledActivitiesStore;        }
        else {
            return [];

        }
    }

    function fetchCostCodesLookup(options) {
        if (options.data && options.data.activityId && options.data.transactionType.toUpperCase() === 'JOB') {
            const jobStore = new CustomStore({
                key:      'id',
                loadMode: 'raw',
                load:     () => {
                    return options.data.activityId.id ? fetchJobCostCodes(options.data.activityId.id) : [];
                }
            });
            return jobStore;
        }
        else if (options.data && options.data.activityId && options.data.transactionType.toUpperCase() === 'SERVICE') {
            const serviceStore = new CustomStore({
                key:      'id',
                loadMode: 'raw',
                load:     () => {
                    return workOrderLaborCostCodes;
                }
            });
            return serviceStore;
        }
        else if (options.data && options.data.activityId && options.data.transactionType.toUpperCase()=== 'UNBILLED') {
            return [];
        }
        else {
            return [];
        }
    }

    function fetchEmployeePayCodesLookup(options) {
        const store = new CustomStore({
            key:      'paycodeId',
            loadMode: 'raw',
            load:     () => {
                return employeePayCodeLookup; //.filter(pc => pc.payType !== 5);
            }
        });
        return store;
    }

    const onEditCanceled = e => {
        setIsInEditMode(false);
        setNewActivityType('');
    };

    return (
        <>
            { isInRefresh ?
                <LoadingMask />
                :
                <DataGrid
                    ref={gridRef}
                    height={'calc(100vh - 240px'}
                    dataSource={gridData}
                    //columnHidingEnabled={true}
                    columnMinWidth={50}
                    allowColumnResizing={true}
                    showBorders={true}
                    showColumnLines={true}
                    noDataText={'Create a new timesheet'}
                    rowAlternationEnabled={true}
                    //onAdaptiveDetailRowPreparing={onAdaptiveDetailRowPreparing}
                    onCellPrepared={onCellPrepared}
                    onEditCanceled={onEditCanceled}
                    onEditingStart={onEditingStart}
                    onEditorPreparing={onEditorPreparing}
                    onInitNewRow={onInitNewRow}
                    onRowInserting={onRowInserting}
                    onRowPrepared={onRowPrepared}
                    onRowUpdating={onRowUpdating}
                    onRowValidating={onRowValidating}
                    renderAsync={false}
                >
                    <LoadPanel
                        enabled={true}
                    />
                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                    />
                    <ColumnFixing enabled={true} />
                    <Column
                        dataField={'activityId.id'}
                        caption={'Activity'}
                        cellRender={renderActivityCell}
                        editCellComponent={ActivityLookup}
                        minWidth={250}
                        setCellValue={
                            newActivityType === 'JOB' ? setJobActivityCellValue :
                                newActivityType === 'SERVICE' ? setServiceActivityCellValue :
                                    newActivityType === 'UNBILLED' ? setUnbilledActivityCellValue :
                                        setJobActivityCellValue
                        }
                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={fetchActivityLookups}
                            valueExpr={'id'}
                            displayExpr={'activityId'}
                        />
                    </Column>
                    <Column
                        dataField={'costcodeId.id'}
                        caption={'Cost Code'}
                        cellRender={renderCostCodeCell}
                        width={250}
                        //hidingPriority={10}
                        setCellValue={setCostCodeCellValue}
                        editCellComponent={CostCodeLookup}
                    >
                        { newActivityType === 'JOB' || newActivityType === 'SERVICE' ?
                            <RequiredRule />
                            : null
                        }
                        <Lookup
                            dataSource={fetchCostCodesLookup}
                            displayExpr={newActivityType && newActivityType.toUpperCase() === 'SERVICE' ? 'costCodeAlias' : 'description'}
                            valueExpr={'id'}
                        />
                    </Column>
                    <Column
                        dataField={'paycodeId.id'}
                        caption={'Pay Code'}
                        cellRender={renderPayCodeCell}
                        width={200}
                        editCellComponent={PayCodeLookup}
                        setCellValue={setPayCodeCellValue}
                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={fetchEmployeePayCodesLookup}
                            displayExpr={'paycodeDescription'}
                            valueExpr={'paycodeId'}
                        />
                    </Column>
                    <Column
                        dataField={'equimentId'}
                        caption={'Equipment'}
                        visible={false}
                    />
                    {
                        periodDays.map((period, index) => (
                            <Column
                                key={index}
                                dataField={period.formattedDate}
                                width={90}
                                alignment="left"
                                cellRender={onCellRender}
                                dataType="number"
                            >
                                <NumericRule />
                                <RangeRule
                                    min={-24}
                                    max={24}
                                    message={'You may not enter more than 24 hours'}
                                />
                            </Column>
                        ))
                    }
                    <Column
                        dataField={'transactionType'}
                        visible={false}
                        showInColumnChooser={false}
                    />
                    <Column
                        dataField={'transactionId'}
                        visible={false}
                        showInColumnChooser={false}
                    />
                    <Column
                        dataField={'timesheetId'}
                        visible={false}
                        showInColumnChooser={false}
                    />
                    <Column
                        name="totals"
                        caption={'Total'}
                        width={75}
                        alignment={'right'}
                        cellRender={totalCellRender}
                    />
                    <Column
                        caption={'Actions'}
                        name="commandColumn"
                        type="buttons"
                        width={75}
                        allowColumnResizing={false}
                        allowReordering={false}
                    >
                        <GridButton
                            name="edit"
                            hint="Edit Activity"
                            icon="fa-solid fa-pencil"
                            visible={isEditingAllowed}
                        />
                        <GridButton
                            name="delete"
                            hint="Delete Activity"
                            icon="fa-regular fa-trash-can"
                            visible={isDeletingAllowed}
                            onClick={handleRowDelete}
                        />
                    </Column>
                    <Summary
                        calculateCustomSummary={calculateCustomTotalHoursSummary}
                        recalculateWhileEdting={true}
                    >
                        <TotalItem
                            column={'paycodeId.id'}
                            summaryType="count"
                            customizeText={renderTotalHoursText}
                            alignment="right"
                        />
                        <TotalItem
                            column={periodDays[0]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            column={periodDays[1]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            column={periodDays[2]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            column={periodDays[3]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            column={periodDays[4]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            column={periodDays[5]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            column={periodDays[6]?.formattedDate}
                            summaryType="sum"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            summaryType="custom"
                            name="totalPeriodHours"
                            showInColumn="totals"
                            customizeText={customizeTotalFooter}
                        />
                        <TotalItem
                            summaryType="custom"
                            name="totalPeriodHours1"
                            showInColumn="commandColumn"
                            customizeText={customizeTotalFooter}
                        />
                    </Summary>
                </DataGrid>
            }
            {!isInEditMode ?
                <>
                    <SpeedDialAction
                        icon="fa-regular fa-building"
                        label={'Add Job Activity'}
                        index={0}
                        onClick={onNewJobActivityClick}
                    />
                    <SpeedDialAction
                        icon="fa-solid fa-hammer"
                        label={'Add Service Activity'}
                        index={1}
                        onClick={onNewServiceActivityClick}
                    />
                    <SpeedDialAction
                        icon="fa-regular fa-money-bill-1"
                        label={'Add Unbilled Activity'}
                        index={2}
                        onClick={onNewUnbilledActivityClick}
                    />
                </>
                :
                <>
                    <SpeedDialAction
                        icon="fa-regular fa-floppy-disk"
                        label="Save Record"
                        index={3}
                        onClick={() => {gridRef.current.instance.saveEditData();}}
                    />
                    <SpeedDialAction
                        icon="fa-solid fa-rotate-left"
                        label="Cancel Update"
                        index={4}
                        onClick={() => {gridRef.current.instance.cancelEditData(); setIsInEditMode(false);}}
                    />
                </>
            }
            { isDeleteConfirmationVisible ?
                <ConfirmationDialog
                    showDialog={isDeleteConfirmationVisible}
                    confirmationTitle={deleteConfirmationTitle}
                    confirmButtonAction={handleDeleteConfirmation}
                    confirmButtonText="Yes"
                    denyButtonAction={handleDeleteDeny}
                    denyButtonText="No"
                    renderContent={renderTimeDeleteContent}
                    height={300}
                    width={500}
                />
                : null
            }
            {
                isDeleteNoteConfirmationVisible ?
                    <ConfirmationDialog
                        showDialog={isDeleteNoteConfirmationVisible}
                        confirmationTitle={deleteConfirmationTitle}
                        confirmButtonAction={handleDeleteNoteConfirmation}
                        confirmButtonText="Yes"
                        denyButtonAction={handleDeleteDeny}
                        denyButtonText="No"
                        renderContent={renderNoteDeleteContent}
                        height={300}
                        width={500}
                    />
                    : null
            }
            {
                isShowAnnotationForm ?
                    <AnnotationForm
                        activeEmployee={activeEmployee}
                        annotationType={AnnotationTypes.TIMETRANSACTION}
                        cancelButtonAction={handleAnnotationCancel}
                        handleAddNote={handleAddNote}
                        isNoteReadOnly={isNoteReadOnly}
                        isNewNote={isNewNote}
                        noteData={noteData}
                        noteSectionLabel={noteSectionLabel}
                        objectId={selectedTransactionId}
                        setIsShowAnnotationForm={setIsShowAnnotationForm}
                        showAnnotationForm={isShowAnnotationForm}
                        showAttachmentForm={false}
                        showNoteForm={true}
                        target={annotationTarget}
                        title={'Annotations'}
                    />
                    : null
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        activeCompany:         state.time.activeCompany,
        activeEmployee:        state.employee.activeEmployee,
        currentTimesheet:      state.time.currentTimesheet,
        currentUser:           state.currentUser,
        currentWeekEndingDate: state.time.currentWeekEndingDate,
        employeePayCodes:      state.employee.employeePayCodes,
        isInEditMode:          state.time.isInEditMode,
        isInRefresh:           state.time.isInRefresh,
        isSaveValid:           state.time.isSaveValid,
        jobList:               state.job.jobs,
        periodDays:            state.time.periodDays,
        timesheetData:         state.time.timesheetData,
        timesheetStartDate:    state.time.timesheetStartDate,
        unbilledActivities:    state.activities.unbilledActivities,
        workOrderCostCodes:    state.workOrder.costCodes,
        workOrderList:         state.workOrder.workOrders
    };
};

const mapDispatchToProps = {
    ...AnnotationActions,
    ...EmployeeActions,
    ...JobActions,
    ...WorkOrderActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryTable);
