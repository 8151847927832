import { Flex, Text, useTheme } from '@aws-amplify/ui-react';
import getYear from 'date-fns/getYear';

export function Footer() {
    const { tokens } = useTheme();

    return (
        <Flex justifyContent="center" padding={tokens.space.medium}>
            <Text>Copyright &copy; {getYear(new Date())} WennSoft, Inc. All rights reserved.</Text>
        </Flex>
    );
}
