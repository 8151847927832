import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataSource from 'devextreme/data/data_source';
import ScrollView from 'devextreme-react/scroll-view';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../utils/media-query';
import { ProfileCard } from '../../components/profile-card/ProfileCard';
import { withLoadPanel } from '../../utils/withLoadPanel';
import { service } from '../../data/company-settings-service';
import devices from 'devextreme/core/devices';
import { getTimeOnlyValue } from '../../helpers/timeHelpers';

import * as TimeActions from '../../actions/timeActions';

import './CompanySettings.scss';

const CompanySettingsContent = ({
    activeCompany,
    currentUser,
    companySettingsInfoItems,
    emailInfoItems,
    employeeLaborPayCodes,
    fsmIntegrationInfoItems,
    handleContentScrolled,
    handleDataChanged,
    settingsData,
    scrollViewRef,
    wennsoftIntegrationItems
}) => {
    const { t } = useTranslation();
    const { isLarge } = useScreenSize();

    const onScroll = useCallback(reachedTop => {
        handleContentScrolled(reachedTop);
    }, [handleContentScrolled]);

    const columnCount = useMemo(() => {
        if (isLarge) {
            return 4;
        }
        return 2;
    }, [isLarge]);


    return (
        <ScrollView
            ref={scrollViewRef}
            className="view-wrapper-scroll"
            height="calc(100% - 175px)"
            onScroll={onScroll}
            showScrollbar="always"
            useNative={true}
        >
            <div className="cards-container">
                <ProfileCard
                    cardData={settingsData}
                    colCount={columnCount}
                    currentUser={currentUser}
                    employeeLaborPayCodes={employeeLaborPayCodes}
                    items={companySettingsInfoItems}
                    onDataChanged={handleDataChanged}
                    title={t('companySettings.companySettings')}
                    wrapperCssClass="profile-card company-settings-card"
                >
                    <div className="profile-card-top-item">
                        <div className="image-wrapper">
                            <i className="dx-icon dx-icon-preferences" />
                        </div>
                        <div className="title-text">
                            {`${activeCompany?.name} ${t('common.settings')}`}
                        </div>
                    </div>
                </ProfileCard>
                <ProfileCard
                    cardData={settingsData}
                    currentUser={currentUser}
                    colCount={2}
                    items={emailInfoItems}
                    onDataChanged={handleDataChanged}
                    title={t('companySettings.adminEmail')}
                    wrapperCssClass="profile-card email-settings-card"
                >
                    <div className="profile-card-top-item">
                        <div className="image-wrapper">
                            <i className="dx-icon dx-icon-mention" />
                        </div>
                        <div>
                            <div className="title-text">
                                {t('companySettings.emailAdminNotification')}
                            </div>
                            <div className="subtitle-text">{t('companySettings.adminEmailSent')}</div>
                        </div>
                    </div>
                </ProfileCard>
                <ProfileCard
                    cardData={settingsData}
                    colCount={2}
                    items={fsmIntegrationInfoItems}
                    title={t('companySettings.signaturePanelLabel')}
                    currentUser={currentUser}
                    wrapperCssClass="profile-card fsm-settings-card"
                >
                    <div className="profile-card-top-item">
                        <div className="image-wrapper">
                            <i className="dx-icon dx-icon-info" />
                        </div>
                        <div>
                            <div className="title-text">{`Signature ${t('menu.settings')}`}</div>
                            <div className="subtitle-text">{`${t('companySettings.settingsAreSet')} Signature`}</div>
                        </div>
                    </div>
                </ProfileCard>
                <ProfileCard
                    currentUser={currentUser}
                    wrapperCssClass="profile-card wennsoft-settings-card"
                    title="WennSoft Settings"
                    colCount={2}
                    cardData={settingsData}
                    items={wennsoftIntegrationItems}
                    onDataChanged={handleDataChanged}
                >
                    <div className="profile-card-top-item">
                        <div className="image-wrapper">
                            <i className="dx-icon dx-icon-lock" />
                        </div>
                        <div>
                            <div className="title-text">{`WennSoft ${t('companySettings.controlled')}`}</div>
                            <div className="subtitle-text">{`${t('companySettings.settingsControlledBy')} WennSoft`}</div>
                        </div>
                    </div>
                </ProfileCard>
            </div>
        </ScrollView>
    );
};

const defaultSettings = {
    defaultPaycodeId:                  null,
    requireManagerApproval:            false,
    useShiftCode:                      false,
    timeEntryNotifyDay:                'Off',
    timeEntryNotifyTime:               '00:00:00',
    approvalNotifyDay:                 'Off',
    approvalNotifyTime:                '00:00:00',
    adminEmailNotifications:           [],
    useTransactionAcknowledgement:     false,
    transactionAcknowledgementMessage: null,
    useTimeInTimeOut:                  false,
    lockTimeInTimeOut:                 false
};

const CompanySettingsContentWithLoadPanel = withLoadPanel(CompanySettingsContent);

const CompanySettings = ({
    activeCompany,
    companyPayCodes,
    currentUser,
    featureFlags,
    fetchTimeSettings,
    settings,
    updateTimeSettings,
}) => {
    const { t } = useTranslation();

    const [companySettingsData, setCompanySettingsData] = useState();
    // const [defaultCompanySettings, setDefaultCompanySettings] = useState(defaultSettings);
    const [savedCompanyData, setSavedCompanyData] = useState();
    const [companySettingsItems, setCompanySettingsItems] = useState([]);
    const [emailInfoItems, setEmailInfoItems] = useState([]);
    const [fsmIntegrationInfoItems, setFsmIntegrationInfoItems] = useState([]);
    const [wennsoftIntegrationItems, setWennsoftIntegrationItems] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isContentScrolled, setIsContentScrolled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [employeeLaborPayCodes, setEmployeeLaborPayCodes] = useState([]);

    const scrollViewRef = useRef();

    const defaultCompanySettings = useRef(defaultSettings);
    const setDefaultCompanySettings = data => {
        defaultCompanySettings.current = data;
    };

    const companySettingsDataRef = useRef();
    const setCompanySettingsDataRef = data => {
        companySettingsDataRef.current = data;
    };

    const dataChanged = useCallback((data) => {
        setCompanySettingsDataRef(data.cardData);
        setIsDataChanged(true);
    }, []);

    const handleContentScrolled = useCallback((reachedTop) => {
        setIsContentScrolled(!reachedTop);
    }, []);

    const setSavedData = useCallback((data = companySettingsData) => {
        setSavedCompanyData(data);
    }, [companySettingsData]);

    const onCancel = useCallback(() => {
        setCompanySettingsDataRef(cloneDeep(defaultCompanySettings.current));
        setCompanySettingsData(cloneDeep(defaultCompanySettings.current));
        setSavedData();
        setIsDataChanged(false);
    }, [defaultCompanySettings, setSavedData]);

    const onSave = useCallback(async () => {
        // make api call to save the data using the savedCompanyData
        let body = cloneDeep(settings);
        let allowSave = true;

        if (!isEqual(defaultCompanySettings, companySettingsDataRef.current)) {
            body.requireManagerApproval = companySettingsDataRef.current.requireManagerApproval;
            body.defaultPaycodeId = companySettingsDataRef.current.defaultPaycodeId;
            body.useShiftCode = companySettingsDataRef.current.useShiftCode;
            body.timeEntryNotifyDay = companySettingsDataRef.current.timeEntryNotifyDay;
            body.approvalNotifyDay = companySettingsDataRef.current.approvalNotifyDay;
            body.useTransactionAcknowledgement = companySettingsDataRef.current.useTransactionAcknowledgement;
            body.transactionAcknowledgementMessage = companySettingsDataRef.current.transactionAcknowledgementMessage;
            body.useTimeInTimeOut = companySettingsDataRef.current.useTimeInTimeOut;
            body.lockTimeInTimeOut = companySettingsDataRef.current.lockTimeInTimeOut;
            body.registeredTimeEmployeeCount = companySettingsDataRef.current.registeredTimeEmployeeCount;
            body.adminEmailNotifications = companySettingsDataRef.current.adminEmailNotifications;
            if (companySettingsDataRef.current.useTransactionAcknowledgement && (!companySettingsDataRef.current.transactionAcknowledgementMessage || companySettingsDataRef.current.transactionAcknowledgementMessage.length === 0)) {
                allowSave = false;
                alert('When using acknowledgements, you must enter a message.', 'Acknowledgement Message');
            }
        }

        body.timeEntryNotifyTime = getTimeOnlyValue(companySettingsDataRef.current.timeEntryNotifyTime, true);
        body.approvalNotifyTime = getTimeOnlyValue(companySettingsDataRef.current.approvalNotifyTime, true);

        if (allowSave) {
            setIsLoading(true);
            await updateTimeSettings(settings.id, body, t);

            setIsDataChanged(false);
            setIsLoading(false);
        }

        setSavedData();
    }, [savedCompanyData, setSavedData, settings, defaultCompanySettings]);

    useEffect(() => {

        const settingsPromise = fetchTimeSettings();

        settingsPromise.then(data => {
            setCompanySettingsData(data);
            setSavedCompanyData(cloneDeep(data));
            setDefaultCompanySettings(cloneDeep(data));
        });

        Promise.all([
            settingsPromise
        ]).then((settings) => {
            setDefaultCompanySettings(cloneDeep(settings[0]));
            const laborCodes = companyPayCodes?.filter(pc => pc.payType !== 5);
            const groupedData = new DataSource({
                store: laborCodes,
                key:   'id',
                group: [{selector: 'groupTypeOrder', desc: false}]
            });
            setEmployeeLaborPayCodes(groupedData);

            setIsLoading(false);
        });

        let companyItems = service.getCompanySettingsInfoItems(devices.current().isPhone, t);
        const timeInOutFeatureFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');
        if (timeInOutFeatureFlag && !timeInOutFeatureFlag.featureFl) {
            companyItems = companyItems.filter(ci => ci.feature !== 'timeInOut');
        }
        setCompanySettingsItems(companyItems);
        setEmailInfoItems(service.getEmailInfoItems(t));
        setFsmIntegrationInfoItems(service.getFsmInfoItems(t));
        setWennsoftIntegrationItems(service.getWennsoftInfoItems(t));

    }, []);

    const cancelButtonElementAttr = {
        class: 'cancel-button'
    };

    const cancelOptions = {
        elementAttr: cancelButtonElementAttr,
        text:        t('common.cancel'),
        disabled:    !isDataChanged,
        stylingMode: 'outlined',
        type:        'normal',
        onClick:     onCancel
    };

    const saveButtonElementAttr = {
        class: 'save-button'
    };

    const saveOptions = {
        elementAttr: saveButtonElementAttr,
        disabled:    !isDataChanged,
        text:        t('common.save'),
        icon:        'save',
        type:        'default',
        stylingMode: 'contained',
        onClick:     onSave
    };

    return (
        <div className="view-host company-settings">
            <div className="view-wrapper">
                <Toolbar className={`theme-dependent ${isContentScrolled} ? 'scrolled' : ''`}>
                    <Item location="before">
                        <div className="header-text">{t('companySettings.timeSettings')}</div>
                    </Item>
                    <Item
                        location="after"
                        locateInMenu={devices.current().phone ? 'always' : 'auto'}
                        widget="dxButton"
                        options={cancelOptions}
                    />
                    <Item
                        location="after"
                        locateInMenu={devices.current().phone ? 'always' : 'auto'}
                        widget="dxButton"
                        options={saveOptions}
                    />
                </Toolbar>
                <CompanySettingsContentWithLoadPanel
                    activeCompany={activeCompany}
                    currentUser={currentUser}
                    companySettingsInfoItems={companySettingsItems}
                    emailInfoItems={emailInfoItems}
                    employeeLaborPayCodes={employeeLaborPayCodes}
                    fsmIntegrationInfoItems={fsmIntegrationInfoItems}
                    wennsoftIntegrationItems={wennsoftIntegrationItems}
                    handleContentScrolled={handleContentScrolled}
                    handleDataChanged={dataChanged}
                    hasData={!isLoading}
                    loading={isLoading}
                    panelProps={{
                        container: '.view-wrapper',
                        position:  { of: '.content' }
                    }}
                    settingsData={companySettingsData}
                    scrollViewRef={scrollViewRef}
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCompany:   state.time.activeCompany,
        companyPayCodes: state.time.companyPayCodes,
        currentUser:     state.currentUser,
        featureFlags:    state.core.featureFlags,
        settings:        state.time.settings
    };
};

const mapDispatchToProps = {
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings);


