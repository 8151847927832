import React, { useCallback, useEffect, useState } from 'react';
import Form, { Item as FormItem, Label } from 'devextreme-react/form';
import TextArea from 'devextreme-react/text-area';
import { Validator, RequiredRule, StringLengthRule } from 'devextreme-react/validator';
import { useTranslation } from 'react-i18next';

const TimeRejectionForm = ({
    formData,
    handleRejectionChange,
    validationGroup
}) => {
    const { t } = useTranslation();
    const [rejectReason, setRejectReason] = useState();

    useEffect(() => {
        if (formData && formData.statusComment) {
            setRejectReason(formData?.statusComment);
        }
        else {
            setRejectReason();
        }
    }, [formData]);

    const handleValueChanged = useCallback((e) => {
        if (e.event && e.event.type === 'keyup') {
            const statusCommentField = 'statusComment';
            const newData = {...formData, ...{[statusCommentField]: e.value}};
            handleRejectionChange(newData);
        }
    }, []);

    return (
        <Form
            formData={formData}
        >
            <FormItem
                isRequired
            >
                <Label text={t('time.rejectReason')} />
                <TextArea
                    height="100px"
                    maxLength={250}
                    onValueChanged={handleValueChanged}
                    value={rejectReason}
                    valueChangeEvent="keyup"
                >
                    <Validator>
                        <RequiredRule />
                        <StringLengthRule message="Reason may not exceed 250 characters" max={250} />
                    </Validator>
                </TextArea>
            </FormItem>
        </Form>

    );
};

export default TimeRejectionForm;
