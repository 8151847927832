import axios from 'axios';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import { setupCache } from 'axios-cache-adapter';

import { uniq } from 'lodash';

import { dropUuids, processKey, flushCache, DEFAULT_CACHE_TIME } from './cacheHelpers';

import cognitoData from '../awsConfig';

// Register the custom `memoryDriver` to `localforage`
localforage.defineDriver(memoryDriver);

// Create `localforage` instance
const localforageStore = localforage.createInstance({
    // List of drivers used
    driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        memoryDriver._driver
    ],
    // Prefix all storage keys to prevent conflicts
    name: 'k2a-core-cache'
});

// Create normal `axios-cache-adapter` instance
const cache = setupCache({
    maxAge:  DEFAULT_CACHE_TIME,
    store:   localforageStore,
    exclude: {
        query: false,
    },
    invalidate: async (config, request) => {
        const verb = request.method.toLowerCase();
        const willFlush = flushCache();

        if (verb !== 'get' || willFlush) {
            await invalidateCache(config, request);
        }
    }
});

// Create `axios-cache-adapter` instance that ignores cache
const noCache = setupCache({
    maxAge:  DEFAULT_CACHE_TIME,
    store:   localforageStore,
    exclude: {
        query: false,
    },
    ignoreCache: true,
    invalidate:  async (config, request) => {
        const verb = request.method.toLowerCase();

        if (verb !== 'get') {
            await invalidateCache(config, request);
        }
    }
});

const invalidateCache = async (config, request) => {
    let rawRequestKeys;
    // The request URL of the given endpoint is the value of config.uuid
    // Here we split a few times to get at the keywords of the request minus the base URL and query string parameters
    if (config.uuid.includes(cognitoData.key2act.applications.apipublic.url)) {
        rawRequestKeys = config.uuid.split(cognitoData.key2act.applications.apipublic.url)[1].split('?')[0].split('/');
    } else {
        rawRequestKeys = config.uuid.split('?')[0].split('/');
    }
    // Create an array of unique keywords that do not include UUIDs or empty strings
    const requestKeys = uniq(dropUuids(rawRequestKeys)).filter(key => key !== '');

    // Get the keys of the current IndexedDB cache
    const keys = await localforageStore.keys();

    keys.forEach(key => {
        const union = processKey(cognitoData.key2act.applications.apipublic.url, key, requestKeys);

        // Search for likely cached versions of the corresponding GET and remove.
        // Note: WILL remove similarly structured caches
        if (union.length >= 1) {
            config.store.removeItem(key);
        }
    });

    await config.store.removeItem(config.uuid);
};

export const k2aBaseCacheApi = axios.create({
    adapter: cache.adapter,
    baseURL: cognitoData.key2act.applications.apipublic.url
});

export default axios.create({
    adapter: noCache.adapter,
    baseURL: cognitoData.key2act.applications.apipublic.url
});

export const wennsoftBlobDownloadResult = axios.create({
    adapter:      noCache.adapter,
    baseURL:      cognitoData.key2act.applications.apipublic.url,
    responseType: 'blob'
});

export const wennsoftResultBaseApi = axios.create({
    adapter:        noCache.adapter,
    baseURL:        cognitoData.key2act.applications.apipublic.url,
    validateStatus: status => status < 500
});
