export const notificationDays = [
    {
        id: 'Off',
        en: 'Off',
        es: 'Apagada',
    },
    {
        id: 'Sunday',
        en: 'Sunday',
        es: 'Domingo'
    },
    {
        id: 'Monday',
        en: 'Monday',
        es: 'Lunes'
    },
    {
        id: 'Tuesday',
        en: 'Tuesday',
        es: 'Martes'
    },
    {
        id: 'Wednesday',
        en: 'Wednesday',
        es: 'Miércoles'
    },
    {
        id: 'Thursday',
        en: 'Thursday',
        es: 'Jueves'
    },
    {
        id: 'Friday',
        en: 'Friday',
        es: 'Viernes'
    },
    {
        id: 'Saturday',
        en: 'Saturday',
        es: 'Sábado'
    }
];

export const service = {
    getCompanySettingsInfoItems (isPhone, t) {
        return [
            {
                dataField:  'requireManagerApproval',
                colSpan:    2,
                label:      t('companySettings.requireManagerApproval'),
                labelWidth: 150,
                editorType: 'dxSwitch'
            },
            {
                dataField:  'useShiftCode',
                colSpan:    2,
                label:      t('companySettings.useShiftCodes'),
                labelWidth: 150,
                editorType: 'dxSwitch'
            },
            {
                dataField:  'useTimeInTimeOut',
                colSpan:    2,
                label:      t('companySettings.useTimeInOut'),
                editorType: 'dxSwitch',
                feature:    'timeInOut'
            },
            {
                dataField:  'lockTimeInTimeOut',
                colSpan:    2,
                label:      t('companySettings.lockTimeEntries'),
                editorType: 'dxSwitch',
                feature:    'timeInOut'
            },
            {
                dataField:  'defaultPaycodeId',
                colSpan:    2,
                label:      t('time.defaultLaborPayCode'),
                labelWidth: 150
            },
            {
                dataField: 'emptySpace',
                colSpan:   2
            },
            {
                dataField:     'timeEntryNotifyDay',
                colSpan:       2,
                label:         t('companySettings.employeeReminder'),
                labelWidth:    150,
                editorType:    'dxSelectBox',
                editorOptions: {
                    items: notificationDays,
                    width: 250
                }
            },
            {
                dataField:     'approvalNotifyDay',
                colSpan:       2,
                label:         t('companySettings.approvalReminder'),
                labelWidth:    150,
                editorType:    'dxSelectBox',
                editorOptions: {
                    items: notificationDays,
                    width: 250
                }
            },
            {
                dataField:  'useTransactionAcknowledgement',
                colSpan:    isPhone ? 2 : 1,
                label:      t('companySettings.useAcknowledgement'),
                labelWidth: 150,
                editorType: 'dxSwitch'
            },
            {
                dataField:     'transactionAcknowledgementMessage',
                colSpan:       isPhone ? 2 : 3,
                label:         t('companySettings.acknowledgementMessage'),
                editorType:    'dxHtmlEditor',
                editorOptions: {
                    height:      '200px',
                    elementAttr: {
                        id: 'acknowledgementEditor'
                    },
                    toolbar: {
                        multiline: false,
                        items:     [
                            { name: 'undo' },
                            { name: 'redo' },
                            { name: 'seperator' },
                            { name:           'size',
                                acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                                options:        {
                                    inputAttr: {
                                        'aria-label': 'Font size',
                                    },
                                }
                            },
                            { name: 'seperator' },
                            {
                                name:           'font',
                                acceptedValues: [
                                    'Arial',
                                    'Courier New',
                                    'Georgia',
                                    'Impact',
                                    'Lucida Console',
                                    'Tahoma',
                                    'Times New Roman',
                                    'Verdana'
                                ],
                                options: {
                                    inputAttr: {
                                        'aria-label': 'Font family',
                                    },
                                }
                            },
                            { name: 'bold' },
                            { name: 'italic' },
                            { name: 'strike' },
                            { name: 'underline' },
                            { name: 'color' },
                            { name: 'separator' },
                            { name: 'alignLeft' },
                            { name: 'alignCenter' },
                            { name: 'alignRight' },
                            { name: 'alignJustify' }
                        ]
                    }
                }
            }
        ];
    },
    getFsmInfoItems (t) {
        return [
            {
                dataField:     'defaultWeekEndingDay',
                colSpan:       2,
                label:         t('companySettings.defaultWeekendingDay'),
                labelWidth:    150,
                editorOptions: {
                    readOnly: true,
                    width:    200
                }
            }
        ];
    },
    getWennsoftInfoItems(t) {
        return [
            {
                dataField:  'registeredTimeEmployeeCount',
                colSpan:    2,
                label:      t('companySettings.registeredUseCount'),
                labelWidth: 150,
            },
            {
                dataField:     'currentlyUsedTimeEmployeeCount',
                colSpan:       2,
                label:         t('companySettings.assignedUserCount'),
                labelWidth:    150,
                editorOptions: {
                    readOnly: true,
                    width:    200
                }
            }
        ];
    },
    getEmailInfoItems(t) {
        return [
            {
                dataField:  'adminEmailNotifications',
                colSpan:    2,
                label:      t('companySettings.adminEmailLabel'),
                labelWidth: 250
            }
        ];
    }
};
