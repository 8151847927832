import { Flex, Heading, useTheme } from '@aws-amplify/ui-react';

import './SignInHeader.scss';

export function SignInHeader() {
    const { tokens } = useTheme();

    return (
        <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
            <>
                <Flex justifyContent="center">
                    <div className="login-header-welcome">Welcome to</div>
                </Flex>
                <Flex justifyContent="center">
                    <div className="login-header-title">Signature Time</div>
                </Flex>
            </>
        </Heading>
    );
}
