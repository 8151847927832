import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Form, {
    GroupItem,
    Item,
    Label,
    RequiredRule,
    StringLengthRule
} from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import 'devextreme-react/text-area';
import { cloneDeep } from 'lodash';

import * as AnnotationTypes from '../../data/annotationTypes';
import * as AnnotationActions from '../../actions/annotationActions';
import * as TimeActions from '../../actions/timeActions';
import { TransactionStatus } from '../../data/transactionStatuses';
import showToastMessage from '../../helpers/toastHelper';

import './NoteForm.scss';

const defaultFormData = {
    annotationType: null,
    attachment:     null,
    id:             null,
    isAttachment:   false,
    subject:        null,
    isVisible:      true,
    noteText:       null
};



const NoteForm = ({
    activeCompany,
    activeEmployee,
    annotationData,
    annotationType,
    cancelButtonAction,
    createAnnotation,
    currentTimesheet,
    currentUser,
    fetchEmployeeTimesheet,
    handleRefreshSavedNote,
    insertTimeSheetTransaction,
    isNoteReadOnly = false,
    isNewNote,
    noteSectionLabel = 'Note',
    noteData,
    objectId,
    setIsShowAnnotationForm,
    timesheetStartDate,
    updateAnnotation,
    useTimeEntryForm,
    fromTransactionForm = false,
    gridInstance,
    listInstance,
    popupRef
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [data, setData] = useState(cloneDeep(defaultFormData));
    const [isFormDirty, setIsFormDirty] = useState(false);

    const handleNoteValueChanged = e => {
        if (e.event) {  // Only set form dirty if user changes value
            setIsFormDirty(true);
        }
    };

    const handlePaste = e => {
        if (e.event) {
            setIsFormDirty(true);
        }
    };

    const notesEditorOptions = {
        height:           100,
        maxLength:        32000,
        stylingMode:      'outlined',
        readOnly:         isNoteReadOnly,
        onPaste:          handlePaste,
        onValueChanged:   handleNoteValueChanged,
        valueChangeEvent: 'change keyup input'
    };

    const setFormFocus = e => {
        const formInstance = formRef.current?.instance;
        if (formInstance) {
            formInstance.getEditor('noteText')?.focus();
        }
    };

    useEffect(() => {
        if (!isNewNote) {
            setData(noteData);
        }

        if (popupRef.current?.instance) {
            popupRef.current?.instance.on('shown', setFormFocus);
        }
    }, [noteData, isNewNote]);

    const handleSaveAnnotationRecord = async (e) => {
        if (isFormDirty) {
            if (formRef.current) {
                const result = formRef.current.instance.validate();

                if (!result.isValid) {
                    return;
                }

                const { props } = formRef.current;
                const { formData } = props;
                if (formData.id) {
                    const updated = await updateAnnotation(activeCompany.id, formData, t);
                    if (updated && annotationType === AnnotationTypes.TIMETRANSACTION) {
                        const trxToUpdate = currentTimesheet.timeTransactions.find(trx => trx.id === objectId);
                        if (trxToUpdate && trxToUpdate.status === TransactionStatus.Rejected) {
                            updateRejectedTransaction(trxToUpdate, formData.id);
                        }
                        else {
                            closeFormAndRefresh({id: formData.id});
                        }
                    }
                }
                else {
                    // create the note
                    formData.annotationType = annotationType;
                    formData.isAttachment = false;
                    formData.subject = annotationType;
                    formData.attachment = null;
                    formData.objectId = objectId;

                    const saved = await createAnnotation(activeCompany.id, formData, t);
                    if (saved && annotationType === AnnotationTypes.TIMETRANSACTION) {
                        const trxToUpdate = currentTimesheet.timeTransactions.find(trx => trx.id === objectId);
                        if (trxToUpdate && trxToUpdate.status === TransactionStatus.Rejected) {
                            updateRejectedTransaction(trxToUpdate, saved.id);
                        }
                        else {
                            closeFormAndRefresh({id: saved.id});
                        }
                    }
                }
            }
        }
        else {
            showToastMessage(t('time.noChangesToSave'), 'warning');
            setIsShowAnnotationForm(false);
        }
    };

    const updateRejectedTransaction = useCallback(async (trxToUpdate, annotationId) => {
        trxToUpdate.status = TransactionStatus.Open;
        trxToUpdate.statusComment = null;

        trxToUpdate.employeeId = trxToUpdate.employeeId?.id;
        trxToUpdate.activityId = trxToUpdate.activityId?.id;
        trxToUpdate.paycodeId = trxToUpdate.paycodeId?.id;
        trxToUpdate.shiftCodeId = trxToUpdate.shiftCodeId?.id;
        if (trxToUpdate.costcodeId) {
            trxToUpdate.costcodeId = trxToUpdate.costcodeId?.id;
        }

        const timesheetData = {
            timesheetId:        trxToUpdate.timesheetId,
            timesheetStartDate: timesheetStartDate,
            employeeId:         trxToUpdate.employeeId,
            transactions:       [trxToUpdate]
        };
        const result = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
        if (result.success) {
            closeFormAndRefresh({id: annotationId});
        }
    }, [currentTimesheet, timesheetStartDate]);

    const closeFormAndRefresh = async(note) => {
        if (!useTimeEntryForm) {
            // update the table with the new note
            await updateTimesheet();
            setIsShowAnnotationForm(false);
        }
        else {
            handleRefreshSavedNote(note);
            setIsShowAnnotationForm(false);
        }
    };

    const updateTimesheet = async () => {
        if (useTimeEntryForm) {
            if (gridInstance) {
                gridInstance.refresh();
            }
            if (listInstance) {
                listInstance.reload();
            }
        }
        else {
            const options = {
                includeexternalorigindetails: true,
                companyid:                    activeCompany.id,
                requesterid:                  currentUser.id
            };
            await fetchEmployeeTimesheet([activeEmployee.id], timesheetStartDate, options);
        }
    };

    return (
        <div>
            <Form
                ref={formRef}
                formData={data}
            >
                <GroupItem caption={noteSectionLabel} colCount={2}>
                    <Item
                        colSpan={2}
                        dataField="noteText"
                        editorType="dxTextArea"
                        editorOptions={notesEditorOptions}
                    >
                        <RequiredRule message={t('time.enterNoteText')} />
                        <StringLengthRule max={32000} message={t('time.textToLong')} />
                        <Label text={t('time.note')} />
                    </Item>
                </GroupItem>
            </Form>
            <div className={'note-container'}>
                <div className={'item'}>
                    <Button
                        name="closeButton"
                        icon={'fa-solid fa-xmark'}
                        text={t('common.close')}
                        onClick={cancelButtonAction}
                        stylingMode={'contained'}
                    />
                </div>
                <div className={'item'}>
                    <Button
                        name="saveButton"
                        icon={'fa-solid fa-floppy-disk'}
                        text={t('common.save')}
                        onClick={handleSaveAnnotationRecord}
                        stylingMode={'contained'}
                        type={'default'}
                        visible={!isNoteReadOnly}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeCompany:      state.time.activeCompany,
    currentTimesheet:   state.time.currentTimesheet,
    currentUser:        state.currentUser,
    timesheetStartDate: state.time.timesheetStartDate
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteForm);
