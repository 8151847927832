import React from 'react';
import Box, { Item as BoxItem } from 'devextreme-react/box';
import { TransactionType } from '../../data/transactionTypes';
import { isNull } from 'lodash';

import './TimeManagerMobileApprovalList.scss';

const getActivityIconClass = activityType => {
    return activityType === 'JOB' ? 'fa-regular fa-building' : activityType === 'SERVICE' ? 'fa-solid fa-hammer' : 'fa-regular fa-money-bill-1';
};

export const RenderItemView = (t, langId) => (itemData) => {
    return (
        <div className="approval-item-container">
            <Box direction="row">
                <BoxItem ratio={1}><div className="approval-employee-name">{itemData.employeeName}</div></BoxItem>
            </Box>
            <Box direction="row">
                <BoxItem ratio={1}><div className="approval-period">{`${t('time.period')}: ${itemData.period}`}</div></BoxItem>
            </Box>
            <div  className="approval-hours-box">
                <Box direction="row">
                    <BoxItem ratio={1}>
                        <div className="approval-hours-container">
                            <div className="approval-hours-label">{`${t('time.regularHours')}:`}</div>
                            <div className="approval-hours">{itemData.regularHours}</div>
                        </div>
                    </BoxItem>
                </Box>
                <Box direction="row">
                    <BoxItem ratio={1}>
                        <div className="approval-hours-container">
                            <div className="approval-hours-label">{`${t('time.otherHours')}:`}</div>
                            <div className="approval-hours">{itemData.otherHours}</div>
                        </div>
                    </BoxItem>
                </Box>
                <Box direction="row">
                    <BoxItem ratio={1}>
                        <div className="approval-hours-container">
                            <div className="approval-total-hours">{`${t('time.totalHours')}:`}</div>
                            <div className="approval-hours">{itemData.totalHours}</div>
                        </div>
                    </BoxItem>
                </Box>
            </div>
        </div>
    );
};

export const renderDayView = (useShiftCode, useTimeInTimeOut, t, language) => (item) => {
    let detailBaseSize = 40;

    if (useShiftCode && useTimeInTimeOut) {
        detailBaseSize = 80;
    }
    else if (useShiftCode || useTimeInTimeOut) {
        detailBaseSize = 60;
    }

    let activity;
    let customerLocation;
    let itemNote = '';
    switch (item.transactionType) {
        case TransactionType.Job:
        case TransactionType.Service:
            activity = `${item.activityName} - ${item.activityDescription}`;
            customerLocation = `${item.activityClientName} - ${item.activitySiteName}`;
            break;
        case TransactionType.Unbilled:
            activity = item.activityName;
            break;
        default:
            break;
    }

    if (item.notes.length !== 0) {
        itemNote = item.notes[0].noteText;
    }

    const activityType = item.transactionType?.toUpperCase();
    const iconClass = getActivityIconClass(activityType);
    const unbilledIconStyling = {
        paddingRight: '5px'
    };
    const jobIconStyling = {
        paddingRight: '5px'
    };
    const serviceIconStylng = {
        paddingRight: '5px'
    };

    const formatDisplayDate = (displayDate, language) => {
        if (!isNull(displayDate)) {
            return new Date(displayDate).toLocaleTimeString(language, {hour: '2-digit', minute: '2-digit'});
        }
        else {
            return null;
        }
    };

    return (
        <div className="mobile-transaction-container" >
            <Box direction="col" height={'100%'} width="100vw">
                <BoxItem ratio={1} baseSize={40}>
                    <div className="activity-row">
                        <div><i className={iconClass} style={activityType === 'Job' ? jobIconStyling : activityType === 'Service' ? serviceIconStylng : unbilledIconStyling} />{activity}</div>
                    </div>
                    {item.transactionType !== TransactionType.Unbilled ? (
                        <div className="customer-row">
                            <div style={{textOverflow: 'ellipsis'}}>{customerLocation}</div>
                        </div>
                    ) : null}
                </BoxItem>
                <BoxItem ratio={1} baseSize={detailBaseSize}>
                    <div>
                        <span className="list-item-label">
                            {item.transactionType !== TransactionType.Unbilled
                                ? `${t('time.costCode')}:`
                                : null}
                        </span>
                        <span className="list-item-value">
                            {item.transactionType === TransactionType.Job
                                ? item.costcodeAlias
                                : item.costcodeDescription}
                        </span>
                    </div>
                    <div>
                        <span className="list-item-label">{`${t('time.payCode')}:`}</span>
                        <span className="list-item-value">{item.paycodeDescription}</span>
                    </div>
                    {
                        useShiftCode ?
                            <div>
                                <span className="list-item-label">{`${t('time.shiftCode')}:`}</span>
                                <span className="list-item-value">{item.shiftCodeDescription}</span>
                            </div>
                            : null
                    }
                    <Box direction="row" width={'100vw'}>
                        <BoxItem ratio={1} baseSize={50}>
                            <div>
                                <span className="list-item-label">{`${t('common.date')}:`}</span>
                                <span className="list-item-value">{item.transactionFormattedDate}</span>
                            </div>
                        </BoxItem>
                        <BoxItem ratio={1} baseSize={50}>
                            <div>
                                <span className="list-item-label">{`${t('common.hours')}:`}</span>
                                <span className="list-item-value">{parseFloat(item.hours).toFixed(2)}</span>
                            </div>
                        </BoxItem>
                    </Box>
                    { useTimeInTimeOut ?
                        <Box direction="row" width={'100vw'}>
                            <BoxItem ratio={1} baseSize={50}>
                                <div>
                                    <span className="list-item-label">{`${t('time.timeIn')}:`}</span>
                                    <span className="list-item-value">{formatDisplayDate(item.timeIn, language)}</span>
                                </div>
                            </BoxItem>
                            <BoxItem ratio={1} baseSize={50}>
                                <div>
                                    <span className="list-item-label">{`${t('time.timeOut')}:`}</span>
                                    <span className="list-item-value">{formatDisplayDate(item.timeOut, language)}</span>
                                </div>
                            </BoxItem>
                        </Box>
                        : null }
                </BoxItem>
                {itemNote.length !== 0 ?
                    <BoxItem ratio={1} baseSize={50}>
                        <div>
                            <div>
                                <span className="list-item-label">{`${t('time.note')}:`}</span>
                            </div>
                            <div>
                                <span className="list-item-value">{itemNote}</span>
                            </div>
                        </div>
                    </BoxItem>
                    : null}
            </Box>
        </div>
    );
};
