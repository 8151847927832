import * as ActionTypes from './actionTypes';

export const signIn = (session) => ({
    type:    ActionTypes.IS_SIGNED_IN,
    payload: { session }
});

export const signOut = () => ({
    type:    ActionTypes.IS_SIGNED_OUT,
    payload: null
});
