import { connect, disconnect, send } from '@giantmachines/redux-websocket';

export const connectSocket = (socketUrl) => async (dispatch) => {
    dispatch(connect(socketUrl));
};

export const sendPacket = (msg) => async (dispatch) => {
    dispatch(send(msg));
};

export const disconnectSocket = () => async (dispatch) => {
    dispatch(disconnect());
};
