import { differenceInMinutes, isValid, parseISO } from 'date-fns';
import { TransactionStatus } from '../../data/transactionStatuses';
import { isNumber } from 'lodash';
import { toHoursAndMinutes } from '../../helpers/timeHelpers';
import showToastMessage from '../../helpers/toastHelper';

export const defaultTransactionData = {
    timesheetId:     null,
    employeeId:      null,
    activityId:      null,
    costcodeId:      null,
    paycodeId:       null,
    equipmentId:     null,
    status:          TransactionStatus.Open,
    transactionType: null,
    transactionDate: null,
    hours:           null,
    id:              null,
    timeIn:          null,
    timeOut:         null,
    transactions:    []
};

export const renderActivityCell = (cellData) => {
    const { row } = cellData;
    const activityType = row.data.transactionType.toUpperCase();
    const iconClass = activityType === 'JOB' ? 'fa-regular fa-building' : activityType === 'SERVICE' ? 'fa-solid fa-hammer' : 'fa-regular fa-money-bill-1';
    const unbilledIconStyling = {
        paddingRight: '5px'
    };
    const jobIconStyling = {
        paddingRight: '5px'
    };
    const serviceIconStylng = {
        paddingRight: '5px'
    };

    return (
        <>
            { activityType !== 'UNBILLED' ?
                <>
                    <div><i className={iconClass} style={activityType === 'JOB' ? jobIconStyling : serviceIconStylng} /> {`${row.data.activityName} - ${row.data.activityDescription}`}</div>
                    <div style={{ fontSize: '11px' }}>{`${row.data.activityClientName} - ${row.data.activitySiteName}`}</div>
                </>
                :
                <div><i className={iconClass} style={unbilledIconStyling} />{row.data.activityName}</div>
            }
        </>
    );
};

export const totalCellRender = (row) => {
    const { data } = row;
    let totalHours = 0;

    Object.keys(data).forEach((key) => {
        if (isValid(new Date(key))) {
            totalHours += data[key];
        }
    });

    return <div>{parseFloat(totalHours).toFixed(2)}</div>;
};

export const renderCostCodeCell = (cellData) => {
    const { row } = cellData;
    return (
        <>
            { row.data.transactionType.toUpperCase() === 'JOB' ?
                <>
                    <div>{row.data.costcodeAlias}</div>
                    <div style={{ fontSize: '11px' }}>{row.data.costcodeDescription}</div>
                </>
                : row.data.transactionType.toUpperCase() === 'SERVICE' ?
                    <div>{row.data.costcodeDescription}</div>
                    :
                    <div />
            }
        </>
    );
};

export const renderPayCodeCell = (cellData) => {
    const { row } = cellData;
    return (
        <>
            <div>{`${row.data.paycodeName}`}</div>
            <div style={{ fontSize: '11px' }}>{row.data?.paycodeDescription}</div>
        </>
    );
};

export const renderShiftCodeCell = (cellData) => {
    const { row } = cellData;
    return (
        <>
            <div>{`${row.data.shiftCodeName ? row.data.shiftCodeName : ''}`}</div>
            <div style={{fontSize: '11px'}}>{row.data.shiftCodeDescription ? row.data.shiftCodeDescription : ''}</div>
        </>
    );
};

export const formatDateCellHeader = (cells, langId) => {
    if (!langId) {
        langId = navigator.language;
    }

    //const locale = { locale: langId === 'en' ? locales[enUS] : locales[es] };

    cells.forEach(cell => {
        if (cell.cellElement && isValid(parseISO(cell.column.caption))) {
            //const locale = navigator.language;
            const transDate = parseISO(cell.column.caption);
            const formattedDate = transDate.toLocaleString(langId, {
                month: '2-digit',
                day:   '2-digit'
            });
            const formattedDay = transDate.toLocaleDateString(langId, {
                weekday: 'short'
            });
            cell.cellElement.className = 'list-date-header-cell';
            cell.cellElement.innerHTML = `<div>${formattedDay}<br />${formattedDate}<div>`;
        }
        else if (cell.cellElement && cell.column.name === 'totals') {
            cell.cellElement.className = 'list-total-header-cell';
        }
    });
};

const getSubTotalDisplay = (subTotal) => {
    if (subTotal) {
        let regularHours = subTotal.regularHours ? parseFloat(subTotal.regularHours).toFixed(2) : 0.00;
        let otherHours = subTotal.otherHours ? parseFloat(subTotal.otherHours).toFixed(2) : 0.00;
        if (regularHours === 0) {
            regularHours = '0.00';
        }

        if (otherHours === 0) {
            otherHours = '0.00';
        }

        const innerHTML = `<div class='subtotal-container'><div class='regular-hours'>${regularHours}</div><div class='other-hours'>${otherHours}</div></div>`;
        return innerHTML;
    }
    else {
        const regularHours = '0.00';
        const otherHours = '0.00';
        const innerHTML = `<div class='subtotal-container'><div class='regular-hours'>${regularHours}</div><div class='other-hours'>${otherHours}</div></div>`;
        return innerHTML;
    }
};

export const formatFooterCells = (cells, subTotals, useShiftCode, t) => {
    cells.forEach(cell => {
        if (cell.cellElement && isValid(parseISO(cell.column.caption))) {
            cell.cellElement.className = 'list-total-cell';
            const subTotal = subTotals.find(s => s.formattedDate === cell.column.caption);
            const innerText = getSubTotalDisplay(subTotal);
            if (innerText !== '')
            {
                const innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
                cell.cellElement.innerHTML = innerHTML;
            }
        }
        else if (cell.cellElement && (useShiftCode ? cell.column.dataField === 'shiftCodeName' : cell.column.dataField === 'paycodeName')) {
            cell.cellElement.className = 'list-paycode-total-cell';
            const innerText = `<div class='subtotal-container'><div class='regular-hours'>${t('time.regularHours')}:</div><div class='other-hours'>${t('time.otherHours')}:</div></div>`;
            const innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
            cell.cellElement.innerHTML = innerHTML;
        }
        else if (cell.cellElement && cell.column.name === 'totals') {
            cell.cellElement.className = 'list-total-cell';
            const innerText = getTotalHoursDisplay(subTotals);
            const innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
            cell.cellElement.innerHTML = innerHTML;
        }
        else if (cell.cellElement && cell.column.name === 'commandColumn') {
            let totalHours = 0;
            subTotals?.forEach(s => {
                totalHours += s.regularHours + s.otherHours;
            });
            totalHours = parseFloat(totalHours).toFixed(2);
            if (totalHours === 0)
                totalHours = 0.00;

            cell.cellElement.className += ' total-cell';
            const innerText = `<div class='subtotal-container'><div class='regular-hours'>Total Hrs</div><div>${totalHours}</div></div>`;
            cell.cellElement.innerHTML = `<div class="dx-datagrid-summary-item dx-datagrid-text-content" style="text-align: right;">${innerText}</div>`;
        }
    });
};

const getTotalHoursDisplay = (subTotals) => {
    let regularTotal = 0;
    let otherTotal = 0;

    subTotals?.forEach(t => {
        regularTotal += t.regularHours;
        otherTotal += t.otherHours;
    });

    if (regularTotal === 0) {
        regularTotal = '0.00';
    }
    else {
        regularTotal = parseFloat(regularTotal).toFixed(2);
    }

    if (otherTotal === 0) {
        otherTotal = '0.00';
    }
    else {
        otherTotal = parseFloat(otherTotal).toFixed(2);
    }

    const innerHTML = `<div class='subtotal-container'><div class='regular-hours'>${regularTotal}</div><div class='other-hours'>${otherTotal}</div></div>`;
    return innerHTML;
};

export const hasAnnotations = cellData => {
    if (!cellData.rowType === 'data' || !cellData.value) return false;
    if (!isValid(new Date(cellData.column.caption))) return false;


    let annotationCount = 0;

    const transactionColumn = cellData.column.caption + '_transactions';
    if (cellData.data[transactionColumn]) {
        cellData.data[transactionColumn].forEach(trx => {
            annotationCount += trx.notes.length;
        });
    }

    return annotationCount !== 0;
};

export const hasUnsubmittedTransactions = cellData => {
    if (!cellData.rowType === 'data' && !isNumber(cellData.value)) return false;
    if (!isValid(new Date(cellData.column.caption))) return false;

    let transactionCount = 0;

    const transactionColumn = cellData.column.caption + '_transactions';
    if (cellData.data[transactionColumn]) {
        transactionCount = cellData.data[transactionColumn].filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn).length;
    }

    return transactionCount > 0;
};

export const hasTimedInEntry = cellData => {
    if (!cellData.rowType === 'data' && !isNumber(cellData.value)) return false;
    if (!isValid(new Date(cellData.column.caption))) return false;

    let timedInCount = 0;

    const transactionColumn = cellData.column.caption + '_transactions';
    if (cellData.data[transactionColumn]) {
        timedInCount = cellData.data[transactionColumn].filter(t => t.status === TransactionStatus.TimedIn).length;
    }

    return timedInCount > 0;
};

export const validateTimeOut = (data, transactions, t, allowOver24Hours = false) => {
    const timeOutTime = new Date();
    const timeDiff = differenceInMinutes(timeOutTime, new Date(data.timeIn));
    const newHours = Number(toHoursAndMinutes(timeDiff));

    if (!allowOver24Hours && timeDiff > 1440) {
        showToastMessage(t('validation.totalDayHourExceeds24'), 'error');
        return false;
    }
    else if (timeDiff < 1) {
        showToastMessage(t('validation.endTimeBeforeStartTime'), 'error');
        return false;
    }

    // check for all hours of the day
    const daysTransactions = transactions.filter(trx => trx.transactionFormattedDate === data.transactionFormattedDate);
    if (daysTransactions) {
        let totalHourCount = 0;
        daysTransactions.forEach(trx => {
            if (trx.id === data.id) {
                totalHourCount += newHours;
            }
            else {
                totalHourCount += trx.hours;
            }
        });
        if (!allowOver24Hours && totalHourCount > 24) {
            showToastMessage(t('validation.totalHoursExceed24'), 'error');
            return false;
        }
    }

    return true;
};
