import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import reduxWebsocket from '@giantmachines/redux-websocket';
import thunk from 'redux-thunk';

import App from './containers/App';

import { Amplify } from 'aws-amplify';
import cognitoData from './awsConfig';

import unwrapReduxWebsocket from './middleware/unwrapReduxWebsocket';
import reducers from './reducers';

import './localization/i18n';

import './index.css';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId:       cognitoData.aws.cognito.userPoolId,
            identityPoolId:   cognitoData.aws.cognito.identityPoolId,
            userPoolClientId: cognitoData.aws.cognito.clientId
        }
    }
});

const reduxWebsocketMiddleware = reduxWebsocket({ reconnectOnClose: true });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            thunk,
            reduxWebsocketMiddleware,
            unwrapReduxWebsocket
        )
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
