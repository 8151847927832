import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './containers/Authentication/Login';

export default function UnauthenticatedContent(companyId) {
    const navigatePage = companyId && companyId.companyId && companyId !== 'null' ? `/signin?companyId=${companyId.companyId}` : '/signin';
    return (
        <Routes>
            <Route
                path="/signin"
                element={
                    <Login />
                }
            />
            <Route path="*" element={<Navigate to={navigatePage} />} />
        </Routes>
    );
}
