import React, {useCallback, useEffect, useState, useRef} from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { alert } from 'devextreme/ui/dialog';
import { useTranslation } from 'react-i18next';

import DataGrid, {
    AsyncRule,
    Column,
    Editing,
    FilterRow,
    Grouping,
    GroupPanel,
    SearchPanel
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Button, LoadPanel, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import devices from 'devextreme/core/devices';

import * as EmployeeActions from '../../actions/employeeActions';
import * as TimeActions from '../../actions/timeActions';

import { useScreenSize } from '../../utils/media-query';

import './Licensing.scss';

const Licensing = ({
    activeCompany,
    fetchEmployeesList,
    settings,
    updateEmployeeLicensing
}) => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const [assignedCount, setAssignedCount] = useState(0);
    const [gridDataSource, setGridDataSource] = useState([]);
    const [registeredCount,setRegisteredCount] = useState();
    const [editRowData, setEditRowData] = useState();
    const [isMauiApp, setIsMauiApp] = useState(false);

    const originalEmployees = useRef([]);
    const setOriginalEmployees = data => {
        originalEmployees.current = data;
    };

    const {isXSmall, isSmall, isMedium } = useScreenSize();

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams({});

    useEffect(() => {
        const isMaui = sessionStorage.getItem('isMauiApp');
        if (isMaui) {
            setIsMauiApp(true);
        }
        else {
            setIsMauiApp(false);
        }
    }, []);

    useEffect(() => {
        setSearchParams({companyId: activeCompany.id});

        const store = new CustomStore({
            key:  'id',
            load: async (loadOptions) => {
                const list = await fetchEmployeesList(null, {includeNonTimeUsers: true});
                list.forEach(emp => {
                    if (emp.managerId) {
                        const mgr = list.find(m => m.id === emp.managerId);
                        if (mgr) {
                            emp.managerName = mgr.fullName;
                        }
                        else {
                            emp.managerName = '';
                        }
                    }
                    else {
                        emp.managerName = '';
                    }
                });
                return list;
            },
            onLoaded: (data, loadOptions) => {
                let count = 0;
                data.forEach(emp => {
                    if (emp.isTimeUser) {
                        count += 1;
                    }
                });
                setAssignedCount(count);
                setOriginalEmployees(data);
            },
            update: async (key, values) => {
                const foundEmployee = originalEmployees.current.find(emp => emp.id === key);
                if (foundEmployee) {
                    Object.keys(values).forEach(key => {
                        foundEmployee[key] = values[key];
                    });
                    const body = {
                        isApplicationUser: foundEmployee.isApplicationUser,
                        isTimeUser:        foundEmployee.isTimeUser
                    };
                    return updateEmployeeLicensing(key, body, t);
                }
            }
        });

        setGridDataSource(store);
    }, [activeCompany.id]);

    useEffect(() => {
        if (settings) {
            setRegisteredCount(settings.registeredTimeEmployeeCount);
        }
    }, [settings]);

    function setIsApplicationUserCellValue (rowData, value) {
        rowData.isApplicationUser = value;

        if (value) {
            rowData.isTimeUser = value;
        }
    }

    function setIsTimeUserCellValue(rowData, value) {
        rowData.isTimeUser = value;

        if (!value) {
            rowData.isApplicationUser = value;
        }
    }

    const licenseCountRuleCheck = async (params) => {
        let isValid = true;
        const foundEmployee = originalEmployees.current.find(emp => emp.id === params.data.id);
        if (foundEmployee) {
            if (params.value && !foundEmployee.isTimeUser) {
                // user is not already a time user so we need to perform the license count check
                if (assignedCount + 1 > registeredCount) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                isValid = true;
            }
        }
        return isValid;
    };

    const displayToolbar = () => {
        return (
            <Toolbar>
                <Item location="before" render={renderLicenseCounts} />
                <Item location="after">
                    <Button
                        icon="refresh"
                        onClick={refreshGrid}
                    />
                </Item>
            </Toolbar>
        );
    };

    const refreshGrid = () => {
        const gridInstance = gridRef.current?.instance;
        if (gridInstance) {
            gridInstance.refresh();
        }
    };

    const renderLicenseCounts = () => {
        return (
            <div className="license-toolbar-container">
                <div className="license-title">Licensing</div>
                <div className="license-registered-count">{`Registration Count: ${registeredCount}`}</div>
                <div className="license-assigned-count">{`Assigned Count *: ${assignedCount}`}</div>
            </div>
        );
    };

    const onRowUpdating = useCallback(async e => {
        const allowUpdate = new Promise((resolve, reject) => {
            const { newData } = e;
            if (Object.prototype.hasOwnProperty.call(newData, 'isApplicationUser')) {
                if (newData.isApplicationUser) {
                    const result = alert(`You are about to send an email invite to ${editRowData.fullName}`, 'Employee Login');
                    result.then(dialogResult => {
                        setEditRowData();
                        resolve(false);
                    });
                }
                else {
                    setEditRowData();
                    resolve(false);
                }
            }
            else {
                setEditRowData();
                resolve(false);
            }
        });

        e.cancel = allowUpdate;

    }, [editRowData, setEditRowData]);

    const onEditStart = useCallback(e => {
        setEditRowData(e.data);
    }, []);

    const onEditCanceled = useCallback(e => {
        setEditRowData(null);
    }, []);

    return (
        <div className={ isXSmall || isSmall || isMedium ? 'mobile-list-block' : 'content-block'}>
            {isMauiApp ?
                <div className="require-desktop-browser">
                    <p>
                        {t('common.requireDesktopBrowser', {page: 'WennSoft Licensing'})}
                    </p>
                    <img
                        src="/assets/images/WSStamp.png"
                        height={64}
                        width={64}
                        alt="WennSoft"
                    />
                </div>
                :
                <>
                    {displayToolbar()}
                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        onEditingStart={onEditStart}
                        onEditCanceled={onEditCanceled}
                        onRowUpdating={onRowUpdating}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        height={devices.current().phone ? null : 'calc(100vh - 200px)'}
                    >
                        <FilterRow visible={true} />
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Grouping autoExpandAll={true} />
                        <LoadPanel
                            enabled={true}
                        />
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowAdding={false}
                            allowDeleting={false}
                        />
                        <Column
                            dataField="fullName"
                            allowEditing={false}
                            width={devices.current().phone ? 250 : null}
                            showWhenGrouped={true}
                        />
                        <Column
                            caption="Enable Time Cards *"
                            dataField="isTimeUser"
                            setCellValue={setIsTimeUserCellValue}
                            width={devices.current().phone ? 150 : null}
                            showWhenGrouped={true}
                        >
                            <AsyncRule
                                validationCallback={licenseCountRuleCheck}
                                message="You have reached the maximum number of users"
                            />
                        </Column>
                        <Column
                            caption="Login Access"
                            dataField="isApplicationUser"
                            setCellValue={setIsApplicationUserCellValue}
                            width={devices.current().phone ? 150: null}
                            showWhenGrouped={true}
                        />
                        <Column
                            caption="Email Address"
                            dataField="email"
                            width={devices.current().phone ? 250 : null}
                            showWhenGrouped={true}
                        />
                        <Column
                            dataField="managerName"
                            allowEditing={false}
                            width={devices.current().phone ? 175 : null}
                            showWhenGrouped={true}
                        />
                        <Column
                            dataField="department"
                            allowEditing={false}
                            width={devices.current().phone ? 175 : null}
                            showWhenGrouped={true}
                        />
                        <Column
                            dataField="position"
                            allowEditing={false}
                            width={devices.current().phone ? 175 : null}
                        />
                    </DataGrid>
                </>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        activeCompany: state.time.activeCompany,
        employeeList:  state.employee.employeeList,
        settings:      state.time.settings,
    });
};

const mapDispatchToProps = {
    ...EmployeeActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Licensing);
