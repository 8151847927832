import React from 'react';

import Lookup, { DropDownOptions } from 'devextreme-react/lookup';
import Validator from 'devextreme-react/validator';

export const FormLookup = ({
    dataSource,
    displayExpression,
    dropDownCentered,
    dropDownHeight,
    grouped = false,
    groupRender,
    itemRender,
    noDataText,
    onValueChanged,
    placeholder,
    readOnly,
    searchEnabled,
    searchExpression,
    showClearButton = false,
    useFullScreenDropDown = false,
    value,
    valueExpression,
    children
}) => {
    return (
        <Lookup
            dataSource={dataSource}
            defaultValue={value}
            displayExpr={displayExpression}
            dropDownCentered={dropDownCentered}
            grouped={grouped}
            groupRender={groupRender}
            itemRender={itemRender}
            noDataText={noDataText}
            onValueChanged={onValueChanged}
            placeholder={placeholder}
            readOnly={readOnly}
            searchEnabled={searchEnabled}
            searchExpr={searchExpression}
            showClearButton={showClearButton}
            value={value}
            valueExpr={valueExpression}
        >
            <DropDownOptions
                height={dropDownHeight}
                showCloseButton={true}
                maxHeight={'300px'}
                fullScreen={useFullScreenDropDown}
            />
            <Validator>
                {children}
            </Validator>
        </Lookup>
    );
};
