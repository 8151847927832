import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import DataGrid, {
    Column,
    Export,
    FilterRow,
    Item,
    LoadPanel,
    SearchPanel,
    Toolbar
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';

import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportPdfDataGrid} from 'devextreme/pdf_exporter';
import { exportDataGrid as exportExcelDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

import * as TimeActions from '../../actions/timeActions';

import './LicensingReport.scss';

const exportFormats = ['pdf', 'xlsx'];

const headerText = 'Signature Time Licensing Report';

const onExporting = (e) => {
    const formattedDate = format(new Date(), 'yyyy-MM-dd');
    const fileName = `Time Licensing Report ${formattedDate}`;

    if (e.format === 'pdf') {
        exportPdfFile(e, fileName);
    }
    else if (e.format === 'xlsx') {
        exportExcelFile(e, fileName);
        e.cancel = true;
    }
};

const exportPdfFile = (e, fileName) => {
    const doc = new jsPDF({
        orientation: 'portrait'
    });
    exportPdfDataGrid({
        jsPDFDocument: doc,
        component:     e.component,
        topLeft:       { x: 1, y: 15 },

    })
        .then(() => {
            const printedDate = `Printed on ${format(new Date(), 'MM-dd-yyyy hh:mm aa')}`;
            const header = headerText;
            const totalPages = doc.internal.getNumberOfPages();
            const pageWidth = doc.internal.pageSize.getWidth();
            const headerWidth = doc.getTextDimensions(header).w;
            doc.setFontSize(18);
            doc.text(header, (pageWidth - headerWidth) / 2, 20);

            for (let i = 0; i < totalPages; i++) {
                doc.setFontSize(9);
                const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
                const pageFooter = `Page ${currentPage} of ${i + 1}`;
                doc.text(pageFooter, 10, 292);

                doc.text(printedDate, 85, 292);
            }
            doc.save(`${fileName}.pdf`);
        });
};

const exportExcelFile = (e, fileName) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Licensing');
    exportExcelDataGrid({
        component:         e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell:       { row: 4, column: 1}
    })
        .then((cellRange) => {
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;
            worksheet.mergeCells(2, 1, 2, 8);

            headerRow.getCell(1).value = headerText;
            headerRow.getCell(1).font = { bold: true, size: 18 };
            headerRow.getCell(1).alignment = {horizontal: 'center' };

            const footerRowIndex = cellRange.to.row + 2;
            const footerRow = worksheet.getRow(footerRowIndex);
            worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

            footerRow.getCell(1).value = `Printed on ${format(new Date(), 'MM-dd-yyyy hh:mm aa')}`;
            footerRow.getCell(1).alignment = {horizontal: 'left'};

            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${fileName}.xlsx`);
                });
        });

};

const renderToolbarHeader = () => {
    return <div className="toolbar-header">{headerText}</div>;
};

const LicensingReport = ({
    activeCompany,
    fetchLicensingReport
}) => {
    const { t } = useTranslation();
    const [reportData, setReportData] = useState();

    const gridRef = useRef();

    useEffect(() => {
        const init = async () => {
            refreshGrid();
        };

        if (activeCompany.id) init();
    },[activeCompany.id]);

    const refreshGrid = async () => {
        const gridInstance = gridRef.current.instance;

        if (gridInstance) {
            gridInstance.beginCustomLoading();
        }

        const data = await fetchLicensingReport(t);
        setReportData(data);

        if (gridInstance) {
            gridInstance.endCustomLoading();
        }

    };

    return (
        <div className="content-block">
            <DataGrid
                ref={gridRef}
                rowAlternationEnabled={true}
                dataSource={reportData}
                onExporting={onExporting}
                height="calc(100vh - 175px)"
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
            >
                <SearchPanel
                    visible={true}
                />
                <FilterRow
                    visible={true}
                />
                <LoadPanel enabled={true} />
                <Export
                    enabled={true}
                    formats={exportFormats}
                    allowExportSelectedData={false}
                    texts={{
                        exportAll: 'Export to {0}'
                    }}
                />
                <Column
                    caption="Company Name"
                    dataField="companyName"
                    width={500}
                />
                <Column
                    caption="License Count"
                    dataField="timeEmployeeLicenseCount"
                    width={150}
                />
                <Column
                    caption="Assigned Count"
                    dataField="currentlyUsedTimeEmployeeLicenseCount"
                    width={150}
                />
                <Column
                    caption="Available Licenses"
                    dataField="availableCount"
                    width={150}
                />
                <Toolbar>
                    <Item location="before" render={renderToolbarHeader} />
                    <Item
                        location="after"
                        name="exportButton"
                    />
                    <Item location="after">
                        <Button
                            icon="refresh"
                            onClick={refreshGrid}
                        />
                    </Item>
                    <Item
                        location="after"
                        name="searchPanel"
                    />
                </Toolbar>
            </DataGrid>
        </div>
    );

};

const mapStateToProps = state => {
    return ({
        activeCompany: state.time.activeCompany
    });
};

const mapDispatchToProps = {
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensingReport);
