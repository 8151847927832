import React from 'react';
import Lookup, { DropDownOptions } from 'devextreme-react/lookup';
import {
    PayCodeItem,
    getPayCodeDisplayExpr,
    renderPayCodeGroupHeader
} from '../../time-entry-form/TimeEntryFormHelper';
import { useTranslation } from 'react-i18next';

const DefaultPaycodeLookup = ({
    dataSource,
    onValueChanged,
    value,
    valueExpression
}) => {
    const { t } = useTranslation();

    return (
        <Lookup
            dataSource={dataSource}
            displayExpr={getPayCodeDisplayExpr}
            grouped={true}
            groupRender={renderPayCodeGroupHeader(t)}
            itemRender={PayCodeItem}
            noDataText={t('common.noLaborPaycodes')}
            onValueChange={onValueChanged}
            placeholder={t('companySettings.selectDefaultPaycodePlaceholder')}
            searchEnabled={true}
            searchExpr={['paycodeName', 'paycodeDescription']}
            showClearButton={true}
            value={value}
            valueExpr={valueExpression}
            width={250}
        >
            <DropDownOptions
                height={350}
                showCloseButton={true}
                maxHeight={'350px'}
            />
        </Lookup>
    );
};

export default DefaultPaycodeLookup;
