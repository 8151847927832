import 'devextreme/dist/css/dx.common.css';
import '../themes/generated/theme.base.css';
import '../themes/generated/theme.additional.css';

import React, { Suspense, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePrevious } from '../hooks/usePrevious';
import { signIn, signOut } from '../actions/authActions';

import { BrowserRouter as Router, useSearchParams, useLocation } from 'react-router-dom';

import { NavigationProvider } from '../contexts/navigation';
import { useScreenSizeClass } from '../utils/media-query';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import Time from './Time';
import UnauthenticatedContent from '../UnauthenticatedContent';
import LoadingMask from '../components/loadingMask/loadingMask';
import { initUser, setCurrentUser } from '../actions/currentUserActions';
import { fetchApiVersion, fetchCompanies, fetchFeatureFlags } from '../actions/coreActions';

function App() {
    const { t, i18n } = useTranslation();
    const screenSizeClass = useScreenSizeClass();
    const dispatch = useDispatch();
    const location = useLocation();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const prevAuthStatus = usePrevious(authStatus);
    const [searchParams, setSearchParams] = useSearchParams();

    const getUserInfo = async () => {
        const userData = await fetchAuthSession();
        return userData;
    };

    useEffect(() => {
        if (navigator.userAgent.indexOf('iPhone') > 1) {
            // This is to stop the page from auto zooming in on certain controls
            document
                .querySelector('[name=viewport')
                .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
        }
    }, []);

    useEffect(() => {
        if (location && location.key === 'default') {
            sessionStorage.setItem('returnUrl', `${location.pathname}${location.search}`);
        }
    }, [location]);

    useEffect(() => {
        let userData;
        const init = async () => {
            switch (authStatus) {
                case 'authenticated':
                    dispatch(fetchApiVersion());
                    userData = await getUserInfo();
                    userAuthenticated(userData);
                    break;
                case 'unathenticated':
                    dispatch(fetchApiVersion());
                    userSignedOut();
                    break;
                default:
                    break;
            }
        };
        if (prevAuthStatus !== authStatus) init();
    }, [authStatus]);

    const userAuthenticated = userData => {
        const init = async () => {
            if (userData?.tokens) {
                userData.tokens.idToken.jwtToken = userData.tokens.idToken.toString();
                userData.tokens.accessToken.jwtToken = userData.tokens.accessToken.toString();
                dispatch(signIn(userData.tokens));
                dispatch(initUser());
                sessionStorage.setItem('currentSession', JSON.stringify(userData.tokens));
                dispatch(fetchCompanies());
                dispatch(fetchFeatureFlags());
            }
            else {
                dispatch(signOut());
                sessionStorage.removeItem('returnUrl');
                sessionStorage.removeItem('currentSession');
            }
        };
        init();
    };

    const userSignedOut = () => {
        dispatch(signOut());
        dispatch(initUser());
        dispatch(setCurrentUser(null));
        sessionStorage.removeItem('returnUrl');
        sessionStorage.removeItem('currentSession');
        if (searchParams.has('companyId')) {
            searchParams.delete('companyId');
            setSearchParams(searchParams);
        }
    };

    return (
        <>
            {
                authStatus === 'configuring' && <LoadingMask />
            }
            {
                authStatus !== 'authenticated' ?
                    <UnauthenticatedContent companyId={searchParams.get('companyId')} />
                    :
                    <div className={`app ${screenSizeClass}`}>
                        <Time
                            searchCompanyId={searchParams.get('companyId')}
                            t={t}
                            i18n={i18n}
                        />
                    </div>
            }
        </>
    );
}

function Root() {
    return (
        <Router>
            <Authenticator.Provider>
                <NavigationProvider>
                    <App />
                </NavigationProvider>
            </Authenticator.Provider>
        </Router>
    );
}

export default function wrappedRoot() {
    return (
        <Suspense fallback={<LoadingMask />}>
            <Root />
        </Suspense>
    );
}
