/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug:         false,
        fallback:      'en',
        interoplation: {
            escapeValue: false
        }
    });

i18n.services.formatter.add('FORMATTED_LONG_DATE', (value, lng) => {
    const formattedDate = value.toLocaleString(lng, {
        weekday: 'long',
        year:    'numeric',
        month:   'long',
        day:     'numeric'
    });
    return formattedDate;
});

export default i18n;
