// import { Auth } from 'aws-amplify';
import { get, isEmpty, cloneDeep, orderBy } from 'lodash';
import { addDays, addMinutes, format, getDay, setHours, setMinutes, setSeconds } from 'date-fns';

import template from 'url-template';

import * as ActionTypes from './actionTypes';

import awsHeader from '../helpers/awsAuth';
import showToastMessage from '../helpers/toastHelper';
import { handle422ErrorMessage, handle403ErrorMessage } from '../helpers/errorHelper';
import wennsoftBaseApi, { wennsoftResultBaseApi, wennsoftBlobDownloadResult } from '../apis/core';
import { TransactionStatus } from '../data/transactionStatuses';

const companyPayCodeUrl = template.parse('/paycodes{?limit,page}');
const departmentsUrl = template.parse('/departments{?excludeInactive}');
const jobDivisionUrl = template.parse('/division-jobs');
const serviceDivisionUrl = template.parse('/division-services');
const timeSettingsUrl = template.parse('/time-settings');
const timeSettingsUpdateUrl = template.parse('/time-settings/{timeSettingsId}');
const timesheetUrl = template.parse('/time-sheets{?timesheetStartDate,employeeids,transactionstatus,includeexternalorigindetails,requesterid,companyid,page,limit,forapproval,queryParameterId}');
const timesheetCopyUrl = template.parse('/copy-timesheet');
const timesheetPostUrl = template.parse('/time-trx');
const timesheetDeleteUrl = template.parse('/time-trx/{transactionId}');
const queryParameterUrl = template.parse('/queryparameters');
const licensingReportUrl = template.parse('/internal-company-registrations');
const shiftCodesUrl = template.parse('/shift-codes{?page,limit}');
const dxReportUrl = template.parse('/dx-reports{?reportName,parameterid,emailReport}');

const DEFAULT_PAGE_SIZE = 200;

export const setActiveCompany = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_ACTIVE_COMPANY,
        payload
    });
};

export const setChangePasswordVisible = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_CHANGE_PASSWORD_VISIBLE,
        payload
    });
};

export const setIsInEditMode = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_IS_IN_EDIT_MODE,
        payload
    });
};

export const setIsInRefresh = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_IS_IN_REFRESH,
        payload
    });
};

export const setIsSaveValid = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_IS_SAVE_VALID,
        payload
    });
};

export const setTimesheetGridInstance = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_TIMESHEET_GRID_INSTANCE,
        payload
    });
};

export const fetchTimeSettings = () => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.get(
            timeSettingsUrl.expand({}),
            header
        );

        const data = get(response, 'data', {});

        const newDate = new Date();
        const offset = newDate.getTimezoneOffset() * -1;
        if (data.timeEntryNotifyTime && data.timeEntryNotifyTime.includes(':')) {
            const values = data.timeEntryNotifyTime.split(':');
            let entryTime = setHours(newDate, values[0]);
            entryTime = setMinutes(entryTime, values[1]);
            entryTime = setSeconds(entryTime, values[2]);
            entryTime = addMinutes(entryTime, offset);
            data.timeEntryNotifyTime = entryTime;
        }
        if (data.approvalNotifyTime && data.approvalNotifyTime.includes(':')) {
            const values = data.approvalNotifyTime.split(':');
            let notifyTime = setHours(newDate, values[0]);
            notifyTime = setMinutes(notifyTime, values[1]);
            notifyTime = setSeconds(notifyTime, values[2]);
            notifyTime = addMinutes(notifyTime, offset);
            data.approvalNotifyTime = notifyTime;
        }

        dispatch({
            type:    ActionTypes.FETCH_TIME_SETTINGS,
            payload: data
        });
        return data;
    }
    catch (error) {
        dispatch({
            type:    ActionTypes.FETCH_TIME_SETTINGS,
            payload: {}
        });
        showToastMessage(error, 'error');
    }

};

export const updateTimeSettings = (timeSettingsId, body, t) => async(dispatch, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.put(
            timeSettingsUpdateUrl.expand({
                timeSettingsId
            }),
            body,
            header
        );

        const status = get(response, 'status');

        if (status === 200 || status === 204) {
            dispatch(fetchTimeSettings());
            showToastMessage(t('time.settingsSaved'), 'success');
            return true;
        }
        else {
            showToastMessage(t('time.settingsSavedFailure'), 'error');
            return false;
        }
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};

export const fetchDepartments = (t) => async(dispatch, getState) => {
    const header = await awsHeader();

    const options = {
        excludeInactive: true
    };

    await wennsoftBaseApi.get(
        departmentsUrl.expand({
            ...options
        }),
        header
    )
        .then(response => {
            if (response.status === 200) {
                const { data } = response;
                const departmentList = data.map(dept => {
                    return (
                        {
                            id:          dept.id,
                            description: dept.description
                        }
                    );

                });
                dispatch({
                    type:    ActionTypes.FETCH_DEPARTMENTS,
                    payload: departmentList
                });
            }
            else if (response.status === 422) {
                const { data } = response.data;
                handle422ErrorMessage(data);
            }
            else if (response.status === 403) {
                handle403ErrorMessage(t);
            }
            else {
                showToastMessage(response.statusText, 'error');
            }
        })
        .catch(error => {
            showToastMessage(error, 'error');
        });
};

export const fetchJobDivisions = (t) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        await wennsoftBaseApi.get(
            jobDivisionUrl.expand(),
            header
        )
            .then(response => {
                if (response.status === 200) {
                    const { data } = response;
                    const divisionList = data.map(div => {
                        return (
                            {
                                id:          div.id,
                                description: div.externalId
                            }
                        );

                    });
                    dispatch({
                        type:    ActionTypes.FETCH_JOB_DIVISIONS,
                        payload: divisionList
                    });
                }
                else if (response.status === 422) {
                    const { data } = response.data;
                    handle422ErrorMessage(data);
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                }
                else {
                    showToastMessage(response.statusText, 'error');
                }
            });
    }
    catch (error) {
        showToastMessage(error, 'error');
    }
};

export const fetchServiceDivisions = (t) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        await wennsoftBaseApi.get(
            serviceDivisionUrl.expand(),
            header
        )
            .then(response => {
                if (response.status === 200) {

                    const { data } = response;
                    const divisionList = data.map(div => {
                        return (
                            {
                                id:          div.id,
                                description: div.externalId
                            }
                        );

                    });
                    dispatch({
                        type:    ActionTypes.FETCH_SERVICE_DIVISIONS,
                        payload: divisionList
                    });
                }
                else if (response.status === 422) {
                    const { data } = response.data;
                    handle422ErrorMessage(data);
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                }
                else {
                    showToastMessage(response.statusText, 'error');
                }
            });
    }
    catch (error) {
        showToastMessage(error, 'error');
    }
};

export const fetchCompanyShiftCodes = (t, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        options.limit = options.limit || 5000;
        options.page = options.page || 1;

        const result = await wennsoftResultBaseApi.get(
            shiftCodesUrl.expand({
                ...options
            }),
            header
        )
            .then(response => {
                if (response.status === 200) {
                    const payload = orderBy(response.data.data, ['name', 'description'], ['asc', 'asc']);
                    dispatch({
                        type: ActionTypes.SET_COMPANY_SHIFT_CODES,
                        payload
                    });
                }
                else if (response.status === 422) {
                    const { data } = response.data;
                    handle422ErrorMessage(data);
                    dispatch({
                        type:    ActionTypes.SET_COMPANY_SHIFT_CODES,
                        payload: []
                    });
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    dispatch({
                        type:    ActionTypes.SET_COMPANY_SHIFT_CODES,
                        payload: []
                    });
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    dispatch({
                        type:    ActionTypes.SET_COMPANY_SHIFT_CODES,
                        payload: []
                    });
                }
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_COMPANY_SHIFT_CODES,
            payload: []
        });
    }
};

export const setTimesheetStartDate = (currentPeriod) => async(dispatch, getState) => {
    try {
        dispatch({
            type:    ActionTypes.SET_TIMESHEET_START_DATE,
            payload: currentPeriod
        });
    }
    catch (error) {
        dispatch({
            type:    ActionTypes.SET_TIMESHEET_START_DATE,
            payload: null
        });
    }
};

export const setCurrentWeekEndingDate = (currentPeriod) => async (dispatch, getState) => {
    try {
        dispatch({
            type:    ActionTypes.SET_CURRENT_WEEKENDING_DATE,
            payload: currentPeriod
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
    }
};

export const setPeriodDays = (weekEndingDate) => async (dispatch, getState) => {
    try {
        if (weekEndingDate) {
            const payload = getDaysOfPeriod(weekEndingDate);

            dispatch({

                type: ActionTypes.SET_DAYS_FOR_PERIOD,
                payload
            });
        }
    }
    catch (error) {
        dispatch({
            type:    ActionTypes.SET_DAYS_FOR_PERIOD,
            payload: []
        });
    }
};


export const storeQueryParamter = data => async (dispatch, getState) => {
    const header = await awsHeader();

    const parameters = { parameters: { ...data }};

    try {
        const response = await wennsoftBaseApi.post(
            queryParameterUrl.expand({}), parameters, header
        );

        const payload = get(response, 'data', '');

        return payload;
    } catch (error) {
        showToastMessage(error, 'error');
    }
};

function getDaysOfPeriod(weekEndingDate) {
    const daysOfPeriod = [6];
    let tempDate = weekEndingDate;
    for (let i = 6; i >= 0; i--) {
        daysOfPeriod[i] = {
            id:            i,
            date:          tempDate,
            formattedDate: format(tempDate, 'yyyy-MM-dd'),
            dayOfWeek:     getDay(tempDate)
        };
        tempDate = addDays(tempDate, -1);
    }
    return daysOfPeriod;
}

export const fetchCompanyPayCodes = (t) => async (dispatch, getState) => {
    const header = await awsHeader();
    const options = {
        page:  1,
        limit: 200
    };

    let allPayCodes = [];

    try {
        const response = await wennsoftResultBaseApi.get(
            companyPayCodeUrl.expand({
                ...options
            }),
            header
        );

        const data = get(response, 'data.data', []);
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i < count; i++) {
            options.page = i;
            promises.push(
                wennsoftResultBaseApi.get(
                    companyPayCodeUrl.expand({
                        ...options
                    }),
                    header
                )
            );
        }

        let results = [];
        await Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => results.push(r.data.data));
            });

        allPayCodes = data.concat(...results);

        allPayCodes = orderBy(allPayCodes, 'name', 'description');
        allPayCodes.forEach(pc => {
            pc.paycodeName = pc.name;
            pc.paycodeDescription = pc.description;

            switch (pc.payType) {
                case 1:
                case 2:
                    pc.groupTypeOrder = 1;
                    pc.groupType = 'Regular Pay Codes';
                    break;
                default:
                    pc.groupTypeOrder = 2;
                    pc.groupType = 'Other Pay Codes';
                    break;
            }
        });

        dispatch({
            type:    ActionTypes.FETCH_COMPANY_PAYCODES,
            payload: allPayCodes
        });

        return allPayCodes;

    }
    catch (error) {
        dispatch({
            type:    ActionTypes.FETCH_COMPANY_PAYCODES,
            payload: []
        });
        showToastMessage(error, 'error');
        return false;
    }
};

export const fetchEmployeeTimesheet = (employeeIds, timesheetStartDate, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const daysOfPeriod = getState().time.periodDays;

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: true
        });

        options.limit = DEFAULT_PAGE_SIZE;
        options.page = 1;
        options.timesheetStartDate = timesheetStartDate;

        let url = timesheetUrl.expand({
            ...options
        });

        employeeIds.forEach(id => {
            url = `${url}&employeeids=${id}`;
        });

        const response = await wennsoftBaseApi.get(
            url,
            header
        );

        const data = get(response, 'data.data');

        let timesheetEntries = data.find(ts => ts.employeeId === employeeIds[0]);
        if (!timesheetEntries) {
            timesheetEntries = {
                id:                          null,
                employeeId:                  employeeIds[0],
                timesheetStartDate:          timesheetStartDate,
                timeSheetFormattedStartDate: timesheetStartDate,
                timeTransactions:            [],
                subTotals:                   []
            };
        }
        else {
            timesheetEntries.timeTransactions.forEach(trx => {
                trx.isTrxDirty = false;
            });
            timesheetEntries.subTotals = createSubTotalsByPayCodeType(timesheetEntries.timeTransactions);
        }

        const formattedData = createTimesheetGridDataSet(daysOfPeriod, timesheetEntries.timeTransactions);

        dispatch({
            type:    ActionTypes.SET_TIMESHEET_DATA,
            payload: formattedData
        });

        dispatch({
            type:    ActionTypes.SET_CURRENT_TIMESHEET,
            payload: timesheetEntries
        });

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });
        return formattedData;
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_CURRENT_TIMESHEET,
            payload: {
                id:                          null,
                employeeId:                  employeeIds[0],
                timesheetStartDate:          timesheetStartDate,
                timeSheetFormattedStartDate: timesheetStartDate,
                timeTransactions:            [],
                subTotals:                   []
            }
        });

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });

        return [];
    }
};

export const fetchEmployeeTimesheetList = (employeeIds, timesheetStartDate, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const daysOfPeriod = getState().time.periodDays;

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: true
        });

        options.limit = DEFAULT_PAGE_SIZE;
        options.page = 1;
        options.timesheetStartDate = timesheetStartDate;

        let url = timesheetUrl.expand({
            ...options
        });

        employeeIds.forEach(id => {
            url = `${url}&employeeids=${id}`;
        });

        const response = await wennsoftBaseApi.get(
            url,
            header
        );

        const data = get(response, 'data.data');

        let timesheetEntries = data.find(ts => ts.employeeId === employeeIds[0]);
        if (!timesheetEntries) {
            timesheetEntries = {
                id:                          null,
                employeeId:                  employeeIds[0],
                timesheetStartDate:          timesheetStartDate,
                timeSheetFormattedStartDate: timesheetStartDate,
                timeTransactions:            [],
                subTotals:                   []
            };
        }
        else {
            timesheetEntries.timeTransactions.forEach(trx => {
                trx.isTrxDirty = false;
                trx.shiftCodeId = trx.shiftCodeId ? trx.shiftCodeId : {id: null};
            });
            timesheetEntries.subTotals = createSubTotalsByPayCodeType(timesheetEntries.timeTransactions);
        }

        const formattedData = createTimesheetEntriesDataSet(daysOfPeriod, timesheetEntries.timeTransactions);

        dispatch({
            type:    ActionTypes.SET_TIMESHEET_DATA,
            payload: formattedData
        });

        dispatch({
            type:    ActionTypes.SET_CURRENT_TIMESHEET,
            payload: timesheetEntries
        });

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });

        return formattedData;
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_CURRENT_TIMESHEET,
            payload: {
                id:                          null,
                employeeId:                  employeeIds[0],
                timesheetStartDate:          timesheetStartDate,
                timeSheetFormattedStartDate: timesheetStartDate,
                timeTransactions:            [],
                subTotals:                   []
            }
        });

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });

        return [];
    }
};

export const fetchMobileEmployeeTimesheetList = (employeeIds, timesheetStartDate, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: true
        });

        options.limit = DEFAULT_PAGE_SIZE;
        options.page = 1;
        options.timesheetStartDate = timesheetStartDate;

        let url = timesheetUrl.expand({
            ...options
        });

        employeeIds.forEach(id => {
            url = `${url}&employeeids=${id}`;
        });

        const response = await wennsoftBaseApi.get(
            url,
            header
        );

        const data = get(response, 'data.data');
        let timesheetEntries = data.find(ts => ts.employeeId === employeeIds[0]);
        if (!timesheetEntries) {
            timesheetEntries = {
                id:                          null,
                employeeId:                  employeeIds[0],
                timesheetStartDate:          timesheetStartDate,
                timeSheetFormattedStartDate: timesheetStartDate,
                timeTransactions:            [],
                subTotals:                   []
            };
        }
        else {
            timesheetEntries.timeTransactions.forEach(trx => {
                trx.isTrxDirty = false;
                trx.shiftCodeId = trx.shiftCodeId ? trx.shiftCodeId : {id: null};
                trx.isTimedIn = trx.status === TransactionStatus.TimedIn ? 10 : 11;
            });
        }

        dispatch({
            type:    ActionTypes.SET_CURRENT_TIMESHEET,
            payload: timesheetEntries
        });

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });

        // return formattedData;
        return orderBy(timesheetEntries.timeTransactions, ['isTimedIn', 'transactionType', 'activityName', 'costcodeAlias', 'paycodeName', 'shiftCodeName'],['desc', 'asc', 'asc', 'asc', 'asc', 'asc']);
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_CURRENT_TIMESHEET,
            payload: {
                id:                          null,
                employeeId:                  employeeIds[0],
                timesheetStartDate:          timesheetStartDate,
                timeSheetFormattedStartDate: timesheetStartDate,
                timeTransactions:            [],
                subTotals:                   []
            }
        });

        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });

        return [];
    }
};

function createTimesheetGridDataSet(daysOfPeriod, timesheetData) {
    let entries = [];
    if (isEmpty(timesheetData)) return [];

    timesheetData.forEach(t => {
        const entry = entries.find(e =>
            e.transactionType === t.transactionType &&
            e.activityId.id === t.activityId.id &&
            e.costcodeId?.id === t.costcodeId?.id &&
            e.paycodeId.id === t.paycodeId.id &&
            e.shiftCodeId?.id === t.shiftCodeId?.id &&
            e.equipmentId?.id === t.equipmentId?.id &&
            e.status === t.status);
        if (!entry) {
            const newRecord = t;
            const dowIndex = daysOfPeriod.findIndex(d => t.transactionFormattedDate === d.formattedDate);
            if (dowIndex !== -1) {
                newRecord[daysOfPeriod[0].formattedDate] = null;
                newRecord[daysOfPeriod[1].formattedDate] = null;
                newRecord[daysOfPeriod[2].formattedDate] = null;
                newRecord[daysOfPeriod[3].formattedDate] = null;
                newRecord[daysOfPeriod[4].formattedDate] = null;
                newRecord[daysOfPeriod[5].formattedDate] = null;
                newRecord[daysOfPeriod[6].formattedDate] = null;
                newRecord[daysOfPeriod[dowIndex].formattedDate] = t.hours;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
            }
            entries.push(newRecord);
        }
        else {
            const dowIndex = daysOfPeriod.findIndex(d => t.transactionFormattedDate === d.formattedDate);
            if (dowIndex !== -1) {

                if (entry[daysOfPeriod[dowIndex].formattedDate]) {
                    entry[daysOfPeriod[dowIndex].formattedDate] += t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                }
                else {
                    entry[daysOfPeriod[dowIndex].formattedDate] = t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                }
            }
        }
    });

    return entries;
}

function createTimesheetEntriesDataSet(daysOfPeriod, timesheetData) {
    let entries = [];
    if (isEmpty(timesheetData)) return [];

    orderBy(timesheetData, ['transactionType', 'activityName', 'costcodeAlias', 'paycodeName', 'shiftCodeName'],['asc', 'asc', 'asc', 'asc', 'asc']).forEach(t => {
        const entry = entries.find(e =>
            e.transactionType === t.transactionType &&
            e.activityId.id === t.activityId.id &&
            e.costcodeId?.id === t.costcodeId?.id &&
            e.paycodeId.id === t.paycodeId.id &&
            e.shiftCodeId?.id === t.shiftCodeId?.id &&
            e.equipmentId?.id === t.equipmentId?.id);
        if (!entry) {
            const newRecord = cloneDeep(t);
            const dowIndex = daysOfPeriod.findIndex(d => t.transactionFormattedDate === d.formattedDate);
            if (dowIndex !== -1) {
                newRecord.day0 = null;
                newRecord.day1 = null;
                newRecord.day2 = null;
                newRecord.day3 = null;
                newRecord.day4 = null;
                newRecord.day5 = null;
                newRecord.day6 = null;
                newRecord[daysOfPeriod[0].formattedDate] = null;
                newRecord[daysOfPeriod[1].formattedDate] = null;
                newRecord[daysOfPeriod[2].formattedDate] = null;
                newRecord[daysOfPeriod[3].formattedDate] = null;
                newRecord[daysOfPeriod[4].formattedDate] = null;
                newRecord[daysOfPeriod[5].formattedDate] = null;
                newRecord[daysOfPeriod[6].formattedDate] = null;
                newRecord[`day${dowIndex}`] = t.hours;
                newRecord[daysOfPeriod[dowIndex].formattedDate] = t.hours;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                newRecord[daysOfPeriod[dowIndex].formattedDate + '_transactions'] = [t];
            }
            entries.push(newRecord);
        }
        else {
            const dowIndex = daysOfPeriod.findIndex(d => t.transactionFormattedDate === d.formattedDate);
            if (dowIndex !== -1) {

                if (entry[daysOfPeriod[dowIndex].formattedDate]) {
                    entry[`day${dowIndex}`] += t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate] += t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                    if (entry[daysOfPeriod[dowIndex].formattedDate + '_transactions'])
                        entry[daysOfPeriod[dowIndex].formattedDate + '_transactions'].push(t);
                    else
                        entry[daysOfPeriod[dowIndex].formattedDate + '_transactions'] = [t];
                }
                else {
                    entry[`day${dowIndex}`] += t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate] = t.hours;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_notes'] = t.notes;
                    entry[daysOfPeriod[dowIndex].formattedDate + '_id'] = t.id;
                    if (entry[daysOfPeriod[dowIndex].formattedDate + '_transactions'])
                        entry[daysOfPeriod[dowIndex].formattedDate + '_transactions'].push(t);
                    else
                        entry[daysOfPeriod[dowIndex].formattedDate + '_transactions'] = [t];
                }
            }
        }
    });

    return entries;
}

export const fetchTimesheetsForApproval = (employeeList, timesheetStartDate, t, options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    // const employeeIds = employeeList.map(emp => emp.id);
    const formattedData = [];
    let result = [];

    dispatch({
        type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
        payload: true
    });



    try {
        options.limit = DEFAULT_PAGE_SIZE;
        options.page = 1;

        const fetchParameters = {
            // EmployeeIds:                  employeeIds,
            Page:                         options.page,
            Limit:                        options.limit,
            TimeSheetStartDate:           timesheetStartDate,
            IncludeExternalOriginDetails: true,
            TransactionStatus:            options.transactionstatus,
            ForApproval:                  options.forapproval
        };

        const queryParameterId = await dispatch(storeQueryParamter(fetchParameters));

        result = await wennsoftBaseApi.get(
            timesheetUrl.expand({queryParameterId: queryParameterId}),
            header
        )
            .then(response => {
                if (response.status === 200) {
                    const data = response.data.data;
                    data.forEach(timesheet => {
                        let regularHours = 0;
                        let otherHours = 0;
                        let totalHours = 0;
                        timesheet.timeTransactions.forEach(tt => {
                            if (tt.status === TransactionStatus.Submitted) {
                                if (tt.payCodeType === 1 || tt.payCodeType === 2) {
                                    regularHours += tt.hours;
                                }
                                else {
                                    otherHours += tt.hours;
                                }
                                totalHours += tt.hours;
                            }
                        });

                        const formattedDates = `${timesheet.timeSheetFormattedStartDate} - ${format(addDays(new Date(timesheet.timeSheetStartDate), 7), 'yyyy-MM-dd')}`;
                        const employeeTimesheet = {
                            id:                          timesheet.id,
                            companyId:                   timesheet.companyId,
                            employeeId:                  timesheet.employeeId,
                            timesheetStartDate:          timesheet.timeSheetStartDate,
                            timesheetFormattedStartDate: timesheet.timeSheetFormattedStartDate,
                            employeeName:                `${timesheet.timeTransactions[0].employeeFirstName} ${timesheet.timeTransactions[0].employeeLastName}`,
                            period:                      formattedDates,
                            regularHours:                parseFloat(regularHours).toFixed(2),
                            otherHours:                  parseFloat(otherHours).toFixed(2),
                            totalHours:                  parseFloat(totalHours).toFixed(2),
                            status:                      'Submitted',
                            transactions:                orderBy(timesheet.timeTransactions, 'transactionDate', 'asc')
                        };
                        formattedData.push(employeeTimesheet);
                    });
                    return orderBy(formattedData, 'employeeName', 'asc');
                }
                else if (response.status === 422) {
                    const {data} = response;
                    handle422ErrorMessage(data);
                    return [];
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return [];
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return [];
                }
            });
    }
    catch (err) {
        showToastMessage(err, 'error');
    }
    finally {
        dispatch({
            type:    ActionTypes.SET_IS_LOADING_TIMESHEET,
            payload: false
        });
    }

    return result;
};

const createSubTotalsByPayCodeType = timesheetData => {
    const subTotals = [];

    try {
        timesheetData.forEach(tt => {
            const record = subTotals.find(st => st.formattedDate === tt.transactionFormattedDate);

            if (record) {
                // Hourly = 1 and Salary = 2
                if (tt.payCodeType === 1 || tt.payCodeType === 2) {
                    record.regularHours += tt.hours;
                }
                else {
                    record.otherHours += tt.hours;
                }
            }
            else {
                const subTotal = {
                    transactionDate: tt.transactionDate,
                    formattedDate:   tt.transactionFormattedDate,
                    regularHours:    tt.payCodeType === 1 || tt.payCodeType === 2 ? tt.hours : 0,
                    otherHours:      tt.payCodeType !== 1 && tt.payCodeType !== 2 ? tt.hours : 0
                };
                subTotals.push(subTotal);
            }
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
        return subTotals;
    }

    return subTotals;
};

export const fetchSubTotals = () => async (disptach, getState) => {
    const totals = await getState().time.currentTimesheet.subTotals;
    return totals;
};

export const insertTimeSheetTransaction = (employeeId, timesheetData, t, options = {}) => async (dispatch, getState) => {
    // create one transaction for each item in the timesheetData
    const header = await awsHeader();

    const activeCompany = getState().time.activeCompany;
    const currentUser = getState().currentUser;
    const featureFlags = getState().core.featureFlags;
    const useTimeEntryFormFlag = featureFlags.find(ff => ff.name === 'useTimeEntryForm');

    let useTimeEntryForm = false;
    if (useTimeEntryFormFlag) {
        useTimeEntryForm = useTimeEntryFormFlag.featureFl;
    }
    try {
        let body = {
            timesheetId:        timesheetData.timesheetId,
            timesheetStartDate: timesheetData.timesheetStartDate,
            employeeId:         timesheetData.employeeId,
            transactions:       []
        };

        timesheetData.transactions.filter(trx => trx.status !== TransactionStatus.Processed).forEach(trans => {
            const ttx = {
                id:                       trans.id ? trans.id : null,
                timesheetId:              trans.timesheetId ? trans.timesheetId : null,
                employeeId:               trans.employeeId ? trans.employeeId : employeeId,
                activityId:               trans.activityId,
                costType:                 trans.costType,
                costcodeId:               trans.costcodeId ? trans.costcodeId : null,
                paycodeId:                trans.paycodeId,
                equipmentId:              trans.equipmentId ? trans.equipmentId : null,
                shiftCodeId:              trans.shiftCodeId ? trans.shiftCodeId : null,
                status:                   trans.status,
                statusComment:            trans.statusComment ? trans.statusComment : null,
                description:              trans.description ? trans.description : null,
                transactionType:          trans.transactionType,
                transactionDate:          trans.transactionDate,
                timeIn:                   trans.timeIn ? trans.timeIn : null,
                timeOut:                  trans.timeOut ? trans.timeOut : null,
                hours:                    trans.hours,
                acknowledgementTimestamp: trans.acknowledgementTimestamp
            };
            body.transactions.push(ttx);
        });

        const result = await wennsoftResultBaseApi.post(
            timesheetPostUrl.expand({
                ...options
            }),
            body,
            header
        )
            .then((response) => {
                if (response.status === 200) {
                    const newIds = response.data;

                    const fetchOptions = {
                        includeexternalorigindetails: true,
                        companyid:                    activeCompany.id,
                        requesterId:                  currentUser.id
                    };
                    if (!useTimeEntryForm) {
                        dispatch(fetchEmployeeTimesheet([employeeId], timesheetData.timesheetStartDate, fetchOptions));
                    }
                    showToastMessage(t('time.transactionsSaved'), 'success');
                    return {
                        success: true,
                        id:      newIds
                    };
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    return {
                        success: false,
                        id:      []
                    };
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return {
                        success: false,
                        id:      []
                    };
                }
            })
            .catch(error => {
                showToastMessage(error, 'error');
                return {
                    success: false,
                    id:      []
                };
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return {
            success: false,
            id:      null
        };
    }
};

export const copyEmployeeTimesheet = (employeeid, copytimesheetstartdate, t) => async (dispatch, getState) => {
    const header = await awsHeader();

    const body = {
        employeeid:             employeeid,
        copytimesheetstartdate: copytimesheetstartdate
    };

    try {
        const result = await wennsoftResultBaseApi.post(
            timesheetCopyUrl.expand(),
            body,
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.satus === 204) {
                    return true;
                }
                else if (response.status === 422) {
                    const { errors } = response.data;
                    handle422ErrorMessage(errors);
                    return false;
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return false;
                }
            });
        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};

export const deleteTimeTransactions = (transaction, t) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const transactionId = transaction.id;
        const response = await wennsoftBaseApi.delete(
            timesheetDeleteUrl.expand({
                transactionId
            }),
            header
        );

        const responseStatus = get(response, 'status');

        if (responseStatus === 200 || responseStatus === 204) {
            showToastMessage(t('time.timeEntryForDeleted', {transDate: transaction.formattedDate}), 'success');
        }
        else {
            showToastMessage(t('time.timeEntryForNotDeleted', {transDate: transaction.formattedDate}), 'error');
        }
        return true;
    }
    catch (error) {
        const message = t('time.errorDeletingTimeEntryFor', {transDate: transaction.formattedDate, error: error});
        showToastMessage(message, 'error');
        return false;
    }
};

export const fetchLicensingReport = (t) => async(dispatch, getState) => {
    const header = await awsHeader();

    try {
        const result = await wennsoftResultBaseApi.get(
            licensingReportUrl.expand({}),
            header
        )
            .then(response => {
                if (response.status === 200) {
                    const reportData = response.data.timeReport;
                    reportData.forEach(data => {
                        data.availableCount = data.timeEmployeeLicenseCount - data.currentlyUsedTimeEmployeeLicenseCount;
                    });
                    return orderBy(reportData, 'companyName', 'asc');
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    return [];
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return [];
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return [];
                }
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return null;
    }
};

export const printEmployeeTimesheetReport = (companyId, employeeIds, timesheetStartDate, sendAsEmail, t) => async(dispatch, getState) => {
    const header = await awsHeader();
    const newDate = new Date();
    const offset = newDate.getTimezoneOffset() * -1;
    try {
        const fetchParameters = {
            companyid:          companyId,
            employeeids:        employeeIds,
            timesheetstartdate: timesheetStartDate,
            timezoneoffset:     offset
        };

        const queryParameterId = await dispatch(storeQueryParamter(fetchParameters));

        await wennsoftBlobDownloadResult.get(
            dxReportUrl.expand({
                reportName:  'EmployeeTimesheetReport',
                parameterid: queryParameterId,
                emailReport: sendAsEmail
            }),
            header
        )
            .then(response => {
                if (response.status === 200) {

                    if (!sendAsEmail)
                    {
                        const blob = new Blob([response.data], { type: 'application/pdf'});
                        const url = window.URL.createObjectURL(blob);

                        // create a temp link to start the download
                        const tempLink = document.createElement('a');
                        tempLink.href = url;
                        tempLink.target = '_blank';
                        tempLink.setAttribute('download', 'Employee Timesheet Report.pdf');
                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(url);

                        showToastMessage(t('common.reportDownloaded'), 'success');
                    }
                    else {
                        showToastMessage(t('common.reportEmailed'), 'success');
                    }
                }
                else if (response.status === 422) {
                    const data = response;
                    handle422ErrorMessage(data);
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                }
                else {
                    showToastMessage(response.statusText, 'error');
                }
            })
        ;

    }
    catch (error) {
        showToastMessage(error, 'error');
    }
};

export const setCurrentTimeSheet = (payload) => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_CURRENT_TIMESHEET,
        payload
    });
};

export const clearEmployeeTimesheet = () => async(dispatch, getState) => {
    dispatch({
        type:    ActionTypes.SET_CURRENT_TIMESHEET,
        payload: {
            timesheetId:                 null,
            employeeId:                  null,
            timesheetStartDate:          null,
            timeSheetFormattedStartDate: null,
            timeTransactions:            [],
            subTotals:                   []
        }

    });

    dispatch({
        type:    ActionTypes.SET_TIMESHEET_DATA,
        payload: []
    });
};
