import { get, orderBy } from 'lodash';
import template from 'url-template';
import showToastMessage from '../helpers/toastHelper';
//import {handleNetworkError, enqueueSnackbar} from './applicationMessageActions';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';

import * as ActionTypes from './actionTypes';

const AuthorizationRolesUrl = template.parse('/companies/{companyId}/authorization-roles{?searchFilter,limit}');
const CompanyDetailsUrl = template.parse('/companies/{companyId}');

const DEFAULT_PAGE_LIMIT = 100;

export const fetchCompanyInfo = (company, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const companyId = company.company.id;
        options.limit || (options.limit = DEFAULT_PAGE_LIMIT);

        let rolesPromise = [];
        let companyPromise = [];
        const companyInfo = getState().companyInfo;

        rolesPromise.push(wennsoftBaseApi.get(
            AuthorizationRolesUrl.expand({
                companyId,
                ...options
            }),
            header
        ));

        const [roleData] = await Promise.all(rolesPromise.map(p => p.catch(err => err)));
        const roles = get(roleData, 'data.data', []);

        if (roles.length !== 0) {
            companyPromise.push(wennsoftBaseApi.get(
                CompanyDetailsUrl.expand({
                    companyId
                }),
                header
            ));
            const [companyDetails] = await Promise.all(companyPromise.map(p => p.catch(err => err)));
            if (companyDetails) {

                companyInfo.applications = company.applications;
                companyInfo.accentColor = company.company.accentColor;
                companyInfo.billingStatus = company.company.billingStatus;
                companyInfo.billingContact = companyDetails.data.billingContact;
                companyInfo.companyName = company.company.name;
                companyInfo.companyId = company.company.id;
                companyInfo.companyAddress = company.company.address.formattedAddress;
                companyInfo.enabled = company.company.enabled;
                companyInfo.hasLogo = company.company.hasLogo;
                companyInfo.timeZone = company.company.timeZone;
                companyInfo.timeZoneOffset = company.company.timeZoneOffset;
                companyInfo.roles = orderBy(roles, ['name'], ['name']);
            }

            dispatch({
                type:    ActionTypes.FETCH_COMPANY_INFO,
                payload: companyInfo
            });
            return true;
        }
        else {
            dispatch({
                type:    ActionTypes.FETCH_COMPANY_INFO,
                payload: {
                    billingStatus:   null,
                    billingContact:  {},
                    companyName:     null,
                    companyId:       null,
                    companyAddress:  null,
                    contractorType:  null,
                    contractorTypes: [],
                    enabled:         false,
                    roles:           [],
                    role:            {},
                    scoringEnabled:  false,
                    integrations:    []
                }
            });
            return false;
        }
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.FETCH_COMPANY_INFO,
            payload: {
                billingStatus:   null,
                billingContact:  {},
                companyName:     null,
                companyId:       null,
                companyAddress:  null,
                contractorType:  null,
                contractorTypes: [],
                enabled:         false,
                roles:           [],
                role:            {},
                scoringEnabled:  false,
                integrations:    []
            }
        });
    }
};
