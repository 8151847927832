import React from 'react';
import Popup, { Position } from 'devextreme-react/popup';
import {AccountSettings} from '@aws-amplify/ui-react';
import showToastMessage from '../../helpers/toastHelper';
import { useTranslation } from 'react-i18next';

const ChangePassword = ({
    visible = false,
    setVisible
}) => {
    const { t } = useTranslation();
    const regExPattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

    const onHiding = () => {
        setVisible(false);
    };

    const onSuccess = () => {
        showToastMessage(t('common.passwordSuccessfullyChanged'), 'success');
        setVisible(false);
    };

    const minLengthValidator = {
        validationMode: 'onChange',
        validator:      (password) => password.length >= 8,
        message:        t('validation.password8Characters')
    };

    const specialCharacterValidator = {
        validationMode: 'onChange',
        validator:      (password) => {
            const isValid = regExPattern.test(password);
            return isValid;
        },
        message: t('validation.password1Special')
    };

    const upperCaseValidator = {
        validationMode: 'onChange',
        validator:      (password) => {
            const isValid = regExPattern.test(password);
            return isValid;
        },
        message: t('validation.password1UpperCase')
    };

    const lowerCaseValidator = {
        validationMode: 'onChange',
        validator:      (password) => {
            const isValid = regExPattern.test(password);
            return isValid;
        },
        message: t('validation.password1LowerCase')
    };

    const numberValidator = {
        validationMode: 'onChange',
        validator:      (password) => {
            const isValid = regExPattern.test(password);
            return isValid;
        },
        message: t('validation.password1Number')
    };

    return (
        <Popup
            visible={visible}
            title={t('common.changePassword')}
            dragEnabled={false}
            container=".dx-viewport"
            width={500}
            height={'auto'}
            onHiding={onHiding}
            showCloseButton={true}
        >
            <Position
                at="center"
                my="center"
                of=""
                collision="fit"
            />
            <AccountSettings.ChangePassword
                onSuccess={onSuccess}
                validators={[
                    minLengthValidator,
                    specialCharacterValidator,
                    upperCaseValidator,
                    lowerCaseValidator,
                    numberValidator
                ]}
            />
        </Popup>
    );
};

export default ChangePassword;
