import React, { useCallback, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import DataGrid, {
    Column,
} from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { FormPopup } from '../utils/form-popup/FormPopup';
import TimeApprovalModal from './TimeAppovalModal';
import PeriodSelector from '../PeriodSelector/PeriodSelector';

import * as AnnotationActions from '../../actions/annotationActions';
import * as TimeActions from '../../actions/timeActions';
import { TransactionStatus } from '../../data/transactionStatuses';

import './TimeManagerApproval.scss';

const fetchTimeSheetOptions = {
    includeexternalorigindetails: true,
    companyid:                    null,
    requesterid:                  null
};

const defaultApprovalModal = {
    title:               '',
    timesheet:           {},
    transactions:        [],
    fullTransactionList: []
};

const TimeManagerApproval = ({
    activeCompany,
    createAnnotation,
    currentUser,
    employeeList,
    fetchTimesheetsForApproval,
    insertTimeSheetTransaction,
    isLoadingTimesheet,
    timesheetStartDate,
    updateAnnotation
}) => {
    const { t } = useTranslation();
    const [approvalModal, setApprovalModal] = useState(defaultApprovalModal);
    const [popupVisible, setPopupVisible] = useState(false);

    const gridRef = useRef();

    const gridDataSource = useMemo(() => {
        const store = new CustomStore({
            loadMode:  'processed',
            onLoading: (loadOptions) => {
                const availableEmployees = currentUser.isSuperUser ? employeeList : employeeList.filter(emp => emp.userId !== currentUser.id && emp.managerId);

                fetchTimeSheetOptions.companyid = activeCompany.id;
                fetchTimeSheetOptions.requesterid = currentUser.id;
                fetchTimeSheetOptions.transactionstatus = TransactionStatus.Submitted;
                fetchTimeSheetOptions.forapproval = true;
                loadOptions.userData = {
                    employeeIds:  availableEmployees,
                    startDate:    timesheetStartDate,
                    fetchOptions: fetchTimeSheetOptions
                };
            },
            load: async (loadOptions) => {
                const timesheets = await fetchTimesheetsForApproval(loadOptions.userData.employeeIds, loadOptions.userData.startDate, t, loadOptions.userData.fetchOptions);
                return timesheets;
            },
            onLoaded: (data) => {
                if (popupVisible) {
                    const employeeData = data.find(emp => emp.employeeId === approvalModal.timesheet.employeeId);
                    if (employeeData) {
                        if (employeeData.transactions.length !== 0) {
                            handlePeriodClick(employeeData);
                        }
                        else {
                            setApprovalModal(defaultApprovalModal);
                            setPopupVisible(false);
                        }

                    }
                    else {
                        setApprovalModal(defaultApprovalModal);
                        setPopupVisible(false);
                    }
                }
            }
        });

        return new DataSource({
            id:    'id',
            store: store
        });

    }, [employeeList, timesheetStartDate, popupVisible]);

    const handleRefreshClick = useCallback(async e => {
        const gridInstance = gridRef.current?.instance;
        if (gridInstance) {
            gridInstance.refresh();
        }
    }, []);

    const RefreshButton = () => {
        return (
            <div>
                <Button
                    name="refreshButton"
                    icon="fa-solid fa-rotate"
                    height={32}
                    onClick={handleRefreshClick}
                    hint={t('common.refresh')}
                    disabled={isLoadingTimesheet}
                />
            </div>
        );
    };

    const renderLabel = () => {
        return <div className="manager-approval-label">{t('time.managerApprovals')}</div>;
    };

    const displayToolbar = () => {
        return (
            <Toolbar>
                <Item
                    location="before"
                    render={renderLabel}
                />
                <Item location="after">
                    <PeriodSelector
                        timesheetGridInstance={gridRef}
                    />
                </Item>
                <Item location="after">
                    <RefreshButton />
                </Item>
            </Toolbar>
        );
    };

    const handlePeriodClick = (data) => {
        const title = t('time.approvalModalTitle', {employeeName: data.employeeName, period: data.period});
        const timesheet = {
            id:                 data.id,
            employeeId:         data.employeeId,
            timesheetStartDate: data.timesheetFormattedStartDate
        };
        const transactions = data.transactions.filter(trx => trx.status === TransactionStatus.Submitted);
        setPopupVisible(true);
        setApprovalModal({
            timesheet,
            title,
            transactions,
            fullTransactionList: data.transactions
        });
    };

    const renderPeriodCell = (cellData) => {
        const { data } = cellData;
        return (
            <div
                id={data.employeeId}
                className="manager-approval-period-cell"
                onClick={() => handlePeriodClick(data)}
                role="none"
            >
                {cellData.value}
            </div>
        );
    };

    const onApprovalModalHiding = useCallback(e => {
        setApprovalModal(defaultApprovalModal);
        setPopupVisible(false);
    }, []);

    const changeModalVisibility = useCallback(e => {
        setApprovalModal(defaultApprovalModal);
        setPopupVisible(!popupVisible);
        if (!e) {
            const gridInstance = gridRef.current?.instance;
            if (gridInstance) {
                gridInstance.refresh();
            }
        }
    }, [popupVisible]);

    const formatTransactionsForSave = (transactions, approvalModal) => {
        let transactionList = [];
        if (isArray(transactions)) {
            transactions.forEach(trx => {
                const ttx = {
                    id:                       trx.id ? trx.id : null,
                    timesheetId:              trx.timesheetId ? trx.timesheetId : null,
                    employeeId:               trx.employeeId?.id ? trx.employeeId.id : approvalModal.timesheet.employeeId,
                    activityId:               trx.activityId.id,
                    costcodeId:               trx.costcodeId?.id ? trx.costcodeId.id : null,
                    paycodeId:                trx.paycodeId.id,
                    shiftCodeId:              trx.shiftCodeId?.id,
                    equipmentId:              trx.equipment?.id ? trx.equipmentId.id : null,
                    status:                   trx.status,
                    statusComment:            trx.statusComment ? trx.statusComment : null,
                    description:              trx.description ? trx.description : null,
                    transactionType:          trx.transactionType,
                    transactionDate:          trx.transactionDate,
                    timeIn:                   trx.timeIn ? trx.timeIn : null,
                    timeOut:                  trx.timeOut ? trx.timeOut : null,
                    hours:                    trx.hours,
                    acknowledgementTimestamp: trx.acknowledgementTimestamp
                };
                transactionList.push(ttx);
            });
        }
        else {
            const ttx = {
                id:                       transactions.id ? transactions.id : null,
                timesheetId:              transactions.timesheetId ? transactions.timesheetId : approvalModal.timesheet.id,
                employeeId:               transactions.employeeId?.id ? transactions.employeeId.id : approvalModal.timesheet.employeeId,
                activityId:               transactions.activityId.id,
                costcodeId:               transactions.costcodeId?.id ? transactions.costcodeId.id : null,
                paycodeId:                transactions.paycodeId.id,
                shiftCodeId:              transactions.shiftCodeId?.id ? transactions.shiftCodeId.id : null,
                equipmentId:              transactions.equipment?.id ? transactions.equipmentId.id : null,
                status:                   transactions.status,
                statusComment:            transactions.statusComment ? transactions.statusComment : null,
                description:              transactions.description ? transactions.description : null,
                transactionType:          transactions.transactionType,
                transactionDate:          transactions.transactionDate,
                timeIn:                   transactions.timeIn ? transactions.timeIn : null,
                timeOut:                  transactions.timeOut ? transactions.timeOut : null,
                hours:                    transactions.hours,
                acknowledgementTimestamp: transactions.acknowledgementTimestamp
            };
            transactionList.push(ttx);
            // transactionList.push(transactions);
        }
        const formattedData = {
            timesheetId:        approvalModal.timesheet.id,
            timesheetStartDate: approvalModal.timesheet.timesheetStartDate,
            employeeId:         approvalModal.timesheet.employeeId,
            transactions:       transactionList
        };
        return formattedData;
    };

    const handleTransactionAccept = useCallback(async e => {
        const {rowType, data} = e.row;
        if (rowType === 'data') {
            // Set the status of the transaction to Approved
            data.status = TransactionStatus.Approved;

            const transactions = formatTransactionsForSave(data, approvalModal);
            const result = await insertTimeSheetTransaction(transactions.employeeId, transactions, t);
            if (result.success) {
                const gridInstance = gridRef.current?.instance;
                if (gridInstance) {
                    gridInstance.refresh();
                }
            }

        }
    }, [approvalModal]);

    const handleTransactionReject = useCallback(async data => {
        if (data) {
            data.status = TransactionStatus.Rejected;
            const transactions = formatTransactionsForSave(data, approvalModal);
            const result = await insertTimeSheetTransaction(transactions.employeeId, transactions, t);
            if (result.success) {
                const gridInstance = gridRef.current?.instance;
                if (gridInstance) {
                    gridInstance.refresh();
                }
            }
        }
    }, [approvalModal]);

    const handleTransactionAcceptAll = useCallback(async transactions => {
        transactions.forEach(trx => trx.status = TransactionStatus.Approved);
        const formattedTransactions = formatTransactionsForSave(transactions, approvalModal);
        const result = await insertTimeSheetTransaction(formattedTransactions.employeeId, formattedTransactions, t);
        if (result.success) {
            const gridInstance = gridRef.current?.instance;
            if (gridInstance) {
                gridInstance.refresh();
            }
        }
    }, [approvalModal]);

    const handleTranscationUpdate = useCallback(async transaction => {
        let noteToUpdate;
        if (transaction.notes.length !== 0) {
            noteToUpdate = transaction.notes[0];
        }
        const formattedTransactions = formatTransactionsForSave(transaction, approvalModal);
        const result = await insertTimeSheetTransaction(formattedTransactions.employeeId, formattedTransactions, t);
        if (result.success && noteToUpdate) {
            // save the note
            if (noteToUpdate.isUpdated) {
                if (noteToUpdate.id) {
                    await updateAnnotation(activeCompany.id, noteToUpdate, t);
                }
                else {
                    await createAnnotation(activeCompany.id, noteToUpdate, t);
                }
            }
        }

        const gridInstance = gridRef.current?.instance;
        if (gridInstance) {
            gridInstance.refresh();
        }
        return result.success;
    }, [approvalModal]);

    return (
        <>
            <div className="manager-approval-header-wrapper">
                <div className="manager-approval-toolbar-row">
                    <div className="manager-approval-toolbar-container">
                        {displayToolbar()}
                    </div>
                </div>
            </div>
            <div className="manager-approval-grid-container">
                <div className="manager-approval-grid">
                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        noDataText={t('time.noSubmittedTimesheets')}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        height={'calc(100vh - 250px)'}
                    >
                        <Column
                            dataField="employeeName"
                            caption={t('time.employeeName')}
                        />
                        <Column
                            dataField="period"
                            cellRender={renderPeriodCell}
                            caption={t('time.period')}
                        />
                        <Column
                            dataField="regularHours"
                            caption={t('time.regularHours')}
                        />
                        <Column
                            dataField="otherHours"
                            caption={t('time.otherHours')}
                        />
                        <Column
                            dataField="totalHours"
                            caption={t('time.totalHours')}
                        />
                        <Column
                            dataField="status"
                            caption={t('time.status')}
                        />
                    </DataGrid>
                    {
                        popupVisible ?
                            <FormPopup
                                cancelButtonText={t('common.close')}
                                onHiding={onApprovalModalHiding}
                                showSaveButton={false}
                                showMultiSaveButton={false}
                                setVisible={changeModalVisibility}
                                title={approvalModal.title}
                                visible={popupVisible}
                                width="80vw"
                            >
                                <TimeApprovalModal
                                    fullTransactionList={approvalModal.fullTransactionList}
                                    handleTransactionAccept={handleTransactionAccept}
                                    handleTransactionAcceptAll={handleTransactionAcceptAll}
                                    handleTransactionReject={handleTransactionReject}
                                    handleTransactionUpdate={handleTranscationUpdate}
                                    timesheet={approvalModal.timesheet}
                                    transactions={approvalModal.transactions}
                                />
                            </FormPopup>
                            : null
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    activeCompany:         state.time.activeCompany,
    currentUser:           state.currentUser,
    employeeList:          state.employee.employeeList,
    isLoadingTimesheet:    state.time.isLoadingTimesheet,
    timesheetStartDate:    state.time.timesheetStartDate,
    timesheetsForApproval: state.time.timesheetsForApproval,
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeManagerApproval);
