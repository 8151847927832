import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isNull } from 'lodash';
import { Tooltip } from 'devextreme-react/tooltip';
import { useTranslation } from 'react-i18next';

import DataGrid, {
    Button as GridButton,
    Column,
    Format,
    Grouping,
    GroupPanel,
    Item,
    Toolbar
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import { custom } from 'devextreme/ui/dialog';

import { FormPopup } from '../utils/form-popup/FormPopup';
import TimeManagerEditForm from './TimeManagerEditForm';
import TimeRejectionForm from './TimeRejectionForm';
import {
    renderActivityCell,
    renderCostCodeCell,
    renderPayCodeCell,
    renderShiftCodeCell,
} from '../time-entry-list/TimeEntryListHelper.js';

import { TransactionType } from '../../data/transactionTypes';

import * as AnnotationActions from '../../actions/annotationActions';
import * as TimeActions from '../../actions/timeActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as JobActions from '../../actions/jobActions';

import './TimeApprovalModal.scss';

const ApproveButtonAttr = {
    class: 'approve-button'
};

const RejectButtonAttr = {
    class: 'reject-button'
};

const TimeApprovalModal = ({
    featureFlags,
    fetchEmployeePayCodes,
    fetchJobCostCodes,
    fullTransactionList,
    handleTransactionAccept,
    handleTransactionReject,
    handleTransactionAcceptAll,
    handleTransactionUpdate,
    settings,
    transactions,
}) => {
    const { t, i18n } = useTranslation();
    const [gridData, setGridData] = useState([]);
    const [transactionData, setTransactionData] = useState();
    const [popupVisible, setPopupVisible] = useState(false);
    const [timeEntryPopupVisible, setTimeEntryPopupVisible] = useState(false);
    const [editFormData, setEditFormData] = useState();
    const [editFormEmployee, setEditFormEmployee] = useState();
    const [jobCostCodes, setJobCostCodes] = useState([]);
    const [useTimeInTimeOut, setUseTimeInTimeOut] = useState(false);

    const gridRef = useRef();

    const rejectionPopupVisibleRef = useRef();
    const setRejectionPopupVisibleRef = data => {
        rejectionPopupVisibleRef.current = data;
        if (!data) {
            setRejectionFormData(null);
        }
        setPopupVisible(data);
    };

    const managerEditFormData = useRef();
    const setManagerEditFormData = data => {
        managerEditFormData.current = data;
        setEditFormData(data);
    };

    const editFormPopupVisibleRef = useRef();
    const setEditFormPopuVisibleRef = data => {
        editFormPopupVisibleRef.current = data;
        if (!data) {
            setEditFormData(null);
        }
        setTimeEntryPopupVisible(data);
    };

    const isFormDirtyStateRef = useRef(false);
    const setIsFormDirtyState = data => {
        isFormDirtyStateRef.current = data;
    };

    const isEditFormDirtyStateRef = useRef(false);
    const setIsEditFormDirtyStateRef = data => {
        isEditFormDirtyStateRef.current = data;
    };

    const rejectionFormData = useRef();
    const setRejectionFormData = async data => {
        rejectionFormData.current = data;
        setIsFormDirtyState(true);
        await setTransactionData(data);
    };

    const isResetFormRef = useRef(false);
    const setIsResetFormRef = data => {
        isResetFormRef.current = data;
    };

    useEffect(() => {
        const useTimeInOutFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');
        if (useTimeInOutFlag) {
            if (useTimeInOutFlag.featureFl && settings.useTimeInTimeOut) {
                setUseTimeInTimeOut(true);
            }
            else {
                setUseTimeInTimeOut(false);
            }
        }
        else {
            setUseTimeInTimeOut(false);
        }
    }, [featureFlags, settings.useTimeInTimeOut]);

    useEffect(() => {
        setGridData(transactions);
    }, [transactions]);

    const onDataChanged = useCallback(data => {

        setRejectionFormData(data);
    }, []);


    const renderTimeCell = useTimeInOut => cellData => {
        const { column, data } = cellData;
        const cellId = `${column.dataField}-${cellData.columnIndex}-${cellData.rowIndex}`;
        let tooltipValue;
        let displayValue = data[column.dataField];
        if (useTimeInOut && !isNull(data[column.dataField])) {
            tooltipValue = new Date(displayValue).toLocaleString(i18n.resolvedLanguage, {
                weekday: 'long',
                year:    'numeric',
                month:   'long',
                day:     'numeric'
            });
            const formattedTime = new Date(displayValue).toLocaleTimeString(i18n.resolvedLanguage);
            displayValue = formattedTime;
            tooltipValue = `${tooltipValue} ${formattedTime}`;
        }
        return (
            <>
                <div id={cellId}>{displayValue}</div>
                { tooltipValue &&
                <Tooltip
                    target={`#${cellId}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="bottom"
                    hideOnOutsideClick={true}
                >
                    <div className="time-tooltip">
                        <div className="time-tooltip-label">{column.dataField === 'timeIn' ? `${t('time.timeIn')}:` : `${t('time.timeOut')}:`}</div>
                        <div className="time-tooltip-label">{tooltipValue}</div>
                    </div>
                </Tooltip>
                }
            </>
        );
    };

    const renderNoteCell = useCallback(cellData => {
        const { data } = cellData;
        const note = data.notes.length === 0 ? '' : data.notes[0].noteText;

        return (
            <div className="manager-approval-employee-note">
                {note}
            </div>
        );
    }, []);

    const handleApproveAll = useCallback(e => {
        const customDialog = custom({
            title:       t('common.approveAll'),
            messageHtml: t('time.confirmApproveAll'),
            buttons:     [{
                text:        t('common.yes'),
                stylingMode: 'text',
                onClick:     e => {
                    return true;
                }
            },
            {
                text:        t('common.no'),
                stylingMode: 'text',
                onClick:     e => {
                    return false;
                }
            }]
        });

        customDialog.show()
            .then(dialogResult => {
                if (dialogResult) {
                    handleTransactionAcceptAll(transactions);
                }
            });
    }, [transactions]);

    const renderApproveIcon = () => {
        return <i className="fa-regular fa-thumbs-up fa-lg approve-button" style={{color: 'green'}} />;
    };

    const renderRejectIcon = () => {
        return <i className="fa-regular fa-thumbs-down fa-lg reject-button" style={{color: 'red'}} />;
    };

    const handleRejectionShowModal = useCallback(async e => {
        const { data } = e.row;
        setRejectionFormData(data);
        setIsFormDirtyState(false);
        setRejectionPopupVisibleRef(true);
    }, []);

    const handleRejection = useCallback(async e => {
        setIsFormDirtyState(false);
        if (rejectionFormData.current) {
            handleTransactionReject(rejectionFormData.current);
            changeRejectModalVisibility(false);
        }
        setRejectionFormData(null);
    }, [rejectionFormData, setRejectionPopupVisibleRef]);

    const handleEditEntry = useCallback(async e => {
        setIsEditFormDirtyStateRef(false);
        const {data} = e.row;
        await setEditFormEmployee(`${data.employeeFirstName} ${data.employeeLastName}`);
        if (data.transactionType === TransactionType.Job) {
            // get the job cost codes
            await fetchJobCostCodes(data.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        await fetchEmployeePayCodes(data.employeeId.id);
        const editData = cloneDeep(data);
        setEditFormData(editData);
        setManagerEditFormData(editData);
        changeEditFormPopupVisibility();

    }, []);

    const changeRejectModalVisibility = useCallback(async e => {
        setRejectionPopupVisibleRef(!rejectionPopupVisibleRef);
    }, [setRejectionPopupVisibleRef]);

    const changeEditFormPopupVisibility = useCallback(async e => {
        setEditFormPopuVisibleRef(!editFormPopupVisibleRef.current);
    }, [editFormPopupVisibleRef]);

    const handleEditFormHiding = useCallback(e => {}, []);

    const handleEditFormSave = useCallback(async e => {
        if (managerEditFormData && managerEditFormData.current) {
            const saved = await handleTransactionUpdate(managerEditFormData.current);
            if (saved) {
                setIsEditFormDirtyStateRef(false);
            }
            return saved;
        }
    }, [managerEditFormData]);

    const handleResetEditForm = useCallback(e => {}, []);

    const onEditFormDataChanged = useCallback(async data => {
        setManagerEditFormData(data);
        setIsEditFormDirtyStateRef(true);
    },[]);

    return (
        <>
            <DataGrid
                ref={gridRef}
                dataSource={gridData}
                rowAlternationEnabled={true}
                showColumnLines={true}
                showBorders={true}
            >
                <Toolbar>
                    <Item location="after">
                        <Button
                            onClick={() => handleApproveAll(transactions)}
                            stylingMode={'contained'}
                            text={t('common.approveAll')}
                            type="default"
                        />
                    </Item>
                </Toolbar>
                <GroupPanel
                    visible={true}
                    allowCollapsing={false}
                />
                <Grouping autoExandall={true} />
                <Column
                    caption={t('time.activity')}
                    dataField="activityName"
                    cellRender={renderActivityCell}
                />
                <Column
                    caption={t('time.costCode')}
                    dataField="costcodeDescription"
                    cellRender={renderCostCodeCell}
                />
                <Column
                    caption={t('time.payCode')}
                    dataField="paycodeDescription"
                    cellRender={renderPayCodeCell}
                />
                {
                    settings.useShiftCode ?
                        <Column
                            caption={t('time.shiftCode')}
                            dataField="shiftCodeDescription"
                            cellRender={renderShiftCodeCell}
                        />
                        : null
                }
                {
                    useTimeInTimeOut &&
                    <Column
                        caption={t('time.timeIn')}
                        cellRender={renderTimeCell(useTimeInTimeOut)}
                        dataField="timeIn"
                        width={125}
                    />
                }
                {
                    useTimeInTimeOut &&
                    <Column
                        caption={t('time.timeOut')}
                        cellRender={renderTimeCell(useTimeInTimeOut)}
                        dataField="timeOut"
                        width={125}
                    />
                }
                <Column
                    caption={t('common.hours')}
                    dataField="hours"
                    width="100px"
                >
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>
                <Column
                    caption={t('common.date')}
                    dataField="transactionFormattedDate"
                    width="150px"
                />
                <Column
                    caption={t('time.notes')}
                    dataField="notes"
                    cellRender={renderNoteCell}
                />
                <Column type="buttons" width="100px">
                    <GridButton
                        hint={t('time.editTime')}
                        onClick={handleEditEntry}
                        icon="edit"
                    />
                    <GridButton
                        elementAttr={ApproveButtonAttr}
                        hint={t('common.approve')}
                        onClick={handleTransactionAccept}
                        render={renderApproveIcon}
                    />
                    <GridButton
                        elementAttr={RejectButtonAttr}
                        hint={t('common.reject')}
                        onClick={handleRejectionShowModal}
                        render={renderRejectIcon}
                    />
                </Column>
            </DataGrid>
            {popupVisible ?
                <FormPopup
                    cancelButtonText={t('common.cancel')}
                    isFormDirtyStateRef={isFormDirtyStateRef}
                    onSave={handleRejection}
                    saveButtonText={t('common.save')}
                    setIsFormDirtyState={setIsFormDirtyState}
                    setVisible={changeRejectModalVisibility}
                    showMultiSaveButton={false}
                    title={t('time.rejectTimeEntry')}
                    visible={popupVisible}
                    width="400px"
                >
                    <TimeRejectionForm
                        formData={transactionData}
                        handleRejectionChange={onDataChanged}
                    />
                </FormPopup>
                : null
            }
            {
                timeEntryPopupVisible ?
                    <FormPopup
                        cancelButtonText={t('common.cancel')}
                        closeOnSave={true}
                        isFormDirtyStateRef={isEditFormDirtyStateRef}
                        onHiding={handleEditFormHiding}
                        onSave={handleEditFormSave}
                        onResetForm={handleResetEditForm}
                        saveButtonText={t('common.save')}
                        showMultiSaveButton={false}
                        setIsFormDirtyState={setIsEditFormDirtyStateRef}
                        setIsResetForm={setIsResetFormRef}
                        setVisible={changeEditFormPopupVisibility}
                        title={t('time.editEntryTitle', {employee: editFormEmployee})}
                        visible={timeEntryPopupVisible}
                        height={useTimeInTimeOut ? '95vh' : null}
                    >
                        <TimeManagerEditForm
                            data={editFormData}
                            fullTransactionList={fullTransactionList}
                            jobCostCodes={jobCostCodes}
                            onFormDataChanged={onEditFormDataChanged}
                            setIsFormDirtyState={setIsEditFormDirtyStateRef}
                            setJobCostCodes={setJobCostCodes}
                        />
                    </FormPopup>
                    : null
            }
        </>
    );
};

const mapStateToProps = state => ({
    featureFlags: state.core.featureFlags,
    settings:     state.time.settings
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...EmployeeActions,
    ...JobActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeApprovalModal);
