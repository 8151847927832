import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import { userHasPermission } from '../../helpers/permissionHelper';
import { useTranslation } from 'react-i18next';
import './SideNavigationMenu.scss';

import * as events from 'devextreme/events';

export default function SideNavigationMenu(props) {
    const {
        compactMode,
        companyUser,
        currentUser,
        featureFlags,
        menuStatus,
        onMenuReady,
        openMenu,
        selectedItemChanged,
        settings,
        timeUserSettings,
        children
    } = props;
    const { t } = useTranslation();
    const { isLarge } = useScreenSize();

    function normalizeSubItems (items) {
        const subItems = items.map(item => ({
            ...item,
            text:    t(item.text),
            visible: userHasPermission(companyUser, item, item.permission, featureFlags, settings, currentUser.isSuperUser),
            path:    item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path,
            items:   item.items ? normalizeSubItems(item.items) : []
        }));
        return subItems;
    }

    function normalizePath () {
        const navMap = navigation.map((item) => (
            {
                ...item,
                text:     t(item.text),
                visible:  userHasPermission(companyUser, item, item.permission, featureFlags, settings, currentUser.isSuperUser),
                expanded: isLarge,
                path:     item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path,
                items:    item.items ? normalizeSubItems(item.items) : []
            }
        ));
        return navMap;
    }

    const items = useMemo(
        normalizePath,
        [settings, timeUserSettings?.viewData, t]
    );

    const { navigationData: { currentPath } } = useNavigation();

    const treeViewRef = useRef(null);
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', (e) => {
            openMenu(e);
        });
    }, [openMenu]);

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath);
            treeView.expandItem(currentPath);
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [currentPath, compactMode]);

    useEffect(() => {
        if (menuStatus === MenuStatus.Closed) {
            const treeView = treeViewRef.current && treeViewRef.current.instance;
            if (!treeView) {
                return;
            }
            treeView.collapseAll();
        }
    }, [menuStatus]);

    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            style={{
                backgroundColor: '#3f4044',
                color:           '#fff',
                fontWeight:      '400',
                fontSize:        '14px',
                lineHeight:      1.2857
            }}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                />
            </div>
        </div>
    );
}

const MenuStatus = {
    Closed:          1,
    Opened:          2,
    TemporaryOpened: 3
};
