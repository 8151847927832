import React, { useEffect, useState } from 'react';
import { Button } from 'devextreme-react';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import { useTranslation } from 'react-i18next';

import './WebBrowserPopup.scss';
import showToastMessage from '../../../helpers/toastHelper';

const WebBrowserPopup =({
    popupHidden,
    showPopup,
    title,
    url
}) => {
    const { t } = useTranslation();
    const [popupHeight, setPopupHeight] = useState('100vw');
    const [popupWidth, setPopupWidth] = useState('100vw');
    const toolbarHeight = 42;

    useEffect(()=> {
        setPopupHeight(window.innerHeight - toolbarHeight);
        setPopupWidth(window.innerWidth);
    }, [window.innerHeight, window.innerWidth]);

    const handleShown = (e) => {
        setPopupHeight(window.innerHeight - toolbarHeight);
        setPopupWidth(window.innerWidth);
    };

    const copyLink = link => {
        navigator.clipboard.writeText(link);
        showToastMessage(t('time.linkCopied'), 'success');
    };

    return (
        <Popup
            fullScreen={true}
            showTitle={false}
            visible={showPopup}
            onHidden={popupHidden}
            onShown={handleShown}
        >
            <ToolbarItem
                location="center"
                locateInMenu="never"
                text={title}
            />
            <ToolbarItem
                location="after"
                locateInMenu="never"
                widget="dxButton"
            >
                <Button icon="close" onClick={popupHidden} />
            </ToolbarItem>
            <object
                type="text/html"
                data={url}
                width={popupWidth}
                height={popupHeight}
                className="popup-object"
            >
                <p>These links need to be opened in an external browser</p>
                <div>
                    <b>Feature Requests</b><br />
                    https://ideas.wennsoft.com
                    <Button
                        icon="copy"
                        onClick={() => copyLink('https://ideas.wennsoft.com')}
                    />
                </div>
                <br />
                <div>
                    <b>Customer Portal</b><br />
                    https://www.wennsoft.com/wsportal/home
                    <Button
                        icon="copy"
                        onClick={() => copyLink('https://www.wennsoft.com/wsportal/home')}
                    />
                </div>
                <br />
                <div>
                    <a href={url}>Return to {title}</a>
                </div>
            </object>
        </Popup>
    );
};

export default WebBrowserPopup;
