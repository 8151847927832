import { t } from 'i18next';

export const service = {
    getBasicInfoItems (t) {
        return [
            {
                dataField:     'department',
                colSpan:       2,
                label:         t('common.department'),
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField:     'position',
                colSpan:       2,
                label:         t('common.position'),
                editorOptions: {
                    readOnly: true,
                }
            }
        ];
    },
    getUserSettingsInfoItems () {
        return [
            {
                dataField:  'defaultLaborPayCodeId',
                colSpan:    2,
                label:      t('time.defaultLaborPayCode'),
                labelWidth: 150,
            },
            {
                dataField:  'selectedLanguage',
                colSpan:    2,
                label:      t('userSettings.preferredLanguage'),
                labelWidth: 150,
            },
            {
                dataField:  'employeeDepartmentFilter',
                colSpan:    2,
                label:      t('userSettings.departmentFilter'),
                labelWidth: 150,
            },
            {
                dataField:  'jobDefaultDivisions',
                colSpan:    2,
                label:      t('userSettings.jobDivisionFilters'),
                labelWidth: 150,

            },
            {
                dataField:  'serviceDefaultDivisions',
                colSpan:    2,
                label:      t('userSettings.serviceDivisionFilters'),
                labelWidth: 150,
            }

        ];
    },
    getDefaultUserSettings() {
        return {
            defaultPayCodeId: null
        };
    }
};
