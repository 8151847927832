import {
    FETCH_COMPANY_USER,
    SET_IS_TIME_APPLICATION_USER
} from '../actions/actionTypes';

const initialState = {
    companyUser:           {},
    isTimeApplicationUser: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_USER:
            return {
                ...state,
                companyUser: action.payload
            };
        case SET_IS_TIME_APPLICATION_USER:
            return {
                ...state,
                isTimeApplicationUser: action.payload
            };
        default:
            return state;
    }
};
