/* eslint-disable import/no-anonymous-default-export */
const local = {
    production:      false,
    googleAnalytics: {
        trackingId: ''
    },
    aws: {
        region:  'us-east-1',
        cognito: {
            region:         'us-east-1',
            projectRegion:  'us-east-1',
            userPoolId:     'us-east-1_tiPKfInGV',
            identityPoolId: 'us-east-1:0581f065-7447-4a8c-9d66-c511ba7da72f',
            clientId:       '6tna4vv9tfe1n7pfgoeij644v0',
            loginKey:       'cognito-idp.us-east-1.amazonaws.com/us-east-1_tiPKfInGV'
        }
    },
    key2act: {
        helpUrl: 'https://docs.wennsoft.com/time',
        cookies: {
            authentication: {
                url:    '.k2a.localhost',
                secure: true
            }
        },
        applications: {
            apipublic: {
                url: 'https://hub-api-core.key2act.work'
            },
            apilambda: {
                url: 'https://bob-api.key2act.work/'
            },
            socket: {
                host: 'next-wss.key2act.work',
                path: 'dev'
            },
            swagger: {
                host: 'bob-api.key2act.work'
            },
            servicerequests: {
                domain: 'dev.email.servicerequests.io'
            },
            websocket: {
                host: 'wss://ds3p5gbkp4.execute-api.us-east-1.amazonaws.com',
                path: 'dev'
            },
            companyLogo: {
                url: 'https://dev-next-image-storage-output.s3.amazonaws.com/companyLogo/'
            }
        }
    },
    elasticsearch: {
        searchapi: {
            url: 'https://bob-api.key2act.work/core/basagents/elastic-data'
        }
    },
    integrations: {
        vendors: {
            name:            'key2act',
            integrationApis: {
                connect:                       'https://apiconnect.key2act.work/',
                signature:                     'https://apisignature.key2act.work/',
                serviceChannelCallback:        'https://esms.key2act.work/service-channel-oauth-callback',
                serviceChannelAppRegistration: 'https://sb2.servicechannel.com/sc/admin/integration/apiaccess',
                serviceChannelAuth:            'https://sb2login.servicechannel.com/oauth/authorize'
            }
        }
    }
};

const dev = {
    production:      false,
    googleAnalytics: {
        trackingId: 'UA-100087052-4'
    },
    aws: {
        region:  'us-east-1',
        cognito: {
            region:         'us-east-1',
            projectRegion:  'us-east-1',
            userPoolId:     'us-east-1_P9fUD5yIX',
            identityPoolId: 'us-east-1:3e085ff8-bc58-417a-88a3-aba98e95047d',
            clientId:       '65l2vsnpfnet2hmlb0823imeik',
            loginKey:       'cognito-idp.us-east-1.amazonaws.com/us-east-1_P9fUD5yIX'
        }
    },
    key2act: {
        helpUrl: 'https://docs.wennsoft.com/time',
        cookies: {
            authentication: {
                url:    '.key2act.work',
                secure: true
            }
        },
        applications: {
            apipublic: {
                url: 'https://hub-api-core.key2act.work'
            },
            apilambda: {
                url: 'https://bob-api.key2act.work/'
            },
            socket: {
                host: 'next-wss.key2act.work',
                path: 'dev'
            },
            swagger: {
                host: 'bob-api.key2act.work'
            },
            servicerequests: {
                domain: 'dev.email.servicerequests.io'
            },
            websocket: {
                host: 'wss://q8hwq25613.execute-api.us-east-1.amazonaws.com',
                path: 'dev'
            },
            companyLogo: {
                url: 'https://dev-next-image-storage-output.s3.amazonaws.com/companyLogo/'
            }
        }
    },
    elasticsearch: {
        searchapi: {
            url: 'https://search-firehose-es-development-itowhhnxley2tfyhabeklykpza.us-east-1.es.amazonaws.com/cwl*/_search'
        }
    },
    integrations: {
        vendors: {
            name:            'key2act',
            integrationApis: {
                connect:                       'https://apiconnect.key2act.work/',
                signature:                     'https://apisignature.key2act.work/',
                serviceChannelCallback:        'https://esms.key2act.work/service-channel-oauth-callback',
                serviceChannelAppRegistration: 'https://sb2.servicechannel.com/sc/admin/integration/apiaccess',
                serviceChannelAuth:            'https://sb2login.servicechannel.com/oauth/authorize'
            }
        }
    }
};

const test = {
    production:      false,
    googleAnalytics: {
        trackingId: ''
    },
    aws: {
        region:  'us-east-1',
        cognito: {
            region:         'us-east-1',
            projectRegion:  'us-east-1',
            userPoolId:     'us-east-1_W53uMBUzc',
            identityPoolId: 'us-east-1:a25beaa5-aaa8-4dca-aa76-4a6c952f5b20',
            clientId:       '49chls7oqmr1jgj7lu357gn0p2',
            loginKey:       'cognito-idp.us-east-1.amazonaws.com/us-east-1_W53uMBUzc'
        }
    },
    key2act: {
        helpUrl: 'https://docs.wennsoft.com/time',
        cookies: {
            authentication: {
                url:    '.key2act.me',
                secure: true
            }
        },
        applications: {
            apipublic: {
                url: 'https://bob-api.key2act.me/core'
            },
            apilambda: {
                url: 'https://bob-api.key2act.me/'
            },
            socket: {
                host: 'next-wss.key2act.me',
                path: 'test'
            },
            swagger: {
                host: 'bob-api.key2act.me'
            },
            servicerequests: {
                domain: 'test.email.servicerequests.io'
            },
            websocket: {
                host: '',
                path: ''
            }
        }
    },
    integrations: {
        vendors: {
            name:            'key2act',
            integrationApis: {
                connect:                       'https://apiconnect.key2act.me/',
                signature:                     'https://apisignature.key2act.me/',
                serviceChannelCallback:        'https://esms.key2act.me/service-channel-oauth-callback',
                serviceChannelAppRegistration: 'https://sb2.servicechannel.com/sc/admin/integration/apiaccess',
                serviceChannelAuth:            'https://sb2login.servicechannel.com/oauth/authorize'
            }
        }
    }
};

const stage = {
    production:      true,
    googleAnalytics: {
        trackingId: 'UA-100087052-3'
    },
    aws: {
        region:  'us-east-1',
        cognito: {
            region:        'us-east-1',
            projectRegion: 'us-east-1',
            userPoolId:    'us-east-1_JeyRvIIQw',
            clientId:      '66qjv5f83t29nd0htdu17moflp'
        }
    },
    key2act: {
        helpUrl: 'https://docs.wennsoft.com/time',
        cookies: {
            authentication: {
                url:    '.key2act.live',
                secure: true
            }
        },
        applications: {
            apipublic: {
                url: 'https://hub-api-core.key2act.live'
            },
            apilambda: {
                url: 'https://bob-api.key2act.live/'
            },
            socket: {
                host: 'next-wss.key2act.live',
                path: 'uat'
            },
            swagger: {
                host: 'bob-api.key2act.live'
            },
            servicerequests: {
                domain: 'uat.email.servicerequests.io'
            },
            websocket: {
                host: 'wss://d5meux9qn8.execute-api.us-east-1.amazonaws.com',
                path: 'uat'
            },
            companyLogo: {
                url: 'https://uat-next-image-storage-output.s3.amazonaws.com/companyLogo/'
            }
        }
    },
    integrations: {
        vendors: {
            name:            'key2act',
            integrationApis: {
                connect:                       'https://apiconnect.key2act.live/',
                signature:                     'https://apisignature.key2act.live/',
                serviceChannelCallback:        'https://esms.key2act.live/service-channel-oauth-callback',
                serviceChannelAppRegistration: 'https://sb2.servicechannel.com/sc/admin/integration/apiaccess',
                serviceChannelAuth:            'https://sb2login.servicechannel.com/oauth/authorize'
            }
        }
    }
};

const prod = {
    production:      true,
    googleAnalytics: {
        trackingId: 'UA-100087052-5'
    },
    aws: {
        region:  'us-east-1',
        cognito: {
            region:        'us-east-1',
            projectRegion: 'us-east-1',
            userPoolId:    'us-east-1_GM2rMc24I',
            clientId:      '55bbpbgddtl3chgnsfsenh58l8'
        }
    },
    key2act: {
        helpUrl: 'https://docs.wennsoft.com/time',
        cookies: {
            authentication: {
                url:    '.key2act.io',
                secure: true
            }
        },
        applications: {
            apipublic: {
                url: 'https://hub-api-core.key2act.io'
            },
            apilambda: {
                url: 'https://bob-api.key2act.io/'
            },
            socket: {
                host: 'insite-wss.key2act.io',
                path: 'prod'
            },
            swagger: {
                host: 'bob-api.key2act.io'
            },
            servicerequests: {
                domain: 'email.servicerequests.io'
            },
            websocket: {
                host: 'wss://vpnebxyaz5.execute-api.us-east-1.amazonaws.com',
                path: 'prod'
            },
            companyLogo: {
                url: 'https://prod-next-image-storage-output.s3.amazonaws.com/companyLogo/'
            }
        }
    },
    integrations: {
        vendors: {
            name:            'key2act',
            integrationApis: {
                connect:                       'https://apiconnect.key2act.io/',
                signature:                     'https://apisignature.key2act.io/',
                serviceChannelCallback:        'https://esms.key2act.io/service-channel-oauth-callback',
                serviceChannelAppRegistration: 'https://www.servicechannel.com/sc/admin/integration/apiaccess',
                serviceChannelAuth:            'https://login.servicechannel.com/oauth/authorize'
            }
        }
    }
};

const prod_au = {
    production:      true,
    googleAnalytics: {
        trackingId: 'TBD'
    },
    aws: {
        region:  'ap-southeast-2',
        cognito: {
            region:        'ap-southeast-2',
            projectRegion: 'ap-southeast-2',
            userPoolId:    'ap-southeast-2_CVAW5uWr6',
            clientId:      '5245bejo4vbfeqvnf6mtpcaca9'
        }
    },
    key2act: {
        helpUrl: 'https://docs.wennsoft.com/time',
        cookies: {
            authentication: {
                url:    '.key2act.io',
                secure: true
            }
        },
        applications: {
            apipublic: {
                url: 'https://hub-au-api.key2act.io/core'
            },
            apilambda: {
                url: 'https://hub-au-api.key2act.io/'
            },
            socket: {
                host: 'insite-au-wss.key2act.io',
                path: 'prod_au'
            },
            swagger: {
                host: 'hub-au-api.key2act.io'
            },
            servicerequests: {
                domain: 'email.servicerequests.io'
            },
            websocket: {
                host: 'wss://tbd.vpnebxyaz5.execute-api.ap-southeast-2.amazonaws.com',
                path: 'prod_au'
            },
            companyLogo: {
                url: 'https://prod-au-next-image-storage-output.s3.amazonaws.com/companyLogo/'
            }
        }
    },
    integrations: {
        vendors: {
            name:            'key2act',
            integrationApis: {
                connect:                       'https://tbd.apiconnect.key2act.io/',
                signature:                     'https://tbd.apisignature.key2act.io/',
                serviceChannelCallback:        'https://tbd.esms.key2act.io/service-channel-oauth-callback',
                serviceChannelAppRegistration: 'https://tbd.www.servicechannel.com/sc/admin/integration/apiaccess',
                serviceChannelAuth:            'https://tbd.login.servicechannel.com/oauth/authorize'
            }
        }
    }
};

const config = () => {
    switch (process.env.REACT_APP_STAGE) {
        case 'dev':
            return dev;
        case 'local':
            return local;
        case 'prod':
            return prod;
        case 'prod_au':
            return prod_au;
        case 'stage':
            return stage;
        case 'test':
            return test;
        default:
            break;
    }
};

export default {
    ...config()
};
