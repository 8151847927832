import React, { useMemo } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
    mdiTimerPlayOutline,
    mdiClockOutline,
    mdiCalendarRemove,
    mdiCommentTextOutline,
    mdiCommentRemoveOutline,
    mdiCommentPlusOutline,
    mdiTimerStopOutline
} from '@mdi/js';

import './timeCellContextMenu.scss';
import { TransactionStatus } from '../../data/transactionStatuses';

const setIsAddAllowed = (cellData, useTimeEntryForm) => {
    const { column, data } = cellData;
    if (!useTimeEntryForm) {
        return data.status === TransactionStatus.Open && data[column.dataField] === null;
    }
    else {
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn);
        return openTransactions === undefined || openTransactions.length === 0;
    }
};

const setIsEditAllowed = (cellData, hasError, useTimeEntryForm) => {
    const { column, data } = cellData;
    if (!useTimeEntryForm) {
        return ((data.status === TransactionStatus.Open || data.status === TransactionStatus.TimedIn || hasError) && data[column.dataField] !== null);
    }
    else {
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn || t.status === TransactionStatus.Rejected || t.status === TransactionStatus.Error);
        return openTransactions !== undefined && openTransactions.length >= 1;
    }

};

const setIsDeleteAllowed = (cellData, hasError, useTimeEntryForm) => {
    const { column, data } = cellData;
    if (!useTimeEntryForm) {
        return ((data.status === TransactionStatus.Open || data.status === TransactionStatus.TimedIn || hasError) && data[column.dataField] !== null);
    }
    else {
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn || t.status === TransactionStatus.Rejected || t.status === TransactionStatus.Error);
        return openTransactions !== undefined && openTransactions.length >= 1;
    }

};

const setIsAddNoteAllowed = (cellData, hasError, noteColumn, useTimeEntryForm) => {
    const { column, data } = cellData;
    if (!useTimeEntryForm) {
        return ((data.status === TransactionStatus.Open || data.status === TransactionStatus.TimedIn || hasError) && data[column.dataField] !== null && data[noteColumn] && data[noteColumn].length === 0 );
    }
    else {
        let allowAdd = false;
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn || t.status === TransactionStatus.Rejected || t.status === TransactionStatus.Error);
        if (openTransactions !== undefined && openTransactions.length !== 0 ) {
            openTransactions.forEach(trx => {
                if (trx.notes && trx.notes.length === 0) {
                    allowAdd = true;
                }
            });
        }
        return allowAdd;
    }

};

const setIsUpdateNoteAllowed = (cellData, hasError, noteColumn, useTimeEntryForm) => {
    const { column, data } = cellData;
    if (!useTimeEntryForm) {
        return ((data.status === TransactionStatus.Open || data.status === TransactionStatus.TimedIn || hasError) && data[column.dataField] !== null && data[noteColumn] && data[noteColumn].length !== 0);
    }
    else {
        let allowEdit = false;
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn || t.status === TransactionStatus.Rejected || t.status === TransactionStatus.Error);
        if (openTransactions !== undefined && openTransactions.length !== 0 ) {
            openTransactions.forEach(trx => {
                if (trx.notes && trx.notes.length !== 0) {
                    allowEdit = true;
                }
            });
        }
        return allowEdit;
    }

};

const setIsDeleteNoteAllowed = (cellData, noteColumn, useTimeEntryForm) => {
    const { column, data } = cellData;
    const hasError = data.status === TransactionStatus.Error || data.status === TransactionStatus.Rejected;
    if (!useTimeEntryForm) {
        return ((data.status === TransactionStatus.Open || data.status === TransactionStatus.TimedIn || hasError) && data[column.dataField] !== null && data[noteColumn] && data[noteColumn].length !== 0);
    }
    else {
        let allowDelete = false;
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status === TransactionStatus.Open || t.status === TransactionStatus.TimedIn || t.status === TransactionStatus.Rejected || t.status === TransactionStatus.Error);
        if (openTransactions !== undefined && openTransactions.length !== 0) {
            openTransactions.forEach(trx => {
                if (trx.notes && trx.notes.length > 0) {
                    allowDelete = true;
                }
            });
        }
        return allowDelete;
    }

};

const setIsViewNoteAllowed = (cellData, noteColumn, useTimeEntryForm) => {
    const { column, data } = cellData;
    if (!useTimeEntryForm) {
        return (data.status !== TransactionStatus.Open && data.status !== TransactionStatus.TimedIn && data[column.dataField] !== null && data[noteColumn] && data[noteColumn].length !== 0);
    }
    else {
        let allowView = false;
        const transactions = cloneDeep(data[`${column.caption}_transactions`]);
        const openTransactions = transactions?.filter(t => t.status !== TransactionStatus.openTransactions && t.status !== TransactionStatus.TimedIn);
        if (openTransactions !== undefined && openTransactions.length !== 0) {
            openTransactions.forEach(trx => {
                if (trx.notes && trx.notes.length > 0) {
                    allowView = true;
                }
            });
        }
        return allowView;
    }

};


const TimeCellContextMenu = ({
    cellId,
    cellData,
    handleAddNote,
    handleCellAddTime,
    handleCellEditTime,
    handleCellDeleteTime,
    handleCellTimeIn,
    handleCellTimeOut,
    handleDeleteNote,
    handleUpdateNote,
    handleViewNote,
    isTimeInAllowed,
    isTimeOutAllowed,
    useTimeEntryForm
}) => {
    const { t } = useTranslation();
    const { column, data } = cellData;
    const noteColumn = column.dataField + '_notes';
    const hasError = data.status === TransactionStatus.Error || data.status === TransactionStatus.Rejected;

    const isAddAllowed = setIsAddAllowed(cellData, useTimeEntryForm);
    const isEditAllowed = setIsEditAllowed(cellData, hasError, useTimeEntryForm);
    const isDeleteAllowed = setIsDeleteAllowed(cellData, hasError, useTimeEntryForm);
    const isAddNoteAllowed = setIsAddNoteAllowed(cellData, hasError, noteColumn, useTimeEntryForm);
    const isUpdateNoteAllowed = setIsUpdateNoteAllowed(cellData, hasError, noteColumn, useTimeEntryForm);
    const isDeleteNoteAllowed = setIsDeleteNoteAllowed(cellData, hasError, noteColumn, useTimeEntryForm);
    const isViewNoteAllowed = setIsViewNoteAllowed(cellData, noteColumn, useTimeEntryForm);

    const timeCellMenuItems = useMemo(() => ([
        {
            text:    t('time.addTime'),
            icon:    mdiClockOutline,
            onClick: () => { handleCellAddTime(cellData); },
            visible: isAddAllowed
        },
        {
            text:      t('time.timeInNewEntry'),
            icon:      mdiTimerPlayOutline,
            onClick:   () => { handleCellTimeIn(cellData); },
            visible:   isTimeInAllowed,
            iconColor: 'green'
        },
        {
            text:      t('time.timeOut'),
            icon:      mdiTimerStopOutline,
            onClick:   () => {handleCellTimeOut(cellData); },
            visible:   isTimeOutAllowed,
            iconColor: 'red'
        },
        {
            text:    t('time.editTime'),
            icon:    mdiClockOutline,
            onClick: () => { handleCellEditTime(cellData); },
            visible: isEditAllowed
        },
        {
            text:    t('time.deleteTime'),
            icon:    mdiCalendarRemove ,
            onClick: () => { handleCellDeleteTime(cellData); },
            visible: isDeleteAllowed
        },
        {
            text:    t('time.addNote'),
            icon:    mdiCommentPlusOutline,
            onClick: () => { handleAddNote(cellData); },
            visible: isAddNoteAllowed
        },
        {
            text:    t('time.editNote'),
            icon:    mdiCommentTextOutline,
            onClick: () => { handleUpdateNote(cellData); },
            visible: isUpdateNoteAllowed
        },
        {
            text:    t('time.deleteNote'),
            icon:    mdiCommentRemoveOutline,
            onClick: () => { handleDeleteNote(cellData); },
            visible: isDeleteNoteAllowed
        },
        {
            text:    t('time.viewNote'),
            icon:    mdiCommentTextOutline,
            onClick: () => { handleViewNote(cellData); },
            visible: isViewNoteAllowed && !isEditAllowed
        }
    ]), []);

    const ItemTemplate = itemData => (
        <div className="item-template-container">
            {itemData.icon &&
                <Icon
                    path={itemData.icon}
                    size={'18px'}
                    color={itemData.iconColor ? itemData.iconColor : 'black'}
                />
            }
            <span className="dx-menu-item-text item-template-text">{itemData.text}</span>
        </div>
    );

    return (
        <div>
            <i className="fa-solid fa-ellipsis-vertical" />
            <ContextMenu
                items={timeCellMenuItems}
                target={`#${cellId}`}
                showEvent={'dxclick'}
                itemRender={ItemTemplate}
            >
                <Position my={'top center'} at={'bottom center'} />
            </ContextMenu>
        </div>
    );
};

export default TimeCellContextMenu;
