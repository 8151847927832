export const languages = [
    {
        id:         'en',
        nativeName: 'English',
        display:    'English'
    },
    {
        id:         'es',
        nativeName: 'Spanish',
        display:    'Spanish'
    }
];
