import React, {useEffect, useRef, useState} from 'react';
import { Tooltip } from 'devextreme-react';

import './OverflowTooltip.scss';

const OverflowTooltip = ({
    cellId,
    cellText,
    children
}) => {
    const textElementRef = useRef();
    const [hover, setHover] = useState(false);
    const compareSize = () => {
        const compare = textElementRef.current ? textElementRef.current.scrollWidth > textElementRef.current.clientWidth : false;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    });

    return (
        <>
            <div id={cellId} ref={textElementRef}>{cellText}</div>
            {hover &&
            <Tooltip
                target={`#${cellId}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position="bottom"
            >
                <div className="tooltip">{cellText}</div>
            </Tooltip>
            }
        </>
    );
};

export default OverflowTooltip;
