import React from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import './confirmationDialog.scss';

const ConfirmationDialog = ({
    confirmationTitle,
    confirmButtonAction,
    confirmButtonText = 'Confirm',
    defaultButton = 'confirm',
    denyButtonAction,
    denyButtonText = 'Cancel',
    dialogMessage,
    height = 250,
    showConfirmButton = true,
    showCloseButton = true,
    showDialog,
    width,
    renderContent
}) => {

    const closeButtonOptions = {
        icon:        'fa-solid fa-xmark',
        text:        denyButtonText,
        onClick:     denyButtonAction,
        stylingMode: defaultButton.toLowerCase() === 'cancel' ? 'contained' : 'outlined',
        visible:     showCloseButton
    };

    const confirmButtonOptions = {
        icon:        'fa-solid fa-check',
        text:        confirmButtonText,
        onClick:     confirmButtonAction,
        stylingMode: defaultButton.toLowerCase() === 'confirm' ? 'contained' : 'outlined',
        visible:     showConfirmButton
    };

    return (
        <>
            <Popup
                visible={showDialog}
                dragEnabled={false}
                showCloseButton={false}
                showTitle={true}
                title={confirmationTitle}
                height={height}
                maxHeight={'100%'}
                width={width ?? 400}
                contentRender={renderContent}
            >
                <Position
                    at="center"
                    my="center"
                    of=""
                    collision="fit"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={confirmButtonOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                />
                {
                    dialogMessage && dialogMessage.length > 0 ?
                        <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                            <p>{dialogMessage}</p>
                        </div>
                        : null
                }
            </Popup>
        </>
    );
};

export default ConfirmationDialog;
