/* eslint-disable import/no-anonymous-default-export */
import {
    REDUX_WEBSOCKET_BEGIN_RECONNECT,
    REDUX_WEBSOCKET_BROKEN,
    REDUX_WEBSOCKET_CLOSED,
    REDUX_WEBSOCKET_CONNECT,
    REDUX_WEBSOCKET_ERROR,
    REDUX_WEBSOCKET_MESSAGE,
    REDUX_WEBSOCKET_OPEN,
    REDUX_WEBSOCKET_RECONNECTED,
    REDUX_WEBSOCKET_RECONNECT_ATTEMPT
} from '../actions/actionTypes';

/**
 * Unwraps the payload of a REDUX_WEBSOCKET_MESSAGE returning it as a
 * first-class Redux application action.
 */
const unwrapActionMessage = (action) => {
    let { meta, payload: { event, message, origin } } = action;
    message || (message = '{}');
    let messageJson = {
        type: action.type
    };
    try {
        messageJson = {
            ...messageJson,
            ...JSON.parse(message)
        };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('JSON parse error', message, error);
    }
    const unwrappedAction = {
        '@@unwrapped': true,
        type:          messageJson.type,
        message:       {
            event,
            meta,
            origin
        },
        payload: messageJson.payload
    };
    return unwrappedAction;
};

const logConnectionState = (action) => {
    // eslint-disable-next-line no-console
    console.warn(action.type, action);
    return action;
};


/**
 * Converts Redux WebSocket messages into Redux application messages by
 * unwrapping the application payload from the WebSocket payload and parsing
 * the JSON string.
 */
export default ({ dispatch, getState }) => {
    return next => action => {
        switch (action.type) {
            case REDUX_WEBSOCKET_MESSAGE:
                return next(unwrapActionMessage(action));
            case REDUX_WEBSOCKET_BEGIN_RECONNECT:
            case REDUX_WEBSOCKET_BROKEN:
            case REDUX_WEBSOCKET_CLOSED:
            case REDUX_WEBSOCKET_CONNECT:
            case REDUX_WEBSOCKET_ERROR:
            case REDUX_WEBSOCKET_OPEN:
            case REDUX_WEBSOCKET_RECONNECTED:
            case REDUX_WEBSOCKET_RECONNECT_ATTEMPT:
                return next(logConnectionState(action));
            default:
                next(action);
        }
    };
};
