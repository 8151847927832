import TimeEntry from './containers/TimeEntry/TimeEntry';
import TimeApproval from './containers/TimeApproval/TimeApproval';
import ManagerSetup from './containers/Admin/ManagerSetup';
import Licensing from './containers/Admin/Licensing';
import { withNavigationWatcher } from './contexts/navigation';
import LicensingReport from './containers/Admin/LicensingReport';
import UserProfile from './containers/UserProfile/UserProfile';
import CompanySettings from './containers/Admin/company-settings';

const routes = [
    {
        path:       '/timeentry',
        element:    TimeEntry,
        permission: 'isTimeUser'
    },
    {
        path:       '/timeapproval',
        element:    TimeApproval,
        perimssion: 'isManager,isTimeAdmin,isPayrollAdmin'
    },
    {
        path:       '/administration/companysettings',
        element:    CompanySettings,
        permission: 'isTimeAdmin'
    },
    {
        path:       '/administration/approversetup',
        element:    ManagerSetup,
        permission: 'isTimeAdmin'
    },
    {
        path:       '/administration/licensing',
        element:    Licensing,
        permission: 'isTimeAdmin'
    },
    {
        path:       '/administration/licensingreport',
        element:    LicensingReport,
        permission: 'isSuperUser'
    },
    {
        path:       '/userprofile',
        element:    UserProfile,
        permission: 'isTimeUser'
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path, route.permission)
    };
});
