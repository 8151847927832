import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, orderBy, isNull } from 'lodash';
import { addDays, addMinutes, addSeconds, differenceInMinutes, format, isBefore, isSameDay, parseISO, setDate, setMonth, setYear, setSeconds, setMilliseconds, addHours, isFuture } from 'date-fns';
import { ScrollView, Validator } from 'devextreme-react';
import Box, { Item as BoxItem } from 'devextreme-react/box';
import Form, { Item as FormItem, Label, RequiredRule, RangeRule, CustomRule } from 'devextreme-react/form';
import NumberBox from 'devextreme-react/number-box';
import DateBox from 'devextreme-react/date-box';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { getSizeQualifier, useScreenSize } from '../../utils/media-query';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../confirmationDialog/confirmationDialog';
import DataSource from 'devextreme/data/data_source';
import devices from 'devextreme/core/devices';
import Icon from '@mdi/react';
import {
    mdiLockOpenOutline,
    mdiLockOutline,
    mdiAlertCircleOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiDeleteOutline,
    mdiCommentPlusOutline,
    mdiCommentTextOutline,
    mdiCommentRemoveOutline,
    mdiTimerPlayOutline,
    mdiTimerStopOutline,
    mdiTimerSand,
} from '@mdi/js';
import { v4 as uuidv4 } from 'uuid';
import { FormLookup } from '../';
import {
    ActivityItem,
    CostCodeItem,
    getActivityDisplayExpr,
    getCostCodeDisplayExpr,
    getPayCodeDisplayExpr,
    getShiftCodeDisplayExpr,
    PayCodeItem,
    renderDayTab,
    renderPayCodeGroupHeader,
    ShiftCodeItem,
    ShowLegendDetails
} from './TimeEntryFormHelper';
import AnnotationForm from '../annotations/AnnotationForm';
import { toHoursAndMinutes, defaultFormData } from '../../helpers/timeHelpers';

import { TransactionStatus } from '../../data/transactionStatuses';
import { TransactionType } from '../../data/transactionTypes';

import * as AnnotationTypes from '../../data/annotationTypes';
import * as AnnotationActions from '../../actions/annotationActions';
import * as TimeActions from '../../actions/timeActions';
import * as JobActions from '../../actions/jobActions';

import './TimeEntryForm.scss';
import showToastMessage from '../../helpers/toastHelper';

const unbilledActivitySearchExpression = ['name'];
const activitySearchExpression = ['activityId', 'description', 'clientName', 'siteName'];
const jobCostCodeSearchExpression = ['costCodeAlias', 'description'];
const serviceCostCodeSearchExpression = ['description'];

const defaultNoteData = {
    annotationType: null,
    isAttachment:   false,
    attachment:     null,
    subject:        null,
    isVisible:      true,
    noteText:       'Default Note'
};

const numBoxFormat = {
    type:      'fixedPoint',
    precision: '2'
};

const TimeEntryForm = ({
    activeCompany,
    activeEmployee,
    currentTimesheet,
    data,
    deleteAnnotation,
    employeePayCodes,
    featureFlags,
    fetchAnnotationById,
    fetchJobCostCodes,
    formRef,
    fullTransactionList,
    gridInstance,
    handleTimeEntryDeletion,
    insertTimeSheetTransaction,
    isFormDirtyStateRef,
    isNewRecord,
    isResetForm,
    jobCostCodes,
    jobList,
    listInstance,
    onDataChanged,
    periodDays,
    selectedTransactionId,
    setEditRowKeys,
    setIsFormDirtyState,
    setJobCostCodes,
    setSelectedTransactionId,
    settings,
    shiftCodes,
    timesheetStartDate,
    unbilledActivities,
    workOrderCostCodes,
    workOrderList
}) => {
    const { t, i18n } = useTranslation();
    const [activityLabel, setActivityLabel] = useState({text: 'Activity'});
    const [activityHelpText, setActivityHelpText] = useState('');
    const [activityType, setActivityType] = useState();
    // eslint-disable-next-line no-unused-vars
    const [annotationTarget, setAnnotationTarget] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [expensePayCodes, setExpensePayCodes] = useState([]);
    const [formData, setFormData] = useState(defaultFormData);
    const [isCostCodeVisible, setIsCostCodeVisible] = useState(true);
    const [isNoteReadOnly, setIsNoteReadOnly] = useState(false);
    const [isShowAnnotationForm, setIsShowAnnotationForm] = useState(false);
    const [laborPayCodes, setLaborPayCodes] = useState([]);
    const [noteData, setNoteData ] = useState(defaultNoteData);
    const [noteSectionLabel, setNoteSectionLabel] = useState('Note');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [isNewNote, setIsNewNote ] = useState(false);
    const [isDeleteNoteConfirmationVisible, setIsDeleteNoteConfirmationVisible ] = useState(false);
    const [deleteConfirmationTitle, setDeleteConfirmationTitle ] = useState('Delete Transaction');
    const [noteToDelete, setNoteToDelete ] = useState();
    const [deleteConfirmationData, setDeleteConfirmationData ] = useState();
    // eslint-disable-next-line no-unused-vars
    const [isDeleteConfirmation, setIsDeleteNoteConfirmation ] = useState(false);
    const [isFormReadOnly, setIsFormReadOnly] = useState(false);
    const [inputAttr, setInputAttr] = useState({id: null});
    const [useTimeInOut, setUseTimeInOut] = useState(false);
    const [lockTimeInOutEntries, setLockTimeInOutEntries] = useState(false);
    const [hasTimedInEntry, setHasTimedInEntry] = useState(true);
    const [minTimeInValue, setMinTimeInValue] = useState(null);
    const [maxTimeInValue, setMaxTimeInValue] = useState(null);
    const [minTimeOutValue, setMinTimeOutValue] = useState(null);
    const [timeOutRangeErrorMessage, setTimeOutRangeErrorMessage] = useState(t('validation.maxTimeOutRange'));
    const [hideTimeInOutFields, setHideTimeInOutFields] = useState(false);

    const { isXSmall, isSmall } = useScreenSize();

    const tabRef = useRef();

    useEffect(() => {
        if (periodDays) {
            const index = periodDays.findIndex(p => p.formattedDate === data.transactionFormattedDate);
            setSelectedTabIndex(index);
        }

        return () => {
            setFormData(defaultFormData);
            if (formRef.current) {
                formRef.current.instance.dispose();
            }
        };
    }, []);

    useEffect(() => {
        const timeInOutFeatureFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');

        if (timeInOutFeatureFlag && timeInOutFeatureFlag.featureFl && settings?.useTimeInTimeOut) {
            setUseTimeInOut(true);
        }
        else {
            setUseTimeInOut(false);
        }

        if (timeInOutFeatureFlag && timeInOutFeatureFlag.featureFl && settings?.lockTimeInTimeOut) {
            setLockTimeInOutEntries(true);
        }
        else {
            setLockTimeInOutEntries(false);
        }

    }, [featureFlags, settings.useTimeInTimeOut, settings.lockTimeInTimeOut]);

    useEffect(() => {
        if (currentTimesheet && currentTimesheet.timeTransactions) {
            const timedInCount = currentTimesheet.timeTransactions.filter(trx => trx.status === TransactionStatus.TimedIn).length;
            if (timedInCount !== 0 || data.status === TransactionStatus.TimedIn) {
                setHasTimedInEntry(true);
            }
            else {
                setHasTimedInEntry(false);
            }
        }
        else {
            setHasTimedInEntry(false);
        }
    }, [currentTimesheet.timeTransactions]);

    useEffect(() => {
        const laborCodes = employeePayCodes.filter(pc => pc.payType !== 5);
        const expenseCodes = employeePayCodes.filter(pc => pc.payType === 5);
        laborCodes.forEach(lc => lc.groupType = t(lc.groupType));
        const groupedData = new DataSource({
            store: laborCodes,
            key:   'paycodeid',
            group: [{selector: 'groupTypeOrder', desc: false}]
        });

        setLaborPayCodes(groupedData);
        setExpensePayCodes(expenseCodes);
    }, [employeePayCodes]);

    useEffect(() => {
        const init = async () => {
            setActivityType(data.transactionType);
            if (data.transactionType === TransactionType.Job && !data.activityId.id) {
                setJobCostCodes([]);

            }
            if (!data.activityClientName || !data.activitySiteName) {
                if (data.transactionType === TransactionType.Job && data.activityId.id) {
                    const job = jobList.find(j => j.id === data.activityId.id);
                    if (job) {
                        data.activityClientName = job.clientName;
                        data.activitySiteName = job.siteName;
                    }

                }
                else if (data.transactionType === TransactionType.Service && data.activityId.id) {
                    const sc = workOrderList.find(wo => wo.id === data.activityId.id);
                    if (sc) {
                        data.activityClientName = sc.clientName;
                        data.activitySiteName = sc.siteName;
                    }
                }
                else {
                    setActivityHelpText('');
                }
            }
            if (data.activityClientName && data.activitySiteName) {
                setActivityHelpText(`${data.activityClientName} - ${data.activitySiteName}`);
            }
            else {
                setActivityHelpText('');
            }

            if (isFormDirtyStateRef.current) {
                // Keep dirty form data
                setIsFormReadOnly(false);
                if (selectedTransactionId) {    // Selected Transaction ID set when modifying notes
                    return;
                }
                else {
                    setFormData(data);
                }
            }
            else {
                setFormData(data);
                setIsFormReadOnly(false);
            }

            if (data.status === TransactionStatus.Approved || data.status === TransactionStatus.Submitted || data.status === TransactionStatus.Processed) {
                setIsFormReadOnly(true);
            }
            if (isResetForm?.current && !isFormDirtyStateRef.current && formRef.current?.instance) {
                // Only need to repaint on Save and New - Before form is Dirty
                formRef.current?.instance.repaint();
            }

            if (!isNull(data.timeIn)) {
                setHasTimedInEntry(true);
            }

            const hideFields = isFuture(new Date(data.transactionFormattedDate));
            setHideTimeInOutFields(hideFields);

            const idValue = data && data.id ? data.id : uuidv4();
            setInputAttr({ id: `${idValue}-number-box`, class: 'transaction-number-box' });
            let minTimeIn = new Date(data.transactionFormattedDate);
            const utcOffset = new Date(minTimeIn).getTimezoneOffset();
            minTimeIn = addMinutes(minTimeIn, utcOffset);
            const minTimeOut = addMinutes(minTimeIn, 1);
            setMinTimeInValue(new Date(minTimeIn));
            setMinTimeOutValue(minTimeOut);
            let maxTimeInValue = addDays(minTimeIn, 1);
            maxTimeInValue = addSeconds(maxTimeInValue, -1);
            setMaxTimeInValue(maxTimeInValue);
        };
        if (data && data.transactionType && data.transactionDate && periodDays) init();
    }, [data]);

    useEffect(() => {
        if (data && data.transactionType) {
            setIsCostCodeVisible(data.transactionType !== TransactionType.Unbilled);
            switch (data.transactionType) {
                case TransactionType.Job:
                    setActivityLabel({text: t('time.job')});
                    break;
                case TransactionType.Service:
                    setActivityLabel({text: t('time.serviceCall')});
                    break;
                case TransactionType.Unbilled:
                    setActivityLabel({text: t('time.unbilledActivity')});
                    break;
                default:
                    break;
            }
        }
    }, [data?.transactionType]);

    const workOrderLaborCostCodes = useMemo(() => {
        return orderBy(workOrderCostCodes.filter(cc => cc.costType.toLowerCase() === 'labor'), 'description', 'asc');
    }, [workOrderCostCodes]);

    const onOptionChanged = useCallback(async args => {
        if (args.name === 'selectedIndex' && args.value > -1 && formData) {
            const transactionDateField = 'transactionDate';
            const transactionFormattedDateField = 'transactionFormattedDate';
            const dirtyField = 'isTrxDirty';
            setSelectedTabIndex(args.value);
            const formattedDate = periodDays.find(p => p.id === args.value).formattedDate;

            if (isFuture(new Date(formattedDate))) {
                setHideTimeInOutFields(true);
            }
            else {
                setHideTimeInOutFields(false);
            }

            const trx = cloneDeep(formData);
            trx.isTrxDirty = true;
            if (trx.status === TransactionStatus.Rejected || trx.status === TransactionStatus.Error) {
                trx.status = TransactionStatus.Open;
                trx.statusComment = null;
            }

            const newData = {
                ...formData,
                ...{[transactionDateField]: formattedDate},
                ...{[transactionFormattedDateField]: formattedDate},
                ...{[dirtyField]: true}
            };
            const formInstance = formRef.current?.instance;
            onDataChanged(newData);
            await setIsFormDirtyState(true);
            if (formInstance) {
                formInstance.validate();
            }
        }
    }, [formData, periodDays]);

    const handleAnnotationCancel = () => {
        setIsShowAnnotationForm(false);
        setIsNoteReadOnly(false);
    };

    const handleAddNote = useCallback(async (e) => {
        e.preventDefault();
        setEditRowKeys({
            activityId:      formData.activityId,
            costcodeId:      formData.costcodeId,
            paycodeId:       formData.paycodeId,
            equipmentId:     formData.equipmentId,
            shiftCodeId:     formData.shiftCodeId,
            employeeId:      formData.employeeId,
            transactionType: formData.transactionType,
            transactionDate: formData.transactionFormattedDate,
            id:              formData.id
        });
        const target = `#button_add_note_${formData.transactionFormattedDate}`;
        setSelectedTransactionId(formData.id);
        setAnnotationTarget(target);
        const noteLabel = t('time.noteLabel', {transDate: parseISO(formData.transactionFormattedDate), hourCount: formData.hours});
        setNoteSectionLabel(noteLabel);
        setIsNoteReadOnly(false);
        setIsNewNote(true);

        const transactionNoteData = {
            id:             null,
            annotationType: 'TimeTransaction',
            isAttachemnt:   false,
            attachment:     null,
            subject:        null,
            noteText:       null
        };
        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    }, [formData]);

    const handleUpdateNote = useCallback(e => {
        e.preventDefault();
        setEditRowKeys({
            activityId:      formData.activityId,
            costcodeId:      formData.costcodeId,
            paycodeId:       formData.paycodeId,
            equipmentId:     formData.equipmentId,
            shiftCodeId:     formData.shiftCodeId,
            employeeId:      formData.employeeId,
            transactionType: formData.transactionType,
            transactionDate: formData.transactionFormattedDate,
            id:              formData.id
        });

        const { currentTarget } = e;
        const { note } = currentTarget.dataset;
        const noteData = JSON.parse(note);
        const target = `#viewButton_${formData.id}`;
        const noteLabel = t('time.noteLabel', {transDate: parseISO(formData.transactionFormattedDate), hourCount: formData.hours});
        setIsNewNote(false);
        setAnnotationTarget(target);
        setNoteSectionLabel(noteLabel);
        setSelectedTransactionId(formData.id);
        setIsNoteReadOnly(false);
        setNoteData(noteData);
        setIsShowAnnotationForm(true);
    }, [formData]);

    const handleViewNote = useCallback(e => {
        e.preventDefault();
        setEditRowKeys({
            activityId:      formData.activityId,
            costcodeId:      formData.costcodeId,
            paycodeId:       formData.paycodeId,
            equipmentId:     formData.equipmentId,
            shiftCodeId:     formData.shiftCodeId,
            employeeId:      formData.employeeId,
            transactionType: formData.transactionType,
            transactionDate: formData.transactionFormattedDate,
            id:              formData.id
        });

        const { currentTarget } = e;
        const { note } = currentTarget.dataset;
        const noteData = JSON.parse(note);
        const target = `#viewButton_${formData.id}`;
        const noteLabel = t('time.noteLabel', {transDate: parseISO(formData.transactionFormattedDate), hourCount: formData.hours});

        setIsNewNote(false);
        setAnnotationTarget(target);
        setNoteSectionLabel(noteLabel);
        setSelectedTransactionId(formData.id);
        setIsNoteReadOnly(true);
        setNoteData(noteData);
        setIsShowAnnotationForm(true);
    }, [formData]);

    const handleDeleteNote = useCallback(e => {
        e.preventDefault();
        setEditRowKeys({
            activityId:      formData.activityId,
            costcodeId:      formData.costcodeId,
            paycodeId:       formData.paycodeId,
            equipmentId:     formData.equipmentId,
            shiftCodeId:     formData.shiftCodeId,
            employeeId:      formData.employeeId,
            transactionType: formData.transactionType,
            transactionDate: formData.transactionFormattedDate,
            id:              formData.id
        });

        const { currentTarget } = e;
        const { note } = currentTarget.dataset;
        const noteData = JSON.parse(note);

        setNoteToDelete(noteData);
        setSelectedTransactionId(formData.id);
        const title = `${t('common.delete')} ${t('time.noteLabel', {transDate: parseISO(formData.transactionFormattedDate), hourCount: formData.hours})}`;
        setDeleteConfirmationData(formData.transactionFormattedDate);
        setDeleteConfirmationTitle(title);
        setIsDeleteNoteConfirmation(true);
        setIsDeleteNoteConfirmationVisible(true);
    }, [formData]);

    const renderNoteDeleteContent = () => {
        return (
            <>
                <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                    <div>
                        <div className="confirmation-dialog-header">
                            {t('time.deleteNoteConfirmation')}
                        </div>
                        <div className="confirmation-note-message" style={{width: '100%'}}>
                            {t('time.deleteNoteConfirmationDate', {transDate: parseISO(deleteConfirmationData)})}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleDeleteNoteConfirmation = useCallback(async () => {
        if (noteToDelete) {
            const deleted = await deleteAnnotation(activeCompany.id, noteToDelete.id, noteToDelete.isAttachment, t);
            if (deleted) {
                // remove this note from the transaction
                const originalTransactions = cloneDeep(transactions);
                const trxToUpdate = originalTransactions.find(trx => trx.id === selectedTransactionId);

                if (trxToUpdate) {
                    trxToUpdate.notes = [];
                    if (trxToUpdate.status === TransactionStatus.Rejected) {
                        trxToUpdate.status = TransactionStatus.Open;
                        trxToUpdate.statusComment = null;
                        const updatedFormData = cloneDeep(trxToUpdate);

                        trxToUpdate.employeeId = trxToUpdate.employeeId?.id;
                        trxToUpdate.activityId = trxToUpdate.activityId?.id;
                        trxToUpdate.paycodeId = trxToUpdate.paycodeId?.id;
                        trxToUpdate.shiftCodeId = trxToUpdate.shiftCodeId?.id;
                        if (trxToUpdate.costcodeId) {
                            trxToUpdate.costcodeId = trxToUpdate.costcodeId?.id;
                        }

                        const timesheetData = {
                            timesheetId:        trxToUpdate.timesheetId,
                            timesheetStartDate: timesheetStartDate,
                            employeeId:         trxToUpdate.employeeId,
                            transactions:       [trxToUpdate]
                        };
                        const result = await insertTimeSheetTransaction(trxToUpdate.employeeId, timesheetData, t);
                        if (result.success) {
                            setFormData(updatedFormData);
                            setTransactions(originalTransactions);
                        }
                    }
                    else {
                        setFormData(trxToUpdate);
                        setTransactions(originalTransactions);
                    }
                }

                if (gridInstance) {
                    gridInstance.refresh();
                }
                if (listInstance) {
                    listInstance.reload();
                }
            }
        }

        setIsDeleteNoteConfirmation(false);
        setIsDeleteNoteConfirmationVisible(false);
    }, [noteToDelete, timesheetStartDate]);

    const handleDeleteDeny = async () => {
        setIsDeleteNoteConfirmation(false);
        setIsDeleteNoteConfirmationVisible(false);
    };

    const handleRefreshSavedNote = async (savedNote, transactionId) => {
        const annotation = await fetchAnnotationById(savedNote.id);
        if (annotation) {
            const originalTransactions = cloneDeep(transactions);
            const noteToRefresh = originalTransactions.filter(trx => {
                let note = trx.notes.some(note => note.id === savedNote.id);
                return note;
            });
            if (noteToRefresh && noteToRefresh.length !== 0) {
                noteToRefresh[0].notes = [annotation];
            }
            else {
                const noteTrx = originalTransactions.find(trx => trx.id === transactionId);
                if (noteTrx) {
                    noteTrx.notes = [annotation];
                }
            }
            setTransactions(originalTransactions);

            if (!isFormDirtyStateRef.current && gridInstance) {
                gridInstance.refresh();
            }
            else if (!isFormDirtyStateRef.current && listInstance) {
                listInstance.reload();
                formRef.current?.instance?.repaint();
            }
        }
    };

    const updateActivityField = useCallback(field => async lookup => {
        if (lookup.event && lookup.previousValue !== undefined && lookup.value !== lookup.previousValue && lookup.value !== formData?.activityId.id) {
            const costcodeField = 'costcodeId';
            const dirtyField = 'isTrxDirty';

            const trx = cloneDeep(formData);
            if (trx.status === TransactionStatus.Open || trx.status === TransactionStatus.Error || trx.status === TransactionStatus.Rejected) {
                trx.activityId = {id: lookup.value};
                if (activityType === TransactionType.Job) {
                    trx.costcodeId = {id: null};
                }
                if (lookup.event && !trx.isTrxDirty) {
                    trx.isTrxDirty = true;
                }
            }

            if (activityType === TransactionType.Job) {
                let defaultCostCode = { id: null };
                const job = jobList.find(j => j.id === lookup.value);
                if (job) {
                    formData.activityClientName = job.clientName;
                    formData.activitySiteName = job.siteName;
                }
                else {
                    formData.activityClientName = '';
                    formData.activitySiteName = '';
                }
                fetchJobCostCodes(lookup.value).then(codes => {
                    setJobCostCodes(codes);
                    if (codes.length === 1) {
                        defaultCostCode = { id: codes[0].id };
                    }
                });
                let newData = {
                    ...formData,
                    ...{[field]: {id: lookup.value}},
                    ...{[costcodeField]: defaultCostCode},
                    ...{[dirtyField]: true}
                };


                if (trx.status === TransactionStatus.Rejected || trx.status === TransactionStatus.Error) {
                    const statusField = 'status';
                    const statusCommentField = 'statusComment';

                    newData = {
                        ...newData,
                        ...{[statusField]: TransactionStatus.Open},
                        ...{[statusCommentField]: null}
                    };
                }

                onDataChanged(newData);
                setIsFormDirtyState(!(isNewRecord && !lookup.value));
            }
            else if (activityType === TransactionType.Service || activityType === TransactionType.Unbilled) {
                if (activityType === TransactionType.Service) {
                    const workOrder = workOrderList.find(wo => wo.id === lookup.value);
                    if (workOrder) {
                        formData.activityClientName = workOrder.clientName;
                        formData.activitySiteName = workOrder.siteName;
                    }
                    else {
                        formData.activityClientName = '';
                        formData.activitySiteName = '';
                    }
                }
                else {
                    formData.activityClientName = '';
                    formData.activitySiteName = '';
                }
                let newData = {
                    ...formData,
                    ...{[field]: {id: lookup.value}},
                    ...{[dirtyField]: true}
                };

                if (trx.status === TransactionStatus.Rejected || trx.status === TransactionStatus.Error) {
                    const statusField = 'status';
                    const statusCommentField = 'statusComment';

                    newData = {
                        ...newData,
                        ...{[statusField]: TransactionStatus.Open},
                        ...{[statusCommentField]: null}
                    };
                }

                onDataChanged(newData);
                setIsFormDirtyState(!(isNewRecord && !lookup.value));
            }
        }
        else
        {
            if (activityType === TransactionType.Job && formData?.activityId.id) {
                fetchJobCostCodes(lookup.value).then(codes => {
                    setJobCostCodes(codes);
                });
            }
        }
    }, [formData, isNewRecord]);

    const updateLookupField = useCallback(field => lookup => {
        const previousFormValue = field === 'costcodeId' ? formData?.costcodeId.id : formData?.paycodeId.id;
        if (lookup.event && lookup.previousValue !== undefined && lookup.value !== lookup.previousValue && lookup.value !== previousFormValue) {
            const shiftCodeField = 'shiftCodeId';
            const dirtyField = 'isTrxDirty';
            let newShiftCodeId = null;


            const trx = cloneDeep(formData);
            if (trx.status === TransactionStatus.Open || trx.status === TransactionStatus.Error || trx.status === TransactionStatus.Rejected) {
                trx[field] = {id: lookup.value};
                if (lookup.event && !trx.isTrxDirty) {
                    trx.isTrxDirty = true;
                }
            }

            if (settings.useShiftCode && field === 'paycodeId') {
                // find the employee paycode's shift code
                const selectedEmployeePayCode = employeePayCodes.find(pc => pc.paycodeId === lookup.value);
                if (selectedEmployeePayCode) {
                    newShiftCodeId = selectedEmployeePayCode.shiftCodeId;
                }
                else {
                    newShiftCodeId = formData.shiftCodeId;
                }
            }

            let newData = {
                ...formData,
                ...{[field]: {id: lookup.value}},
                ...{[dirtyField]: true}
            };
            if (settings.useShiftCode && field === 'paycodeId') {
                newData = {
                    ...newData,
                    ...{[shiftCodeField]: {id: newShiftCodeId}}
                };
            }

            if (trx.status === TransactionStatus.Rejected || trx.status === TransactionStatus.Error) {
                const statusField = 'status';
                const statusCommentField = 'statusComment';

                newData = {
                    ...newData,
                    ...{[statusField]: TransactionStatus.Open},
                    ...{[statusCommentField]: null}
                };
            }

            onDataChanged(newData);
            setIsFormDirtyState(!(isNewRecord && !lookup.value));
        }
    }, [formData, settings]);

    const onHourValueChanged = useCallback(e => {
        if (e.event) {
            const field = 'hours';
            const dirtyField = 'isTrxDirty';

            let newData = {
                ...formData, ...{[field]: e.value},
                ...{[dirtyField]: true}
            };

            if (formData.status === TransactionStatus.Rejected || formData.status === TransactionStatus.Error) {
                const statusField = 'status';
                const statusCommentField = 'statusComment';

                newData = {
                    ...newData,
                    ...{[statusField]: TransactionStatus.Open},
                    ...{[statusCommentField]: null}
                };
            }

            if (useTimeInOut && !isNull(formData.timeIn)) {
                const totalMinutes = Math.round(e.value * 60);
                let endTime = addMinutes(new Date(formData.timeIn), totalMinutes);
                endTime = setMilliseconds(setSeconds(endTime, 0), 0);
                newData = {
                    ...newData,
                    ...{'timeOut': endTime},
                    ...{'status': TransactionStatus.Open}
                };
            }

            onDataChanged(newData);
            setIsFormDirtyState(true);
        }
    }, [useTimeInOut, formData]);

    const handleDeleteTransaction = () => {
        handleTimeEntryDeletion(formData);
    };

    const validateZeroHours = useCallback(e => {
        if (formData.status === TransactionStatus.TimedIn) {
            return true;
        }
        return e.value !== 0;
    });

    const validateTotalHoursForDay = useCallback(e => {
        if (isFormReadOnly) {
            return true;
        }
        let selectedDate = tabRef.current?.instance.option('selectedItem')?.text;
        if (!selectedDate) {
            selectedDate = formData.transactionFormattedDate;
        }
        // Need to validate day hours do not exceed 24 hours
        let exceeds24Hours = false;
        let dayHourTotal = 0;
        const dayTransactions = fullTransactionList?.filter(trx => trx.transactionFormattedDate === selectedDate || trx.id === formData.id);
        dayTransactions?.forEach(trx => {
            if (trx.id === formData.id) {
                dayHourTotal += e.value;
            }
            else {
                dayHourTotal += trx.hours;
            }
        });
        if (!formData.id) {
            dayHourTotal += e.value;
        }
        if (dayHourTotal > 24 || dayHourTotal < -24) {
            exceeds24Hours = true;
        }
        return !exceeds24Hours;
    }, [formData, fullTransactionList]);

    const handleTimeInOutUpdate = useCallback(dataField => e => {

        if (e.event) {
            const { component } = e;

            let allowUpdate = true;
            const timeValue = e.value ? setMilliseconds(setSeconds(new Date(e.value), 0), 0) : null;
            const originalData = cloneDeep(formData);

            let newData = {
                ...formData,
                ...{[dataField]: timeValue},
                ...{'isTrxDirty': true}
            };

            if (dataField === 'timeIn' && !isNull(e.value) && isNull(formData.timeOut)) {
                let timeOutTime = formData.timeOut;
                let status = TransactionStatus.TimedIn;
                if (formData.hours) {
                    timeOutTime = addMinutes(e.value, Math.round(formData.hours * 60));
                    status = TransactionStatus.Open;
                }
                newData = {
                    ...newData,
                    ...{'timeOut': timeOutTime},
                    ...{'status': status}
                };
            }

            if (dataField === 'timeIn' && isNull(e.value)) {
                let newTimeOut = null;
                let newHours = newData.hours;
                newData = {
                    ...newData,
                    ...{'status': TransactionStatus.Open},
                    ...{'hours': newHours},
                    ...{'timeOut': newTimeOut}
                };

                let minTimeIn = new Date(newData.transactionFormattedDate);
                minTimeIn = addMinutes(minTimeIn, minTimeIn.getTimezoneOffset);
                setMinTimeInValue(minTimeIn);
                let maxTimeInValue = addDays(minTimeIn, 1);
                maxTimeInValue = addSeconds(maxTimeInValue, -1);
                setMaxTimeInValue(maxTimeInValue);
            }
            else {
                const maxTimeOut = addMinutes(e.value, 1);
                setMinTimeOutValue(maxTimeOut);
            }
            if (dataField === 'timeOut' && !isNull(newData.timeIn)) {
                const difference = differenceInMinutes(timeValue, new Date(originalData.timeIn));
                allowUpdate = difference >= 1 && difference <= 1440;
                if (allowUpdate) {
                    const totalHours = e.value ? toHoursAndMinutes(differenceInMinutes(timeValue, new setMilliseconds(setSeconds(new Date(formData.timeIn), 0), 0))) : null;
                    newData = {
                        ...newData,
                        ...{'status': TransactionStatus.Open},
                        ...{'hours': totalHours ? Number(totalHours) : null}
                    };
                }
                else {
                    if (difference > 1440) {
                        setTimeOutRangeErrorMessage(t('validation.maxTimeOutRange'));
                    }
                    else {
                        setTimeOutRangeErrorMessage(t('validation.endTimeBeforeStartTime'));
                    }
                    component.option('isValid', false);
                }
            }

            if (allowUpdate)
                onDataChanged(newData);
        }
    }, [formData]);

    const handleTimeIn = useCallback(e => {
        let timeInTime = setMilliseconds(setSeconds(new Date(), 0), 0);
        let status = TransactionStatus.TimedIn;
        let hours = formData.hours;
        let timeOutTime = null;
        const columnDate = formData.transactionFormattedDate.split('-');
        timeInTime = setYear(timeInTime, columnDate[0]);
        timeInTime = setMonth(timeInTime, columnDate[1] - 1);
        timeInTime = setDate(timeInTime, columnDate[2]);

        setMaxTimeInValue(addMinutes(timeInTime, 1));
        if (formData.hours) {
            timeOutTime = addMinutes(timeInTime, Math.round(hours * 60));
            status = TransactionStatus.Open;
        }
        const newData = {
            ...formData,
            ...{'timeIn': timeInTime},
            ...{'timeOut': timeOutTime},
            ...{'status': status},
            ...{'isTrxDirty': true}
        };
        onDataChanged(newData);
    }, [formData]);

    const handleTimeOut = useCallback(e => {
        const timeOutTime = setMilliseconds(setSeconds(new Date(), 0), 0);
        if (!isBefore(timeOutTime, new Date(formData.timeIn))) {
            const totalHours = toHoursAndMinutes(differenceInMinutes(timeOutTime, setMilliseconds(setSeconds(new Date(formData.timeIn), 0), 0)));
            const newData = {
                ...formData,
                ...{'timeOut': timeOutTime},
                ...{'status': TransactionStatus.Open},
                ...{'hours': Number(totalHours)},
                ...{'isTrxDirty': true}
            };
            onDataChanged(newData);
        }
        else {
            showToastMessage(t('validation.endTimeBeforeStartTime'), 'error');
            const data = {
                ...formData,
            };
            onDataChanged(data);
        }
    }, [formData]);

    const timerStartIcon = useMemo(() => {
        const newDate = format(new Date(), 'yyyy-MM-dd');
        const sameDay = isSameDay(new Date(formData.transactionFormattedDate), new Date(newDate));
        if (!sameDay) {
            return false;
        }

        if (useTimeInOut && formData && formData.status === TransactionStatus.Open && isNull(formData.timeIn) && !hasTimedInEntry) {
            return (
                <div id={`timerstartbutton_${formData.id}`}>
                    <button
                        aria-label={t('time.timeIn')}
                        className="button-image"
                        id={`timeinbutton_${formData.id}`}
                        onClick={handleTimeIn}
                    >
                        <Icon
                            path={mdiTimerPlayOutline}
                            color={'green'}
                            size={1}
                            title={t('time.timeIn')}
                        />
                    </button>
                </div>
            );
        }
        else {
            return null;
        }
    }, [useTimeInOut, formData, hasTimedInEntry]);

    const timerStopIcon = useMemo(() => {
        if (useTimeInOut && formData && formData.status === TransactionStatus.TimedIn) {
            return (
                <div id={`timerstopbutton_${formData.id}`}>
                    <button
                        aria-label={t('time.timeOut')}
                        className="button-image"
                        id={`timeoutbutton_${formData.id}`}
                        onClick={handleTimeOut}
                    >
                        <Icon
                            path={mdiTimerStopOutline}
                            color={'red'}
                            size={1}
                            title={t('time.timeOut')}
                        />
                    </button>
                </div>
            );
        }
        else {
            return null;
        }
    }, [useTimeInOut, formData]);

    const deleteIcon = useMemo(() => {
        if (formData) {
            switch (formData.status) {
                case TransactionStatus.Open:
                case TransactionStatus.TimedIn:
                    if (formData.id) {
                        return (
                            <div id={`deletebutton_${formData.id}`}>
                                <button
                                    aria-label={t('time.deleteEntry')}
                                    className="button-image"
                                    id={`delete_entry_button_${formData.id}`}
                                    data-noteid={formData.id}
                                    onClick={handleDeleteTransaction}
                                >
                                    <Icon
                                        path={mdiDeleteOutline}
                                        size={1}
                                        title={t('time.deleteEntry')}
                                    />
                                </button>
                            </div>
                        );
                    }
                    else {
                        return null;
                    }
                case TransactionStatus.Rejected:
                case TransactionStatus.Error:
                    return (
                        <div id={`deletebutton_${formData.id}`}>
                            <button
                                aria-label={t('time.deleteEntry')}
                                className="button-image"
                                id={`delete_entry_button_${formData.id}`}
                                data-noteid={formData.id}
                                onClick={handleDeleteTransaction}
                            >
                                <Icon
                                    path={mdiDeleteOutline}
                                    size={1}
                                    title={t('time.deleteEntry')}
                                />
                            </button>
                        </div>
                    );
                default:
                    return;
            }
        } else {
            return;
        }
    }, [formData?.id, formData?.status]);

    const statusIcon = useMemo(() => {
        if (formData) {
            switch (formData.status) {
                case TransactionStatus.Open:
                    return (
                        <Icon
                            path={mdiLockOpenOutline}
                            size={1}
                            color="#4588bf"
                            title={t(`statuses.${formData.status.toLowerCase()}`)}
                        />);
                case TransactionStatus.Rejected:
                case TransactionStatus.Error:
                    return (
                        <Icon
                            path={mdiAlertCircleOutline}
                            size={1}
                            color="red"
                            title={t(`statuses.${formData.status.toLowerCase()}`)}
                        />);
                case TransactionStatus.Submitted:
                case TransactionStatus.Approved:
                    return (
                        <Icon
                            path={mdiLockOutline}
                            size={1}
                            color="#4588bf"
                            title={t(`statuses.${formData.status.toLowerCase()}`)}
                        />);
                case TransactionStatus.Processed:
                    return (
                        <Icon
                            path={mdiCheckboxMarkedCircleOutline}
                            size={1}
                            color="green"
                            title={t(`statuses.${formData.status.toLowerCase()}`)}
                        />);
                case TransactionStatus.TimedIn:
                    return (
                        <Icon
                            path={mdiTimerSand}
                            size={1}
                            color={'green'}
                            title={t(`statuses.${formData.status.toLowerCase()}`)}
                            spin
                        />
                    );
                default:
                    return;
            }
        }
        else {
            return;
        }
    }, [formData?.status]);

    const notesIcons = useMemo(() => {
        if (formData) {
            if (formData?.notes) {
                if (formData.status === TransactionStatus.Submitted || formData.status === TransactionStatus.Approved || formData.status === TransactionStatus.Processed) {
                    if (formData.notes.length === 1) {
                        return (
                            <div id={`viewbutton_${formData.notes[0].id}`}>
                                <button
                                    aria-label={t('time.viewNote')}
                                    className="button-image"
                                    id={`button_view_${formData.notes[0].id}`}
                                    onClick={handleViewNote}
                                    data-note={JSON.stringify(formData.notes[0])}
                                >
                                    <Icon
                                        path={mdiCommentTextOutline}
                                        size={1}
                                        title={t('time.viewNote')}
                                    />
                                </button>
                            </div>
                        );
                    }
                    else {
                        return;
                    }
                }
                else if (formData.notes.length === 1) {
                    return (<>
                        <div className="transaction-hours-note-container">
                            <div className="transaction-hours-view-note" id={`viewbutton_${formData.notes[0].id}`}>
                                <button
                                    id={`button_view_${formData.notes[0].id}`}
                                    aria-label={t('time.editNote')}
                                    className="button-image"
                                    onClick={handleUpdateNote}
                                    data-note={JSON.stringify(formData.notes[0])}
                                >
                                    <Icon
                                        path={mdiCommentTextOutline}
                                        size={1}
                                        title={t('time.editNote')}
                                    />
                                </button>
                            </div>
                            <div className="transaction-hours-delete-note" id={`deletebutton_${formData.notes[0].id}`}>
                                <button
                                    id={`button_delete_note_${formData.notes[0].id}`}
                                    aria-label={t('time.deleteNote')}
                                    className="button-image"
                                    onClick={handleDeleteNote}
                                    data-note={JSON.stringify(formData.notes[0])}
                                >
                                    <Icon
                                        path={mdiCommentRemoveOutline}
                                        size={1}
                                        title={t('time.deleteNote')}
                                    />
                                </button>
                            </div>
                        </div>
                    </>);
                }
                else if (!isNull(formData.id) && formData.notes.length === 0 && (formData.status === TransactionStatus.Open || formData.status === TransactionStatus.TimedIn || formData.status === TransactionStatus.Rejected || formData.status === TransactionStatus.Error)) {
                    return (
                        <div id={`addnotebutton_${formData.id}`}>
                            <button
                                aria-label={t('time.addNote')}
                                className="button-image"
                                id={`button_add_note_${formData.id}`}
                                onClick={handleAddNote}
                            >
                                <Icon
                                    path={mdiCommentPlusOutline}
                                    size={1}
                                    title={t('time.addNote')}
                                />
                            </button>
                        </div>
                    );
                }
                return;
            }
        }
        else {
            return;
        }
    }, [formData?.notes, formData?.status]);

    const renderForm = useCallback((e) => {
        return (<></>);
    }, []);

    return (
        <>
            <TabPanel
                animationEnabled={false}
                disabled={isFormReadOnly || (hasTimedInEntry && formData.status === TransactionStatus.TimedIn) }
                itemRender={renderForm}
                itemTitleRender={renderDayTab(i18n.resolvedLanguage)}
                loop={false}
                onOptionChanged={onOptionChanged}
                ref={tabRef}
                swipeEnabled={false}
                selectedIndex={selectedTabIndex}
            >
                {
                    periodDays.map((period, index) => (
                        <TabItem
                            key={index}
                            title={period.formattedDate}
                            text={period.formattedDate}
                            period={period}
                        />
                    ))
                }
            </TabPanel>
            <div className="transaction-entry-form">
                <Box direction="col" height={devices.current().phone || devices.current().tablet ? 'calc(100vh - 250px)' : '69vh'}>
                    <BoxItem ratio={1}>
                        <ScrollView width="100%" height="100%">
                            <Form
                                formData={formData}
                                key={`timeEntryForm_${formData?.title}`}
                                readOnly={isFormReadOnly}
                                ref={formRef}
                                screenByWidth={getSizeQualifier}
                            >
                                <FormItem
                                    helpText={activityHelpText}
                                >
                                    <Label text={`${activityLabel.text} *`} />
                                    <FormLookup
                                        dataSource={activityType && activityType === TransactionType.Job ? jobList : activityType && activityType === TransactionType.Service ? workOrderList : unbilledActivities}
                                        displayExpression={getActivityDisplayExpr}
                                        dropDownCentered={false}
                                        dropDownHeight={devices.current().phone || devices.current().tablet ? 'calc(100vh - 250px)' : '350px'}
                                        itemRender={ActivityItem}
                                        noDataText={activityType && activityType === TransactionType.Job ? t('time.noJobsFound') : activityType && activityType === TransactionType.Service ? t('time.noServiceCallsFound') : t('time.noActivitiesFound')}
                                        onValueChanged={updateActivityField('activityId')}
                                        placeholder={`Select ${activityLabel.text}`}
                                        readOnly={isFormReadOnly}
                                        searchEnabled={true}
                                        searchExpression={activityType && activityType === TransactionType.Unbilled ? unbilledActivitySearchExpression : activitySearchExpression}
                                        value={formData.activityId?.id}
                                        valueExpression="id"
                                    >
                                        <RequiredRule message={t('validation.activityRequired', {activityType: activityLabel.text})} />
                                    </FormLookup>
                                </FormItem>
                                {isCostCodeVisible ?
                                    <FormItem>
                                        <Label text={`${t('time.costCode')} *`} />
                                        <FormLookup
                                            dataSource={activityType === TransactionType.Job ? jobCostCodes : workOrderLaborCostCodes}
                                            displayExpression={getCostCodeDisplayExpr}
                                            dropDownCentered={false}
                                            dropDownHeight="250px"
                                            itemRender={CostCodeItem}
                                            noDataText={activityType === TransactionType.Job ? t('time.selectJobForCostCode') : t('time.noCostCodesFound')}
                                            onValueChanged={updateLookupField('costcodeId')}
                                            placeholder={t('time.selectCostCode')}
                                            readOnly={isFormReadOnly}
                                            searchEnabled={true}
                                            searchExpression={activityType === TransactionType.Job ? jobCostCodeSearchExpression : serviceCostCodeSearchExpression}
                                            value={formData.costcodeId?.id}
                                            valueExpression="id"
                                        >
                                            <RequiredRule message={t('validation.costCodeRequired')} />
                                        </FormLookup>
                                    </FormItem>
                                    : null
                                }
                                <FormItem>
                                    <Label text={`${t('time.payCode')} *`} />
                                    <FormLookup
                                        dataSource={laborPayCodes}
                                        displayExpression={getPayCodeDisplayExpr}
                                        dropDownCentered={false}
                                        dropDownHeight="250px"
                                        grouped={true}
                                        groupRender={renderPayCodeGroupHeader(t)}
                                        noDataText={t('time.noEmployeePayCodes')}
                                        itemRender={PayCodeItem}
                                        onValueChanged={updateLookupField('paycodeId')}
                                        placeholder={t('time.selectPayCode')}
                                        readOnly={isFormReadOnly}
                                        searchEnabled={true}
                                        searchExpression={['paycodeName', 'paycodeDescription']}
                                        value={formData.paycodeId?.id}
                                        valueExpression="paycodeId"
                                    >
                                        <RequiredRule message={t('validation.paycodeRequired')} />
                                    </FormLookup>
                                </FormItem>
                                {
                                    settings.useShiftCode ?
                                        <FormItem>
                                            <Label text={t('time.shiftCode')} />
                                            <FormLookup
                                                dataSource={shiftCodes}
                                                displayExpression={getShiftCodeDisplayExpr}
                                                dropDownCentered={false}
                                                dropDownHeight="250px"
                                                grouped={false}
                                                noDataText={t('time.noShiftCodes')}
                                                itemRender={ShiftCodeItem}
                                                onValueChanged={updateLookupField('shiftCodeId')}
                                                placeholder={t('time.selectShiftCode')}
                                                readOnly={isFormReadOnly}
                                                isRequired={false}
                                                searchEnabled={true}
                                                searchExpression={['name', 'description']}
                                                showClearButton={true}
                                                value={formData.shiftCodeId?.id}
                                                valueExpression="id"
                                            />
                                        </FormItem>
                                        : null
                                }
                                {
                                    useTimeInOut && !hideTimeInOutFields &&
                                    <FormItem>
                                        <Label text={t('time.timeIn')} />
                                        <div className="time-in-out-container">
                                            <div className="time-in-out-picker">
                                                <DateBox
                                                    min={minTimeInValue}
                                                    max={maxTimeInValue}
                                                    onValueChanged={handleTimeInOutUpdate('timeIn')}
                                                    pickerType="calendar"
                                                    readOnly={lockTimeInOutEntries || hasTimedInEntry || isFormReadOnly}
                                                    showAnalogClock={false}
                                                    showClearButton={(!hasTimedInEntry || isFormReadOnly || (lockTimeInOutEntries && !isNull(formData.timeIn)))}
                                                    type="datetime"
                                                    value={formData.timeIn}
                                                    valueChangeEvent="keyup blur input change focusout"
                                                    width={devices.current().phone || devices.current().tablet ? 225 : 225}
                                                />
                                            </div>
                                            <div className={`time-in-out-icon${devices.current().phone || devices.current().tablet ? '-mobile' : ''}`}>{timerStartIcon}</div>
                                        </div>
                                    </FormItem>
                                }
                                {
                                    useTimeInOut && !hideTimeInOutFields &&
                                    <FormItem>
                                        <Label text={t('time.timeOut')} />
                                        <div className="time-in-out-container">
                                            <div className="time-in-out-picker">
                                                <DateBox
                                                    dateOutOfRangeMessage={timeOutRangeErrorMessage}
                                                    min={minTimeOutValue}
                                                    max={addHours(new Date(formData.timeIn), 24)}
                                                    onValueChanged={handleTimeInOutUpdate('timeOut')}
                                                    pickerType="calendar"
                                                    readOnly={lockTimeInOutEntries || isFormReadOnly || isNull(formData.timeIn)}
                                                    showAnalogClock={false}
                                                    showClearButton={!(isFormReadOnly || isNull(formData.timeIn) || (lockTimeInOutEntries && !isNull(formData.timeOut)))}
                                                    type="datetime"
                                                    value={formData.timeOut}
                                                    valueChangeEvent="keyup blur input change focusout"
                                                    width={devices.current().phone || devices.current().tablet ? 225 : 225}
                                                />
                                            </div>
                                            <div className={`time-in-out-icon${devices.current().phone || devices.current().tablet ? '-mobile' : ''}`}>{timerStopIcon}</div>
                                        </div>
                                    </FormItem>
                                }
                                <FormItem>
                                    <Label text={`${t('time.hours')} *`} />
                                    <div className={`transaction-hours-container${devices.current().phone || devices.current().tablet ? '-mobile' : ''}`}>
                                        <div className="transaction-hours-hours">
                                            <NumberBox
                                                value={formData.hours}
                                                showClearButton={true}
                                                showSpinButtons={true}
                                                useLargeSpinButtons={devices.current().phone || devices.current().tablet}
                                                inputAttr={inputAttr}
                                                width={devices.current().phone || devices.current().tablet ? 180 : 150}
                                                readOnly={isFormReadOnly}
                                                max={24}
                                                min={-24}
                                                valueChangeEvent="keyup change input"
                                                onValueChanged={onHourValueChanged}
                                                format={numBoxFormat}
                                            >
                                                <Validator>
                                                    { formData && formData.status !== TransactionStatus.TimedIn && <RequiredRule message={t('validation.hoursRequired')} /> }
                                                    <RangeRule
                                                        min={-24}
                                                        max={24}
                                                    />
                                                    <CustomRule
                                                        validationCallback={validateTotalHoursForDay}
                                                        reevaluate={true}
                                                        message={t('validation.totalHoursExceed24')}
                                                    />
                                                    { formData && formData.status !== TransactionStatus.TimedIn &&
                                                    <CustomRule
                                                        validationCallback={validateZeroHours}
                                                        reevaluate={true}
                                                        message={t('validation.hoursNotZero')}
                                                    />
                                                    }
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                        <div className="transaction-hours-delete">{deleteIcon}</div>
                                        <div className="transaction-hours-status">{statusIcon}</div>
                                        <div className="transaction-hours-notes">{notesIcons}</div>
                                        <div className="transaction-hours-attachments" />
                                        {formData && (formData.status === TransactionStatus.Error || formData.status === TransactionStatus.Rejected) ?
                                            <div className="transaction-status-comment">
                                                {
                                                    formData.status === TransactionStatus.Error || formData.status === TransactionStatus.Rejected ?
                                                        formData.statusComment
                                                        :
                                                        ''
                                                }
                                            </div>
                                            : null}
                                    </div>
                                </FormItem>
                            </Form>
                        </ScrollView>
                    </BoxItem>
                    <BoxItem
                        render={ShowLegendDetails(t)}
                    />
                </Box>
                {
                    isShowAnnotationForm ?
                        <AnnotationForm
                            activeEmployee={activeEmployee}
                            annotationType={AnnotationTypes.TIMETRANSACTION}
                            cancelButtonAction={handleAnnotationCancel}
                            handleAddNote={handleAddNote}
                            isNoteReadOnly={isNoteReadOnly}
                            isNewNote={isNewNote}
                            noteData={noteData}
                            noteSectionLabel={noteSectionLabel}
                            objectId={selectedTransactionId}
                            setIsShowAnnotationForm={setIsShowAnnotationForm}
                            showAnnotationForm={isShowAnnotationForm}
                            showAttachmentForm={false}
                            showNoteForm={true}
                            target={annotationTarget}
                            title={t('time.annotations')}
                            useTimeEntryForm={true}
                            handleRefreshSavedNote={handleRefreshSavedNote}
                            gridInstance={gridInstance}
                            listInstance={listInstance}
                            useShading={false}
                        />
                        : null
                }
                {
                    isDeleteNoteConfirmationVisible ?
                        <ConfirmationDialog
                            showDialog={isDeleteNoteConfirmationVisible}
                            confirmationTitle={deleteConfirmationTitle}
                            confirmButtonAction={handleDeleteNoteConfirmation}
                            confirmButtonText={t('common.yes')}
                            denyButtonAction={handleDeleteDeny}
                            denyButtonText={t('common.no')}
                            renderContent={renderNoteDeleteContent}
                            height={300}
                            width={isXSmall || isSmall ? '99vw' : 500}
                        />
                        : null
                }
            </div>

        </>
    );
};


const mapStateToProps = state => ({
    activeCompany:      state.time.activeCompany,
    activeEmployee:     state.employee.activeEmployee,
    currentTimesheet:   state.time.currentTimesheet,
    currentUser:        state.currentUser,
    employeePayCodes:   state.employee.employeePayCodes,
    featureFlags:       state.core.featureFlags,
    jobList:            state.job.jobs,
    periodDays:         state.time.periodDays,
    settings:           state.time.settings,
    shiftCodes:         state.time.shiftCodes,
    timesheetStartDate: state.time.timesheetStartDate,
    timeUserSettings:   state.configurations.timeUserSettings,
    unbilledActivities: state.activities.unbilledActivities,
    workOrderCostCodes: state.workOrder.costCodes,
    workOrderList:      state.workOrder.workOrders
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...JobActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryForm);

