import React, { useState, useEffect } from 'react';

import './loadingMask.scss';

const LoadingMask =({
    imageSize = 'large'
}) => {
    const [image, setImage] = useState('WS110x110.gif');

    useEffect(() => {
        if (imageSize === 'large') {
            setImage('WS110x110BLUE.gif');
        }
        else {
            setImage('WS55x55BLUE.gif');
        }
    }, [imageSize]);

    return (
        <div className={'loading-mask'}>
            <div className={'loading-icon-container'}>
                <img src={`/assets/images/${image}`} alt="loader" />
            </div>
            {/* <div className={'color'} /> */}
        </div>
    );
};

export default LoadingMask;
