import showToastMessage from './toastHelper';

export const handle422ErrorMessage = (data) => {
    if (data.errors && (typeof data.errors !== 'string' || data.errors instanceof String)) {
        Object.keys(data.errors).forEach(key => {
            Object.keys(data.errors[key]).forEach(child => {
                showToastMessage(data.errors[key][child], 'error');
            });
        });
    }
    else if (data.errors) {
        showToastMessage(data.errors, 'error');
    }
    else {
        showToastMessage(data, 'error');
    }
};

export const handle403ErrorMessage = (t) => {
    showToastMessage(t ? t('common.userNotAuthorized') : 'User is not authorized to access this resource', 'error');
};
