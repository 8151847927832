import React from 'react';
import { signOut as AuthSignOut } from 'aws-amplify/auth';
import { useNavigate , useSearchParams } from 'react-router-dom';
import DropDownButton from 'devextreme-react/drop-down-button';
import ShowToastMessage from '../../helpers/toastHelper';
import { SET_CHANGE_PASSWORD_VISIBLE, USER_LOGOUT } from '../../actions/actionTypes';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import ContextMenu, { Position } from 'devextreme-react/context-menu';
// import List from 'devextreme-react/list';

import './UserPanel.scss';

const UserPanel = ({
    currentUser,
    menuMode,
    parentToolbar
}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const signOut = async () => {
        try {
            if (searchParams.has('companyId')) {
                searchParams.delete('companyId');
                setSearchParams(searchParams);
            }
            sessionStorage.removeItem('userSettings');
            sessionStorage.removeItem('returnUrl');

            await AuthSignOut({global: true})
                .catch(err => {
                    AuthSignOut();
                });
            dispatch({
                type: USER_LOGOUT
            });
        }
        catch (error) {
            ShowToastMessage(error, 'error');
        }
    };

    const changePassword = () => {
        if (parentToolbar.current?.instance) {
            parentToolbar.current?.instance.repaint();
        }
        dispatch({
            type:    SET_CHANGE_PASSWORD_VISIBLE,
            payload: true
        });
    };

    const loadUserProfile = () => {
        navigate(`/userprofile`);
    };

    // const menuItems = useMemo(
    //     () => [{
    //         text:    'Sign Out',
    //         icon:    'runner',
    //         onClick: signOut
    //     }],
    //     [signOut]
    // );

    const userActionItems = [
        {
            id:      0,
            text:    t('menu.userProfile'),
            icon:    'user',
            onClick: loadUserProfile
        },
        {
            id:      0,
            text:    t('menu.changePassword'),
            icon:    'key',
            onClick: changePassword
        },
        {
            id:      1,
            text:    t('menu.signOut'),
            icon:    'runner',
            onClick: signOut
        }
    ];

    return (
        <DropDownButton
            text={currentUser.userName}
            displayExpr={'text'}
            icon={'user'}
            items={userActionItems}
            showArrowIcon={false}
            dropDownOptions={{
                minWidth: '175px'
            }}
        />
    );

    // return (
    //     <div className="user-panel">
    //         <div className="user-info">
    //             <div className="image-container">
    //                 <div
    //                     style={{
    //                         background:     `url(${profilePicture}) no-repeat #fff`,
    //                         backgroundSize: 'cover'
    //                     }}
    //                     className="user-image"
    //                 />
    //             </div>
    //             <div className="user-name">{currentUser.userName}</div>
    //         </div>
    //         { menuMode === 'context' && (
    //             <ContextMenu
    //                 items={menuItems}
    //                 target=".user-button"
    //                 showEvent="dxclick"
    //                 width={210}
    //                 cssClass="user-menu"
    //             >
    //                 <Position my="top center" at="bottom center" />
    //             </ContextMenu>
    //         )}
    //         { menuMode === 'list' && (
    //             <List className="dx-toolbar-menu-action" items={menuItems} />
    //         )}
    //     </div>
    // );
};

export default UserPanel;
