import Box, {Item as BoxItem} from 'devextreme-react/box';
import { TransactionType } from '../../data/transactionTypes';
import { TransactionStatus } from '../../data/transactionStatuses';
import Icon from '@mdi/react';
import {
    mdiLockOpenOutline,
    mdiLockOutline,
    mdiAlertCircleOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiCommentTextOutline,
    mdiTimerSand
} from '@mdi/js';
import { isNull } from 'lodash';

export const ViewItems = [
    {
        id:      0,
        text:    'time.byEntry',
        visible: true,
        sort:    [{selector: 'isTimedIn', desc: false}, {selector: 'transactionFormattedDate', desc: false} , {selector: 'status', desc: false}, {selector: 'timeIn', desc: false}],
        group:   null
    },
    {
        id:      1,
        text:    'time.byDay',
        visible: true,
        sort:    [{selector: 'isTimedIn', desc: false}, {selector: 'transactionFormattedDate', desc: false} , {selector: 'status', desc: false}, {selector: 'timeIn', desc: false}],
        group:   {selector: 'transactionFormattedDate', desc: true}
    },
    {
        id:      2,
        text:    'time.byActivity',
        visible: false
    }
];

export const EditActionItems = [
    {
        id:              'timeOut',
        index:           0,
        isEntryAction:   true,
        isNoteAction:    false,
        text:            'time.timeOut',
        allowedStatuses: [TransactionStatus.TimedIn]
    },
    {
        id:              'timeInNewEntry',
        index:           1,
        isEntryAction:   true,
        isNoteAction:    false,
        text:            'time.timeInNewEntry',
        allowedStatuses: [TransactionStatus.Open, TransactionStatus.Rejected, TransactionStatus.Error, TransactionStatus.Approved, TransactionStatus.Submitted, TransactionStatus.Processed]
    },
    {
        id:              'editEntry',
        index:           2,
        isEntryAction:   true,
        isNoteAction:    false,
        text:            'time.editEntry',
        allowedStatuses: [TransactionStatus.Open, TransactionStatus.TimedIn, TransactionStatus.Rejected, TransactionStatus.Error]
    },
    {
        id:              'viewEntry',
        index:           3,
        isEntryAction:   true,
        isNoteAction:    false,
        text:            'time.viewEntry',
        allowedStatuses: [TransactionStatus.Submitted, TransactionStatus.Approved, TransactionStatus.Processed]
    },
    {
        id:              'deleteEntry',
        index:           4,
        isEntryAction:   true,
        isNoteAction:    false,
        text:            'time.deleteEntry',
        allowedStatuses: [TransactionStatus.Open, TransactionStatus.TimedIn, TransactionStatus.Rejected, TransactionStatus.Error]
    },
    {
        id:              'addNote',
        index:           5,
        isEntryAction:   false,
        isNoteAction:    true,
        text:            'time.addNote',
        requiresNotes:   false,
        allowedStatuses: [TransactionStatus.Open, TransactionStatus.TimedIn, TransactionStatus.Rejected, TransactionStatus.Error]
    },
    {
        id:              'editNote',
        index:           6,
        isEntryAction:   false,
        isNoteAction:    true,
        text:            'time.editNote',
        requiresNotes:   true,
        allowedStatuses: [TransactionStatus.Open, TransactionStatus.TimedIn, TransactionStatus.Rejected, TransactionStatus.Error]
    },
    {
        id:              'deleteNote',
        index:           7,
        isEntryAction:   false,
        isNoteAction:    true,
        text:            'time.deleteNote',
        requiresNotes:   true,
        allowedStatuses: [TransactionStatus.Open, TransactionStatus.TimedIn, TransactionStatus.Rejected, TransactionStatus.Error]
    },
    {
        id:              'viewNote',
        index:           8,
        isEntryAction:   false,
        isNoteAction:    true,
        text:            'time.viewNote',
        requiresNotes:   true,
        allowedStatuses: [TransactionStatus.Submitted, TransactionStatus.Approved, TransactionStatus.Processed]
    }
];

const getActivityIconClass = activityType => {
    return activityType === 'JOB' ? 'fa-regular fa-building' : activityType === 'SERVICE' ? 'fa-solid fa-hammer' : 'fa-regular fa-money-bill-1';
};


const noteAttachmentIcon = (item) => {
    if (item.notes?.length !== 0) {
        return (
            <Icon
                path={mdiCommentTextOutline}
                size={'16px'}
                title=""
            />
        );
    }
    else {
        return null;
    }
};

const itemStatusDisplay = (item) => {
    switch (item.status) {
        case TransactionStatus.Open:
            return (
                <div className="mobile-list-status-container">
                    <div className="mobile-list-status-icon">
                        <Icon
                            path={mdiLockOpenOutline}
                            size={'16px'}
                            title=""
                            color="#4588bf"
                        />
                    </div>
                    <div className="mobile-list-status-text">
                        {item.statusString ? item.statusString : item.status}
                    </div>
                </div>
            );
        case TransactionStatus.Submitted:
        case TransactionStatus.Approved:
            return (
                <div className="mobile-list-status-container">
                    <div className="mobile-list-status-icon">
                        <Icon
                            path={mdiLockOutline}
                            size={'16px'}
                            title=""
                            color="#4588bf"
                        />
                    </div>
                    <div className="mobile-list-status-text">
                        {item.statusString ? item.statusString : item.status}
                    </div>
                </div>
            );
        case TransactionStatus.Rejected:
        case TransactionStatus.Error:
            return (
                <div className="mobile-list-status-container">
                    <div className="mobile-list-status-icon">
                        <Icon
                            path={mdiAlertCircleOutline}
                            size={'16px'}
                            title=""
                            color="red"
                        />
                    </div>
                    <div className="mobile-list-status-text">
                        {item.statusString ? item.statusString : item.status}
                    </div>
                </div>
            );
        case TransactionStatus.Processed:
            return (
                <div className="mobile-list-status-container">
                    <div className="mobile-list-status-icon">
                        <Icon
                            path={mdiCheckboxMarkedCircleOutline}
                            size={'16px'}
                            title=""
                            color="green"
                        />
                    </div>
                    <div className="mobile-list-status-text">
                        {item.statusString ? item.statusString : item.status}
                    </div>
                </div>
            );
        case TransactionStatus.TimedIn:
            return (
                <div className="mobile-list-status-container">
                    <div className="mobile-list-status-icon">
                        <Icon
                            path={mdiTimerSand}
                            size={'16px'}
                            title=""
                            color="green"
                            spin
                        />
                    </div>
                    <div className="mobile-list-status-text">
                        {item.statusString ? item.statusString : item.status}
                    </div>
                </div>
            );
        default:
            return null;
    }
};

const formatDisplayDate = (displayDate, language) => {
    if (!isNull(displayDate)) {
        return new Date(displayDate).toLocaleTimeString(language, {hour: '2-digit', minute: '2-digit'});
    }
    else {
        return null;
    }

};

export const renderDayView = (useShiftCode, useTimeInOut, t, language) => (item) => {
    let activity;
    let customerLocation;
    switch (item.transactionType) {
        case TransactionType.Job:
        case TransactionType.Service:
            activity = `${item.activityName} - ${item.activityDescription}`;
            customerLocation = `${item.activityClientName} - ${item.activitySiteName}`;
            break;
        case TransactionType.Unbilled:
            activity = item.activityName;
            break;
        default:
            break;
    }
    const activityType = item.transactionType?.toUpperCase();
    const iconClass = getActivityIconClass(activityType);
    const unbilledIconStyling = {
        paddingRight: '5px'
    };
    const jobIconStyling = {
        paddingRight: '5px'
    };
    const serviceIconStylng = {
        paddingRight: '5px'
    };

    return (
        <div className={`mobile-transaction-container${item.status === TransactionStatus.TimedIn ? '-timed-in' : ''}`} >
            <div className="activity-row">
                <div><i className={iconClass} style={activityType === 'Job' ? jobIconStyling : activityType === 'Service' ? serviceIconStylng : unbilledIconStyling} />{activity}</div>
            </div>
            {item.transactionType !== TransactionType.Unbilled ? (
                <div className="customer-row">
                    <span>{customerLocation}</span>
                </div>
            ) : null}
            <Box direction="row" width={'100vw'}>
                <BoxItem ratio={2}>
                    <div>
                        <span className="list-item-label">
                            {item.transactionType !== TransactionType.Unbilled
                                ? `${t('time.costCode')}:`
                                : null}
                        </span>
                        <span className="list-item-value">
                            {item.transactionType === TransactionType.Job
                                ? item.costcodeAlias
                                : item.costcodeDescription}
                        </span>
                    </div>
                    <div>
                        <span className="list-item-label">{`${t('time.payCode')}:`}</span>
                        <span className="list-item-value">{item.paycodeDescription}</span>
                    </div>
                    {
                        useTimeInOut ?
                            <>
                                <div>
                                    <span className="list-item-label">{`${t('time.timeIn')}:`}</span>
                                    <span className="list-item-value">{formatDisplayDate(item.timeIn, t, language)}</span>
                                </div>
                            </>
                            : null
                    }
                    { useShiftCode ?
                        <>
                            <div>
                                <span className="list-item-label">{`${t('time.shiftCode')}:`}</span>
                                <span className="list-item-value">{item.shiftCodeDescription}</span>
                            </div>
                        </>
                        :
                        null
                    }
                    <div className="status-value">{itemStatusDisplay(item)}</div>
                </BoxItem>
                <BoxItem ratio={1} baseSize={50}>
                    <div>
                        <span className="list-item-label">{`${t('common.date')}:`}</span>
                        <span className="list-item-value">{item.transactionFormattedDate}</span>
                    </div>
                    <div>
                        <span className="list-item-label">{`${t('common.hours')}:`}</span>
                        <span className="list-item-value">{!isNull(item.hours) ? parseFloat(item.hours).toFixed(2) : null}</span>
                    </div>
                    {
                        useTimeInOut ?
                            <>
                                <div>
                                    <span className="list-item-label">{`${t('time.timeOut')}:`}</span>
                                    <span className="list-item-value">{formatDisplayDate(item.timeOut, t, language)}</span>
                                </div>
                            </>
                            : null
                    }
                    <div>
                        <span style={{float: 'right', paddingRight: '30px'}}>{noteAttachmentIcon(item)}</span>
                    </div>
                </BoxItem>
            </Box>
        </div>
    );
};

export const renderGroupView = (langId, t) => (item) => {
    if (item.key) {
        let trxDate = new Date(item.key);   // Convert UTC time string to local timezone date
        trxDate.setHours(trxDate.getHours() + (trxDate.getTimezoneOffset()/60));

        let regularHours = 0;
        let otherHours = 0;
        let totalHours = 0;

        item.items.forEach((trx) => {
            if (trx.payCodeType === 1 || trx.payCodeType === 2) {
                regularHours += trx.hours;
            }
            else {
                otherHours += trx.hours;
            }
            totalHours += trx.hours;
        });

        return (
            <Box direction="row" style={{color: 'white'}}>
                <BoxItem ratio={1} >
                    {trxDate.toLocaleDateString(langId, { month: 'short', day: 'numeric', weekday: 'short'})}
                </BoxItem>
                <BoxItem ratio={1}>
                    <div>
                        <span style={{float: 'right'}}>{`${t('time.regularAbbreviation')}: ${regularHours.toFixed(2)}`}</span>
                    </div>
                </BoxItem>
                <BoxItem ratio={1}>
                    <div>
                        <span style={{float: 'right'}}>{`${t('time.other')}: ${otherHours.toFixed(2)}`}</span>
                    </div>
                </BoxItem>
                <BoxItem ratio={1}>
                    <div>
                        <span style={{float: 'right'}}>{`${t('time.total')}: ${totalHours.toFixed(2)}`}</span>
                    </div>
                </BoxItem>
            </Box>
        );
    }
    else {
        return <></>;
    }
};
