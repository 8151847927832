import React from 'react';
import './UserAvatar.scss';

const UserAvatar = ({ name }) => {
    const nameParts = name.split(' ');
    const firstNameInitial = nameParts[0] ? nameParts[0][0] : '';
    const lastNameInitial = nameParts[1] ? nameParts[1][0] : '';

    return (
        <div className="form-photo-view">
            <span className="user-avatar-image">
                {firstNameInitial}
                {lastNameInitial}
            </span>
        </div>
    );
};
export default UserAvatar;
