import {
    SET_ACTIVE_EMPLOYEE,
    FETCH_EMPLOYEE_LIST,
    FETCH_EMPLOYEE_DELEGATE_LIST,
    FETCH_FILTERED_EMPLOYEE_LIST,
    SET_EMPLOYEE_PAYCODES
} from '../actions/actionTypes';

const initialState = {
    activeEmployee: {
        id: null
    },
    employeeList:         [],
    employeeDelegates:    [],
    employeePayCodes:     [],
    filteredEmployeeList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_EMPLOYEE:
            return {
                ...state,
                activeEmployee: {...action.payload}
            };
        case FETCH_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...action.payload]
            };
        case SET_EMPLOYEE_PAYCODES:
            return {
                ...state,
                employeePayCodes: [...action.payload]
            };
        case FETCH_EMPLOYEE_DELEGATE_LIST:
            return {
                ...state,
                employeeDelegates: [...action.payload]
            };
        case     FETCH_FILTERED_EMPLOYEE_LIST:
            return {
                ...state,
                filteredEmployeeList: [...action.payload]
            };
        default:
            return state;
    }
};
