import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { TransactionStatus } from '../data/transactionStatuses';
import {
    mdiLockOpenOutline,
    mdiLockOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiInformationOutline,
    mdiTimerSand
} from '@mdi/js';

export function getTimeOnlyValue(timestamp, isUTCNeeded) {
    if (isUTCNeeded) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedTime = zonedTimeToUtc(timestamp, timeZone);
        const dateParts = zonedTime.toISOString().split('T');
        const timeString = dateParts[1].substring(0, dateParts[1].indexOf('.'));
        return timeString;
    }
    else {
        return format(timestamp, 'hh:mm:ss');
    }
}

export function getUtcToZonedTime(timestamp) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(timestamp, timeZone);
    return zonedDate;
}

export function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = (totalMinutes % 60) / 60;
    return (hours + minutes).toFixed(2);
}

export const defaultFormData = {
    id:                       null,
    timesheetId:              null,
    transactionType:          null,
    activityId:               {id: null},
    costcodeId:               {id: null},
    paycodeId:                {id: null},
    shiftCodeId:              {id: null},
    transactions:             [],
    transactionDate:          null,
    transactionFormattedDate: null,
    hours:                    null,
    status:                   TransactionStatus.Open,
    statusComment:            null,
    timeIn:                   null,
    timeOut:                  null,
    notes:                    []
};


export const getStatusIconColor = status => {
    switch (status) {
        case TransactionStatus.Open:
        case TransactionStatus.Submitted:
            return '#4588bf';
        case TransactionStatus.Processed:
        case TransactionStatus.TimedIn:
            return 'green';
        case TransactionStatus.Error:
        case TransactionStatus.Rejected:
            return 'red';
        default:
            return '#4588bf';
    }
};

export const getStatusIcon = status => {
    switch (status) {
        case TransactionStatus.Open:
            return mdiLockOpenOutline;
        case TransactionStatus.Submitted:
        case TransactionStatus.Approved:
            return mdiLockOutline;
        case TransactionStatus.Rejected:
        case TransactionStatus.Error:
            return mdiInformationOutline;
        case TransactionStatus.Processed:
            return mdiCheckboxMarkedCircleOutline;
        case TransactionStatus.TimedIn:
            return mdiTimerSand;
        default:
            break;
    }
};
