export const CLIENT = 'Client';
export const SITE = 'Site';
export const EQUIPMENT = 'Equipment';
export const CONTRACT = 'Contract';
export const PROJECT = 'Project';
export const JOB = 'Job';
export const APPOINTMENT = 'Appointment';
export const APPOINTMENTRESOLUTION = 'AppointmentResolution';
export const SYSTEM = 'System';
export const TIMETRANSACTION = 'TimeTransaction';
