import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../../components';
import './side-nav-outer-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import AccessDenied from '../../containers/AccessDenied/AccessDenied';
import LoadingMask from '../../components/loadingMask/loadingMask';

export default function SideNavOuterToolbar({
    activeCompany,
    companyUser,
    currentOS,
    currentUser,
    featureFlags,
    isBootStrapping,
    isMobileApp,
    isTimeUser,
    settings,
    setUserLanguage,
    timeUserSettings,
    title,
    children
}) {
    const scrollViewRef = useRef(null);
    const navigate = useNavigate();
    const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(
        MenuStatus.Closed
        //isLarge ? MenuStatus.Opened : MenuStatus.Closed
    );

    useEffect(() => {
        if (isTimeUser === false) {
            navigate('/accessdenied');
        }
    }, [isTimeUser]);

    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        );
        event.stopPropagation();
    }, []);

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        );
    }, []);

    const onOutsideClick = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
                ? MenuStatus.Closed
                : prevMenuStatus
        );
        return !isLarge;
    }, [isLarge]);

    const onNavigationChanged = useCallback(({ itemData, event, node }) => {
        if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
            event.preventDefault();
            return;
        }

        navigate(`${itemData.path}?companyId=${activeCompany.id}`);
        scrollViewRef.current.instance.scrollTo(0);

        if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
            setMenuStatus(MenuStatus.Closed);
            event.stopPropagation();
        }
    }, [navigate, menuStatus, isLarge]);

    if (isTimeUser) {
        return (
            <div className={'side-nav-outer-toolbar'}>
                <Header
                    menuToggleEnabled
                    toggleMenu={toggleMenu}
                    title={title}
                    currentOS={currentOS}
                    currentUser={currentUser}
                    isMobileApp={isMobileApp}
                    setUserLanguage={setUserLanguage}
                />
                { isBootStrapping ?
                    <LoadingMask />
                    :
                    <Drawer
                        className={['drawer', patchCssClass].join(' ')}
                        position={'before'}
                        closeOnOutsideClick={onOutsideClick}
                        openedStateMode={isLarge ? 'shrink' : 'overlap'}
                        revealMode={isXSmall || isSmall ? 'slide' : 'expand'}
                        minSize={isXSmall || isSmall || isMedium ? 0 : 60}
                        maxSize={250}
                        shading={isLarge ? false : true}
                        opened={menuStatus === MenuStatus.Closed ? false : true}
                        template={'menu'}
                    >
                        <div className={'container'}>
                            <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
                                <div className={'content'}>
                                    {React.Children.map(children, (item) => {
                                        return item.type !== Footer && item;
                                    })}
                                </div>
                                <div className={'content-block'}>
                                    {React.Children.map(children, (item) => {
                                        return item.type === Footer && item;
                                    })}
                                </div>
                            </ScrollView>
                        </div>
                        <Template name={'menu'}>
                            <SideNavigationMenu
                                compactMode={menuStatus === MenuStatus.Closed}
                                companyUser={companyUser}
                                currentUser={currentUser}
                                featureFlags={featureFlags}
                                menuStatus={menuStatus}
                                onMenuReady={onMenuReady}
                                openMenu={temporaryOpenMenu}
                                selectedItemChanged={onNavigationChanged}
                                settings={settings}
                                timeUserSettings={timeUserSettings}
                            />
                        </Template>
                    </Drawer>
                }
            </div>
        );
    }
    else {
        return (
            <AccessDenied
                activeCompany={activeCompany}
                currentOS={currentOS}
                currentUser={currentUser}
                isMobileApp={isMobileApp}
            />
        );
    }
}

const MenuStatus = {
    Closed:          1,
    Opened:          2,
    TemporaryOpened: 3
};
