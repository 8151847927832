import  React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import List from 'devextreme-react/list';
import ScrollView from 'devextreme-react/scroll-view';
import ActionSheet from 'devextreme-react/action-sheet';

import { FormPopup } from '../utils/form-popup/FormPopup';
import TimeManagerEditForm from '../time-manager-approval/TimeManagerEditForm';
import {
    renderDayView
} from './TimeManagerMobileApprovalListHelper';
import TimeRejectionForm from '../time-manager-approval/TimeRejectionForm.js';

import useScreenOrientation from 'use-screen-orientation';
import { useScreenSize } from '../../utils/media-query';
import devices from 'devextreme/core/devices';

import * as AnnotationActions from '../../actions/annotationActions';
import * as TimeActions from '../../actions/timeActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as JobActions from '../../actions/jobActions';

import { TransactionType } from '../../data/transactionTypes';

import './TimeManagerMobileApprovalModal.scss';
import i18n from '../../localization/i18n.js';


const actionSheetItems = [
    {
        id:   'edit',
        text: 'time.editEntry'
    },
    {
        id:   'approve',
        text: 'time.approveEntry'
    },
    {
        id:   'reject',
        text: 'time.rejectEntry'
    }
];

const TimeEntryMobileApprovalModal = ({
    featureFlags,
    fetchEmployeePayCodes,
    fetchJobCostCodes,
    fullTransactionList,
    handleTransactionAccept,
    handleTransactionReject,
    handleTransactionUpdate,
    period,
    settings,
    transactions
}) => {
    const { t } = useTranslation();
    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [orientation] = useScreenOrientation();

    const [editFormEmployee, setEditFormEmployee] = useState();
    const [listHeight, setListHeight] = useState('calc(100vh - 250px)');
    const [isModalActionSheetVisible, setIsModalActionSheetVisible] = useState(false);
    const [isShowTimeManagerEditFormPopup, setIsShowTimeManagerEditFormPopup] = useState(false);
    const [isShowRejectionForm, setIsShowRejectionForm] = useState(false);
    const [editFormData, setEditFormData] = useState();
    const [jobCostCodes, setJobCostCodes] = useState([]);
    const [transactionData, setTransactionData] = useState();
    const [isMauiApp, setIsMauiApp] = useState(false);
    const [useTimeInTimeOut, setUseTimeInTimeOut] = useState(false);
    const [actionItems, setActionTimes] = useState(actionSheetItems);

    const isPopupFormVisible = useRef();
    const setIsPopupFormVisible = data => {
        isPopupFormVisible.current = data;
        setIsShowTimeManagerEditFormPopup(data);
    };

    const isEditFormDirtyStateRef = useRef(false);
    const setIsEditFormDirtyStateRef = data => {
        isEditFormDirtyStateRef.current = data;
    };

    const rejectionPopupVisibleRef = useRef();
    const setRejectionPopupVisibleRef = data => {
        rejectionPopupVisibleRef.current = data;
        if (!data) {
            setRejectionFormData(null);
        }
        setIsShowRejectionForm(data);
    };

    const isFormDirtyStateRef = useRef(false);
    const setIsFormDirtyState = data => {
        isFormDirtyStateRef.current = data;
    };

    const rejectionFormData = useRef();
    const setRejectionFormData = async data => {
        rejectionFormData.current = data;
        setIsFormDirtyState(true);
        await setTransactionData(data);
    };


    const managerEditFormData = useRef();
    const setManagerEditFormData = data => {
        managerEditFormData.current = data;
        setEditFormData(data);
    };

    useEffect(() => {

        const isMaui = sessionStorage.getItem('isMauiApp');
        if (isMaui) {
            setIsMauiApp(true);
        }
        else {
            setIsMauiApp(false);
        }
    }, []);

    useEffect(() => {
        const items = cloneDeep(actionSheetItems);
        items.forEach(item => item.text = t(item.text));
        setActionTimes(items);
        const timeInOutFeatureFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');
        if (timeInOutFeatureFlag) {
            if (timeInOutFeatureFlag.featureFl && settings.useTimeInTimeOut) {
                setUseTimeInTimeOut(true);
            }
            else {
                setUseTimeInTimeOut(false);
            }
        }
        else {
            setUseTimeInTimeOut(false);
        }
    }, [featureFlags, settings]);

    useEffect(() => {
        const init = async () => {

            let heightCalc = 'calc(100% - 250px)';
            if (devices.current().phone) {
                switch (devices.orientation()) {
                    case 'portrait':
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100vh - 140px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 230px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100vh - 140px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 200px)';
                            }
                        }
                        break;
                    default:
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100vh - 265px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 190px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 260px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 190px)';
                            }
                        }
                        else {
                            heightCalc = 'calc(100% - 250px)';
                        }
                        break;
                }
            }
            else if (devices.current().tablet) {
                switch (devices.orientation()) {
                    case 'portrait':
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100vh - 150px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 180px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100vh - 160px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 240px)';
                            }
                        }
                        break;
                    default:
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 250px)';
                            }
                            else {
                                heightCalc = 'calc(100vh - 180px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 250px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 240px)';
                            }
                        }
                        break;
                }
            }
            else {
                heightCalc = 'calc(100vh - 305px)';
            }
            await setListHeight(heightCalc);
        };
        init();
    }, [orientation, isMauiApp, isXSmall, isSmall, isMedium]);

    const onItemClick = useCallback(async e => {
        const { itemData } = e;
        if (itemData.transactionType === TransactionType.Job) {
            // get the job cost codes
            await fetchJobCostCodes(itemData.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        handleEditEntry(itemData);
        setIsModalActionSheetVisible(true);
    }, []);

    const onActionSheetCancelClick = useCallback(e => {
        setIsModalActionSheetVisible(false);
    }, []);

    const onActionSheetItemClick = useCallback(e => {
        switch (e.itemData.id) {
            case 'edit':
                setIsEditFormDirtyStateRef(false);
                setIsPopupFormVisible(true);
                break;
            case 'approve':
                handleTransactionAccept(editFormData);
                break;
            case 'reject':
                setRejectionFormData(editFormData);
                setIsFormDirtyState(false);
                setIsShowRejectionForm(true);
                break;
            default:
                break;
        }

        setIsModalActionSheetVisible(false);
    }, [editFormData]);

    const handleEditEntry = useCallback(async data => {
        await setEditFormEmployee(`${data.employeeFirstName} ${data.employeeLastName}`);
        if (data.transactionType === TransactionType.Job) {
            // get the job cost codes
            await fetchJobCostCodes(data.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        await fetchEmployeePayCodes(data.employeeId.id);
        const editData = cloneDeep(data);
        await setEditFormData(editData);
    }, []);

    const handleCancelClick = useCallback(e => {
        setIsPopupFormVisible(false);
    }, []);

    const handleHiding = useState(e => {
        setIsPopupFormVisible(false);
    }, []);

    const handleRejection = useCallback(e => {
        setIsEditFormDirtyStateRef(false);
        if (rejectionFormData.current) {
            handleTransactionReject(rejectionFormData.current);
            changeRejectModalVisibility(false);
        }
    }, [rejectionFormData, setRejectionPopupVisibleRef]);

    const onDataChanged = useCallback(data => {
        setRejectionFormData(data);
        setIsEditFormDirtyStateRef(true);
    },[]);

    const changeRejectModalVisibility = useCallback(async e => {
        setRejectionPopupVisibleRef(!isShowRejectionForm);
    }, [setRejectionPopupVisibleRef]);

    const onEditFormDataChanged = useCallback(async data => {
        setManagerEditFormData(data);
        setIsEditFormDirtyStateRef(true);
    },[]);

    const handleManagerSave = useCallback(async e => {
        if (managerEditFormData.current) {
            const saved = await handleTransactionUpdate(managerEditFormData.current);
            if (saved) {
                setIsEditFormDirtyStateRef(false);
            }
            return saved;
        }
        return false;
    }, [managerEditFormData]);

    return (
        <>
            <div className="manager-approval-modal-period">{t('time.timePeriod', {period: period})}</div>
            <ScrollView showScrollbar="always" height={listHeight}>
                <List
                    dataSource={transactions}
                    itemRender={renderDayView(settings.useShiftCode, useTimeInTimeOut, t, i18n.resolvedLanguage)}
                    onItemClick={onItemClick}
                    noDataText={t('time.noEntriesToApprove')}
                    listHeight={listHeight}
                />
                <ActionSheet
                    cancelText={t('common.cancel')}
                    dataSource={actionItems}
                    onCancelClick={onActionSheetCancelClick}
                    onItemClick={onActionSheetItemClick}
                    showCancelButton={true}
                    visible={isModalActionSheetVisible}
                />
                {
                    isShowRejectionForm ?
                        <FormPopup
                            cancelButtonText={t('common.cancel')}
                            isFormDirtyStateRef={isEditFormDirtyStateRef}
                            onSave={handleRejection}
                            setIsFormDirtyState={setIsEditFormDirtyStateRef}
                            setVisible={changeRejectModalVisibility}
                            showMultiSaveButton={false}
                            title={t('time.rejectTimeEntry')}
                            visible={isShowRejectionForm}
                            width="400px"
                        >
                            <TimeRejectionForm
                                formData={transactionData}
                                handleRejectionChange={onDataChanged}
                            />
                        </FormPopup>
                        : null
                }
                {
                    isShowTimeManagerEditFormPopup ?
                        <FormPopup
                            cancelButton={t('common.cancel')}
                            closeOnSave={true}
                            isFormDirtyStateRef={isEditFormDirtyStateRef}
                            onCancelClick={handleCancelClick}
                            onHiding={handleHiding}
                            onSave={handleManagerSave}
                            setIsFormDirtyState={setIsEditFormDirtyStateRef}
                            setVisible={setIsPopupFormVisible}
                            showSaveButton={true}
                            showMultiSaveButton={false}
                            title={t('time.editEntryTitle', {employee: editFormEmployee})}
                            visible={isShowTimeManagerEditFormPopup}
                            width="100%"
                        >
                            <TimeManagerEditForm
                                data={editFormData}
                                fullTransactionList={fullTransactionList}
                                jobCostCodes={jobCostCodes}
                                onFormDataChanged={onEditFormDataChanged}
                                setIsFormDirtyState={setIsEditFormDirtyStateRef}
                                setJobCostCodes={setJobCostCodes}
                            />
                        </FormPopup>
                        : null
                }
            </ScrollView>
        </>
    );
};

const mapStateToProps = state => ({
    currentOS:    state.core.currentOS,
    featureFlags: state.core.featureFlags,
    settings:     state.time.settings,
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...EmployeeActions,
    ...JobActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryMobileApprovalModal);
