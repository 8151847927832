import { fetchAuthSession } from 'aws-amplify/auth';
import { get } from 'lodash';

export const getPersistedSession = () => {
    let sessionValue = sessionStorage.getItem('currentSession');
    if (sessionValue) {
        return JSON.parse(sessionValue);
    }
};

export const getSession = ({
    getAwsSession = fetchAuthSession()
} = {}) => {
    return getPersistedSession() || getAwsSession();
};

/**
 * A user's signed in state is derived from either a valid, locally persisted
 * session object or an asynchronously retrieved session object from AWS.
 * Only the locally persisted session can be retrieved synchronously and then
 * used to determine the initial sign in state. If the session can only be
 * retrieved from AWS, then the initial state is indeterminate until that
 * response concludes. Using `null` allows us to communicate this indeterminate
 * state to subscribers.
 */
export const getInitialAuthState = () => {
    const persistedSession = getPersistedSession();
    let session = null;
    let signedIn = null;
    if (validateSession(persistedSession)) {
        session = persistedSession;
        signedIn = true;
    }
    return {
        session,
        signedIn
    };
};

export const getUserRoleByCompany = (roles, companyId) => {
    return roles.find(role => {
        return role.companyId === companyId;
    });
};

export const isAllowed = (userScopes = [], targetScopes = []) => {
    if (!targetScopes.length) {
        return true;
    }

    return targetScopes.every(scope => {
        return userScopes.find(targetScope => {
            return targetScope.label === scope;
        });
    });
};

// export const setPageTitle = (feature, companyName) => {
//     const upper = feature.replace(/^\w/, c => c.toUpperCase());
//     let app = upper;

//     if (upper === 'Myaccount') {
//         app = 'My Account';
//     } else if (upper === 'Iothub') {
//         app = 'IoT Hub';
//     } else if (upper === 'Servicemanagement') {
//         app = 'Service Management';
//     }

//     if (companyName === null) {
//         document.title = `${app}`;
//     } else {
//         document.title = `${app} - ${companyName}`;
//     }

// };

export const validateSession = (session) => {
    if (!session) {
        return false;
    }
    const jwtToken = `${session.idToken.jwtToken}`;
    if (jwtToken.trim && !jwtToken.trim()) {
        return false;
    }
    const email = get(session, 'idToken.payload.email') || '';
    if (email.trim && !email.trim()) {
        return false;
    }
    return true;
};
