import * as ActionTypes from './actionTypes';
import template from 'url-template';
import { get , isEmpty, orderBy } from 'lodash';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';
import { ConcurrencyManager } from 'axios-concurrency';

import showToastMessage from '../helpers/toastHelper';

const JobsUrl = template.parse('/companies/{companyId}/jobs{?page,limit}');
const JobCostCodesUrl = template.parse('/jobs/{jobId}/cost-codes{?page,limit}');

const DEFAULT_PAGE_SIZE = 200;
const MAX_CONCURRENCY_REQUESTS = 10;

export const fetchJobList = (companyId, divisions, options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    options.limit = options.limit || DEFAULT_PAGE_SIZE;
    options.page = 1;
    let allJobs = [];
    const manager = ConcurrencyManager(wennsoftBaseApi, MAX_CONCURRENCY_REQUESTS);
    try {
        let url = JobsUrl.expand({
            companyId,
            ...options
        });

        divisions.forEach(division => {
            url = `${url}&divisionjobid=${division}`;
        });

        const response = await wennsoftBaseApi.get(
            url,
            header
        );

        const data = get(response, 'data.data', []) || [];
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i <= count; i++) {
            options.page = i;
            promises.push(
                wennsoftBaseApi.get(
                    JobsUrl.expand({
                        companyId,
                        ...options
                    }),
                    header
                )
            );
        }

        let results = [];
        await Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => results.push(r.data.data));
            });

        allJobs = data.concat(...results);

        allJobs.forEach(j => j.activityId = j.jobNumber);

        dispatch({
            type:    ActionTypes.SET_JOB_LIST,
            payload: allJobs
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_JOB_LIST,
            payload: []
        });
    }
    finally {
        manager.detach();
    }

};

export const fetchJobCostCodes = (jobId, options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    if (!jobId) {
        return [];
    }

    options.limit = options.limit || DEFAULT_PAGE_SIZE;
    options.page = 1;
    let allCostCodes = [];
    const manager = ConcurrencyManager(wennsoftBaseApi, MAX_CONCURRENCY_REQUESTS);

    try {
        const response = await wennsoftBaseApi.get(
            JobCostCodesUrl.expand({
                jobId,
                ...options
            }),
            header
        );

        const data = get(response, 'data.data', []) || [];
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i <= count; i++) {
            options.page = i;
            promises.push(
                wennsoftBaseApi.get({
                    jobId,
                    ...options
                }),
                header
            );
        }

        let results = Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => results.push(r.data.data));
            });

        if (!isEmpty(results)) {
            allCostCodes = data.concat(...results);
        }
        else {
            allCostCodes = data;
        }

        const payload = orderBy(allCostCodes, ['costCodeAlias'], ['asc']);

        return payload;

    }
    catch (error) {
        showToastMessage(error, 'error');
        return [];
    }
    finally {
        manager.detach();
    }
};
