import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isNull } from 'lodash';
import { addDays, addMinutes, differenceInMinutes, format, formatISO, isAfter, isBefore, isValid, parseISO, setDate, setMonth, setYear, setSeconds, setMilliseconds, setMinutes, isSameDay, setHours } from 'date-fns';
import DropDownButton from 'devextreme-react/drop-down-button';
import Button from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Tooltip } from 'devextreme-react/tooltip';
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { custom } from 'devextreme/ui/dialog';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { TimeEntryForm, FormPopup } from '../../components';
import EmployeeLookup from '../employee-lookup/EmployeeLookup';
import PeriodSelector from '../PeriodSelector/PeriodSelector';
import TimeCellContextMenu from '../timeCellContextMenu/timeCellContextMenu';
import ConfirmationDialog from '../confirmationDialog/confirmationDialog';
import AnnotationForm from '../annotations/AnnotationForm';
import TimeEntryEditList from '../time-entry-edit-list/TimeEntryEditList.js';
import Icon from '@mdi/react';
import { mdiTimerSand } from '@mdi/js';
import { toHoursAndMinutes, defaultFormData } from '../../helpers/timeHelpers';
import { prepareTimeEntriesForSave, splitPayPeriodTransactions } from '../../helpers/timesheetHelper.js';

import {
    defaultTransactionData,
    formatDateCellHeader,
    formatFooterCells,
    hasAnnotations,
    hasTimedInEntry,
    hasUnsubmittedTransactions,
    renderActivityCell,
    renderCostCodeCell,
    renderPayCodeCell,
    renderShiftCodeCell,
    totalCellRender,
    validateTimeOut
} from './TimeEntryListHelper.js';

import { TransactionStatus } from '../../data/transactionStatuses';
import { TransactionType } from '../../data/transactionTypes';

import * as AnnotationTypes from '../../data/annotationTypes';

import * as AnnotationActions from '../../actions/annotationActions';
import * as ActivitiesActions from '../../actions/activitiesActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as JobActions from '../../actions/jobActions';
import * as TimeActions from '../../actions/timeActions';
import * as WorkOrderActions from '../../actions/workOrderActions';

import './TimeEntryList.scss';
import showToastMessage from '../../helpers/toastHelper.js';
import EmployeeSelection from '../employee-timesheet-report/EmployeeSelection.js';

const activitySelectorAttributes = {
    id:    'activity-selector',
    class: 'activity-selector-dropdown'
};

const fetchTimeSheetOptions = {
    includeexternalorigindetails: true,
    companyid:                    null,
    requesterid:                  null
};

const defaultNoteData = {
    id:             null,
    annotationType: 'TimeTransaction',
    isAttachemnt:   false,
    attachment:     null,
    subject:        null,
    noteText:       null
};

const TimeEntryList = ({
    activeCompany,
    activeEmployee,
    copyEmployeeTimesheet,
    createAnnotation,
    currentTimesheet,
    currentUser,
    currentWeekEndingDate,
    deleteAnnotation,
    deleteTimeTransactions,
    employeePayCodes,
    featureFlags,
    fetchAnnotationById,
    fetchEmployeeTimesheetList,
    fetchJobCostCodes,
    fetchJobList,
    fetchWorkOrderList,
    fetchWorkOrderCostCodes,
    fetchUnbilledActivities,
    filteredEmployeeList,
    insertTimeSheetTransaction,
    isLoadingTimesheet,
    jobDivisions,
    periodDays,
    printEmployeeTimesheetReport,
    serviceDivisions,
    settings,
    setTimesheetGridInstance,
    shiftCodes,
    subTotals,
    timesheetStartDate,
    timeUserSettings
}) => {
    const { t, i18n } = useTranslation();

    const activityButtonItems = [
        {
            id:   1,
            name: t('time.jobActivity'),
            icon: 'fa-regular fa-building'
        },
        {
            id:   2,
            name: t('time.serviceActivity'),
            icon: 'fa-solid fa-hammer'
        },
        {
            id:   3,
            name: t('time.unbilledActivity'),
            icon: 'fa-regular fa-money-bill-1'
        }
    ];

    const printItems = [
        {
            id:   1,
            name: t('common.printselect'),
        }
    ];

    const [selectedEmployee, setSelectedEmployee] =  useState();
    const [allowSubmitting, setAllowSubmitting] = useState(false);
    const [gridDataSource, setGridDataSource] = useState([]);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [isDeleteNoteConfirmationVisible, setIsDeleteNoteConfirmationVisible] = useState(false);
    const [isShowAnnotationForm, setIsShowAnnotationForm] = useState(false);
    const [isNoteReadOnly, setIsNoteReadOnly] = useState(false);
    const [deleteConfirmationTitle, setDeleteConfirmationTitle] = useState(t('time.deleteTransaction'));
    const [deleteConfirmationData, setDeleteConfirmationData] = useState();
    const [deleteTransactions, setDeleteTransactions] = useState([]);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [noteSectionLabel, setNoteSectionLabel] = useState(t('time.note'));
    const [annotationTarget, setAnnotationTarget] = useState(null);
    const [noteData, setNoteData] = useState(defaultNoteData);
    const [noteToDelete, setNoteToDelete] = useState();
    const [isNewNote, setIsNewNote] = useState(false);
    const [isNewRecord, setIsNewRecord] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [timeEntryData, setTimeEntryData] = useState();
    const [openTransactions, setOpenTransactions] = useState([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('Default Message');
    const [transactionsToSubmit, setTransactionsToSubmit] = useState([]);
    const [jobCostCodes, setJobCostCodes] = useState([]);
    const [isDeletingTimeEntry, setIsDeletingTimeEntry] = useState(false);
    const [rejectedCount, setRejectedCount] = useState(0);
    const [timedInCount, setTimedInCount] =  useState(0);
    const [activityItemDataSource, setActivityItemDataSource] = useState(activityButtonItems);
    const [editListPopupVisible, setEditListPopupVisible] = useState(false);
    const [editListPopupFormVisible, setEditListPopuFormVisible] = useState(false);
    const [editListPopupWidth, setEditListPopupWidth] = useState(600);
    const [originalEditListTransactions, setOriginalEditListTransactions] = useState([]);
    const [useTimeInTimeOut, setUseTimeInTimeOut] = useState(false);
    const [showEmployeeReportSelectionModal, setShowEmployeeReportSelectionModal] = useState(false);

    const gridRef = useRef(null);
    const formRef = useRef(null);
    const popupVisibleRef = useRef(popupVisible);
    const setPopupVisibleRef = data => {
        popupVisibleRef.current = data;
        setPopupVisible(data);
        if (!data) {
            const gridInstance = gridRef.current?.instance;
            if (gridInstance && isFormDirtyStateRef.current) {
                gridInstance.refresh();
            }
        }
    };

    const editListPopupVisibleRef = useRef(editListPopupVisible);
    const setEditListPopupVisibleRef = data => {
        editListPopupVisibleRef.current = data;
        setEditListPopupVisible(data);
        if (!data) {
            setEditListPopuFormVisibleRef(false);
        }
    };

    const editListPopupFormVisibleRef = useRef(editListPopupFormVisible);
    const setEditListPopuFormVisibleRef = data => {
        editListPopupFormVisibleRef.current = data;
        setEditListPopuFormVisible(data);
    };

    const editRowKeysRef = useRef();
    const setEditRowKeysRef = data => {
        editRowKeysRef.current = data;
    };

    const isFormDirtyStateRef = useRef(false);
    const setIsFormDirtyState = data => {
        isFormDirtyStateRef.current = data;
    };

    const timeTransactionData = useRef();
    const setTimeTransactionData = data => {
        timeTransactionData.current = data;
    };


    const isResetFormRef = useRef(false);
    const setIsResetFormRef = data => {
        isResetFormRef.current = data;
    };

    const activeTimesheet = useRef();
    const setActiveTimesheet = data => {
        activeTimesheet.current = data;
    };

    const defaultPayCodeRef = useRef();
    const setDefaultPayCodeRef = data => {
        defaultPayCodeRef.current = data;
    };

    const payCodeShiftCodeRef = useRef({id: null});
    const setPayCodeShiftCodeRef = data => {
        payCodeShiftCodeRef.current = data;
    };

    const newId = useRef(null);
    const setNewId = data => {
        newId.current = data;
    };

    const employeeTimesheetReportListRef = useRef([]);
    const setEmployeeTimesheetReportListRef = data => {
        employeeTimesheetReportListRef.current = data;
    };

    useEffect(() => {
        setTimesheetGridInstance(gridRef);
    }, []);

    useEffect(() => {
        setActiveTimesheet(currentTimesheet);
    }, [currentTimesheet]);

    useEffect(() => {
        if (employeePayCodes.filter(pc => pc.payType !== 5).length === 1) {
            setDefaultPayCodeRef(employeePayCodes[0].paycodeId);
            const shiftCodeLookup = shiftCodes?.find(sc => sc.id === employeePayCodes[0].shiftCodeId);
            if (shiftCodeLookup) {
                setPayCodeShiftCodeRef({id: employeePayCodes[0].shiftCodeId});
            }
            else {
                setPayCodeShiftCodeRef({id: null});
            }
        }
        else if (activeEmployee?.userId === currentUser.id && timeUserSettings && timeUserSettings.viewData && JSON.parse(timeUserSettings.viewData).defaultLaborPayCodeId) {
            const userSettings = JSON.parse(timeUserSettings.viewData);
            if (userSettings) {
                const foundPayCode = employeePayCodes.find(pc => pc.paycodeId === userSettings.defaultLaborPayCodeId);
                if (foundPayCode) {
                    setDefaultPayCodeRef(foundPayCode.paycodeId);
                    const shiftCodeLookup = shiftCodes?.find(sc => sc.id === foundPayCode.shiftCodeId);
                    if (shiftCodeLookup) {
                        setPayCodeShiftCodeRef({id: foundPayCode.shiftCodeId});
                    }
                    else {
                        setPayCodeShiftCodeRef({id: null});
                    }
                }
            }
            else {
                const foundPayCode = employeePayCodes.find(pc => pc.paycodeId === settings.defaultLaborPayCodeId);
                if (foundPayCode) {
                    setDefaultPayCodeRef(foundPayCode.paycodeId);
                    const shiftCodeLookup = shiftCodes?.find(sc => sc.id === foundPayCode.shiftCodeId);
                    if (shiftCodeLookup) {
                        setPayCodeShiftCodeRef({id: foundPayCode.shiftCodeId});
                    }
                    else {
                        setPayCodeShiftCodeRef({id: null});
                    }

                }
                else {
                    setDefaultPayCodeRef(null);
                    setPayCodeShiftCodeRef({id: null});
                }
            }
        }
        else if (settings.defaultPaycodeId) {
            const foundPayCode = employeePayCodes.find(pc => pc.paycodeId === settings.defaultPaycodeId);
            if (foundPayCode)
            {
                setDefaultPayCodeRef(foundPayCode.paycodeId);
                const shiftCodeLookup = shiftCodes?.find(sc => sc.id === foundPayCode.shiftCodeId);
                if (shiftCodeLookup) {
                    setPayCodeShiftCodeRef({id: foundPayCode.shiftCodeId});
                }
                else {
                    setPayCodeShiftCodeRef({id: null});
                }
            }
            else {
                setDefaultPayCodeRef(null);
                setPayCodeShiftCodeRef({id: null});
            }
        }
        else {
            setDefaultPayCodeRef(null);
            setPayCodeShiftCodeRef({id: null});
        }

    }, [employeePayCodes, settings, shiftCodes, timeUserSettings]);

    useEffect(() => {
        const init = async () => {
            setTimeTransactionData(null);
            const store = new CustomStore({
                loadMode:  'processed',
                onLoading: (loadOptions) => {
                    fetchTimeSheetOptions.companyid = activeCompany.id;
                    fetchTimeSheetOptions.requesterid = currentUser.id;
                    loadOptions.userData = {
                        activeEmployee:     activeEmployee.id,
                        startDate:          timesheetStartDate,
                        isEditFormOpen:     popupVisibleRef?.current,
                        isEditListFormOpen: editListPopupFormVisibleRef?.current,
                        isEditListOpen:     editListPopupVisibleRef?.current,
                        editFormData:       editRowKeysRef?.current,
                        fetchOptions:       fetchTimeSheetOptions
                    };
                },
                load: (loadOptions) => {
                    return fetchEmployeeTimesheetList([loadOptions.userData.activeEmployee], loadOptions.userData.startDate, loadOptions.userData.fetchOptions);
                },
                onLoaded: (data, loadOptions) => {
                    let rejectCount = 0;
                    let timeInCount = 0;
                    let editData = loadOptions.userData.editFormData;
                    const transactionColumn = `${editData?.transactionDate}_transactions`;
                    const openTrx = [];

                    const currentDate = new Date();
                    const showCopy = isBefore(currentDate, new Date(currentWeekEndingDate)) && isAfter(currentDate, new Date(timesheetStartDate));
                    if (showCopy && data.length === 0 ) {
                        const copyItem = {
                            id:   4,
                            name: t('time.copyTimesheet'),
                            icon: 'fa-regular fa-copy'
                        };
                        const newList = cloneDeep(activityButtonItems);
                        newList.push(copyItem);
                        setActivityItemDataSource(newList);
                    }
                    else {
                        setActivityItemDataSource(cloneDeep(activityButtonItems));
                    }

                    if ((loadOptions.userData.isEditFormOpen || loadOptions.userData.isEditListFormOpen) && editData &&  !isResetFormRef.current) {
                        let daysTransactions = data.find(item =>
                            item.activityId.id === editData.activityId.id &&
                            (
                                ((item.costcodeId === null || item.costcodeId?.id === null) && (editData.costcodeId === null || editData.costcodeId?.id === null)) ||
                                (item.costcodeId?.id === editData.costcodeId?.id)
                            ) &&
                            item.paycodeId.id === editData.paycodeId.id &&
                            item.equipmentId?.id === editData.equipmentId?.id &&
                            item.employeeId.id === editData.employeeId.id &&
                            item.shiftCodeId?.id === editData.shiftCodeId?.id
                        );
                        if (daysTransactions) {
                            const newTrx = daysTransactions[transactionColumn].find(trx => trx.id === newId.current);
                            if (newTrx) {
                                newTrx.transactions = daysTransactions[transactionColumn];
                                setTimeEntryData(newTrx);
                                setTimeTransactionData(newTrx);
                                setOriginalEditListTransactions(newTrx);
                            }
                            else {
                                let trx = daysTransactions[transactionColumn][0];
                                trx.transactions = daysTransactions[transactionColumn];
                                setTimeEntryData(trx);
                                setTimeTransactionData(trx);
                                setOriginalEditListTransactions(trx);
                            }
                        }
                        else {
                            setEditListPopupVisibleRef(false);
                        }
                    }
                    else if (loadOptions.userData.isEditListOpen && editData) {
                        let daysTransactions = data.find(item =>
                            item.activityId.id === editData.activityId.id &&
                            (
                                ((item.costcodeId === null || item.costcodeId?.id === null) && (editData.costcodeId === null || editData.costcodeId?.id === null)) ||
                                (item.costcodeId?.id === editData.costcodeId?.id)
                            ) &&
                            item.paycodeId.id === editData.paycodeId.id &&
                            item.equipmentId?.id === editData.equipmentId?.id &&
                            item.employeeId.id === editData.employeeId.id &&
                            item.shiftCodeId?.id === editData.shiftCodeId?.id
                        );
                        if (daysTransactions && daysTransactions[transactionColumn]) {
                            daysTransactions.transactions = daysTransactions[transactionColumn];
                            setTimeEntryData(daysTransactions);
                            setOriginalEditListTransactions(daysTransactions);
                        }
                        else {
                            setEditListPopupVisibleRef(false);
                        }
                    }

                    data.forEach(trx => {
                        Object.keys(trx).forEach(key => {
                            if (key.endsWith('_transactions')) {
                                trx[key].forEach(item => {
                                    if ( item.status === TransactionStatus.TimedIn) {
                                        timeInCount += 1;
                                    }
                                    else if (
                                        item.status === TransactionStatus.Open)
                                    {
                                        openTrx.push(item);
                                        setAllowSubmitting(true);
                                    }
                                    else if ( item.status === TransactionStatus.Rejected) {
                                        rejectCount += 1;
                                    }
                                });
                            }
                            if (isDeletingTimeEntry &&
                                key === editData?.transactionDate &&
                                trx[transactionColumn] === undefined &&
                                loadOptions.userData.isEditFormOpen
                            ) {
                                trx.transactions = [];
                                setTimeEntryData(trx);
                                changePopupVisibility(false);
                                setIsDeletingTimeEntry(false);
                            }
                        });
                    });
                    if (data.length === 0 && loadOptions.userData.isEditFormOpen && isDeletingTimeEntry) {
                        changePopupVisibility(false);
                        setTimeEntryData(null);
                        setIsDeletingTimeEntry(false);
                    }
                    setRejectedCount(rejectCount);
                    setSelectedTransactionId(null);
                    setTimedInCount(timeInCount);
                    setOpenTransactions(openTrx);
                    if (!loadOptions.userData.isEditListOpen) {
                        setEditRowKeysRef(null);
                    }
                    setNewId(null);
                }
            });

            setGridDataSource(new DataSource({
                key:   'id',
                store: store
            }));
        };

        if (activeEmployee.id && timesheetStartDate) init();
    }, [activeEmployee.id, currentWeekEndingDate, timesheetStartDate, isDeletingTimeEntry]);

    useEffect(() => {
        const timeInOutFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');
        if (timeInOutFlag && timeInOutFlag.featureFl) {
            setUseTimeInTimeOut(settings.useTimeInTimeOut);
            if (settings.useTimeInTimeOut) {
                setEditListPopupWidth('50vw');
            }
            else {
                setEditListPopupWidth(600);
            }
        }
        else {
            setUseTimeInTimeOut(false);
            setEditListPopupWidth(600);
        }

    }, [featureFlags, settings.useTimeInTimeOut]);

    const onFormDataChanged = useCallback(async data => {
        await setTimeEntryData(data);
    }, []);

    const onDataChanged = useCallback((data) => {
        setTimeTransactionData(data);
        setEditRowKeysRef({
            activityId:      data.activityId,
            costcodeId:      data.costcodeId,
            paycodeId:       data.paycodeId,
            equipmentId:     data.equipmentId,
            shiftCodeId:     data.shiftCodeId,
            employeeId:      data.employeeId,
            transactionType: data.transactionType,
            transactionDate: data.transactionFormattedDate,
            id:              data.id
        });
        if (editListPopupFormVisible || editListPopupVisibleRef.current) {
            let clonedTransactions = cloneDeep(timeEntryData);
            const foundTrx = clonedTransactions.transactions.find(trx => trx.id === data.id);
            if (!foundTrx) {
                const transactionList = clonedTransactions.transactions;
                transactionList.push(data);
                clonedTransactions = {...clonedTransactions, transactions: transactionList};
            }
            else {
                const updatedTransactions = clonedTransactions.transactions.map((trx) => {
                    if (trx.id === data.id) {
                        return data;
                    }
                    else {
                        return trx;
                    }});
                clonedTransactions = {...clonedTransactions, transactions: updatedTransactions};
            }

            setTimeEntryData(clonedTransactions);
        }
        else {
            setTimeEntryData(data);
        }
        setIsFormDirtyState(true);
    }, [editListPopupVisible, editListPopupFormVisible, timeEntryData]);

    const handleEditListPopupHiding = useCallback(() => {
        setEditListPopupVisibleRef(false);
        setEditRowKeysRef(null);
        setTimeTransactionData(null);
        setTimeEntryData(null);
    }, []);

    const handlePopupHiding = useCallback(async e => {
        if (isFormDirtyStateRef.current) {
            e.cancel = true;

            const confirmDialog = custom({
                title:       t('time.discardChanges'),
                messageHtml: t('time.discardChangesConfirmation'),
                buttons:     [{
                    text:        t('common.yes'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return true;
                    }
                },
                {
                    text:        t('common.no'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return false;
                    }
                }]
            });

            confirmDialog.show()
                .then(dialogResult => {
                    if (dialogResult) {
                        setSelectedTransactionId();
                        setIsFormDirtyState(false);
                        setPopupVisibleRef(false);
                    }
                });
        }
    }, [isFormDirtyStateRef, setSelectedTransactionId]);

    const handleFormReset = useCallback(async (transactionType, transactionDate) => {
        setSelectedTransactionId();
        setIsFormDirtyState(false);
        setIsNewRecord(true);

        let formData = cloneDeep(defaultFormData);
        formData.transactionDate = editRowKeysRef.current ? editRowKeysRef.current.transactionDate : transactionDate;
        formData.transactionFormattedDate = editRowKeysRef.current ? editRowKeysRef.current.transactionDate : transactionDate;
        formData.activityId = editRowKeysRef.current ? editRowKeysRef.current.activityId : null;
        formData.costcodeId = editRowKeysRef.current ? editRowKeysRef.current.costcodeId : null;
        formData.paycodeId = editRowKeysRef.current ? editRowKeysRef.current.paycodeId : null;
        formData.shiftCodeId = editRowKeysRef.current ? editRowKeysRef.current.shiftCodeId : null;

        formData.transactionType = transactionType;

        createNewTransaction(formData);

    }, [editRowKeysRef, setIsNewRecord, setSelectedTransactionId]);

    const handleFormSave = useCallback(async (e) => {
        const transactions = [cloneDeep(timeTransactionData.current)];

        const timesheetData = {
            timesheetId:        timeTransactionData.current.timesheetId,
            timesheetStartDate: timesheetStartDate,
            employeeId:         activeEmployee.id,
            transactions:       []
        };

        if (timeTransactionData.current) {
            const trx = cloneDeep(timeTransactionData.current);
            const periodEndDate = setHours(setMinutes(setSeconds(new Date(currentWeekEndingDate),59),59),23);
            if (useTimeInTimeOut && !isNull(trx.timeOut) && isAfter(new Date(trx.timeOut), periodEndDate)) {
                // create 2 transactions with second trx going to next payperiod
                const updatedTransactions = splitPayPeriodTransactions(trx);
                let futureResult = {success: false};
                if (updatedTransactions.currentTransaction) {
                    const offset = new Date().getTimezoneOffset();
                    const current = prepareTimeEntriesForSave([updatedTransactions.currentTransaction], activeEmployee);
                    const future = prepareTimeEntriesForSave([updatedTransactions.futureTransaction], activeEmployee);

                    // save the current timesheet
                    timesheetData.transactions = current;
                    const currentResult = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                    if (currentResult.success) {
                        setNewId(currentResult.id[0]);
                        setIsFormDirtyState(false);
                        setIsNewRecord(false);

                        const newTimesheetStartDate = formatISO(addMinutes(addDays(new Date(timesheetStartDate), 7), offset), {representation: 'date'});
                        timesheetData.timesheetId = null;
                        timesheetData.timesheetStartDate = newTimesheetStartDate;
                        timesheetData.employeeId = activeEmployee.id;
                        timesheetData.transactions = future;

                        futureResult = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                        if (futureResult.success) {
                            // now we need to add the note if it exists
                            if (future[0].notes && future[0].notes.length !== 0) {
                                const futureNote = cloneDeep(future[0].notes[0]);
                                if (futureNote) {
                                    futureNote.id = null;
                                    futureNote.annotationType = AnnotationTypes.TIMETRANSACTION;
                                    futureNote.objectId = futureResult.id[0];
                                    await createAnnotation(activeCompany.id, futureNote, t);
                                }
                            }
                            showToastMessage(t('time.transactionCrossedPeriods'), 'warning');
                        }
                    }
                    const gridInstance = gridRef.current?.instance;
                    if (gridInstance) {
                        gridInstance.refresh();
                    }
                    return currentResult.success && futureResult.success;
                }
                else {
                    showToastMessage(t('time.noEntryToProcess', 'error'));
                    setIsFormDirtyState(false);
                }
            }
            else {
                const preparedTransactions = prepareTimeEntriesForSave(transactions, activeEmployee);
                timesheetData.transactions = preparedTransactions;

                const result = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                if (result.success) {
                    setNewId(result.id[0]);
                    setIsFormDirtyState(false);
                    setIsNewRecord(false);
                    const gridInstance = gridRef.current?.instance;
                    if (gridInstance)  {
                        gridInstance.refresh();
                    }
                }
                return result.success;
            }
        }
        else {
            setIsFormDirtyState(false);
            const gridInstance = gridRef.current?.instance;
            if (gridInstance) {
                gridInstance.refresh();
            }
            return true;
        }
    }, [
        timeTransactionData.current,
        timesheetStartDate,
        activeEmployee,
        currentWeekEndingDate,
        useTimeInTimeOut
    ]);

    const changePopupVisibility = useCallback((isVisible) => {
        setPopupVisibleRef(isVisible);
    }, []);

    const changeEditListPopupVisibility = useCallback(isVisible => {
        setEditListPopupVisibleRef(isVisible);
    },[]);

    const resetEditListDataSource = useCallback(() => {
        setTimeEntryData(originalEditListTransactions);
    }, [originalEditListTransactions]);

    const handleNewActivityClick = useCallback(async e => {
        const { itemData } = e;
        setSelectedTransactionId();
        if (itemData.id === 4) {
            const confirmDialog = custom({
                title:       t('time.copyTimesheet'),
                messageHtml: t('time.copyTimesheetConfirmation'),
                buttons:     [{
                    text:        t('common.yes'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return true;
                    }
                },
                {
                    text:        t('common.no'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return false;
                    }
                }]
            });

            confirmDialog.show()
                .then(async dialogResult => {
                    if (dialogResult) {
                        const gridInstance = gridRef.current?.instance;
                        if (gridInstance) {
                            gridInstance.beginCustomLoading();
                        }
                        const previousTimeSheetStartDate = format(addDays(new Date(timesheetStartDate), -6), 'yyyy-MM-dd');
                        const copyResult = await copyEmployeeTimesheet(activeEmployee.id, previousTimeSheetStartDate, t);
                        if (copyResult) {
                            if (gridInstance) {
                                gridInstance.refresh();
                            }
                        }
                        if (gridInstance) {
                            gridInstance.endCustomLoading();
                        }
                    }
                });
        }
        else {
            let formData = cloneDeep(defaultFormData);
            switch (itemData.id) {
                case 1:
                    formData.transactionType = TransactionType.Job;
                    break;
                case 2:
                    formData.transactionType = TransactionType.Service;
                    break;
                case 3:
                    formData.transactionType = TransactionType.Unbilled;
                    break;
                default:
                    formData.transactionType = TransactionType.Service;
            }

            // Determine Transaction Date from Timesheet Week
            const today = new Date();
            const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            const startDateArr = timesheetStartDate?.split('-');
            const startDate = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
            const dateDiff = Math.abs(startDate - todayDate)/(1000*60*60*24);
            if (dateDiff >= 7) {
            // Previous Timesheet Week
                formData.transactionDate = format(startDate, 'yyyy-MM-dd');
                formData.transactionFormattedDate = format(startDate, 'yyyy-MM-dd');
            }
            else {
            // Current Timesheet Week
                formData.transactionDate = format(today, 'yyyy-MM-dd');
                formData.transactionFormattedDate = format(today, 'yyyy-MM-dd');
            }

            createNewTransaction(formData);
        }

    }, [
        defaultFormData,
        setIsNewRecord,
        setPopupVisible,
        setPopupVisibleRef,
        setSelectedTransactionId,
        timesheetStartDate
    ]);

    const createNewTransaction = useCallback(async(formData, useTimeIn = false) => {
        // get shiftcode based on default paycode;
        formData.timesheetId = activeTimesheet.current.id;

        formData.paycodeId = formData.paycodeId?.id ? formData.paycodeId : {id: defaultPayCodeRef.current};
        formData.employeeId = {id: activeEmployee.id};
        formData.shiftCodeId = formData.shiftCodeId?.id ? formData.shiftCodeId : payCodeShiftCodeRef.current;
        const transaction = cloneDeep(defaultTransactionData);
        transaction.timesheetId = activeTimesheet.current.id;
        transaction.transactionFormattedDate = formData.transactionDate;
        transaction.transactionDate = formData.transactionDate;
        transaction.transactionType = formData.transactionType;
        transaction.activityId = formData.activityId;
        transaction.costcodeId = formData.costcodeId;
        transaction.paycodeId = formData.paycodeId ? formData.paycodeId : {id: defaultPayCodeRef.current};
        transaction.employeeId = {id: activeEmployee.id};
        transaction.shiftCodeId = formData.shiftCodeId ? formData.shiftCodeId : payCodeShiftCodeRef.current;
        transaction.hours = null;
        transaction.timeIn = useTimeIn ? new Date() : null;
        transaction.status = useTimeIn ? TransactionStatus.TimedIn : TransactionStatus.Open;
        transaction.timeOut = false;
        formData.transactions = [transaction];
        if (formData.transactionType === TransactionType.Job && formData.activityId && formData.activityId.id) {
            await fetchJobCostCodes(formData.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        setIsNewRecord(true);
        setTimeEntryData(formData);
        setPopupVisibleRef(true);
    }, [payCodeShiftCodeRef, defaultTransactionData, activeEmployee]);

    const handleSubmit = useCallback(async e => {
        await setIsDeleteConfirmationVisible(false);
        setDeleteTransactions([]);
        setIsDeleteConfirmation(false);
        setDeleteConfirmationData();
        await setShowConfirmationDialog(false);

        if (openTransactions.length !== 0) {
            setTransactionsToSubmit(cloneDeep(openTransactions));
            const dialogMessage = t('time.submitEntriesConfirmation', {count: openTransactions.length});
            setConfirmationDialogMessage(dialogMessage);
            setShowConfirmationDialog(true);
        }
    }, [openTransactions]);

    const handleRefreshClick = useCallback(async e => {
        const userSettings = sessionStorage.getItem('userSettings');
        let userSetting = {};
        if (userSettings) {
            userSetting = JSON.parse(userSettings);
        }
        const gridInstance = gridRef.current?.instance;
        if (gridInstance) {
            gridInstance.beginCustomLoading();
            gridInstance.refresh();
            const promises = [
                fetchJobList(activeCompany.id, userSetting?.jobDefaultDivisions ? userSetting.jobDefaultDivisions : [], {limit: 2000}),
                fetchWorkOrderList(activeCompany.id, userSetting?.serviceDefaultDivisions ? userSetting.serviceDefaultDivisions : [], {limit: 2000}),
                fetchWorkOrderCostCodes(),
                fetchUnbilledActivities()
            ];

            await Promise.all(promises.map(p => p));
            gridInstance.endCustomLoading();
        }
    }, [activeCompany]);

    const onRowPrepared = useCallback((e) => {
        const { cells } = e;
        if (e.rowType === 'header') {
            formatDateCellHeader(cells, i18n.resolvedLanguage);
        }
        else if (e.rowType === 'totalFooter') {
            formatFooterCells(cells, subTotals, settings.useShiftCode, t);
        }
    }, [subTotals, settings.useShiftCode]);

    const SubmitButton = () => {
        return (
            <div>
                <Button
                    name="submitButton"
                    stylingMode={'outlined'}
                    icon="fa-regular fa-circle-check"
                    text={t('time.submitTime')}
                    height={32}
                    hint={t('time.submitTime')}
                    onClick={handleSubmit}
                    disabled={!allowSubmitting}
                />
            </div>
        );
    };

    const RefreshButton = () => {
        return (
            <div>
                <Button
                    name="refreshButton"
                    icon="fa-solid fa-rotate"
                    height={32}
                    onClick={handleRefreshClick}
                    hint={t('common.refresh')}
                    disabled={isLoadingTimesheet}
                />
            </div>
        );
    };

    const PrintButton = () => {

        return (
            <div>
                <DropDownButton
                    disabled={isLoadingTimesheet}
                    displayExpr="name"
                    dropDownOptions={{
                        minWidth: '175px'
                    }}
                    height={32}
                    icon="fa-solid fa-file-pdf"
                    items={printItems}
                    keyExpr="id"
                    onButtonClick={printTimesheet}
                    onItemClick={handleSelectEmployeeTimesheetPrint}
                    splitButton={true}
                    width={'70px'}
                    useSelectMode={false}
                />
            </div>
        );
    };

    const displayToolbar = () => {
        return (
            <Toolbar>
                <Item location="before">
                    <EmployeeLookup
                        selectedEmployee={selectedEmployee}
                        setSelectedEmployee={setSelectedEmployee}
                    />
                </Item>
                <Item location="after">
                    <DropDownButton
                        disabled={isLoadingTimesheet}
                        displayExpr="name"
                        elementAttr={activitySelectorAttributes}
                        height={32}
                        icon="plus"
                        items={activityItemDataSource}
                        keyExpr="id"
                        onItemClick={handleNewActivityClick}
                        text={t('time.addActivity')}
                        useSelectMode={false}
                    />
                </Item>
                <Item location="after">
                    <PeriodSelector
                        timesheetGridInstance={gridRef}
                    />
                </Item>
                <Item location="after">
                    <SubmitButton />
                </Item>
                <Item location="after">
                    <PrintButton />
                </Item>
                <Item location="after">
                    <RefreshButton />
                </Item>
            </Toolbar>
        );
    };

    const handleAddNote = (cellData) => {
        // if cell only has 1 transaction then we can open the note form
        // otherwise we have to open the entry form and default to 1st entry with notes
        const { column, data } = cellData;
        const trxColumn = `${column.caption}_transactions`;
        const trxCount = data[trxColumn].length;
        if (trxCount > 1) {
            handleCellEditTime(cellData);
        }
        else {
            const transDate = parseISO(column.caption);
            const cellId = `#timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
            const transactionId = data[cellData.column.caption + '_id'];
            // Should only be one transaction in array, otherwise entry form should open
            const transactionHrs = data[cellData.column.caption + '_transactions'].length === 1 ? data[cellData.column.caption + '_transactions'][0].hours: 0;
            setSelectedTransactionId(transactionId);
            setAnnotationTarget(cellId);
            const noteLabel = t('time.noteLabel', {transDate: transDate, hourCount: transactionHrs});
            setNoteSectionLabel(noteLabel);
            setIsNoteReadOnly(false);
            setIsNewNote(true);

            const transactionNoteData = {
                id:             null,
                annotationType: 'TimeTransaction',
                isAttachemnt:   false,
                attachment:     null,
                subject:        null,
                noteText:       null
            };

            setNoteData(transactionNoteData);
            setIsShowAnnotationForm(true);
        }
    };

    const handleUpdateNote = async (cellData) => {
        // if cell only has 1 transaction then we can open the note form
        // otherwise we have to open the entry form and default to 1st entry with notes
        const { column, data } = cellData;
        const noteColumn = `${column.caption}_notes`;
        const trxColumn = `${column.caption}_transactions`;
        const trxCount = data[trxColumn].length;
        if (trxCount > 1) {
            handleCellEditTime(cellData);
        }
        else {
            const cellId = `#timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
            // Should only be one transaction in array, otherwise entry form should open
            const transactionHrs = data[cellData.column.caption + '_transactions'].length === 1 ? data[cellData.column.caption + '_transactions'][0].hours: 0;
            setAnnotationTarget(cellId);
            const noteLabel = t('time.noteLabel', {transDate: parseISO(cellData.column.caption), hourCount: transactionHrs});
            setNoteSectionLabel(noteLabel);
            const transactionId = data[cellData.column.caption + '_id'];
            setSelectedTransactionId(transactionId);
            setIsNoteReadOnly(false);
            setIsNewNote(false);
            let transactionNoteData = {};
            if (data[noteColumn] && data[noteColumn].length !== 0) {
                transactionNoteData.id = data[noteColumn][0].id;
                transactionNoteData.annotationType = 'TimeTransaction';
                transactionNoteData.isAttachment = false;
                transactionNoteData.subject = data[noteColumn][0].subject;
                transactionNoteData.noteText = data[noteColumn][0].noteText;
            }
            setNoteData(transactionNoteData);
            setIsShowAnnotationForm(true);
        }
    };

    const handleViewNote = async (cellData) => {
        // if cell only has 1 transaction then we can open the note form
        // otherwise we have to open the entry form and default to 1st entry with notes
        // Read only would be handled fron the entry form
        const { column, data } = cellData;
        const noteColumn = `${column.caption}_notes`;
        const trxColumn = `${column.caption}_transactions`;
        const trxCount = data[trxColumn].length;
        if (trxCount > 1) {
            handleCellEditTime(cellData);
        }
        else {
            const cellId = `#timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
            // Should only be one transaction in array, otherwise entry form should open
            const transactionHrs = data[cellData.column.caption + '_transactions'].length === 1 ? data[cellData.column.caption + '_transactions'][0].hours: 0;
            setAnnotationTarget(cellId);
            const noteLabel = `Note for ${format(parseISO(cellData.column.caption), 'PPP')} - ${transactionHrs} hours`;
            setNoteSectionLabel(noteLabel);
            const transactionId = data[cellData.column.caption + '_id'];
            setSelectedTransactionId(transactionId);
            setIsNoteReadOnly(true);
            setIsNewNote(false);

            let transactionNoteData = {};
            if (data[noteColumn] && data[noteColumn].length !== 0) {
                transactionNoteData.id = data[noteColumn][0].id;
                transactionNoteData.annotationType = 'TimeTransaction';
                transactionNoteData.isAttachment = false;
                transactionNoteData.subject = data[noteColumn][0].subject;
                transactionNoteData.noteText = data[noteColumn][0].noteText;
            }
            setNoteData(transactionNoteData);
            setIsShowAnnotationForm(true);
        }
    };

    const renderConfirmationDialogContent = () => {
        return (
            <>
                <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                    <div>
                        <div className="confirmation-dialog-header">
                            {confirmationDialogMessage}
                        </div>
                        {
                            timedInCount !== 0 ?
                                <div className="confirmation-note-message" style={{width: '100%'}}>
                                    {`${timedInCount} ${t('time.timedInEntriesNotCounted')}`}
                                </div>
                                : null
                        }
                        { rejectedCount !== 0 ?
                            <div className="confirmation-note-message" style={{width: '100%'}}>
                                {`${rejectedCount} ${t('time.rejectedEntriesNotCounted')}`}
                            </div>
                            : null
                        }
                    </div>
                </div>
                {settings.useTransactionAcknowledgement ?
                    <div className="confirmation-acknowledgement-message">
                        {parse(settings.transactionAcknowledgementMessage)}
                    </div>
                    :
                    null
                }
            </>
        );
    };

    const renderNoteDeleteContent = () => {
        return (
            <>
                <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                    <div>
                        <div className="confirmation-dialog-header">
                            {t('time.deleteNoteConfirmation')}
                        </div>
                        <div className="confirmation-note-message" style={{width: '100%'}}>
                            {t('time.deleteNoteConfirmationDate', {transDate: parseISO(deleteConfirmationData.column.caption)})}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleDeleteNoteConfirmation = async () => {
        if (noteToDelete) {
            const gridInstance = gridRef.current?.instance;
            const deleted = await deleteAnnotation(activeCompany.id, noteToDelete.id, noteToDelete.isAttachment, t);
            if (deleted && gridInstance) {
                if (selectedTransactionId) {
                    const trxToUpdate = currentTimesheet.timeTransactions.find(trx => trx.id === selectedTransactionId);
                    if (trxToUpdate && trxToUpdate.status === TransactionStatus.Rejected) {
                        trxToUpdate.status = TransactionStatus.Open;
                        trxToUpdate.statusComment = null;

                        trxToUpdate.employeeId = trxToUpdate.employeeId?.id;
                        trxToUpdate.activityId = trxToUpdate.activityId?.id;
                        trxToUpdate.paycodeId = trxToUpdate.paycodeId?.id;
                        trxToUpdate.shiftCodeId = trxToUpdate.shiftCodeId?.id;
                        if (trxToUpdate.costcodeId) {
                            trxToUpdate.costcodeId = trxToUpdate.costcodeId?.id;
                        }

                        const timesheetData = {
                            timesheetId:        trxToUpdate.timesheetId,
                            timesheetStartDate: timesheetStartDate,
                            employeeId:         trxToUpdate.employeeId,
                            transactions:       [trxToUpdate]
                        };
                        const result = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                        if (result.success) {
                            gridInstance.refresh();
                        }
                    }
                    else {
                        gridInstance.refresh();
                    }
                }
                else {
                    gridInstance.refresh();
                }
            }
        }

        setShowConfirmationDialog(false);
        setIsDeleteNoteConfirmationVisible(false);
    };

    const handleDeleteDeny = async () => {
        setShowConfirmationDialog(false);
        setIsDeleteNoteConfirmationVisible(false);
    };

    const handleDeleteNote = async (cellData) => {
        const { column, data } = cellData;

        const noteColumn = `${column.caption}_notes`;
        const trxColumn = `${column.caption}_transactions`;
        const trxCount = data[trxColumn].length;
        if (trxCount > 1) {
            handleCellEditTime(cellData);
        }
        else {
            const noteData = data[noteColumn][0];
            setNoteToDelete(noteData);
            setSelectedTransactionId(data.id);
            // Should only be one transaction in array
            const transactionHrs = data[cellData.column.caption + '_transactions'].length === 1 ? data[cellData.column.caption + '_transactions'][0].hours: 0;
            const title = `${t('common.delete')} ${t('time.noteLabel', {transDate: parseISO(cellData.column.caption), hourCount: transactionHrs})}`;
            setDeleteConfirmationData(cellData);
            setDeleteConfirmationTitle(title);
            setIsDeleteNoteConfirmationVisible(true);
        }
    };

    const handleCellAddTime = async (cellData, useTimeIn = false) => {
        // open the form in add mode
        const { column, data } = cellData;
        let timeInTime = null;
        if (useTimeIn) {
            timeInTime = setMilliseconds(setSeconds(new Date(), 0), 0);
            const columnDate = column.caption.split('-');
            timeInTime = setYear(timeInTime, columnDate[0]);
            timeInTime = setMonth(timeInTime, columnDate[1] - 1);
            timeInTime = setDate(timeInTime, columnDate[2]);
        }

        const defaultTransaction = cloneDeep(defaultTransactionData);
        defaultTransaction.timesheetId = currentTimesheet.id;
        defaultTransaction.transactionType = data.transactionType;
        defaultTransaction.transactionDate = column.caption;
        defaultTransaction.transactionFormattedDate = column.caption;
        defaultTransaction.activityId = data.activityId;
        defaultTransaction.costcodeId = data.costcodeId;
        defaultTransaction.paycodeId = data.paycodeId;
        defaultTransaction.equipmentId = data.equipmentId;
        defaultTransaction.shiftCodeId = data.shiftCodeId;
        defaultTransaction.employeeId = {id: activeEmployee.id};
        defaultTransaction.hours = null;
        defaultTransaction.status = useTimeIn ? TransactionStatus.TimedIn : TransactionStatus.Open;
        defaultTransaction.timeIn = timeInTime;
        defaultTransaction.timeOut = null;
        defaultTransaction.statusComment = null;
        defaultTransaction.isTrxDirty = useTimeIn ? true : false;
        defaultTransaction.notes = [];
        defaultTransaction.activityClientName = data.activityClientName;
        defaultTransaction.activitySiteName = data.activitySiteName;

        const transactionColumn = `${column.caption}_transactions`;
        const newData = cloneDeep(defaultFormData);
        newData.timesheetId = currentTimesheet.id;
        newData.transactionType = data.transactionType;
        newData.transactionDate = column.caption;
        newData.transactionFormattedDate = column.caption;
        newData.activityId = data.activityId;
        newData.costcodeId = data.costcodeId;
        newData.paycodeId = data.paycodeId;
        newData.equipmentId = data.equipmentId;
        newData.shiftCodeId = data.shiftCodeId;
        newData.employeeId = {id: activeEmployee.id};
        newData.hours = null;
        newData.status = useTimeIn ? TransactionStatus.TimedIn : TransactionStatus.Open;
        newData.timeIn = timeInTime;
        newData.timeOut = null;
        newData.statusComment = null;
        newData.notes = [];
        newData.activityClientName = data.activityClientName;
        newData.activitySiteName = data.activitySiteName;
        if (data[transactionColumn]) {
            newData.transactions = data[transactionColumn];
            newData.transactions.push(defaultTransaction);
        }
        else {
            newData.transactions = [defaultTransaction];
        }
        if (data.transactionType === TransactionType.Job) {
            await fetchJobCostCodes(data.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        newData.isTrxDirty = useTimeIn ? true : false;

        if (useTimeIn) {
            setIsFormDirtyState(true);
        }

        setSelectedTransactionId(null);
        setIsNewRecord(true);
        onDataChanged(newData);
        setEditRowKeysRef({
            activityId:      newData.activityId,
            costcodeId:      newData.costcodeId,
            paycodeId:       newData.paycodeId,
            equipmentId:     newData.equipmentId,
            shiftCodeId:     newData.shiftCodeId,
            employeeId:      newData.employeeId,
            transactionType: newData.transactionType,
            transactionDate: newData.transactionDate,
            id:              null
        });
        await changePopupVisibility(true);
    };

    const handleCellEditTime = async (cellData) => {
        // open the form in edit mode
        const { column, data } = cellData;
        const editData = cloneDeep(data);
        let editTrx = {};
        editData.transactionDate = column.caption;
        editData.transactionFormattedDate = column.caption;
        const transactionColumn = `${column.caption}_transactions`;
        if (editData[transactionColumn]) {
            editData.transactions = editData[transactionColumn];
        }
        else {
            editData.transactions = [];
        }
        if (data.transactionType === TransactionType.Job) {
            await fetchJobCostCodes(data.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        if (editData.transactions.length === 1) {
            editTrx = editData.transactions[0];
        }
        else {
            editTrx = editData;
        }
        setIsNewRecord(false);
        setTimeEntryData(editTrx);
        setTimeTransactionData(editTrx);
        setIsFormDirtyState(false);
        setEditRowKeysRef({
            activityId:      editTrx.activityId,
            costcodeId:      editTrx.costcodeId,
            paycodeId:       editTrx.paycodeId,
            equipmentId:     editTrx.equipmentId,
            shiftCodeId:     editTrx.shiftCodeId,
            employeeId:      editTrx.employeeId,
            transactionType: editTrx.transactionType,
            transactionDate: editTrx.transactionDate,
            id:              editTrx.id
        });
        if (editData.transactions.length < 2) {
            await changePopupVisibility(true);
        }
        else {
            // need to show the edit list popup
            setOriginalEditListTransactions(editTrx);
            setEditListPopupVisibleRef(true);
        }
    };

    const handleCellDeleteTime = useCallback(async (cellData) => {
        const { column, data } = cellData;
        const deleteData = cloneDeep(data);
        deleteData.transactionDate = column.caption;
        const transactionColumn = `${column.caption}_transactions`;
        if (deleteData[transactionColumn]) {
            deleteData.transactions = deleteData[transactionColumn];
        }
        else {
            deleteData.transactions = [];
        }

        if (deleteData.transactions.length === 1) {
            // show the dialog
            const trxId = deleteData.transactions[0].id;
            setIsDeleteConfirmation(true);
            setDeleteConfirmationTitle(t('time.deleteTimeEntry'));
            setDeleteConfirmationData({type: 'single', data: cellData});
            setDeleteTransactions([{id: trxId, formattedDate: column.caption}]);
            setShowConfirmationDialog(true);
        }
        else if (deleteData.transactions.length > 1) {
            // open the form for deleting a trx
            if (deleteData.transactionType === TransactionType.Job) {
                await fetchJobCostCodes(data.activityId.id).then(codes => {
                    setJobCostCodes(codes);
                });
            }
            setIsNewRecord(false);
            setTimeEntryData(deleteData);
            setEditRowKeysRef({
                activityId:      deleteData.activityId,
                costcodeId:      deleteData.costcodeId,
                paycodeId:       deleteData.paycodeId,
                equipmentId:     deleteData.equipmentId,
                shiftCodeId:     deleteData.shiftCodeId,
                employeeId:      deleteData.employeeId,
                transactionType: deleteData.transactionType,
                transactionDate: column.caption,
                id:              deleteData.id
            });
            setEditListPopupVisibleRef(true);
        }

    }, []);

    const handleTimeEntryDeletion = async (transaction) => {
        if (transaction) {
            const trxId = transaction.id;
            setIsDeleteConfirmation(true);
            setDeleteConfirmationTitle(t('time.deleteTimeEntry'));
            setDeleteConfirmationData({type: 'single', data: transaction});
            setDeleteTransactions([{id: trxId, formattedDate: transaction.transactionFormattedDate}]);
            setShowConfirmationDialog(true);
        }
    };

    const handleDeleteTimeConfirmation = async () => {
        if (deleteTransactions.length !== 0) {
            const promises = [];
            deleteTransactions.forEach(async trx => {
                promises.push(deleteTimeTransactions({id: trx.id, formattedDate: trx.formattedDate}, t));
            });
            await setIsDeletingTimeEntry(true);
            await Promise.all(promises.map(p => p))
                .then(async result => {
                    const gridInstance = gridRef.current?.instance;
                    if (gridInstance)  {
                        gridInstance.refresh();
                    }
                    setDeleteTransactions([]);
                    setDeleteConfirmationData();
                    setShowConfirmationDialog(false);
                    setIsDeleteConfirmationVisible(false);
                    setIsDeletingTimeEntry(false);
                    setPopupVisible(false);
                });
        }
        else {
            setDeleteTransactions([]);
            setDeleteConfirmationData();
            setShowConfirmationDialog(false);
            setIsDeleteConfirmationVisible(false);
            setIsDeletingTimeEntry(false);
        }
    };

    const renderTimeDeleteContent = (e) => {
        if (deleteConfirmationData) {
            const {data, column} = deleteConfirmationData.data;
            let transactions = [];
            let formattedDate;
            if (column) {
                transactions = data[column.caption + '_transactions'];
                formattedDate = column.caption;
            }
            else {
                formattedDate = deleteConfirmationData.data.transactionFormattedDate;
                transactions.push(deleteConfirmationData.data);
            }

            if (transactions.length === 1) {
                const hasNotes = transactions[0].notes.length !== 0;
                return (
                    <>
                        <div>
                            <div className="confirmation-dialog-header">
                                {
                                    transactions[0].hours ?
                                        t('time.deleteTimeConfirmation') :
                                        null
                                }
                            </div>
                            <div className="confirmation-costcode-value">
                                {
                                    transactions[0].hours ?
                                        t('time.deleteTimeEntryMessage', {transDate: parseISO(formattedDate), hours: transactions[0].hours}) :
                                        t('time.deleteTimedInEntry')
                                }
                            </div>
                            {
                                hasNotes && hasNotes.length !== 0 ?
                                    <div className="confirmation-costcode-value" style={{paddingTop: '10px'}}>
                                        {t('time.associatedNoteDeletion')}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                );
            }
        }
    };

    const handleAnnotationCancel = () => {
        setIsShowAnnotationForm(false);
        setIsNoteReadOnly(false);
    };

    const handleRefreshSavedNote = async (savedNote) => {
        const newAnnotation = await fetchAnnotationById(savedNote.id);
        if (newAnnotation) {
            const gridInstance = gridRef.current?.instance;
            if (gridInstance)  {
                gridInstance.refresh();
            }
        }
    };

    const handleSubmitConfirmation = async () => {
        setShowConfirmationDialog(false);
        let acknowledgementTimestamp = new Date();
        const gridInstance = gridRef.current?.instance;
        const timesheet = {
            timesheetId:                 currentTimesheet.id,
            timesheetStartDate:          currentTimesheet.timesheetStartDate ? currentTimesheet.timesheetStartDate : timesheetStartDate,
            timesheetFormattedStartDate: currentTimesheet.timesheetFormattedStartDate,
            employeeId:                  activeEmployee.id,
            transactions:                []
        };
        transactionsToSubmit.forEach(t => {
            t.activityId = t.activityId.id;
            t.costcodeId = t.costcodeId && t.costcodeId.id ? t.costcodeId.id : null;
            t.paycodeId = t.paycodeId.id;
            t.employeeId = t.employeeId.id;
            t.equipmentId = t.equipmentId && t.equipmentId.id ? t.equimentId.id : null;
            t.shiftCodeId = t.shiftCodeId && t.shiftCodeId.id ? t.shiftCodeId.id : null;
            t.status = TransactionStatus.Submitted;
            t.acknowledgementTimestamp = settings.useTransactionAcknowledgement ? acknowledgementTimestamp : null;
        });
        timesheet.transactions = transactionsToSubmit;
        if (gridInstance) {
            gridInstance.beginCustomLoading();
        }

        await insertTimeSheetTransaction(activeEmployee.id, timesheet, t);

        setTransactionsToSubmit([]);
        setAllowSubmitting(false);

        if (gridInstance) {
            gridInstance.refresh().then(data => {
                gridInstance.endCustomLoading();
            });
        }
    };

    const handleDenyConfirmation = async () => {
        setShowConfirmationDialog(false);
        setTransactionsToSubmit([]);
    };

    const onCellPrepared = useCallback((e) => {
        let rejectCount = 0;
        let errorCount = 0;
        if (e.rowType === 'data' && e.cellElement)
        {
            if (isValid(new Date(e.data[e.column.caption]))) {
                const transactions = e.data[`${e.column.caption}_transactions`];
                if (transactions) {
                    const cellId = `cell-${e.columnIndex}-${e.rowIndex}`;
                    e.cellElement.id = cellId;
                    rejectCount = transactions.filter(tt => tt.status === TransactionStatus.Rejected).length;
                    errorCount =  transactions.filter(tt => tt.status === TransactionStatus.Error).length;
                }
            }
        }

        if (e.rowType === 'data' && isValid(new Date(e.column.caption))) {
            if (rejectCount !== 0 && e.data[e.column.caption] !== null) {
                if (e.cellElement && e.cellElement.classList) {
                    e.cellElement.classList.add('transaction-reject');
                    e.cellElement.classList.remove('transaction-error');
                }
            }
            else if (errorCount !== 0 && e.data[e.column.caption] !== null) {
                if (e.cellElement && e.cellElement.classList) {
                    e.cellElement.classList.add('transaction-error');
                    e.cellElement.classList.remove('transaction-reject');
                }
            }
            else if (e.cellElement && e.cellElement.classList) {
                e.cellElement.classList.remove('transaction-error');
                e.cellElement.classList.remove('transaction-reject');
            }
        }
    }, []);

    const handleCellTimeIn = cellData => {
        handleCellAddTime(cellData, true);
    };

    const handleCellTimeOut = useCallback(cellData => {
        const { column, row } = cellData;
        const { data } = row;

        const transactionColumn = `${column.caption}_transactions`;
        const timedInEntry = data[transactionColumn].find(trx => trx.status === TransactionStatus.TimedIn);
        if (timedInEntry) {
            const allowTimeOut = validateTimeOut(timedInEntry, currentTimesheet.timeTransactions, t, true);

            if (allowTimeOut) {

                let timeOutTime = setMilliseconds(setSeconds(new Date(), 0), 0);
                let timeDiff = differenceInMinutes(timeOutTime, new Date(timedInEntry.timeIn));
                if (timeDiff > 1440) {
                    timeOutTime = addMinutes(new Date(timedInEntry.timeIn), 1440);
                    timeDiff = 1440;
                }
                const newHours = toHoursAndMinutes(timeDiff);

                const newData = cloneDeep(timedInEntry);
                newData.timeOut = timeOutTime;
                newData.isTrxDirty = true;
                newData.hours = Number(newHours);
                newData.status = TransactionStatus.Open;

                onDataChanged(newData);
                handleFormSave();
            }
        }
        else {
            showToastMessage('No transaction to time out of', 'error');
        }
    }, [currentTimesheet.timeTransactions]);

    const onCellRender = useCallback((cellData) => {
        let hasError = false;
        let statusComments = [];
        let errorType = '';

        const transactions = cellData.data[`${cellData.column.caption}_transactions`];
        if (transactions) {
            const transaction = transactions.filter(tt => tt.status === TransactionStatus.Error || tt.status === TransactionStatus.Rejected);
            transaction.forEach(trx => {
                hasError = true;
                statusComments.push(trx.statusComment);
                errorType = errorType === '' ? trx.status === TransactionStatus.Error ? t('time.errorInTimeEntry') : trx.status === TransactionStatus.Rejected ? t('time.timeEntryRejected') : '' : '';
            });
        }

        const cellId = `cell-${cellData.columnIndex}-${cellData.rowIndex}`;
        const buttonCellId = `timecell-${cellData.columnIndex}-${cellData.rowIndex}`;
        const cellAttributes = {
            id: buttonCellId
        };

        const hasAnnotation = hasAnnotations(cellData);
        const hasUnsubmitted = hasUnsubmittedTransactions(cellData);
        const hasTimedIn = hasTimedInEntry(cellData);
        const tooltipHeaderMessage = errorType;
        const errorClassName = errorType === t('time.errorInTimeEntry') ? 'fa-solid fa-circle-exclamation' : 'fa-solid fa-triangle-exclamation' ;

        return (
            <div className={'list-time-cell-container'}>
                <div className={`list-time-cell ${hasUnsubmitted ? 'list-unsubmitted-transactions' : ''}`}>
                    {cellData.value ? parseFloat(cellData.value).toFixed(2) : hasTimedIn ? '0.00' : ''}
                    <Button
                        elementAttr={cellAttributes}
                        hoverStateEnabled={false}
                        sytlingMode="text"
                        icon="fa-solid fa-ellipsis-vertical"
                        width={10}
                    >
                        <TimeCellContextMenu
                            cellId={buttonCellId}
                            cellData={cellData}
                            handleAddNote={handleAddNote}
                            handleUpdateNote={handleUpdateNote}
                            handleDeleteNote={handleDeleteNote}
                            handleViewNote={handleViewNote}
                            handleCellAddTime={handleCellAddTime}
                            handleCellEditTime={handleCellEditTime}
                            handleCellDeleteTime={handleCellDeleteTime}
                            handleCellTimeIn={handleCellTimeIn}
                            handleCellTimeOut={handleCellTimeOut}
                            isTimeInAllowed={isTimeInAllowed(cellData)}
                            isTimeOutAllowed={isTimeOutAllowed(cellData)}
                            useTimeEntryForm={true}
                        />
                    </Button>
                </div>
                { hasError ?
                    <Tooltip
                        target={`#${cellId}`}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        position="bottom"
                        hideOnOutsideClick={false}
                    >
                        <div className="list-tooltip-error">
                            <div className="list-tooltip-error-header"><i className={errorClassName} style={{color: '#ff0000', paddingRight: '5px'}} />{tooltipHeaderMessage}</div>
                            {statusComments.map((comment, index) => (
                                <div key={index} className="list-tooltip-error-text">{index !== 0 ? <br /> : null}{comment}</div>
                            ))}
                        </div>
                    </Tooltip>
                    : null}
                { hasAnnotation ?
                    <div className="list-annotation-triangle" />
                    : null}
                {
                    hasTimedIn ?
                        <div className="list-timedin-entry">
                            <Icon
                                path={mdiTimerSand }
                                size={'14px'}
                                color="green"
                                spin
                            />
                        </div>
                        : null
                }
            </div>
        );
    }, [currentTimesheet.timeTransactions]);

    const isTimeInAllowed = (cellData) => {
        const { column } = cellData;
        const newDate = format(new Date(), 'yyyy-MM-dd');
        const sameDay = isSameDay(new Date(column.caption), new Date(newDate));
        if (!useTimeInTimeOut || !sameDay) {
            return false;
        }

        const allowTimeOut = isTimeOutAllowed(cellData);
        if (allowTimeOut) {
            return false;
        }
        const timedInCount = currentTimesheet.timeTransactions.filter(trx => trx.status === TransactionStatus.TimedIn).length;

        return timedInCount === 0;
    };

    const isTimeOutAllowed = (cellData) => {
        if (!useTimeInTimeOut) {
            return false;
        }
        const { column, data} = cellData;
        const transactionColumn = `${column.caption}_transactions`;
        if (data[transactionColumn]) {
            const daysTransactions = data[transactionColumn].filter(trx => trx.status === TransactionStatus.TimedIn);
            if (daysTransactions && daysTransactions.length === 1) {
                return true;
            }
            else {
                return false;
            }
        }

        return false;
    };

    const printTimesheet = async () => {
        await printEmployeeTimesheetReport(activeCompany.id, [activeEmployee.id], timesheetStartDate, true, t);
    };

    const handleSelectEmployeeTimesheetPrint = () => {
        setShowEmployeeReportSelectionModal(true);
    };

    const printSelectedEmployeesTimesheet = useCallback(async () => {
        if (employeeTimesheetReportListRef.current.length !== 0) {
            await printEmployeeTimesheetReport(activeCompany.id, employeeTimesheetReportListRef.current, timesheetStartDate, true, t);
        }
        setShowEmployeeReportSelectionModal(false);
    }, [activeCompany, employeeTimesheetReportListRef.current, timesheetStartDate]);

    return (
        <>
            <div className={'time-entry-header-wrapper'}>
                <div className={'entry-list-toolbar-row'}>
                    <div className="toolbar-container">
                        {displayToolbar()}
                    </div>
                </div>
            </div>
            <div className="time-entry-grid-container">
                <div className="time-entry-grid">
                    <DataGrid
                        ref={gridRef}
                        onCellPrepared={onCellPrepared}
                        dataSource={gridDataSource}
                        height="calc(100vh - 235px)"
                        noDataText={t('time.createNewTimeEntry')}
                        onRowPrepared={onRowPrepared}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        allowColumnReordering={true}
                        columnFixingEnabled={true}
                        columnAutoWidth={true}
                    >
                        <GroupPanel
                            visible={true}
                            emptyPanelText={t('time.emptyPanelText')}
                        />
                        <Grouping autoExandall={true} />
                        <SearchPanel
                            placeholder={t('time.searchEntries')}
                            visible={true}
                        />
                        <Column
                            dataField="activityName"
                            caption={t('time.activity')}
                            cellRender={renderActivityCell}
                            minwidth={250}
                            showWhenGrouped={true}
                        />
                        <Column
                            dataField="costcodeAlias"
                            caption={t('time.costCode')}
                            cellRender={renderCostCodeCell}
                            width="auto"
                            showWhenGrouped={true}
                        />
                        <Column
                            dataField="paycodeName"
                            caption={t('time.payCode')}
                            cellRender={renderPayCodeCell}
                            width="auto"
                            showWhenGrouped={true}
                        />
                        {
                            settings.useShiftCode ?
                                <Column
                                    dataField="shiftCodeName"
                                    caption={t('time.shiftCode')}
                                    cellRender={renderShiftCodeCell}
                                    width="auto"
                                    showWhenGrouped={true}
                                />
                                : null
                        }
                        <Column
                            dataField="day0"
                            caption={periodDays[0]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField="day1"
                            caption={periodDays[1]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField="day2"
                            caption={periodDays[2]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField="day3"
                            caption={periodDays[3]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField="day4"
                            caption={periodDays[4]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField="day5"
                            caption={periodDays[5]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField="day6"
                            caption={periodDays[6]?.formattedDate}
                            cellRender={onCellRender}
                            width={90}
                            showWhenGrouped={true}
                            allowGrouping={false}
                            allowReordering={false}
                        />
                        <Column
                            dataField={'timesheetId'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            dataField={'activityClientName'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            dataField={'activitySiteName'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            dataField={'activityDescription'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            dataField={'costcodeDescription'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            dataField={'paycodeDescription'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            dataField={'paycodeType'}
                            visible={false}
                            showInColumnChooser={false}
                        />
                        <Column
                            alignment={'right'}
                            allowGrouping={false}
                            allowReordering={false}
                            caption={t('time.total')}
                            cellRender={totalCellRender}
                            fixed={true}
                            fixedPosition="right"
                            name="totals"
                            width={75}
                        />
                        <Summary >
                            <TotalItem
                                column={settings.useShiftCode ? 'shiftCodeName' : 'paycodeName'}
                                summaryType="count"
                                alignment="right"
                            />
                            {
                                periodDays.map((period, index) => (
                                    <TotalItem
                                        key={index}
                                        column={`day${index}`}
                                        summaryType="sum"
                                        alignment="right"
                                    />
                                ))
                            }
                        </Summary>
                    </DataGrid>
                    { popupVisible ?
                        <FormPopup
                            cancelButtonText={t('common.cancel')}
                            isFormDirtyStateRef={isFormDirtyStateRef}
                            onHiding={handlePopupHiding}
                            onSave={handleFormSave}
                            onResetForm={handleFormReset}
                            showMultiSaveButton={true}
                            setIsFormDirtyState={setIsFormDirtyState}
                            setIsResetForm={setIsResetFormRef}
                            setVisible={changePopupVisibility}
                            title={`${t('time.timeEntry')} - ${activeEmployee.firstName} ${activeEmployee.lastName} `}
                            visible={popupVisible}
                            width={600}
                            height={'95vh'}
                        >
                            <TimeEntryForm
                                data={timeEntryData}
                                formRef={formRef}
                                fullTransactionList={currentTimesheet.timeTransactions}
                                gridInstance={gridRef.current?.instance}
                                handleTimeEntryDeletion={handleTimeEntryDeletion}
                                isFormDirtyStateRef={isFormDirtyStateRef}
                                isResetForm={isResetFormRef}
                                isNewRecord={isNewRecord}
                                onFormDataChanged={onFormDataChanged}
                                onDataChanged={onDataChanged}
                                jobCostCodes={jobCostCodes}
                                setEditRowKeys={setEditRowKeysRef}
                                setIsFormDirtyState={setIsFormDirtyState}
                                setJobCostCodes={setJobCostCodes}
                                selectedTransactionId={selectedTransactionId}
                                setSelectedTransactionId={setSelectedTransactionId}
                            />
                        </FormPopup>
                        : null
                    }
                    {
                        editListPopupVisible ?
                            <FormPopup
                                setIsResetForm={setIsResetFormRef}
                                showCancelButton={false}
                                showSaveButton={false}
                                setVisible={changeEditListPopupVisibility}
                                title={t('time.timeEntriesForDate', {transDate: parseISO(timeEntryData.transactionFormattedDate)})}
                                visible={editListPopupVisibleRef.current}
                                width={editListPopupWidth}
                            >
                                <TimeEntryEditList
                                    activeEmployee={activeEmployee}
                                    deleteTimeTransactions={deleteTimeTransactions}
                                    featureFlags={featureFlags}
                                    fetchJobCostCodes={fetchJobCostCodes}
                                    formRef={formRef}
                                    fullTransactionList={currentTimesheet.timeTransactions}
                                    gridInstance={gridRef.current?.instance}
                                    handleEditListPopupHiding={handleEditListPopupHiding}
                                    handleTimeEntryDeletion={handleTimeEntryDeletion}
                                    isFormDirtyStateRef={isFormDirtyStateRef}
                                    isResetForm={isResetFormRef}
                                    jobCostCodes={jobCostCodes}
                                    newTransactionId={newId.current}
                                    onFormDataChanged={onFormDataChanged}
                                    onDataChanged={onDataChanged}
                                    onResetForm={handleFormReset}
                                    onSave={handleFormSave}
                                    resetEditList={resetEditListDataSource}
                                    setEditListPopFormVisible={setEditListPopuFormVisibleRef}
                                    setEditRowKeysRef={setEditRowKeysRef}
                                    setIsFormDirtyStateRef={setIsFormDirtyState}
                                    setJobCostCodes={setJobCostCodes}
                                    setNewTransactionId={setNewId}
                                    setShowEditListForm={setEditListPopupVisibleRef}
                                    setPopupIsVisible={setEditListPopuFormVisibleRef}
                                    selectedTransactionId={selectedTransactionId}
                                    setSelectedTransactionId={setSelectedTransactionId}
                                    settings={settings}
                                    transactions={timeEntryData}
                                />
                            </FormPopup>
                            : null
                    }
                    { showConfirmationDialog ?
                        <ConfirmationDialog
                            showDialog={showConfirmationDialog}
                            confirmationTitle={!isDeleteConfirmation ? t('time.submitTimeEntries') : deleteConfirmationTitle}
                            confirmButtonAction={!isDeleteConfirmation ? handleSubmitConfirmation : handleDeleteTimeConfirmation}
                            confirmButtonText={t('common.yes')}
                            denyButtonAction={!isDeleteConfirmation ? handleDenyConfirmation : handleDeleteDeny}
                            denyButtonText={t('common.no')}
                            height="auto"
                            renderContent={isDeleteConfirmation ? renderTimeDeleteContent : renderConfirmationDialogContent}
                        />
                        : null
                    }
                    { isDeleteNoteConfirmationVisible ?
                        <ConfirmationDialog
                            showDialog={isDeleteNoteConfirmationVisible}
                            confirmationTitle={deleteConfirmationTitle}
                            confirmButtonAction={handleDeleteNoteConfirmation}
                            confirmButtonText={t('common.yes')}
                            denyButtonAction={handleDeleteDeny}
                            denyButtonText={t('common.no')}
                            renderContent={renderNoteDeleteContent}
                            height={300}
                            width={500}
                        />
                        : null
                    }
                    { isShowAnnotationForm ?
                        <AnnotationForm
                            activeEmployee={activeEmployee}
                            annotationType={AnnotationTypes.TIMETRANSACTION}
                            cancelButtonAction={handleAnnotationCancel}
                            handleAddNote={handleAddNote}
                            isNoteReadOnly={isNoteReadOnly}
                            isNewNote={isNewNote}
                            noteData={noteData}
                            noteSectionLabel={noteSectionLabel}
                            objectId={selectedTransactionId}
                            setIsShowAnnotationForm={setIsShowAnnotationForm}
                            showAnnotationForm={isShowAnnotationForm}
                            showAttachmentForm={false}
                            showNoteForm={true}
                            target={annotationTarget}
                            title={t('t.annotations')}
                            useTimeEntryForm={true}
                            gridInstance={gridRef.current?.instance}
                            handleRefreshSavedNote={handleRefreshSavedNote}
                        />
                        : null}
                    {
                        showEmployeeReportSelectionModal ?
                            <FormPopup
                                cancelButtonText={t('common.cancel')}
                                onSave={printSelectedEmployeesTimesheet}
                                setVisible={setShowEmployeeReportSelectionModal}
                                saveButtonIcon={'fa-solid fa-file-pdf'}
                                saveButtonText={t('common.generate')}
                                title={t('report.selectemployees')}
                                visible={showEmployeeReportSelectionModal}
                            >
                                <EmployeeSelection
                                    employeeList={filteredEmployeeList}
                                    setSelectedEmployeeIds={setEmployeeTimesheetReportListRef}
                                />
                            </FormPopup>
                            : null
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    activeCompany:         state.time.activeCompany,
    activeEmployee:        state.employee.activeEmployee,
    currentTimesheet:      state.time.currentTimesheet,
    currentUser:           state.currentUser,
    currentWeekEndingDate: state.time.currentWeekEndingDate,
    employeePayCodes:      state.employee.employeePayCodes,
    featureFlags:          state.core.featureFlags,
    filteredEmployeeList:  state.employee.filteredEmployeeList,
    isInRefresh:           state.time.isInRefresh,
    isLoadingTimesheet:    state.time.isLoadingTimesheet,
    jobDivisions:          state.time.jobDivisions,
    periodDays:            state.time.periodDays,
    settings:              state.time.settings,
    serviceDivisions:      state.time.serviceDivisions,
    shiftCodes:            state.time.shiftCodes,
    subTotals:             state.time.currentTimesheet.subTotals,
    timesheetStartDate:    state.time.timesheetStartDate,
    timeUserSettings:      state.configurations.timeUserSettings
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...ActivitiesActions,
    ...EmployeeActions,
    ...JobActions,
    ...TimeActions,
    ...WorkOrderActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryList);
