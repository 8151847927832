/* eslint-disable import/no-anonymous-default-export */

import { fetchAuthSession } from 'aws-amplify/auth';

export default async () => {
    const currentSession = await fetchAuthSession();
    const bearerToken = `Bearer ${currentSession.tokens.idToken.toString()}`;
    const companyId = sessionStorage.getItem('companyId');
    const acceptLanguage = localStorage.getItem('i18nextLng') || 'en';

    return {
        headers: {
            'Content-Type':    'application/json',
            'Authorization':   bearerToken,
            'K2A-Company':     companyId,
            'Accept-Language': acceptLanguage
        }
    };
};
