import {
    SET_WORK_ORDER_LIST,
    SET_WORK_ORDER_COST_CODES
} from '../actions/actionTypes';

const initialState = {
    workOrders: [],
    costCodes:  []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_WORK_ORDER_LIST:
            return {
                ...state,
                workOrders: action.payload
            };
        case SET_WORK_ORDER_COST_CODES:
            return {
                ...state,
                costCodes: action.payload
            };
        default:
            return state;
    }
};
