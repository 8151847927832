import {
    SET_JOB_LIST,
    SET_JOB_COST_CODES
} from '../actions/actionTypes';

const initialState = {
    jobs:         [],
    jobCostCodes: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_JOB_LIST:
            return {
                ...state,
                jobs: action.payload
            };
        case SET_JOB_COST_CODES:
            return {
                ...state,
                jobCostCodes: action.payload
            };
        default:
            return state;
    }
};
