import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import List from 'devextreme-react/list';
import ActionSheet from 'devextreme-react/action-sheet';
import Button from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { custom } from 'devextreme/ui/dialog';

import PeriodSelector from '../PeriodSelector/PeriodSelector';
import { FormPopup } from '../utils/form-popup/FormPopup.js';
import TimeEntryMobileApprovalModal from './TimeManagerMobileApprovalModal.js';

import useScreenOrientation from 'use-screen-orientation';
import { useScreenSize } from '../../utils/media-query';

import * as AnnotationActions from '../../actions/annotationActions';
import * as TimeActions from '../../actions/timeActions';
import { TransactionStatus } from '../../data/transactionStatuses';
import devices from 'devextreme/core/devices';

import {
    RenderItemView
} from './TimeManagerMobileApprovalListHelper.js';

import './TimeManagerMobileApprovalList.scss';
import { ScrollView } from 'devextreme-react';

const fetchTimeSheetOptions = {
    includeexternalorigindetails: true,
    companyid:                    null,
    requesterId:                  null
};

const defaultApprovalModal = {
    title:               '',
    timesheet:           {},
    transactions:        [],
    fullTransactionList: []
};

const TimeManagerMobileApprovalList =({
    activeCompany,
    createAnnotation,
    currentUser,
    employeeList,
    fetchTimesheetsForApproval,
    insertTimeSheetTransaction,
    timesheetStartDate,
    updateAnnotation
}) => {
    const { t, i18n } = useTranslation();
    const { isXSmall, isSmall, isMedium } = useScreenSize();

    const [approvalModal, setApprovalModal] = useState(defaultApprovalModal);
    const [listDataSource, setListDataSource] = useState([]);
    const [listHeight, setListHeight] = useState('calc(100vh - 250px)');
    const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [isMauiApp, setIsMauiApp] = useState(false);
    const [orientation] = useScreenOrientation();

    const actionSheetItems = [
        {
            id:   'view',
            text: t('time.viewEntries')
        }
    ];

    const listRef = useRef();

    const isTimeEntryPopupVisible = useRef(false);
    const setIsTimeEntryPopupVisible = data => {
        setPopupVisible(data);
        isTimeEntryPopupVisible.current = data;
    };

    const approvalModalRef = useRef(defaultApprovalModal);
    const setApprovalModalRef = data => {
        approvalModalRef.current = data;
        setApprovalModal(data);
    };

    useEffect(() => {
        const isMaui = sessionStorage.getItem('isMauiApp');
        if (isMaui) {
            setIsMauiApp(true);
        }
        else {
            setIsMauiApp(false);
        }
    }, []);

    useEffect(() => {
        const init = async () => {
            let heightCalc = 'calc(100% - 250px)';
            if (devices.current().phone) {
                switch (devices.orientation()) {
                    case 'portrait':
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 170px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 250px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 180px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 230px)';
                            }
                        }
                        break;
                    default:
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 275px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 220px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 275px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 220px)';
                            }
                        }
                        break;
                }
            }
            else if (devices.current().tablet) {
                switch (devices.orientation()) {
                    case 'portrait':
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 170px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 250px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 180px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 270px)';
                            }
                        }
                        break;
                    default:
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 170px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 200px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 180px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 270px)';
                            }
                        }
                        break;
                }
            }
            else {
                heightCalc = 'calc(100% - 180px)';
            }

            await setListHeight(heightCalc);

        };
        init();
    }, [orientation, isMauiApp, isXSmall, isSmall, isMedium]);

    useEffect(() => {
        const init = async () => {
            const store = new CustomStore({
                loadMode:  'processed',
                onLoading: (loadOptions) => {
                    const availableEmployees = currentUser.isSuperUser ? employeeList : employeeList.filter(emp => emp.userId !== currentUser.id && emp.managerId);

                    fetchTimeSheetOptions.companyid = activeCompany.id;
                    fetchTimeSheetOptions.requesterId = currentUser.id;
                    fetchTimeSheetOptions.transactionstatus = TransactionStatus.Submitted;
                    fetchTimeSheetOptions.forapproval = true;
                    loadOptions.userData = {
                        employeeIds:  availableEmployees,
                        startDate:    timesheetStartDate,
                        fetchOptions: fetchTimeSheetOptions
                    };
                },
                load: async (loadOptions) => {
                    return fetchTimesheetsForApproval(loadOptions.userData.employeeIds, loadOptions.userData.startDate, t, loadOptions.userData.fetchOptions);
                },
                onLoaded: (data) => {
                    if (popupVisible) {
                        const employeeData = data.find(emp => emp.employeeId === approvalModalRef.current?.timesheet.employeeId);
                        if (employeeData) {
                            if (employeeData.transactions.length !== 0) {
                                prepareApprovalModal(employeeData);
                            }
                            else {
                                setApprovalModalRef(defaultApprovalModal);
                                setPopupVisible(false);
                            }
                        }
                        else {
                            setApprovalModalRef(defaultApprovalModal);
                            setPopupVisible(false);
                        }
                    }
                }
            });

            setListDataSource(new DataSource({
                key:      'id',
                paginate: false,
                store:    store
            }));
        };

        if (employeeList && employeeList.length !== 0) init();
    }, [popupVisible, employeeList, timesheetStartDate]);

    const handleListRefresh = useCallback(e => {
        const listInstance = listRef.current?.instance;
        if (listInstance) {
            listInstance.reload();
        }
    }, []);

    const renderToolbar = () => {
        return (
            <Toolbar>
                <Item location="before">
                    <PeriodSelector />
                </Item>
                <Item location="after" locateInMenu="auto">
                    <Button
                        text={t('common.refresh')}
                        icon="refresh"
                        onClick={handleListRefresh}
                    />
                </Item>
            </Toolbar>
        );
    };

    const prepareApprovalModal = data => {
        const title = t('time.timeEntriesFor', { employee: data.employeeName});
        const timesheet = {
            id:                 data.id,
            employeeId:         data.employeeId,
            timesheetStartDate: data.timesheetFormattedStartDate
        };
        const transactions = data.transactions.filter(trx => trx.status === TransactionStatus.Submitted);

        setApprovalModalRef({
            employeeName:        data.employeeName,
            period:              data.period,
            timesheet,
            title,
            transactions,
            fullTransactionList: data.transactions
        });
    };

    const handleItemClick = useCallback(e => {
        const { itemData } = e;
        prepareApprovalModal(itemData);
        setIsActionSheetVisible(true);

    }, []);

    const handleCancelClick = useCallback(e => {
        setIsActionSheetVisible(false);
    }, []);

    const handlePopupFormHiding = useCallback(async e => {
        setIsActionSheetVisible(false);
        setIsTimeEntryPopupVisible(false);
    }, []);

    const onActionSheetItemClick = useCallback(async e => {
        setIsActionSheetVisible(false);
        setIsTimeEntryPopupVisible(true);
    }, []);

    const handleApproveAll = useCallback(e => {
        const customDialog = custom({
            title:       t('common.approveAll'),
            messageHtml: t('time.confirmApproveAll'),
            buttons:     [{
                text:        t('common.yes'),
                stylingMode: 'text',
                onClick:     e => {
                    return true;
                }
            },
            {
                text:        t('common.no'),
                stylingMode: 'text',
                onClick:     e => {
                    return false;
                }
            }]
        });

        customDialog.show()
            .then(dialogResult => {
                if (dialogResult) {
                    handleTransactionAcceptAll(approvalModalRef.current.transactions);
                }
            });

    }, [approvalModalRef.current.transactions]);

    const formatTransactionsForSave = (transactions, approvalModal) => {
        let transactionList = [];
        if (isArray(transactions)) {
            transactions.forEach(trx => {
                const ttx = {
                    id:                       trx.id ? trx.id : null,
                    timesheetId:              trx.timesheetId ? trx.timesheetId : null,
                    employeeId:               trx.employeeId?.id ? trx.employeeId.id : approvalModal.timesheet.employeeId,
                    activityId:               trx.activityId.id,
                    costcodeId:               trx.costcodeId?.id ? trx.costcodeId.id : null,
                    paycodeId:                trx.paycodeId.id,
                    shiftCodeId:              trx.shiftCodeId?.id ? trx.shiftCodeId.id : null,
                    equipmentId:              trx.equipment?.id ? trx.equipmentId.id : null,
                    status:                   trx.status,
                    statusComment:            trx.statusComment ? trx.statusComment : null,
                    description:              trx.description ? trx.description : null,
                    transactionType:          trx.transactionType,
                    transactionDate:          trx.transactionDate,
                    timeIn:                   trx.timeIn ? trx.timeIn : null,
                    timeOut:                  trx.timeOut ? trx.timeOut : null,
                    hours:                    trx.hours,
                    acknowledgementTimestamp: trx.acknowledgementTimestamp
                };
                transactionList.push(ttx);
            });
        }
        else {
            const ttx = {
                id:                       transactions.id ? transactions.id : null,
                timesheetId:              transactions.timesheetId ? transactions.timesheetId : approvalModal.timesheet.id,
                employeeId:               transactions.employeeId?.id ? transactions.employeeId.id : approvalModal.timesheet.employeeId,
                activityId:               transactions.activityId.id,
                costcodeId:               transactions.costcodeId?.id ? transactions.costcodeId.id : null,
                paycodeId:                transactions.paycodeId.id,
                shiftCodeId:              transactions.shiftCodeId?.id ? transactions.shiftCodeId.id : null,
                equipmentId:              transactions.equipment?.id ? transactions.equipmentId.id : null,
                status:                   transactions.status,
                statusComment:            transactions.statusComment ? transactions.statusComment : null,
                description:              transactions.description ? transactions.description : null,
                transactionType:          transactions.transactionType,
                transactionDate:          transactions.transactionDate,
                timeIn:                   transactions.timeIn ? transactions.timeIn : null,
                timeOut:                  transactions.timeOut ? transactions.timeOut : null,
                hours:                    transactions.hours,
                acknowledgementTimestamp: transactions.acknowledgementTimestamp
            };
            transactionList.push(ttx);
            // transactionList.push(transactions);
        }
        const formattedData = {
            timesheetId:        approvalModal.timesheet.id,
            timesheetStartDate: approvalModal.timesheet.timesheetStartDate,
            employeeId:         approvalModal.timesheet.employeeId,
            transactions:       transactionList
        };
        return formattedData;
    };

    const handleTransactionAccept = useCallback(async data => {
        data.status = TransactionStatus.Approved;

        const transactions = formatTransactionsForSave(data, approvalModal);
        const result = await insertTimeSheetTransaction(transactions.employeeId, transactions, t);
        if (result.success) {
            const listInstance = listRef.current?.instance;
            if (listInstance) {
                listInstance.reload();
            }
        }
    }, [approvalModal]);

    const handleTransactionAcceptAll = useCallback(async transactions => {
        transactions.forEach(trx => trx.status = TransactionStatus.Approved);
        const formattedTransactions = formatTransactionsForSave(transactions, approvalModal);
        const result = await insertTimeSheetTransaction(formattedTransactions.employeeId, formattedTransactions, t);
        if (result.success) {
            const listInstance = listRef.current?.instance;
            if (listInstance) {
                listInstance.reload();
            }
        }
    }, [approvalModal]);

    const handleTransactionReject = useCallback(async data => {
        if (data) {
            data.status = TransactionStatus.Rejected;
            const transactions = formatTransactionsForSave(data, approvalModal);
            const result = await insertTimeSheetTransaction(transactions.employeeId, transactions, t);
            if (result.success) {
                const listInstance = listRef.current?.instance;
                if (listInstance) {
                    listInstance.reload();
                }
            }
        }
    }, [approvalModal]);

    const handleTranscationUpdate = useCallback(async transaction => {
        let noteToUpdate;
        if (transaction.notes.length !== 0) {
            noteToUpdate = transaction.notes[0];
        }
        const formattedTransactions = formatTransactionsForSave(transaction, approvalModal);
        const result = await insertTimeSheetTransaction(formattedTransactions.employeeId, formattedTransactions, t);
        if (result.success && noteToUpdate) {
            // save the note
            if (noteToUpdate.isUpdated) {
                if (noteToUpdate.id) {
                    await updateAnnotation(activeCompany.id, noteToUpdate, t);
                }
                else {
                    await createAnnotation(activeCompany.id, noteToUpdate, t);
                }
            }
        }

        const listInstance = listRef.current?.instance;
        if (listInstance) {
            listInstance.reload();
        }
        return result.success;
    }, [approvalModal]);

    return (
        <>
            <div className="mobile-wrapper">
                <div className="mobile-toolbar">{renderToolbar()}</div>
                <ScrollView
                    height={listHeight}
                >
                    <div className="mobile-list-view">
                        <div className="mobile-list-section-header">{t('time.managerApprovals')}</div>
                        <List
                            ref={listRef}
                            dataSource={listDataSource}
                            height={listHeight}
                            itemRender={RenderItemView(t, i18n.resolvedLanguage)}
                            pullRefreshEnabled={true}
                            onItemClick={handleItemClick}
                            noDataText={t('time.noEntriesToApprove')}
                        />
                    </div>
                </ScrollView>
                <ActionSheet
                    cancelText={t('common.cancel')}
                    dataSource={actionSheetItems}
                    onCancelClick={handleCancelClick}
                    onItemClick={onActionSheetItemClick}
                    showCancelButton={true}
                    visible={isActionSheetVisible}
                />
            </div>
            {
                popupVisible ?
                    <FormPopup
                        cancelButtonText={t('common.close')}
                        onCancelClick={handlePopupFormHiding}
                        onHiding={handlePopupFormHiding}
                        onSave={handleApproveAll}
                        setVisible={setIsTimeEntryPopupVisible}
                        showSaveButton={true}
                        saveButtonText={t('common.approveAll')}
                        showMultiSaveButton={false}
                        title={approvalModalRef.current.title}
                        validationRequired={false}
                        visible={popupVisible}
                        width="100%"
                    >
                        <TimeEntryMobileApprovalModal
                            fullTransactionList={approvalModalRef.current.fullTransactionList}
                            handleTransactionAccept={handleTransactionAccept}
                            handleTransactionReject={handleTransactionReject}
                            handleTransactionUpdate={handleTranscationUpdate}
                            period={approvalModalRef.current.period}
                            timesheet={approvalModalRef.current.timesheet}
                            transactions={approvalModalRef.current.transactions}
                        />
                    </FormPopup>
                    :
                    null
            }
        </>
    );
};

const mapStateToProps = state => ({
    activeCompany:         state.time.activeCompany,
    currentOS:             state.core.currentOS,
    currentUser:           state.currentUser,
    employeeList:          state.employee.employeeList,
    isLoadingTimesheet:    state.time.isLoadingTimesheet,
    timesheetStartDate:    state.time.timesheetStartDate,
    timesheetsForApproval: state.time.timesheetsForApproval,
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeManagerMobileApprovalList);
