/* eslint-disable import/no-anonymous-default-export */
import {
    REDUX_WEBSOCKET_BEGIN_RECONNECT,
    REDUX_WEBSOCKET_BROKEN,
    REDUX_WEBSOCKET_CLOSED,
    REDUX_WEBSOCKET_CONNECT,
    REDUX_WEBSOCKET_ERROR,
    REDUX_WEBSOCKET_OPEN,
    REDUX_WEBSOCKET_RECONNECTED,
    REDUX_WEBSOCKET_RECONNECT_ATTEMPT
} from '../actions/actionTypes';

const initialState = {
    broken:        false,
    connecting:    false,
    lastError:     null,
    open:          false,
    reconnections: 0
};


export default (state = initialState, action) => {
    switch (action.type) {
        case REDUX_WEBSOCKET_BEGIN_RECONNECT:
            return {
                ...state,
                connecting: true
            };
        case REDUX_WEBSOCKET_BROKEN:
            return {
                ...state,
                broken: true,
                open:   false
            };
        case REDUX_WEBSOCKET_CLOSED:
            return {
                ...state,
                open: false
            };
        case REDUX_WEBSOCKET_CONNECT:
        case REDUX_WEBSOCKET_RECONNECTED:
            return {
                ...state,
                broken:        false,
                connecting:    false,
                lastError:     null,
                reconnections: 0
            };
        case REDUX_WEBSOCKET_ERROR:
            return {
                ...state,
                lastError: action.meta
            };
        case REDUX_WEBSOCKET_OPEN:
            return {
                ...state,
                open: true
            };
        case REDUX_WEBSOCKET_RECONNECT_ATTEMPT:
            return {
                ...state,
                reconnections: action.payload.count
            };
        default:
            return state;
    }
};
