import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function RequireAuth ({ children }) {
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);

    if (route !== 'authenticated' && route !== 'setup') {
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }
    return children;
}
