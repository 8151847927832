/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from '../actions/actionTypes';

const initialState = {
    billingStatus:   null,
    billingContact:  {},
    companyName:     null,
    companyId:       null,
    companyAddress:  null,
    contractorType:  null,
    contractorTypes: [],
    enabled:         false,
    roles:           [],
    role:            {},
    scoringEnabled:  false,
    integrations:    []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_COMPANY_INFO:
            return {
                ...action.payload
            };
        case ActionTypes.FETCH_ROLES:
            return {
                ...state,
                roles: [...action.payload]
            };
        case ActionTypes.FETCH_INTEGRATIONS:
            return {
                ...state,
                integrations: [...action.payload]
            };
        case ActionTypes.SET_ROLE:
            return {
                ...state,
                role: {...action.payload}
            };
        default:
            return state;
    }
};
