/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_ALL_SCOPES,
    FETCH_API_VERSION,
    FETCH_COMPANIES,
    FETCH_DEFAULT_COMPANY,
    FETCH_TIME_COMPANIES,
    SET_BOOTSTRAPPING,
    SET_ALL_ROLES,
    SET_CURRENT_OS,
    SET_CURRENT_SCOPES,
    SET_FEATURE_FLAGS
} from '../actions/actionTypes';

const initialState = {
    allRoles:           [],
    allScopes:          [],
    apiVersion:         '',
    companies:          [],
    currentApp:         '',
    currentOS:          '',
    currentScopes:      [],
    defaultCompany:     {},
    featureFlags:       [],
    timeCompanies:      [],
    progressBarLoading: false,
    isBootStrapping:    false,
    isMobileApp:        false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_SCOPES:
            return {
                ...state,
                allScopes: [...action.payload]
            };
        case FETCH_API_VERSION:
            return {
                ...state,
                apiVersion: action.payload
            };
        case FETCH_COMPANIES:
            return {
                ...state,
                companies: [...action.payload]
            };
        case FETCH_DEFAULT_COMPANY:
            return {
                ...state,
                defaultCompany: {...action.payload}
            };
        case SET_ALL_ROLES:
            return {
                ...state,
                allRoles: [...action.payload]
            };
        case SET_BOOTSTRAPPING:
            return {
                ...state,
                isBootStrapping: action.payload
            };
        case SET_CURRENT_OS:                
            return {
                ...state,
                currentOS:   action.payload.currentOS,
                isMobileApp: action.payload.isMobileApp
            };
        case SET_CURRENT_SCOPES:
            return {
                ...state,
                currentScopes: [...action.payload]
            };
        case FETCH_TIME_COMPANIES:
            return {
                ...state,
                timeCompanies: [...action.payload]
            };
        case SET_FEATURE_FLAGS:
            return {
                ...state,
                featureFlags: [...action.payload]
            };
        default:
            return state;
    }
};
