import React from 'react';
import { parseISO } from 'date-fns';
import Icon from '@mdi/react';
import {
    mdiLockOpenOutline,
    mdiLockOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiInformationOutline
} from '@mdi/js';

export const renderDayTab = langId => e => {
    const periodDate = parseISO(e.text);
    const formattedDay = periodDate.toLocaleString(langId, {
        weekday: 'short'
    });

    const formattedDate = periodDate.toLocaleString(langId, {
        day: '2-digit'
    });

    return (
        <div>
            <div>{formattedDay}</div>
            <div>{formattedDate}</div>
        </div>
    );
};

export function getPayCodeDisplayExpr(item) {
    return item && `${item.paycodeName} - ${item.paycodeDescription}`;
}

export function getShiftCodeDisplayExpr(item) {
    return item && `${item.name} - ${item.description}`;
}

export function getActivityDisplayExpr(item) {
    if (item && item.description) {
        return item && `${item.activityId} - ${item.description}`;
    }
    else {
        return item && `${item.activityId}`;
    }
}

export function getCostCodeDisplayExpr(item) {
    return item && `${item.costCodeAlias} - ${item.description}`;
}

export function ActivityItem({
    activityId,
    description,
    clientName,
    siteName,
    ...props
}) {
    const type = props.workOrderNumber ? 'Service Call' : props.jobNumber ? 'Job' : 'Activity';
    const activityName = type === 'Activity' ? `${activityId}` : `${activityId} - ${description}`;
    return (
        <div className="activity-parent">
            <div className="activity-label">{`${type}:`}</div>
            <div className="activity-name">{activityName}</div>
            { type !== 'Activity' ?
                <>
                    <div className="activity-customer-label">Customer:</div>
                    <div className="activity-customer-name">{clientName}</div>
                    <div className="activity-site-label">Location:</div>
                    <div className="activity-site-name">{siteName}</div>
                </>
                : null}
        </div>
    );
}

export function CostCodeItem({
    costCodeAlias,
    description
}) {
    return (<div className="costcode-parent">
        <div className="activity-label">{costCodeAlias}</div>
        <div className="activity-name">{description}</div>
    </div>);
}

export function PayCodeItem({
    paycodeName,
    paycodeDescription
}) {
    return (<div className="costcode-parent">
        <div className="activity-label">{paycodeName}</div>
        <div className="activity-name">{paycodeDescription}</div>
    </div>);
}

export function ShiftCodeItem({
    name,
    description
}) {
    return (<div className="costcode-parent">
        <div className="activity-label">{name}</div>
        <div className="activity-name">{description}</div>
    </div>);
}

export const ShowLegendDetails = t => () => {
    return (
        <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
            <div className="status-legend-container">
                <div className="status-legend-processed">
                    <Icon path={mdiCheckboxMarkedCircleOutline} size={1} color="green" />
                </div>
                <div className="status-legend-processed-text">{t('statuses.processed')}</div>
                <div className="status-legend-error">
                    <Icon path={mdiInformationOutline} size={1} color="red" />
                </div>
                <div className="status-legend-error-text">{t('statuses.error')}</div>
                <div className="status-legend-submitted">
                    <Icon path={mdiLockOutline} size={1} color="#4588bf" />
                </div>
                <div className="status-legend-submitted-text">{t('statuses.submitted')}</div>
                <div className="status-legend-open">
                    <Icon path={mdiLockOpenOutline} size={1} color="#4588bf" />
                </div>
                <div className="status-legend-open-text">{t('statuses.open')}</div>
            </div>
        </div>
    );
};


export const renderPayCodeGroupHeader = t => data => {
    return data.key === 1 ? <div>{t('time.regularPayCodes')}</div> : <div>{t('time.otherPayCodes')}</div>;
};
