import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { getDayOfWeekNumber } from '../../helpers/dayHelpers';
import { addDays, getDay, startOfDay, isAfter, isBefore, isEqual, format } from 'date-fns';
import { isEmpty } from 'lodash';
import { usePrevious} from '../../hooks/usePrevious';
import * as TimeActions from '../../actions/timeActions';

import './PeriodSelector.scss';

const PeriodSelector = ({
    activeCompany,
    activeEmployee,
    clearEmployeeTimesheet,
    currentUser,
    currentWeekEndingDate,
    defaultWeekEndingDay,
    featureFlags,
    isLoadingTimesheet,
    setCurrentWeekEndingDate,
    setPeriodDays,
    setTimesheetStartDate,
    timesheetGridInstance,
    timesheetStartDate,
    ...props
}) => {
    const { t, i18n } = useTranslation();
    const [minDate, setMinDate] = useState(addDays(new Date(), -16));
    const [maxDate, setMaxDate] = useState(addDays(new Date(), 16));
    const [disabledDates, setDisabledDates] = useState([]);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);
    const [useTimeEntryForm, setUseTimeEntryForm] = useState(false);
    const previousWeekendingDate = usePrevious(currentWeekEndingDate);

    const pickerRef = useRef();

    useEffect(() => {
        const init = async () => {
            const featureFlag = featureFlags.find(ff => ff.name === 'useTimeEntryForm');
            if (featureFlag) {
                await setUseTimeEntryForm(featureFlag.featureFl);
            }
        };
        init();
    }, [featureFlags]);

    useEffect(() => {
        const init = async () => {
            if (!isEmpty(defaultWeekEndingDay)) {
                const results = getMaxDate();
                const periodEndingDayOfWeek = getDayOfWeekNumber(defaultWeekEndingDay);
                if (getDay(results.maxDate) === periodEndingDayOfWeek) {
                    const startDate = format(addDays(results.maxDate, -6), 'yyyy-MM-dd');
                    setIsNextDisabled(true);
                    if (pickerRef.current) {
                        pickerRef.current.instance.option('value', results.maxDate);
                    }
                    await setTimesheetStartDate(startDate);
                    if (!currentWeekEndingDate) {
                        await setCurrentWeekEndingDate(results.maxDate);
                    }
                }
                setMinDate(startOfDay(results.minDate));
                setMaxDate(startOfDay(results.maxDate));
                getDisabledDates(results.minDate, results.maxDate, periodEndingDayOfWeek);
                setIsPreviousDisabled(activeEmployee.id === null);
                setIsNextDisabled(activeEmployee.id === null);
            }};
        init();
    }, [defaultWeekEndingDay]);

    useEffect(() => {
        const init = async () => {
            clearEmployeeTimesheet();
            const isWeekEndingDate = isEqual(currentWeekEndingDate, maxDate);
            setIsPreviousDisabled(!isWeekEndingDate || activeEmployee.id === null);
            setIsNextDisabled(isWeekEndingDate || activeEmployee.id === null);
            await setPeriodDays(currentWeekEndingDate);

        };

        if (currentWeekEndingDate && !isEqual(previousWeekendingDate, currentWeekEndingDate)) init();
    }, [currentWeekEndingDate]);

    useEffect(() => {
        if (isLoadingTimesheet) {
            setIsPreviousDisabled(true);
            setIsNextDisabled(true);
        }
        else {
            const isWeekEndingDate = isEqual(currentWeekEndingDate, maxDate);
            setIsPreviousDisabled(!isWeekEndingDate);
            setIsNextDisabled(isWeekEndingDate);
        }
    }, [isLoadingTimesheet]);

    const formatPeriodDisplay = (e) => {
        if (currentWeekEndingDate) {
            const locale = i18n.resolvedLanguage;

            const periodStartDate = addDays(currentWeekEndingDate, -6).toLocaleString(locale, {
                month: '2-digit',
                day:   'numeric',
                year:  'numeric'
            });

            const periodEndDate = e.toLocaleString(locale, {
                month: '2-digit',
                day:   'numeric',
                year:  'numeric'
            });

            const formattedDisplay = `${periodStartDate} - ${periodEndDate}`;
            return formattedDisplay;
        }
        else {
            return e;
        }
    };

    const getMaxDate = () => {
        const currentDayOfWeek = getDay(new Date());
        const periodEndingDayOfWeek = getDayOfWeekNumber(defaultWeekEndingDay);

        let dateDiff = periodEndingDayOfWeek - currentDayOfWeek;
        if (dateDiff < 0) {
            dateDiff += 7;
        }

        const maxDate = startOfDay(addDays(new Date(), dateDiff));
        const minDate = addDays(maxDate, -13);

        return {
            minDate: minDate,
            maxDate: maxDate
        };
    };

    const getDisabledDates = (minDate, maxDate, periodEndingDayOfWeek) => {
        const dates = [];
        for (let currentDate = minDate; currentDate < maxDate; currentDate = addDays(currentDate, 1)) {
            if (getDay(currentDate) !== periodEndingDayOfWeek) {
                dates.push(currentDate);
            }
        }
        setDisabledDates(dates);
    };

    const onValueChanged = e => {
        setCurrentWeekEndingDate(e.value);
    };

    const onClick = e => {
        if (pickerRef.current) {
            pickerRef.current.instance.open();
        }
    };

    const previousButtonClick = async e => {
        const newWeekendingDate = addDays(currentWeekEndingDate, -7);
        if (isAfter(newWeekendingDate, minDate) || isEqual(newWeekendingDate, minDate)) {
            const newTimesheetStartDate = addDays(newWeekendingDate, -6);
            await setTimesheetStartDate(format(newTimesheetStartDate, 'yyyy-MM-dd'));
            await setCurrentWeekEndingDate(newWeekendingDate);

            if (useTimeEntryForm && timesheetGridInstance && timesheetGridInstance.current?.instance)
            {
                timesheetGridInstance.current.instance.refresh();
            }
        }
    };

    const nextButtonClick = async e => {
        const newWeekendingDate = addDays(currentWeekEndingDate, 7);
        if (isBefore(newWeekendingDate, maxDate) || isEqual(newWeekendingDate, maxDate)) {
            const newTimesheetStartDate = addDays(newWeekendingDate, -6);
            await setTimesheetStartDate(format(newTimesheetStartDate, 'yyyy-MM-dd'));
            await setCurrentWeekEndingDate(newWeekendingDate);

            if (useTimeEntryForm && timesheetGridInstance && timesheetGridInstance.current?.instance)
            {
                timesheetGridInstance.current.instance.refresh();
            }
        }
    };

    const openCalendarOptions = {
        stylingMode:       'text',
        onClick:           onClick,
        icon:              'fa-solid fa-calendar-week',
        disabled:          activeEmployee.id === null,
        hoverStateEnabled: false,
        hint:              t('time.openCalendar')
    };

    const previousButtonOptions = {
        stylingMode:       'text',
        icon:              'fa-solid fa-chevron-left',
        onClick:           previousButtonClick,
        disabled:          isPreviousDisabled,
        hoverStateEnabled: false,
        hint:              t('time.previousPeriod')
    };

    const nextButtonOptions = {
        stylingMode:       'text',
        icon:              'fa-solid fa-chevron-right',
        onClick:           nextButtonClick,
        disabled:          isNextDisabled,
        hoverStateEnabled: false,
        hint:              t('time.nextPeriod')
    };

    return (
        <div>
            <DateBox
                ref={pickerRef}
                type={'date'}
                min={minDate}
                max={maxDate}
                label={useTimeEntryForm ? null : 'Period'}
                value={currentWeekEndingDate}
                disabledDates={disabledDates}
                onValueChanged={onValueChanged}
                stylingMode={'outlined'}
                width={275}
                displayFormat={formatPeriodDisplay}
                pickerType="calendar"
                readOnly={true}
            >
                <DateBoxButton
                    name="openCalendar"
                    location="before"
                    options={openCalendarOptions}
                />
                <DateBoxButton
                    name="prevPeriod"
                    location="after"
                    options={previousButtonOptions}
                />
                <DateBoxButton
                    name="nextPeriod"
                    location="after"
                    options={nextButtonOptions}
                />
            </DateBox>
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeCompany:         state.time.activeCompany,
    activeEmployee:        state.employee.activeEmployee,
    currentUser:           state.currentUser,
    currentWeekEndingDate: state.time.currentWeekEndingDate,
    defaultWeekEndingDay:  state.time.settings.defaultWeekEndingDay,
    featureFlags:          state.core.featureFlags,
    isLoadingTimesheet:    state.time.isLoadingTimesheet,
    timesheetStartDate:    state.time.timesheetStartDate
});
const mapDispatchToProps = {
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSelector);
