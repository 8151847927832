import * as ActionTypes from './actionTypes';
import template from 'url-template';
import _ from 'lodash';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';

import showToastMessage from '../helpers/toastHelper';

const companyUserDetailUrl = template.parse('/users/{userId}');

export const fetchCompanyUser = (userId, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.get(
            companyUserDetailUrl.expand({
                userId
            }),
            header
        );

        let data = _.get(response, 'data', {});
        data = camelCaseKeys(data);

        dispatch({
            type:    ActionTypes.FETCH_COMPANY_USER,
            payload: data
        });

        return data;

    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.FETCH_COMPANY_USER,
            payload: []
        });
    }
};

export const setIsTimeApplicationUser = payload => async (dispatch, getState) => {
    dispatch({
        type: ActionTypes.SET_IS_TIME_APPLICATION_USER,
        payload
    });
};

const camelCaseKeys = (obj) => {
    if (!_.isObject(obj)) {
        return obj;
    } else if (_.isArray(obj)) {
        return obj.map((v) => camelCaseKeys(v));
    }
    return _.reduce(obj, (r, v, k) => {
        return {
            ...r,
            [_.camelCase(k)]: camelCaseKeys(v)
        };
    }, {});
};
