import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import TimeManagerApproval from '../../components/time-manager-approval/TimeManagerApproval';
import TimeManagerMobileApprovalList from '../../components/time-manager-mobile-approval/TimeManagerMobileApprovalList';

import devices from 'devextreme/core/devices';

import './TimeApproval.scss';

const TimeApproval = ({
    activeCompany
}) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setSearchParams({companyId: activeCompany.id});
    }, [activeCompany.id]);

    return (
        <>
            { !devices.current().ios && !devices.current().android ?
                <div className={!devices.current().ios && !devices.current().android ? 'content-block' : 'mobile-list-block'}>
                    <TimeManagerApproval />
                </div>
                :
                <div className="mobile-list-block">
                    <TimeManagerMobileApprovalList />
                </div>
            }
        </>
    );
};

const mapStateToProps = state => ({
    activeCompany:  state.time.activeCompany,
    activeEmployee: state.employee.activeEmployee,
    companies:      state.core.companies,
    companyInfo:    state.companyInfo,
    currentUser:    state.currentUser,
    featureFlags:   state.core.featureFlags,
});

export default connect(mapStateToProps, null)(TimeApproval);
