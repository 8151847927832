
export const navigation = [
    {
        text:  'menu.time',
        icon:  'clock',
        path:  '',
        items: [
            {
                text:       'menu.timeEntry',
                path:       '/timeentry',
                permission: 'isTimeUser'
            },
            {
                text:       'menu.managerApproval',
                path:       '/timeapproval',
                permission: 'isManager,isTimeAdmin,isPayrollAdmin'
            },
            // {
            //     text:       'Payroll Approval',
            //     path:       '/payroll',
            //     permission: 'isPayrollAdmin'
            // }
        ],
        permission: 'isTimeUser'
    },
    {
        text:  'menu.reports',
        icon:  'description',
        path:  '',
        items: [
            {
                text:       'menu.licensingReport',
                path:       'administration/licensingreport',
                permission: 'isSuperUser'
            }
        ],
        permission: 'isSuperUser'
    },
    {
        text:  'menu.userProfile',
        icon:  'user',
        path:  '',
        items: [
            {
                text:       'menu.userProfile',
                path:       'userprofile',
                permission: 'isTimeUser'
            }
        ],
        permission: 'isTimeUser'
    },
    {
        text:  'menu.administration',
        icon:  'preferences',
        path:  '',
        items: [
            {
                text:       'menu.settings',
                path:       '/administration/companysettings',
                permission: 'isTimeAdmin'
            },
            {
                text:       'menu.approverSetup',
                path:       '/administration/approversetup',
                permission: 'isTimeAdmin'
            },
            {
                text:       'menu.licensing',
                path:       '/administration/licensing',
                permission: 'isTimeAdmin'
            }
        ],
        permission: 'isTimeAdmin'
    }
];
