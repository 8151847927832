import template from 'url-template';
import { get, orderBy } from 'lodash';
import showToastMessage from '../helpers/toastHelper';
import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';
import { ConcurrencyManager } from 'axios-concurrency';

import * as ActionTypes from './actionTypes';

const fetchAnnotationUrl = template.parse('/annotation{?objectId,annotationType,page,limit,sort,searchFilter');
const fetchAnnotationByIdUrl = template.parse('/annotation/{annotationid}');
const createUpdateAnnotationUrl = template.parse('/companies/{companyId}/annotation');
const deleteAnnotationUrl = template.parse('/companies/{companyId}/annotation/{id}');

const DEFAULT_PAGE_LIMIT = 200;
const MAX_CONCURRENCY_REQUESTS = 10;

export const createAnnotation = (companyId, body, t) => async (dispatch, getState) => {
    const header = await awsHeader();

    const useTimeEntryForm = getState().core.featureFlags.find(ff => ff.name === 'useTimeEntryForm');

    const formData = new FormData();
    formData.append('AnnotationType', body.annotationType);
    formData.append('IsAttachment', body.isAttachment);
    formData.append('Attachment', body.attachment);
    formData.append('Subject', body.subject);
    formData.append('IsVisible', true);
    formData.append('NoteText', body.noteText);
    formData.append('ObjectId', body.objectId);

    try {
        const response = await wennsoftBaseApi.post(
            createUpdateAnnotationUrl.expand({
                companyId
            }),
            formData,
            header
        );

        const status = get(response, 'status');
        const newId = get(response, 'data');

        if (status === 200 || status === 201 || status === 204) {
            const message = t('time.annotationSaved', {annotationType: body.isAttachment ? 'Attachment' : 'Note'});
            showToastMessage(message, 'success');
            if (!useTimeEntryForm.featureFl) {
                return true;
            }
            else {
                return newId;
            }
        }
        else {
            const message = t('time.annotationSaveFailure', {annotationType: body.isAttachment ? 'Attachment' : 'Note', status: status});
            showToastMessage(message, 'warning');
            return false;
        }

    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }

};

export const updateAnnotation = (companyId, body, t) => async (dispatch, getState) => {
    const header = await awsHeader();

    const formData = new FormData();
    formData.append('Id', body.id);
    formData.append('AnnotationType', body.annotationType);
    formData.append('IsAttachment', body.isAttachment);
    formData.append('Subject', body.subject);
    formData.append('IsVisible', true);
    formData.append('NoteText', body.noteText);

    try {
        const response = await wennsoftBaseApi.put(
            createUpdateAnnotationUrl.expand({
                companyId
            }),
            formData,
            header
        );

        const status = get(response, 'status');
        if (status === 200 || status === 204) {
            const message = t('time.annotationSaved', {annotationType: body.isAttachment ? 'Attachment' : 'Note'});
            showToastMessage(message, 'success');
            return true;
        }
        else {
            const message = t('time.annotationSaveFailure', {annotationType: body.isAttachment ? 'Attachment' : 'Note', status: status});
            showToastMessage(message, 'warning');
            return false;
        }

    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};

export const deleteAnnotation = (companyId, id, isAttachment, t) => async (dispatch, getState) => {
    const header = await awsHeader();
    try {
        const response = await wennsoftBaseApi.delete(
            deleteAnnotationUrl.expand({
                companyId,
                id
            }),
            header
        );

        const status = get(response, 'status');
        if (status === 200 || status === 201 || status === 204) {
            const message = t('time.annotationDelete', {annotationType: isAttachment ? 'Attachment' : 'Note'});
            showToastMessage(message, 'success');
            return true;
        }
        else {
            const message = t('time.annotationDeleteFailure', {annotationType: isAttachment ? 'Attachment' : 'Note', status: status});
            showToastMessage(message, 'warning');
            return false;
        }
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }

};

export const fetchAnnotations = (objectId, annotationType, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    const manager = ConcurrencyManager(wennsoftBaseApi, MAX_CONCURRENCY_REQUESTS);

    try {
        options.limit || (options.limit = DEFAULT_PAGE_LIMIT);
        options.page || (options.page = 1);
        let allAnnotations = [];

        const response = wennsoftBaseApi.get(
            fetchAnnotationUrl.expand({
                objectId,
                annotationType,
                ...options
            }),
            header
        );

        const data = get(response, 'data.data', []);
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i < count; i++) {
            options.page = i;
            promises.push(
                wennsoftBaseApi.get(
                    fetchAnnotationUrl.expand({
                        objectId,
                        annotationType,
                        ...options
                    }),
                    header
                )
            );
        }

        let results = [];
        await Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => result.push(r.data.data));
            });

        allAnnotations = data.concat(...results);

        dispatch({
            type:    ActionTypes.FETCH_ANNOTATIONS,
            payload: orderBy(allAnnotations, 'lastModifiedTimestamp', 'asc')
        });

    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.FETCH_ANNOTATIONS,
            payload: []
        });
    }
    finally {
        manager.detach();
    }
};

export const fetchAnnotationById = (annotationid) => async (disptach, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.get(
            fetchAnnotationByIdUrl.expand({
                annotationid
            }),
            header
        );

        const annotation = get(response, 'data');

        return annotation;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return {};
    }
};
