import * as ActionTypes from './actionTypes';
import template from 'url-template';
import { get, orderBy } from 'lodash';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';

import showToastMessage from '../helpers/toastHelper';

const unbilledActivityUrl = template.parse('/technician-activities');

export const fetchUnbilledActivities = () => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.get(
            unbilledActivityUrl.expand(
            ),
            header
        );

        const payload = get(response, 'data', []) || [];
        payload.forEach(p => p.activityId = p.name);

        dispatch({
            type:    ActionTypes.SET_UNBILLED_ACTIVITIES,
            payload: orderBy(payload, 'name', 'asc')
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_UNBILLED_ACTIVITIES,
            payload: []
        });
    }
};
