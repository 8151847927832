/* eslint-disable import/no-anonymous-default-export */
import { getInitialAuthState } from '../helpers/auth';
import * as ActionTypes from '../actions/actionTypes';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    instanceId: uuidv4(),
    session:    null,
    signedIn:   null,
    ...getInitialAuthState()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.IS_SIGNED_IN:
            return {
                ...state,
                session:  action.payload.session,
                signedIn: true
            };
        case ActionTypes.IS_SIGNED_OUT:
            return {
                ...state,
                session:  null,
                signedIn: false
            };
        default:
            return state;
    }
};
