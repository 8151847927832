
import './FormPopup.scss';
import React, { useCallback, useRef } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ValidationGroup from 'devextreme-react/validation-group';
import { useScreenSize } from '../../../utils/media-query';
import Button from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';
import { custom } from 'devextreme/ui/dialog';
import showToastMessage from '../../../helpers/toastHelper';
import { useTranslation } from 'react-i18next';

const mobileCancelSelectorAttributes = {
    class: 'mobile-cancel-selector'
};

const mobileSaveSelectorAttributes = {
    class: 'mobile-cancel-selector'
};

const dropDownButtonElementAttr = {
    class: 'form-save-selector-dropdown'
};

const mobileDropDownButtonElementAttr = {
    class: 'mobile-save-selector-dropdown'
};

export const FormPopup = ({
    cancelButtonText = 'Cancel',
    closeOnSave = false,
    height = '80vh',
    hideMultiSaveSaveButton = false,
    isFormDirtyStateRef,
    isReadOnly = false,
    resetEditList,
    onSave,
    onResetForm,
    saveButtonIcon = 'save',
    saveButtonText = 'Save',
    setIsResetForm,
    setVisible = false,
    showCancelButton = true,
    showSaveButton = true,
    showMultiSaveButton = false,
    title,
    titleRender,
    validationRequired = true,
    visible,
    width = 480,
    wrapperAttr = { class: '' },
    children
}) => {
    const { t } = useTranslation();
    const {isXSmall, isSmall } = useScreenSize();
    const validationGroup = useRef(null);

    const dropdownSaveItems = [
        {
            id:     1,
            action: t('common.save')
        },
        {
            id:     2,
            action: t('common.saveAndNew')
        },
        {
            id:     3,
            action: t('common.saveAndClose')
        }
    ];

    const close = () => {

        setIsResetForm && setIsResetForm(false);
        validationGroup.current?.instance.reset();
        setVisible(false);
    };

    const clearForm = (transactionType, transactionDate) => {
        validationGroup.current?.instance.reset();
        onResetForm && onResetForm(transactionType, transactionDate);
    };

    const showValidationErrors = errors => {
        errors.forEach(err => showToastMessage(err.message, 'error'));
    };

    const onCancelClick = useCallback(() => {
        if (isFormDirtyStateRef && isFormDirtyStateRef.current) {
            const customDialog = custom({
                title:       t('time.discardChanges'),
                messageHtml: t('time.discardChangesConfirmation'),
                buttons:     [{
                    text:        t('common.yes'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return true;
                    }
                },
                {
                    text:        t('common.no'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return false;
                    }
                }]
            });

            customDialog.show()
                .then(dialogResult => {
                    if (dialogResult) {
                        resetEditList && resetEditList();
                        close();
                    }
                });
        }
        else {
            resetEditList && resetEditList();
            close();
        }

    },[close]);

    const onHiding = useCallback(e => {
        const promptForClose = async () => {
            const customDialog = custom({
                title:       t('time.discardChanges'),
                messageHtml: t('time.discardChangesConfirmation'),
                buttons:     [{
                    text:        t('common.yes'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return true;
                    }
                },
                {
                    text:        t('common.no'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return false;
                    }
                }]
            });

            const result = await customDialog.show()
                .then(dialogResult => {
                    if (dialogResult) {
                        resetEditList && resetEditList();
                        close();
                        return false;
                    }
                    else {
                        return true;
                    }
                });
            return result;
        };

        if (isFormDirtyStateRef && isFormDirtyStateRef.current) {
            e.cancel = promptForClose();
        }
    }, [close, isFormDirtyStateRef]);

    const onSaveClick = useCallback(async () => {
        if (!validationRequired) {
            let saved = false;
            if (onSave) {
                saved = await onSave();
            }
            if (closeOnSave && saved) {
                close();
            }
        }
        else {
            let saved = false;
            const validationResult = validationGroup.current?.instance.validate();
            if (!validationResult.isValid) {
                showValidationErrors(validationResult.brokenRules);
                return;
            }
            setIsResetForm && setIsResetForm(false);
            if (onSave) {
                saved = await onSave();
            }
            if (closeOnSave && saved) {
                close();
            }
        }
    }, [isFormDirtyStateRef, validationGroup]);

    const onSaveAndCloseClick = useCallback(async () => {
        let saved = true;
        const validationResult = validationGroup.current?.instance.validate();
        if (!validationResult.isValid) {
            showValidationErrors(validationResult.brokenRules);
            return;
        }
        setIsResetForm && setIsResetForm(false);
        if (onSave) {
            saved = await onSave();
        }
        if (saved) {
            close();
        }
    }, [validationGroup]);

    const onSaveAndNewClick = useCallback(async () => {
        if (isFormDirtyStateRef.current) {
            let saved = true;
            const validationResult = validationGroup.current?.instance.validate();
            if (!validationResult.isValid) {
                showValidationErrors(validationResult.brokenRules);
                return;
            }

            const { data } = children.props;
            setIsResetForm && setIsResetForm(true);
            if (onSave) {
                saved = await onSave();
            }
            // clear the form
            if (saved) {
                clearForm(data?.transactionType, data?.transactionDate);
            }
        }
        else {
            const { data } = children.props;
            setIsResetForm && setIsResetForm(true);
            clearForm(data?.transactionType, data?.transactionDate);
        }
    }, [validationGroup]);

    const onHidden = useCallback(e => {
        close();
    }, [close]);

    const handleDropDownItemClick = useCallback((e) => {
        const { itemData } = e;
        switch (itemData.id) {
            case 1:
                // Save the entry and leave it open
                onSaveClick();
                break;
            case 2:
                // Save the entry and clear the fields for new entry
                onSaveAndNewClick();
                break;
            case 3:
                // Save the entry and close the form
                onSaveAndCloseClick();
                break;
            default:
                break;
        }
    }, []);

    const getMulitSaveItems = () => {
        if (!hideMultiSaveSaveButton) {
            return dropdownSaveItems;
        }
        else {
            return dropdownSaveItems.filter(item => item.id !== 1);
        }
    };

    return (
        <Popup
            title={title}
            height={height}
            visible={visible}
            fullScreen={isXSmall ||isSmall}
            width={width}
            wrapperAttr={{...wrapperAttr, class: `${wrapperAttr?.class} form-popup`}}
            showCloseButton={true}
            onHiding={onHiding}
            onHidden={onHidden}
            titleRender={titleRender}
        >
            <ToolbarItem
                toolbar="bottom"
                location="center"
            >
                <div className={`form-popup-buttons-container ${width <= 360 ? 'flex-buttons' : ''}`}>
                    <Button
                        text={cancelButtonText}
                        stylingMode="contained"
                        onClick={onCancelClick}
                        elementAttr={isXSmall || isSmall ? mobileCancelSelectorAttributes : null}
                        visible={showCancelButton}
                    />
                    {!showMultiSaveButton ?
                        <Button
                            text={saveButtonText}
                            stylingMode="contained"
                            icon={saveButtonIcon}
                            type="default"
                            onClick={onSaveClick}
                            visible={showSaveButton && !isReadOnly}
                            elementAttr={isXSmall || isSmall ? mobileSaveSelectorAttributes : null}
                        />
                        :
                        <>
                            <DropDownButton
                                displayExpr="action"
                                elementAttr={isXSmall || isSmall ? mobileDropDownButtonElementAttr : dropDownButtonElementAttr}
                                icon="save"
                                items={getMulitSaveItems()}
                                keyExpr="id"
                                onItemClick={handleDropDownItemClick}
                                split={false}
                                text={t('common.saveOptions')}
                                useSelectMode={false}
                                visible={!isReadOnly}
                            />

                        </>
                    }
                </div>
            </ToolbarItem>
            <ScrollView width="100%" height="100%">
                <ValidationGroup ref={validationGroup}>
                    {children}
                </ValidationGroup>
            </ScrollView>
        </Popup>
    );
};
