import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as EmployeeActions from '../../actions/employeeActions';
import SelectBox from 'devextreme-react/select-box';

import './ManagerSetup.scss';
import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import TextBox from 'devextreme-react/text-box';

const managerStatuses = ['Available', 'Assigned'];

function removeItem(array, removeIdx) {
    return array.filter((_, idx) => idx !== removeIdx);
}

function insertItem(array, item, insertIdx) {
    const newArray = [...array];
    newArray.splice(insertIdx, 0, item);
    return newArray;
}

function reorderItem(array, fromIdx, toIdx) {
    const item = array[fromIdx];
    const result = removeItem(array, fromIdx);
    return insertItem(result, item, toIdx);
}

const ManagerCard = ({
    item,
    employee,
    employeeList
}) => {
    const { t } = useTranslation();
    const manager = employeeList.find(emp => emp.id === item.managerId);
    const statusClass = employee.managerId === item.id ? 'signature-manager' : item.status.toLowerCase();
    return (
        <div className={`manager-card${statusClass !== 'signature-manager' ? '-movable' : ''} dx-card dx-theme-text-color dx-theme-background-color`}>
            <div className={`manager-card-status status-${statusClass}`} />
            <div className="manager-card-employee">{item.fullName}</div>
            <div className="manager-card-manager">{manager ? t('time.managerDisplay', {managerName: manager.fullName}) : ''}</div>
        </div>
    );
};

const ManagerList = ({
    title,
    index,
    employee,
    managers,
    employeeList,
    onManagerDrop,
    onDragStart,
}) => {
    const { t } = useTranslation();
    const [filteredManagers, setFilteredManagers] = useState(managers);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const originalList = cloneDeep(managers);
        const filtered = originalList.filter(mgr => mgr.fullName.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredManagers(filtered);
    }, [searchValue]);

    useEffect(() => {
        setFilteredManagers(managers);
    }, [managers]);

    const onValueChanged = useCallback(e => {
        setSearchValue(e.value);
    }, []);

    return (
        <div className="manager-list">
            <div className="manager-list-title dx-theme-text-color">{t('time.approvalListHeader', {type: title === 'Assigned' ? t('time.assigned') : t('time.available')})}</div>
            <div className="manager-list-search">
                <TextBox
                    onValueChanged={onValueChanged}
                    placeholder={`${t('time.searchApprovers')}...`}
                    showClearButton={true}
                    stylingMode="outlined"
                    value={searchValue}
                    valueChangeEvent="keyup"
                />
            </div>
            <ScrollView
                className="manager-scrollable-list"
                direction="vertical"
                showScrollbar="always"
            >
                <Sortable
                    className="manager-sortable-cards"
                    employee={employee}
                    group="cardsGroup"
                    data={index}
                    onAdd={onManagerDrop}
                    onDragStart={onDragStart}
                >
                    {
                        filteredManagers.map(mgr =>
                            <ManagerCard
                                key={mgr.id}
                                item={mgr}
                                employee={employee}
                                employeeList={employeeList}
                            />
                        )
                    }
                </Sortable>
            </ScrollView>
        </div>
    );
};

const ManagerSetup = ({
    createEmployeeDelegate,
    deleteEmployeeDelegate,
    employeeDelegates,
    employeeList,
    getEmployeeDelegates,
}) => {
    const { t } = useTranslation();
    const [selectedEmployeeId, setSelectedEmployeeId] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [lists, setLists] = useState();
    const [statuses, setStatuses] = useState(managerStatuses);

    useEffect(() => {
        const init = async () => {
            await getEmployeeDelegates(t);
        };

        init();
    }, []);


    useEffect(() => {
        if (selectedEmployee) {
            // manager id list excludes the selected employee if they are a manager
            let managerIdList = [...new Set(employeeList.filter(emp => ((emp.isManager || emp.isPayrollAdmin || emp.isTimeAdmin) && emp.id !== selectedEmployee.id)).map(e => e.id))];
            const managers = employeeList.filter(({id}) => managerIdList.includes(id));
            if (employeeDelegates.length !== 0) {
                const assignedManagers = employeeDelegates.filter(del => del.employeeId === selectedEmployee.id);
                const masterManagerList = employeeList.filter(emp => assignedManagers.some(am => am.delegateEmployeeId === emp.id));

                managers.forEach(mgr => {
                    const idx = masterManagerList.findIndex(am => am.id === mgr.id);
                    if (idx < 0) {
                        mgr.status = 'Available';
                        mgr.sortIndex = 1;
                    }
                    else {
                        mgr.status = 'Assigned';
                        mgr.sortIndex = selectedEmployee.managerId === mgr.id ? 0 : 1;
                    }
                });

                const managerMap = managers.reduce((result, mgr) => {
                    if (result[mgr.status]) {
                        if (mgr.status === 'Available' && mgr.userId !== selectedEmployee.userId) {
                            result[mgr.status].push(mgr);
                        }
                        else if (mgr.status === 'Assigned') {
                            result[mgr.status].push(mgr);
                        }

                    }
                    else {
                        result[mgr.status] = [mgr];
                    }
                    return result;
                }, {});
                setLists(statuses.map((status) => orderBy(managerMap[status], ['sortIndex', 'fullName'], ['asc','asc'])));
            }
        }
    }, [employeeList, employeeDelegates, selectedEmployee]);

    const selectedEmployeeValueChange = useCallback(e => {
        setSelectedEmployeeId(e);
        const selectedEmp = employeeList.find(emp => emp.id === e);
        setSelectedEmployee(selectedEmp);
    }, []);


    const onDragStart = useCallback(e => {
        // check if the manager being removed is the signature manager and stop it if it is
        if (e.fromData === 1 || e.fromData === 'assignedManagers') {
            e.itemData = lists[e.fromData][e.fromIndex];
            if (selectedEmployee && selectedEmployee.managerId === e.itemData.id) {
                e.cancel = true;
            }
            else {
                e.cancel = false;
            }
        }
        else {
            e.itemData = lists[e.fromData][e.fromIndex];
            e.cancel = false;
        }
    }, [lists]);

    const onListReorder = useCallback(({fromIndex, toIndex}) => {
        setLists((state) => reorderItem(state, fromIndex, toIndex));
        setStatuses((state) => reorderItem(state, fromIndex, toIndex));
    }, []);

    const onManagerDrop = useCallback(async ({fromData, toData, fromIndex, toIndex}) => {
        const updatedLists = [...lists];
        const originalLists = cloneDeep(updatedLists);
        const item = updatedLists[fromData][fromIndex];
        item.status = toData === 1 ? 'Assigned' : 'Available';
        updatedLists[fromData] = removeItem(updatedLists[fromData], fromIndex);
        updatedLists[toData] = insertItem(updatedLists[toData], item, toIndex);
        setLists(updatedLists);
        if (fromData === 0 && fromData !== toData) {
            const createResult = await createEmployeeDelegate(selectedEmployee.id, item.id, t);
            if (createResult) {
                await getEmployeeDelegates(t);
            }
            else {
                setLists(originalLists);
            }
        }
        else if (fromData === 1 && fromData !== toData) {
            // remove the delegate
            const deleteResult = await deleteEmployeeDelegate(selectedEmployee.id, item.id, t);
            if (deleteResult) {
                await getEmployeeDelegates(t);
            }
            else {
                setLists(originalLists);
            }
        }
        else {
            item.status = toData === 1 ? 'Assigned' : 'Available';
            updatedLists[fromData] = removeItem(updatedLists[fromData], fromIndex);
            updatedLists[toData] = insertItem(updatedLists[toData], item, toIndex);
        }
    }, [lists, t]);

    return (
        <div className="content-block">
            <div className="employee-section">
                <div className="manager-setup-header">{t('time.approverSetup')}</div>
                <div className="manager-setup-employee-header">
                    {t('time.employee')}
                </div>
                <div className="manager-setup-employee-select">
                    <SelectBox
                        dataSource={employeeList}
                        displayExpr={'fullName'}
                        valueExpr={'id'}
                        value={selectedEmployeeId}
                        onValueChange={selectedEmployeeValueChange}
                        placeholder={t('time.selectEmployee')}
                    />
                </div>
            </div>
            <div id="manager-kanban">
                <ScrollView
                    className="manager-scrollable-board"
                    direction="horizontal"
                    showScrollbar="always"
                >
                    <Sortable
                        className="manager-scrollable-lists"
                        itemOrientation="horizontal"
                        handle=".list-title"
                        onReorder={onListReorder}
                    >
                        {
                            lists?.map((managers, managerIndex) => {
                                const status = statuses[managerIndex];
                                return (
                                    <ManagerList
                                        employee={selectedEmployee}
                                        key={status}
                                        title={status}
                                        index={managerIndex}
                                        managers={managers}
                                        employeeList={employeeList}
                                        onManagerDrop={onManagerDrop}
                                        onDragStart={onDragStart}
                                    />
                                );
                            })
                        }
                    </Sortable>
                </ScrollView>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        employeeDelegates: state.employee.employeeDelegates,
        employeeList:      state.employee.employeeList
    };
};

const mapDispatchToProps = {
    ...EmployeeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerSetup);
