import './ProfileCard.scss';
import React, { useEffect, useState, useRef } from 'react';
import { Form, EmptyItem, Item, Label, ValidationRule as ValidationRuleComponent } from 'devextreme-react/form';
import Switch from 'devextreme-react/switch';
import NumberBox from 'devextreme-react/number-box';
import devices from 'devextreme/core/devices';
import { useScreenSize, getSizeQualifier } from '../../utils/media-query';
// import { ValidationRule } from 'devextreme-react/common';
import DefaultPaycodeLookup from '../utils/default-paycode-lookup/DefaultPaycodeLookup';
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';
import AdminEmailAddressTemplate from '../admin-email-settings-template/admin-email-template';
import { cloneDeep } from 'lodash';
import { notificationDays } from '../../data/company-settings-service';
import { languages } from '../../data/languages';
import { useTranslation } from 'react-i18next';
import TagBox from 'devextreme-react/tag-box';

const ValidationStatuses = {
    valid:   'valid',
    invalid: 'invalid'
};

const tagBoxDropDownOptions = {
    maxHeight: 300
};

export const ProfileCard = ({
    cardData,
    colCount = 2,
    currentUser,
    departementLookups,
    employeeLaborPayCodes,
    jobDivisionsLookup,
    serviceDivisionsLookup,
    items = [],
    onDataChanged,
    title = '',
    wrapperCssClass,
    children,
}) => {
    const { t, i18n} = useTranslation();
    const { isXSmall } = useScreenSize();
    const [showAcknowledgementButton, setShowAcknowledgementButton] = useState(false);
    const [showApprovalTimeStamp, setShowApprovalTimeStamp] = useState(true);
    const [showEntryTimeStamp, setShowEntryTimeStamp] = useState(true);
    const [registrationCountValidationStatus, setRegistrationCountValidationStatus] = useState(ValidationStatuses.valid);
    const [registrationValidationStatusErrors, setRegistrationValidationStatusErrors] = useState();
    const [languageDataSource, setLanguageDataSource] = useState([]);
    const selectedLanguageFieldName= 'selectedLanguage';

    useEffect(() => {
        if (cardData && Object.hasOwn(cardData, 'useTimeInTimeOut')) {
            formRef.current?.instance.itemOption('lockTimeInTimeOut', 'disabled', !cardData?.useTimeInTimeOut);
        }
    }, [cardData?.useTimeInTimeOut]);

    useEffect(() => {
        if (cardData && cardData.useTransactionAcknowledgement) {
            setShowAcknowledgementButton(cardData.useTransactionAcknowledgement);
        }
        else {
            setShowAcknowledgementButton(false);
        }
    }, [cardData?.useTransactionAcknowledgement]);

    useEffect(() => {
        if (cardData && cardData.approvalNotifyDay)
            setShowApprovalTimeStamp(cardData.approvalNotifyDay !== 'Off');
    }, [cardData?.approvalNotifyDay]);

    useEffect(() => {
        if (cardData && cardData.timeEntryNotifyDay)
            setShowEntryTimeStamp(cardData.timeEntryNotifyDay !== 'Off');
    }, [cardData?.timeEntryNotifyDay]);

    const formRef = useRef(null);

    const onFieldChange = (fieldName) => (value) => {
        const isValid = formRef.current?.instance.validate().isValid;

        if (!isValid) {
            return;
        }

        switch (fieldName) {
            case selectedLanguageFieldName:
                if (value.event) {
                    cardData[fieldName] = value.value;
                }
                break;
            case 'approvalNotifyDay':
                if (value.event) {
                    setShowApprovalTimeStamp(value.value !== 'Off');
                    cardData[fieldName] = value.value;
                }
                break;
            case 'timeEntryNotifyDay':
                if (value.event) {
                    setShowEntryTimeStamp(value.value !== 'Off');
                    cardData[fieldName] = value.value;
                }
                break;
            case 'timeEntryNotifyTime':
            case 'approvalNotifyTime':
            case 'employeeDepartmentFilter':
            case 'jobDefaultDivisions':
            case 'serviceDefaultDivisions':
                if (value.event) {
                    cardData[fieldName] = value.value;
                }
                break;
            case 'useTransactionAcknowledgement':
                if (value.event) {
                    setShowAcknowledgementButton(value.value);
                    cardData[fieldName] = value.value;
                }
                break;
            case 'registeredTimeEmployeeCount':
                if (value.event && value.value < cardData['currentlyUsedTimeEmployeeCount']) {
                    setRegistrationCountValidationStatus(ValidationStatuses.invalid);
                    setRegistrationValidationStatusErrors([{ message: t('companySettings.invalidUserCountValue')}]);
                    return;
                }
                else if (value.event) {
                    setRegistrationCountValidationStatus(ValidationStatuses.valid);
                    setRegistrationValidationStatusErrors(null);
                    cardData[fieldName] = value.value;
                }
                break;
            default:
                if (fieldName === 'useTimeInTimeOut' && formRef.current) {
                    formRef.current.instance.itemOption('lockTimeInTimeOut', 'disabled', !value);
                }
                cardData[fieldName] = value;
                break;
        }

        onDataChanged({
            title,
            cardData
        });
    };
    const onFormFieldChange = e => onFieldChange(e.dataField)(e.value);

    const registeredTimeEmployeeCountHandler = e => {
        onFieldChange('registeredTimeEmployeeCount')(e);
    };

    useEffect(() => {
        if (title === t('userSettings.userSettings'))
        {
            const languageList = cloneDeep(languages);
            languageList.forEach(lang =>
                lang.display = t(`common.${lang.nativeName.toLowerCase()}`)
            );
            setLanguageDataSource(new DataSource({
                store: languageList,
                key:   'id'
            }));
        }
    }, [title]);

    return (
        <div className={wrapperCssClass}>
            <div className="profile-card-panel">
                <div className="title-text profile-card-panel-header">
                    {title}
                </div>
                <div className="form-container">
                    {children}
                    <Form
                        ref={formRef}
                        formData={cardData}
                        showColonAfterLabel
                        colCount={isXSmall ? 2 : colCount}
                        screenByWidth={getSizeQualifier}
                        labelLocation="top"
                        labelMode="outside"
                        onFieldDataChanged={onFormFieldChange}
                    >
                        {items.map((item, index) => (
                            item.dataField === 'emptySpace' ?
                                index % 2 !== 0 ? <EmptyItem key={index} colSpan={item.colSpan} /> : null
                                :
                                <Item
                                    key={index}
                                    dataField={item.dataField}
                                    editorType={item.editorType}
                                    editorOptions={{
                                        stylingMode:      'filled',
                                        valueChangeEvent: 'input',
                                        ...item.editorOptions
                                    }}
                                    colSpan={item.colSpan}
                                    visible={item.dataField !== 'transactionAcknowledgementMessage' || (item.dataField === 'transactionAcknowledgementMessage' && showAcknowledgementButton)}
                                >
                                    {
                                        item.label &&
                                        <Label
                                            location={devices.current().phone || item.label === 'companySettings.defaultWeekendingDay' ? 'top' : 'left'}
                                            visible={item.dataField !== 'adminEmailNotifications' || (item.dataField === 'transactionAcknowledgementMessage' && showAcknowledgementButton)}
                                            text={item.label}
                                        />
                                    }
                                    {
                                        (item.dataField === 'defaultPaycodeId' || item.dataField === 'defaultLaborPayCodeId') &&
                                        <DefaultPaycodeLookup
                                            dataSource={employeeLaborPayCodes}
                                            onValueChanged={onFieldChange(item.dataField)}
                                            value={cardData ? cardData[item.dataField] : null}
                                            valueExpression={item.dataField === 'defaultPaycodeId' ? 'id' : 'paycodeId'}
                                        />
                                    }
                                    {
                                        item.dataField === selectedLanguageFieldName &&
                                        <SelectBox
                                            defaultValue={cardData ? cardData[selectedLanguageFieldName] : 'en'}
                                            dataSource={languageDataSource}
                                            displayExpr="display"
                                            onValueChanged={onFieldChange(item.dataField)}
                                            value={cardData ? cardData[selectedLanguageFieldName] : 'en'}
                                            valueExpr="id"
                                            width={250}
                                        />
                                    }
                                    {
                                        item.dataField === 'employeeDepartmentFilter' &&
                                        <TagBox
                                            dataSource={departementLookups}
                                            defaultValue={cardData ? cardData['employeeDepartmentFilter'] : null}
                                            displayExpr="description"
                                            dropDownOptions={tagBoxDropDownOptions}
                                            onValueChanged={onFieldChange(item.dataField)}
                                            placeholder="Select Departments"
                                            searchEnabled={true}
                                            showDropDownButton={true}
                                            valueExpr="id"
                                            width={250}
                                        />
                                    }
                                    {
                                        (item.dataField === 'jobDefaultDivisions' || item.dataField === 'serviceDefaultDivisions') &&
                                        <TagBox
                                            dataSource={item.dataField === 'jobDefaultDivisions' ? jobDivisionsLookup : serviceDivisionsLookup}
                                            defaultValue={cardData ? cardData[item.dataField] : null}
                                            displayExpr="description"
                                            dropDownOptions={tagBoxDropDownOptions}
                                            onValueChanged={onFieldChange(item.dataField)}
                                            placeholder="Select Divisions"
                                            searchEnabled={true}
                                            showDropDownButton={true}
                                            valueExpr="id"
                                            width={250}
                                        />
                                    }
                                    {
                                        item.dataField === 'timeEntryNotifyDay' &&
                                        <>
                                            <div className="reminder-container">
                                                <div className="reminder-child">
                                                    <SelectBox
                                                        dataSource={notificationDays}
                                                        defaultValue={cardData ? cardData['timeEntryNotifyDay'] : null}
                                                        displayExpr={i18n.resolvedLanguage}
                                                        value={cardData ? cardData['timeEntryNotifyDay'] : null}
                                                        valueExpr={'id'}
                                                        width={120}
                                                        onValueChanged={onFieldChange(item.dataField)}
                                                    />
                                                </div>
                                                {showEntryTimeStamp &&
                                                <div className="reminder-child">
                                                    <DateBox
                                                        acceptCustomValue={false}
                                                        defaultValue={cardData ? cardData['timeEntryNotifyTime'] : null}
                                                        openOnFieldClick={true}
                                                        pickerType="list"
                                                        type="time"
                                                        value={cardData ? cardData['timeEntryNotifyTime'] : null}
                                                        width={120}
                                                        onValueChanged={onFieldChange('timeEntryNotifyTime')}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        item.dataField === 'approvalNotifyDay' &&
                                        <>
                                            <div className="reminder-container">
                                                <div className="reminder-child">
                                                    <SelectBox
                                                        dataSource={notificationDays}
                                                        defaultValue={cardData ? cardData['approvalNotifyDay'] : null}
                                                        displayExpr={i18n.resolvedLanguage}
                                                        value={cardData ? cardData['approvalNotifyDay'] : null}
                                                        valueExpr={'id'}
                                                        width={120}
                                                        onValueChanged={onFieldChange(item.dataField)}
                                                    />
                                                </div>
                                                {showApprovalTimeStamp &&
                                                <div className="reminder-child">
                                                    <DateBox
                                                        acceptCustomValue={false}
                                                        defaultValue={cardData ? cardData['approvalNotifyTime'] : null}
                                                        openOnFieldClick={true}
                                                        pickerType="list"
                                                        type="time"
                                                        value={cardData ? cardData['approvalNotifyTime'] : null}
                                                        width={120}
                                                        onValueChanged={onFieldChange('approvalNotifyTime')}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        item.dataField === 'adminEmailNotifications' &&
                                        <AdminEmailAddressTemplate
                                            emailAddresses={cardData ? cardData[item.dataField] : []}
                                            onDataChanged={onFieldChange(item.dataField)}
                                        />
                                    }
                                    {
                                        item.dataField === 'useTransactionAcknowledgement' &&
                                        <Switch
                                            defaultValue={cardData ? cardData['useTransactionAcknowledgement'] : false}
                                            value={cardData ? cardData['useTransactionAcknowledgement'] : false}
                                            onValueChanged={onFieldChange(item.dataField)}
                                        />
                                    }
                                    {
                                        item.dataField === 'registeredTimeEmployeeCount' &&
                                        <NumberBox
                                            onValueChanged={registeredTimeEmployeeCountHandler}
                                            readOnly={!currentUser.isSuperUser}
                                            showSpinButtons={true}
                                            validationStatus={registrationCountValidationStatus}
                                            validationErrors={registrationValidationStatusErrors}
                                            value={cardData ? cardData['registeredTimeEmployeeCount'] : null}
                                            valueChangeEvent={'change keyup blur focusout'}
                                            width={200}
                                        />
                                    }
                                    {
                                        item.validators?.map((rule, index) =>
                                            <ValidationRuleComponent
                                                key={index}
                                                type={rule.type}
                                            />)
                                    }
                                </Item>
                        ))}
                    </Form>
                </div>
            </div>
        </div>
    );
};
