import template from 'url-template';
import { get, orderBy } from 'lodash';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';

import * as ActionTypes from './actionTypes';
import showToastMessage from '../helpers/toastHelper';
import { handle422ErrorMessage } from '../helpers/errorHelper';

const MyCompaniesUrl = template.parse('/me/companies{?page,limit}');
const apiVersionUrl = template.parse('/public/apiversion');
const featureFlagUrl = template.parse('/featureflag/all/lookups');
const defaultCompanyUrl = template.parse('/me/settings');

const DEFAULT_PAGE_LIMIT = 200;

export const OsType = {
    iPhone:  'iPhone',
    iPad:    'iPad',
    Android: 'Android',
    Desktop: 'Desktop'
};

export const fetchCompanies = (options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    options.limit || (options.limit = DEFAULT_PAGE_LIMIT);
    options.page || (options.page = 1);

    try {
        const response = await wennsoftBaseApi.get(
            MyCompaniesUrl.expand({
                ...options
            }),
            header
        );
        const payload = get(response, 'data.data', []);

        const timeCompanies = payload.filter(c => {
            return c.applications.some(ca => ca.applicationName === 'Time');
        });

        const user = { ...getState().currentUser};
        user.isSuperUser = get(response, 'data.isSuperUser') || false;

        dispatch({
            type:    ActionTypes.SET_CURRENT_USER,
            payload: user
        });

        dispatch({
            type: ActionTypes.FETCH_COMPANIES,
            payload
        });

        const sortedTimeCompanies = orderBy(timeCompanies, 'company.name', 'asc');

        dispatch({
            type:    ActionTypes.FETCH_TIME_COMPANIES,
            payload: sortedTimeCompanies
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
    }
};

export const fetchApiVersion = (options ={}) => async(dispatch, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.get(
            apiVersionUrl.expand({
                ...options
            }),
            header
        );

        const payload = get(response, 'data','');

        if (payload) {
            let deployedBranch = payload.deployedBranch;

            if (deployedBranch === 'master') {
                deployedBranch = 'Development';
            }
            else {
                deployedBranch = deployedBranch.substring(deployedBranch.indexOf('/') + 1).replace('-', '.');
            }

            dispatch({
                type:    ActionTypes.FETCH_API_VERSION,
                payload: deployedBranch
            });
        }
    }
    catch (error) {
        showToastMessage(error, 'error');
    }
};

export const fetchFeatureFlags = (options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    try {
        const response = await wennsoftBaseApi.get(
            featureFlagUrl.expand({
                ...options
            }),
            header
        );

        const payload = get(response, 'data', []);

        dispatch({
            type: ActionTypes.SET_FEATURE_FLAGS,
            payload
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_FEATURE_FLAGS,
            payload: []
        });
    }
};

export const setBootstrapping = payload => ({
    type: ActionTypes.SET_BOOTSTRAPPING,
    payload
});

export const setCurrentOS = (options = {}) => async(dispatch, getState) => {
    let payload = {
        isMobileApp: true,
        currentOS:   OsType.Desktop
    };

    if (navigator.userAgent) {
        const agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf(OsType.iPhone.toLowerCase()) > -1) {
            payload.currentOS = OsType.iPhone;
        }
        else if (agent.indexOf(OsType.iPad.toLowerCase()) > -1) {
            payload.currentOS = OsType.iPad;
        }
        else if (agent.indexOf('linux') > -1 || agent.indexOf('android') > -1) {
            payload.currentOS = OsType.Android;
        }
        else {
            payload.isMobileApp = false;
        }
    }
    else {
        payload.isMobileApp = false;
    }

    dispatch({
        type: ActionTypes.SET_CURRENT_OS,
        payload
    });
};

export const updateDefaultCompany = (newDefaultCompanyId, t, options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    try {
        let body = {
            defaultCompany: {
                id: newDefaultCompanyId
            }
        };

        const result = await wennsoftBaseApi.put(
            defaultCompanyUrl.expand({
                ...options
            }),
            body,
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    showToastMessage(t('time.defaultCompanyChanged'), 'success');
                    return true;
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return false;
                }
            })
            .catch(error => {
                showToastMessage(error, 'error');
                return false;
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};
