import {
    FETCH_COMPANY_PAYCODES,
    FETCH_DEPARTMENTS,
    FETCH_JOB_DIVISIONS,
    FETCH_SERVICE_DIVISIONS,
    FETCH_TIME_SETTINGS,
    SET_ACTIVE_COMPANY,
    SET_CHANGE_PASSWORD_VISIBLE,
    SET_COMPANY_SHIFT_CODES,
    SET_CURRENT_WEEKENDING_DATE,
    SET_CURRENT_TIMESHEET,
    SET_DAYS_FOR_PERIOD,
    SET_IS_IN_EDIT_MODE,
    SET_IS_IN_REFRESH,
    SET_IS_LOADING_TIMESHEET,
    SET_IS_SAVE_VALID,
    SET_TIMESHEET_DATA,
    SET_TIMESHEET_GRID_INSTANCE,
    SET_TIMESHEET_START_DATE
} from '../actions/actionTypes';

const inititalState = {
    activeCompany: {
        id: null
    },
    changePasswordVisible: false,
    companyPayCodes:       [],
    currentWeekEndingDate: null,
    currentTimesheet:      {
        timesheetId:                 null,
        employeeId:                  null,
        // timesheetStartDate:          null,
        timeSheetFormattedStartDate: null,
        timeTransactions:            [],
        subTotals:                   []
    },
    departments:        [],
    isInEditMode:       false,
    isInRefresh:        false,
    isLoadingTimesheet: false,
    isSaveValid:        false,
    jobDivisions:       [],
    periodDays:         [],
    serviceDivisions:   [],
    settings:           {
        adminEmailNotifications: []
    },
    timesheetData:         [],
    timesheetGridInstance: null,
    timesheetStartDate:    null
};

export default (state = inititalState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_PAYCODES:
            return {
                ...state,
                companyPayCodes: [...action.payload]
            };
        case FETCH_DEPARTMENTS:
            return {
                ...state,
                departments: [...action.payload]
            };
        case FETCH_JOB_DIVISIONS:
            return {
                ...state,
                jobDivisions: [...action.payload]
            };
        case FETCH_SERVICE_DIVISIONS:
            return {
                ...state,
                serviceDivisions: [...action.payload]
            };
        case FETCH_TIME_SETTINGS:
            return {
                ...state,
                settings: {...action.payload}
            };
        case SET_ACTIVE_COMPANY:
            return {
                ...state,
                activeCompany: {...action.payload}
            };
        case SET_CHANGE_PASSWORD_VISIBLE:
            return {
                ...state,
                changePasswordVisible: action.payload
            };
        case SET_COMPANY_SHIFT_CODES:
            return {
                ...state,
                shiftCodes: action.payload
            };
        case SET_CURRENT_WEEKENDING_DATE:
            return {
                ...state,
                currentWeekEndingDate: action.payload
            };
        case SET_CURRENT_TIMESHEET:
            return {
                ...state,
                currentTimesheet: {...action.payload}
            };
        case SET_DAYS_FOR_PERIOD:
            return {
                ...state,
                periodDays: action.payload
            };
        case SET_IS_IN_EDIT_MODE:
            return {
                ...state,
                isInEditMode: action.payload
            };
        case SET_IS_IN_REFRESH:
            return {
                ...state,
                isInRefresh: action.payload
            };
        case SET_IS_LOADING_TIMESHEET:
            return {
                ...state,
                isLoadingTimesheet: action.payload
            };
        case SET_IS_SAVE_VALID:
            return {
                ...state,
                isSaveValid: action.payload
            };
        case SET_TIMESHEET_DATA:
            return {
                ...state,
                timesheetData: action.payload
            };
        case SET_TIMESHEET_GRID_INSTANCE:
            return {
                ...state,
                timesheetGridInstance: action.payload
            };
        case SET_TIMESHEET_START_DATE:
            return {
                ...state,
                timesheetStartDate: action.payload
            };
        default:
            return state;
    }
};
