import { uniq } from 'lodash';

export const DEFAULT_CACHE_TIME = 5 * 60 * 1000;

export const dropUuids = collection => {
    const uuidRegEx = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

    return collection.filter(word => !uuidRegEx.test(word));
};

export const flushCache = () => {
    const currentTimestamp = new Date();
    const rawTarget = sessionStorage.getItem('apiCacheFlushTarget');

    if (rawTarget) {
        const cacheTarget = JSON.parse(rawTarget);
        const { timestamp } = cacheTarget;

        const delta = Math.abs(Math.round((currentTimestamp.getTime() - parseInt(timestamp, 10)) / 1000 / 60));

        if (delta <= 5) {
            return true;
        }

        sessionStorage.removeItem('apiCacheFlushTarget');
    }

    return false;
};

// This method is responsible for determining the union between the incoming request
// and existing keys within the cache. We need to disregard UUIDs and empty spaces.

/*
    Example:
    baseApi: https://bob-api.key2act.work/core
    requestKeys: ["sites", "areas"]
    key: https://bob-api.key2act.work/core/areas/176f6a94-fcfe-417d-ac77-d0d98441dc42

    Generated Key Parts (cacheKeys): ["areas"]

    returned union: ["areas"]
*/

export const processKey = (baseApi, key, requestKeys) => {
    let rawKeyParts;
    if (key.includes(baseApi)) {
        rawKeyParts = key.split(baseApi)[1].split('?')[0].split('/');
    } else {
        rawKeyParts = key.split('?')[0].split('/');
    }

    const keyParts = dropUuids(rawKeyParts);

    let rawCacheKeys = [];
    keyParts.forEach(keyPart => {
        if (!keyPart.includes('-')) {
            rawCacheKeys.push(keyPart);
        }

        let keyWords = keyPart.split('-');
        rawCacheKeys = [...rawCacheKeys, ...keyWords];
    });
    let cacheKeys = uniq(rawCacheKeys).filter(key => key !== '');

    const union = [];

    cacheKeys.forEach(part => {
        if (requestKeys.includes(part) && part !== '') {
            union.push(part);
        }
    });

    return union;
};
