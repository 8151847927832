import React, { useState, useMemo, useEffect, useContext, useReducer } from 'react';

import DropDownBox, { DropDownOptions, Button as DropDownBoxButton } from 'devextreme-react/drop-down-box';
import DataGrid, { Column, Scrolling, Selection, Paging, FilterRow } from 'devextreme-react/data-grid';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { isArray, isEmpty } from 'lodash';
import { useScreenSize } from '../../utils/media-query';

import './lookups.scss';

const makeDataStore = async function (data) {
    let dataArray = [];
    if (!isArray(data)) {
        dataArray = await data.load();
    }
    return new ArrayStore({
        key:  'id',
        data: dataArray
    });
};

const dropDownDisplayExpr = function (item) {
    let displayValue = item && item.activityId;
    if (item && item.description) {
        displayValue += ` - ${item.description}`;
    }
    return (
        item && displayValue
    );
};

let searchTimer = null;

const initialState = {
    value:            [],
    focusedRowIndex:  0,
    focusedRowKey:    null,
    opened:           false,
    dataGridInstance: null,
    transactionType:  null,
    hint:             null
};

function reducer(state, action) {
    switch (action.type) {
        case 'all':
            return {
                ...state,
                opened: action.opened,
                value:  action.value
            };
        case 'open/close':
            return {
                ...state,
                opened: action.opened
            };
        case 'dataGridInstance':
            return {
                ...state,
                dataGridInstance: action.instance
            };
        case 'hint':
            return {
                ...state,
                hint: action.hint
            };
        case 'focusedRowKey':
            return {
                ...state,
                focusedRowIndex: action.focusedRowIndex,
                focusedRowKey:   action.focusedRowKey
            };
        case 'value':
            return {
                ...state,
                value: action.value
            };
        case 'transactionType':
            return {
                ...state,
                transactionType: action.transactionType
            };
        default:
            throw new Error('Non-Processed action: ', action.type);
    }
}

function isSearchIncomplete(dropDownBox) {
    // compare the last displayed value and the current real text in the input field
    let displayValue = dropDownBox.option('displayValue');
    let text = dropDownBox.option('text');

    text = text && text.length && text;
    displayValue = displayValue && displayValue.length && displayValue[0];

    return text !== displayValue;
}

const DropDownBoxDispatch = React.createContext(null);

const ActivityLookup = ({
    ...props
}) => {
    const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();

    const [lookupDataSource, setLookupDataSource] = useState(new ArrayStore({key: 'id', data: []}));
    const [activityLabel, setActivityLabel] = useState('Activity');

    const dataSource = useMemo(() => new DataSource({
        store: lookupDataSource
    }),[lookupDataSource]);

    const gridDataSource = useMemo(() => new DataSource({
        store:      lookupDataSource,
        searchExpr: ['activityId', 'description', 'clientName', 'siteName']
    }),[lookupDataSource]);

    const [state, dispatch] = useReducer(reducer, initialState);

    let { value, opened, focusedRowIndex, focusedRowKey, dataGridInstance, transactionType, hint } = state;

    const lookupButtonOptions = {
        icon:              'find',
        hoverStateEnabled: false,
        stylingMode:       'text',
        onClick:           () => {
            dispatch({ opened: !opened, type: 'open/close'});
        }
    };

    const dropDownBoxValueChanged = (args) => {
        clearTimeout(searchTimer);
        if (!args.value) {
            // setSelectedRowKeys([]);
            props.data.setValue(args.value);
        }
        if (isArray(args.previousValue) && args.previousValue[0] === undefined) {
            dispatch({ value: args.value, type: 'value'});
        }
        else {
            dispatch({ value: args.value, opened: false, type: 'all'});
        }

        const items = props.data.column.lookup.dataSource(props.data);
        if (!isArray(items)) {
            items.load().done(result => {
                const item = result.find(act => act.id === args.value[0]);
                if (item && item.activityId) {
                    dispatch({hint: `${item.activityId}${item.description ? ` - ${item.description}`: ''}`, type: 'hint'});
                }
                else if (item && item.description) {
                    dispatch({hint: item.description, type: 'hint'});
                }
                else {
                    dispatch({hint: undefined, type: 'hint'});
                }
            });
        }
        else {
            dispatch({hint: undefined, type: 'hint'});
        }
    };

    const onInput = function (e) {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(function () {
            let text = e.component.option('text');
            gridDataSource.searchValue(text);
            if (opened && isSearchIncomplete(e.component)) {
                gridDataSource.load().then((items) => {
                    if (items.length === 1 && dataGridInstance) {
                        dispatch({focusedRowKey: items[0].id, type: 'focusedRowKey'});
                    }
                });
            }
            else {
                dispatch({opened: true, type: 'open/close'});
            }
        }, 500);
    };

    const onKeyDown = (e) => {
        let ddbInstance = e.component;

        if (e.event.keyCode === 13) {
            // enter key
            if (dataGridInstance) {
                if (gridDataSource.searchValue() !== '' && dataGridInstance.getVisibleRows().length === 1 && focusedRowKey) {
                    props.data.setValue(focusedRowKey);
                }
                else {
                    e.event.preventDefault();
                }
            }
            else {
                e.event.preventDefault();
            }
            return;
        }

        if (e.event.keyCode !== 40) return; //not arrow down

        if (!opened) {
            ddbInstance.isKeyDown = true;
            dispatch({ opened: true, type: 'open/close'});
        }
        else {
            dataGridInstance && dataGridInstance.focus();
        }
    };

    const onOpened = (e) => {
        let ddbInstance = e.component;

        if (ddbInstance.isKeyDown) {
            if (!dataGridInstance) return;

            const contentReadyHandler = args => {
                const gridInstance = args.component;
                gridInstance.focus();
                gridInstance.off('contentReady', contentReadyHandler);
            };

            if (!dataGridInstance.isNotFirstLoad) {
                dataGridInstance.on('contentReady', contentReadyHandler);
            }
            else {
                const optionChangedHandler = (args) => {
                    const gridInstance = args.component;
                    if (args.name === 'focusedRowKey' || args.name === 'focusedColunIndex') {
                        gridInstance.off('optionChanged', optionChangedHandler);
                        gridInstance.focus();
                    }
                };
                dataGridInstance.on('optionChanged', optionChangedHandler);
                dispatch({ type: 'focusedRowKey', focusedRowKey: null, focusedRowIndex: 0});
            }
            ddbInstance.isKeyDown = false;
        }
        else if (dataGridInstance && dataGridInstance.isNotFirstLoad && isSearchIncomplete(ddbInstance)) {
            gridDataSource.load().done(items => {
                if (items.length === 1)
                {
                    dispatch({ focusedRowKey: items[0].id, type: 'focusedRowKey'});
                    dataGridInstance.focus();
                }
                else
                    ddbInstance.focus();
            });
        }
    };

    const onClosed = e => {
        const ddbInstance = e.component;
        const searchValue = gridDataSource.searchValue();

        if (isSearchIncomplete(ddbInstance)) {
            dispatch({ value: value === '' ? null : '', type: 'value'});
        }

        if (searchValue) {
            gridDataSource.searchValue(null);
        }
    };

    const onOptionChanged = (args) => {
        if (args.name === 'opened') {
            dispatch({ opened: args.value, type: 'open/close'});
        }
    };

    useEffect(() => {
        return () => {
            dispatch({ type: 'dataGridInstance', instance: null});
        };
    }, []);

    useEffect(() => {
        const init = async () => {
            dispatch({ value: isArray(props.data.value) ? props.data.value : [props.data.value], type: 'value'});
            const source = await makeDataStore(props.data.column.lookup.dataSource(props.data));
            setLookupDataSource(source);
        };
        init();
    }, [props.data]);

    useEffect(() => {
        dispatch({ transactionType: props.data.data.transactionType, type: 'transactionType'});

        switch (props.data.data.transactionType.toUpperCase()) {
            case 'JOB':
                setActivityLabel('Job');
                break;
            case 'SERVICE':
                setActivityLabel('Service Call');
                break;
            default:
                setActivityLabel('Activity');
        }
    }, [props.data.data.transactionType]);

    return (
        <DropDownBoxDispatch.Provider
            value={{
                dataSource:      gridDataSource,
                dispatch:        dispatch,
                focusedRowKey:   focusedRowKey,
                focusedRowIndex: focusedRowIndex,
                transactionType: transactionType,
                setValue:        props.data.setValue,
                activityLabel:   activityLabel,
                isXSmall:        isXSmall,
                isSmall:         isSmall,
                isMedium:        isMedium,
                isLarge:         isLarge
            }}
        >
            <DropDownBox
                showClearButton={true}
                placeholder={`Select ${activityLabel}`}
                onInput={onInput}
                displayExpr={dropDownDisplayExpr}
                valueExpr="id"
                value={value}
                valueChangeEvent=""
                acceptCustomValue={true}
                onOpened={onOpened}
                opened={opened}
                dataSource={dataSource}
                onKeyDown={onKeyDown}
                onClosed={onClosed}
                onValueChanged={dropDownBoxValueChanged}
                onOptionChanged={onOptionChanged}
                contentComponent={DataGridComponent}
                height="53px"
                inputAttr={{id: 'activity-lookup-cell'}}
                hint={hint}
            >
                <DropDownOptions height="400px" width={isLarge || isMedium ? '600px' : '100%'} />
                <DropDownBoxButton
                    name="clear"
                    location="after"
                />
                <DropDownBoxButton
                    name="find"
                    location="after"
                    options={lookupButtonOptions}
                />
            </DropDownBox>
        </DropDownBoxDispatch.Provider>
    );
};

const DataGridComponent = ({ data }) => {
    const { value, component } = data;
    const { dispatch, dataSource, focusedRowKey, focusedRowIndex, transactionType, setValue, activityLabel } = useContext(DropDownBoxDispatch);

    const selectionChanged = (e) => {
        if (!isEmpty(e.selectedRowKeys)) {
            dispatch({ value: e.selectedRowKeys, opened: false, type: 'all'});
        }
        if (e.selectedRowKeys.length !== 0)
        {
            setValue(e.selectedRowKeys[0]);
        }
    };

    const contentReady = e => {
        if (!e.component.isNotFirstLoad) {
            e.component.isNotFirstLoad = true;
            component.focus();
            dispatch({ instance: e.component, type: 'dataGridInstance'});
        }
    };

    const keyDown = e => {
        if (e.event.keyCode === 13) {
            // pressed enter key
            dispatch({ value: [focusedRowKey], opened: false, type: 'all'});

        }
    };

    const focusedRowChanged = e => {
        let rowKey = e.component.getKeyByRowIndex(e.rowIndex);
        if (rowKey === undefined)
        {
            rowKey = e.row.data.id;
        }
        dispatch({ focusedRowIndex: e.rowIndex, focusedRowKey: rowKey, type: 'focusedRowKey'});
    };

    return (
        <DataGrid
            width="100%"
            height="100%"
            onFocusedRowChanged={focusedRowChanged}
            dataSource={dataSource}
            focusedRowEnabled={true}
            onContentReady={contentReady}
            autoNavigateToFocusedRow={false}
            remoteOperations={true}
            hoverStateEnabled={true}
            onKeyDown={keyDown}
            focusedRowIndex={focusedRowIndex}
            focusedRowKey={focusedRowKey}
            onSelectionChanged={selectionChanged}
            defaultSelectedRowKeys={value}
        >
            <Selection mode="single" />
            <Paging enabled={true} pageSize={25} />
            <Scrolling mode="infinite" />
            <FilterRow visible={true} />
            <Column
                dataField={'id'}
                visible={false}
            />
            <Column
                dataField={'activityId'}
                caption={activityLabel}
                width={transactionType.toUpperCase() !== 'UNBILLED' ? 100 : 700}
            />
            <Column
                dataField={'description'}
                width={200}
                visible={transactionType.toUpperCase() !== 'UNBILLED'}
            />
            <Column
                dataField={'clientName'}
                width={200}
                caption={'Customer'}
                visible={transactionType.toUpperCase() !== 'UNBILLED'}
            />
            <Column
                dataField={'siteName'}
                width={200}
                caption={'Location'}
                visible={transactionType.toUpperCase() !== 'UNBILLED'}
            />
        </DataGrid>
    );
};

export default ActivityLookup;
