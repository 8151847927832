import React from 'react';
import LoadPanel from 'devextreme-react/load-panel';

export const withLoadPanel = (WrappedComponent) => {
    return ({ panelProps, loading, hasData, ...props }) => {
        if (!hasData) {
            return (
                <LoadPanel
                    showPane={false} visible {...panelProps}
                    indicatorSrc="/assets/images/WS110x110.gif"
                />
            );
        } else {
            return (
                <>
                    {loading &&
                        <LoadPanel
                            showPane={false}
                            indicatorSrc="/assets/images/WS110x110.gif"
                            visible {...panelProps}
                        />
                    }
                    <WrappedComponent {...props} />
                </>
            );
        }
    };
};
