
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isNull, orderBy } from 'lodash';
import { ScrollView, TextArea } from 'devextreme-react';
import Box, { Item as BoxItem } from 'devextreme-react/box';
import Form, { Item as FormItem, Label } from 'devextreme-react/form';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import DataSource from 'devextreme/data/data_source';
import DateBox from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';
import { Validator, RangeRule, CustomRule, RequiredRule} from 'devextreme-react/validator';
import { useTranslation } from 'react-i18next';
import { addDays, addMinutes, addSeconds, differenceInMinutes, setMilliseconds, setSeconds } from 'date-fns';
import { FormLookup } from '../';
import {
    ActivityItem,
    CostCodeItem,
    getActivityDisplayExpr,
    getCostCodeDisplayExpr,
    getPayCodeDisplayExpr,
    getShiftCodeDisplayExpr,
    PayCodeItem,
    ShiftCodeItem,
    renderDayTab,
    renderPayCodeGroupHeader
} from '../time-entry-form/TimeEntryFormHelper';
import devices from 'devextreme/core/devices';
import { defaultFormData, toHoursAndMinutes } from '../../helpers/timeHelpers';
import { TransactionType } from '../../data/transactionTypes';
import { getSizeQualifier, useScreenSize } from '../../utils/media-query';

import * as AnnotationTypes from '../../data/annotationTypes';
import * as TimeActions from '../../actions/timeActions';
import * as JobActions from '../../actions/jobActions';

import './TimeManagerEditForm.scss';

const unbilledActivitySearchExpression = ['name'];
const activitySearchExpression = ['activityId', 'description', 'clientName', 'siteName'];
const jobCostCodeSearchExpression = ['costCodeAlias', 'description'];
const serviceCostCodeSearchExpression = ['description'];

const defaultNoteData = {
    objectId:       null,
    id:             null,
    annotationType: null,
    isAttachment:   false,
    attachment:     null,
    subject:        null,
    isVisible:      true,
    noteText:       ''
};

const numBoxFormat = {
    type:      'fixedPoint',
    precision: '2'
};

const TimeManagerEditForm = ({
    employeePayCodes,
    data,
    featureFlags,
    fetchJobCostCodes,
    fullTransactionList,
    jobCostCodes,
    jobList,
    onFormDataChanged,
    periodDays,
    setJobCostCodes,
    settings,
    shiftCodes,
    unbilledActivities,
    validationGroup,
    workOrderCostCodes,
    workOrderList
}) => {
    const { t, i18n } = useTranslation();
    const [activityDataSource, setActivityDataSource] = useState([]);
    const [activityHelpText, setActivityHelpText] = useState('');
    const [activityLabel, setActivityLabel] = useState({text: 'Activity'});
    const [activityNoDataText, setActivityNoDataText] = useState('');
    const [activityType, setActivityType] = useState();
    // eslint-disable-next-line no-unused-vars
    const [expensePayCodes, setExpensePayCodes] = useState([]);
    const [formData, setFormData] = useState(defaultFormData);
    const [isCostCodeVisible, setIsCostCodeVisible] = useState(false);
    const [isFormReady, setIsFormReady] = useState(false);
    const [laborPayCodes, setLaborPayCodes] = useState([]);
    const [noteData, setNoteData] = useState();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [useTimeInTimeOut, setUseTimeInTimeOut] = useState(false);
    const [minTimeInValue, setMinTimeInValue] = useState(null);
    const [maxTimeInValue, setMaxTimeInValue] = useState(null);
    const [minTimeOutValue, setMinTimeOutValue] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [maxTimeOutValue, setMaxTimeOutValue] = useState(null);
    const [hasTimeInValue, setHasTimeInValue] = useState(false);
    const [isMauiApp, setIsMauiApp] = useState(false);
    const tabRef = useRef();
    const formRef = useRef();

    const { isLarge } = useScreenSize();

    useEffect(() => {
        const isMaui = sessionStorage.getItem('isMauiApp');
        if (isMaui) {
            setIsMauiApp(true);
        }
        else {
            setIsMauiApp(false);
        }

        if (periodDays) {
            const index = periodDays.findIndex(p => p.formattedDate === data.transactionFormattedDate);
            setSelectedTabIndex(index);
        }

        return () => {
            setFormData(defaultFormData);
            if (formRef.current) {
                formRef.current.instance.dispose();
            }
        };
    }, []);

    useEffect(() => {
        const useTimeInOutFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');
        if (useTimeInOutFlag) {
            if (useTimeInOutFlag.featureFl && settings.useTimeInTimeOut) {
                setUseTimeInTimeOut(true);
            }
            else {
                setUseTimeInTimeOut(false);
            }
        }
        else {
            setUseTimeInTimeOut(false);
        }
    }, [featureFlags, settings.useTimeInTimeOut]);

    useEffect(() => {
        if (data && data.transactionType) {
            setIsCostCodeVisible(data.transactionType !== TransactionType.Unbilled);
            switch (data.transactionType) {
                case TransactionType.Job:
                    setActivityLabel({text: t('time.job')});
                    setActivityDataSource(jobList);
                    setActivityNoDataText(t('time.noJobsFound'));
                    break;
                case TransactionType.Service:
                    setActivityLabel({text: t('time.serviceCall')});
                    setActivityDataSource(workOrderList);
                    setActivityNoDataText(t('time.noServiceCallsFound'));
                    break;
                case TransactionType.Unbilled:
                    setActivityLabel({text: t('time.unbilledActivity')});
                    setActivityDataSource(unbilledActivities);
                    setActivityNoDataText(t('time.noActivitesFound'));
                    break;
                default:
                    break;
            }
        }
    }, [data?.transactionType]);

    useEffect(() => {
        if (data && isFormReady) {
            setActivityType(data.transactionType);
            if (data.activityClientName && data.activitySiteName) {
                setActivityHelpText(`${data.activityClientName} - ${data.activitySiteName}`);
            }
            else {
                setActivityHelpText('');
            }

            if (data.notes.length !== 0) {
                setNoteData(data.notes[0]);
            }
            else {
                setNoteData(cloneDeep(defaultNoteData));
            }

            if (!isNull(data.timeIn)) {
                setHasTimeInValue(true);
            }

            setFormData(data);
            if (useTimeInTimeOut) {
                let minTimeIn = new Date(data.transactionFormattedDate);
                const utcOffset = new Date(minTimeIn).getTimezoneOffset();
                minTimeIn = addMinutes(minTimeIn, utcOffset);
                const minTimeOut = addMinutes(minTimeIn, 1);
                setMinTimeInValue(new Date(minTimeIn));
                setMinTimeOutValue(minTimeOut);
                let maxTimeInValue = addDays(minTimeIn, 1);
                maxTimeInValue = addSeconds(maxTimeInValue, -1);
                setMaxTimeInValue(maxTimeInValue);
                setMaxTimeOutValue(addMinutes(new Date(data.timeIn), 1440));
            }
        }

    }, [data, isFormReady]);

    useEffect(() => {
        const laborCodes = employeePayCodes.filter(pc => pc.payType !== 5);
        const expenseCodes = employeePayCodes.filter(pc => pc.payType === 5);
        const groupedData = new DataSource({
            store: laborCodes,
            key:   'paycodeid',
            group: [{selector: 'groupTypeOrder', desc: false}]
        });

        setLaborPayCodes(groupedData);
        setExpensePayCodes(expenseCodes);
    }, [employeePayCodes]);


    const workOrderLaborCostCodes = useMemo(() => {
        return orderBy(workOrderCostCodes.filter(cc => cc.costType.toLowerCase() === 'labor'), 'description', 'asc');
    }, [workOrderCostCodes]);

    const onOptionChanged = useCallback(e => {
        if (e.name === 'selectedIndex') {
            const transactionDateField = 'transactionDate';
            const transactionFormattedDateField = 'transactionFormattedDate';
            setSelectedTabIndex(e.value);
            const formattedDate = periodDays.find(p => p.id === e.value).formattedDate;
            const newData = {...data,
                ...{[transactionDateField]: formattedDate},
                ...{[transactionFormattedDateField]: formattedDate}
            };
            onFormDataChanged(newData);
        }

    }, [data, formData]);

    const onContentReady = useCallback(component => {
        setIsFormReady(true);
    }, []);

    const updateActivityField = useCallback(field => async lookup => {
        if (isFormReady) {
            if (lookup.previousValue !== undefined && lookup.value !== lookup.previousValue && lookup.value !== formData?.activityId.id) {
                const costCodeField = 'costcodeId';
                const activityClientNameField = 'activityClientName';
                const activitySiteNameField = 'activitySiteName';
                let clientName = '';
                let siteName = '';
                const transactionCopy = cloneDeep(data);
                transactionCopy.activityId = {id: lookup.value};

                if (activityType === TransactionType.Job) {
                    let defaultCostCode = { id: null };
                    const job = jobList.find(j => j.id === lookup.value);
                    if (job) {
                        clientName = job.clientName;
                        siteName = job.siteName;
                    }
                    setActivityHelpText(`${formData.activityClientName} - ${formData.activitySiteName}`);
                    await fetchJobCostCodes(lookup.value).then(async codes => {
                        setJobCostCodes(codes);
                        if (codes.length === 1) {
                            defaultCostCode = {id: codes[0].id};
                        }
                    });
                    const newData = {...data,
                        ...{[field]: {id: lookup.value},
                            ...{[costCodeField]: defaultCostCode},
                            ...{[activityClientNameField]: clientName},
                            ...{[activitySiteNameField]: siteName}
                        }};
                    onFormDataChanged(newData);
                }
                else if (activityType === TransactionType.Service) {
                    const wo = workOrderList.find(wol => wol.id === lookup.value);
                    if (wo) {
                        clientName = wo.clientName;
                        siteName = wo.siteName;
                    }
                    setActivityHelpText(`${formData.activityClientName} - ${formData.activitySiteName}`);
                    const newData = {...data,
                        ...{[field]: {id: lookup.value},
                            ...{[activityClientNameField]: clientName},
                            ...{[activitySiteNameField]: siteName}
                        }};
                    onFormDataChanged(newData);
                }
                else {
                    setActivityHelpText('');
                    const newData = {...data,
                        ...{[field]: {id: lookup.value}
                        }};
                    onFormDataChanged(newData);
                }
            }
            else if (activityType === TransactionType.Job && formData?.activityId.id) {
                await fetchJobCostCodes(lookup.value).then(codes => {
                    setJobCostCodes(codes);
                });
            }
        }
    }, [data, formData]);

    const updateLookupField = useCallback(field => async (lookup) => {
        const shiftCodeField = 'shiftCodeId';
        let newShiftCodeId = null;

        if (isFormReady) {
            const previousFormValue = field === 'costcodeId' ? formData?.costcodeId.id : formData?.paycodeId.id;

            if (lookup.previousValue !== undefined && lookup.value !== lookup.previousValue && lookup.value !== previousFormValue) {
                if (field === 'paycodeId') {
                    let newData = {...data, ...{[field]: {id: lookup.value}}};
                    if (settings.useShiftCode) {
                        const selectedEmployeePayCode = employeePayCodes.find(pc => pc.paycodeId === lookup.value);
                        if (selectedEmployeePayCode) {
                            newShiftCodeId = selectedEmployeePayCode.shiftCodeId;
                        }
                        else {
                            newShiftCodeId = formData.shiftCodeId;
                        }
                    }
                    newData = {...newData, ...{[shiftCodeField]: {id: newShiftCodeId}}};
                    onFormDataChanged(newData);
                }
                else {
                    const newData = {...data, ...{[field]: {id: lookup.value}}};
                    onFormDataChanged(newData);
                }
            }
        }
    }, [data, formData]);

    const handleTimeInOutUpdate = useCallback(dataField => e => {
        if (e.event) {
            const { value } = e;

            let newData = {
                ...formData,
                ...{[dataField]: setMilliseconds(setSeconds(new Date(value), 0), 0)},
                ...{'isTrxDirty': true}
            };

            if (dataField === 'timeIn' && isNull(value)) {
                newData = {
                    ...newData,
                    ...{'timeOut': null},
                    ...{'hours': null}
                };

                let minTimeIn = new Date(newData.transactionFormattedDate);
                minTimeIn = addMinutes(minTimeIn, minTimeIn.getTimezoneOffset);
                setMinTimeInValue(minTimeIn);
                let maxTimeInValue = addDays(minTimeIn, 1);
                maxTimeInValue = addSeconds(maxTimeInValue, -1);
                setMaxTimeInValue(maxTimeInValue);
                const minTimeOut = addMinutes(value, 1);
                setMinTimeOutValue(minTimeOut);
                setMaxTimeOutValue(addMinutes(minTimeOut, 1339));
            }
            else if (dataField === 'timeIn' && !isNull(value)) {
                const hoursAsMinutes = Math.round(newData.hours * 60);
                let newEndTime = addMinutes(new Date(value), hoursAsMinutes);
                newEndTime = setMilliseconds(setSeconds(newEndTime, 0), 0);
                newData = {
                    ...newData,
                    ...{'timeOut': newEndTime},
                    ...{'isTrxDirty': true}
                };
                const maxTimeOutValue = addMinutes(new Date(value), 1);
                setMinTimeOutValue(maxTimeOutValue);
                const maxTimeOut1 = addMinutes(new Date(value), 1440);
                setMaxTimeOutValue(maxTimeOut1);
            }
            if (dataField === 'timeOut' && !isNull(newData.timeIn)) {
                const minuteDiff = differenceInMinutes(setMilliseconds(setSeconds(new Date(value), 0), 0), setMilliseconds(setSeconds(new Date(newData.timeIn), 0), 0));
                const totalHours = toHoursAndMinutes(minuteDiff);
                newData = {
                    ...newData,
                    ...{'hours': Number(totalHours)}
                };
            }

            onFormDataChanged(newData);
        }
    }, [formData]);

    const onHourValueChanged = useCallback(e => {
        if (e.event) {
            const field = 'hours';
            const dirtyField = 'isTrxDirty';

            let newData = {
                ...formData, ...{[field]: e.value},
                ...{[dirtyField]: true}
            };

            if (useTimeInTimeOut && !isNull(formData.timeIn)) {
                const totalMinutes = Math.round(e.value * 60);
                const endTime = setMilliseconds(setSeconds(addMinutes(setMilliseconds(setSeconds(new Date(formData.timeIn), 0), 0), totalMinutes), 0), 0);

                newData = {
                    ...newData,
                    ...{'timeOut': endTime},
                };
            }

            onFormDataChanged(newData);
        }
    }, [useTimeInTimeOut, formData]);

    const handleNoteChange = useCallback(e => {
        if (e.event?.type === 'blur') {
            const notesColumnField = 'notes';
            const newNotes = [];
            if (data.notes.length === 0) {
                const newNoteData = cloneDeep(defaultNoteData);
                newNoteData.annotationType = AnnotationTypes.TIMETRANSACTION;
                newNoteData.objectId = data.id;
                newNoteData.isAttachment = false;
                newNoteData.subject = 'TimeTransaction';
                newNoteData.noteText = e.value;
                newNoteData.isUpdated = true;
                newNotes.push(newNoteData);
            }
            else {
                noteData.isUpdated = true;
                noteData.noteText = e.value;
                newNotes.push(noteData);
            }

            const newData = {...data,
                ...{[notesColumnField]: newNotes}
            };
            onFormDataChanged(newData);
        }
    }, [noteData, data]);

    const validateZeroHours = useCallback(e => {
        return e.value !== 0;
    },[]);

    const validateTotalHoursForDay = useCallback(e => {
        const daysTransactions = fullTransactionList.filter(trx => trx.transactionFormattedDate === data.transactionFormattedDate);
        let totalHours = 0;
        daysTransactions.forEach(dt => {
            if (dt.id === data.id) {
                totalHours += e.value;
            }
            else {
                totalHours += dt.hours;
            }
        });
        return totalHours <= 24;
    }, [data, fullTransactionList]);

    const renderForm = useCallback(e => {
        return (<></>);
    }, []);

    return (
        <React.Fragment>
            <TabPanel
                ref={tabRef}
                loop={false}
                animationEnabled={false}
                disabled={hasTimeInValue}
                swipeEnabled={false}
                selectedIndex={selectedTabIndex}
                onOptionChanged={onOptionChanged}
                itemTitleRender={renderDayTab(i18n.resolvedLanguage)}
                itemRender={renderForm}
            >
                {
                    periodDays.map((period, index) => (
                        <TabItem
                            key={index}
                            title={period.formattedDate}
                            text={period.formattedDate}
                            period={period}
                        />
                    ))
                }
            </TabPanel>
            <div className="manager-edit-form">
                <Box direction="col" height={devices.current().phone || devices.current().tablet ? isMauiApp ? 'calc(100vh - 150px)' : 'calc(100vh - 200px)' : '74vh'}>
                    <BoxItem ratio={1}>
                        <ScrollView height="100%" width="100%" showScrollbar="always">
                            <Form
                                ref={formRef}
                                id="managerEditForm"
                                formData={formData}
                                screenByWidth={getSizeQualifier}
                                onContentReady={onContentReady}
                            >
                                <FormItem
                                    helpText={activityHelpText}
                                >
                                    <Label text={`${activityLabel.text} *`} />
                                    <FormLookup
                                        dataSource={activityDataSource}
                                        displayExpression={getActivityDisplayExpr}
                                        dropDownCentered={false}
                                        dropDownHeight={!isLarge ? 'calc(100vh - 250px)' : '350px'}
                                        isRequired={true}
                                        itemRender={ActivityItem}
                                        noDataText={activityNoDataText}
                                        onValueChanged={updateActivityField('activityId')}
                                        placeholder={t('time.activityPlaceholder', {activity: activityLabel.text})}
                                        searchEnabled={true}
                                        searchExpression={activityType && activityType === TransactionType.Unbilled ? unbilledActivitySearchExpression : activitySearchExpression}
                                        value={formData?.activityId.id}
                                        valueExpression="id"
                                    >
                                        <RequiredRule message={t('validation.activityRequired', {activityType: activityLabel.text})} />
                                    </FormLookup>
                                </FormItem>
                                {isCostCodeVisible ?
                                    <FormItem>
                                        <Label text={`${t('time.costCode')} *`} />
                                        <FormLookup
                                            dataSource={activityType === TransactionType.Job ? jobCostCodes : workOrderLaborCostCodes}
                                            displayExpression={getCostCodeDisplayExpr}
                                            dropDownCentered={false}
                                            dropDownHeight="250px"
                                            isRequired={isCostCodeVisible}
                                            itemRender={CostCodeItem}
                                            noDataText={activityType === TransactionType.Job ? t('time.selectJobForCostCode') : t('time.noCostCodesFound')}
                                            onValueChanged={updateLookupField('costcodeId')}
                                            placeholder={t('time.selectCostCode')}
                                            searchEnabled={true}
                                            searchExpression={activityType === TransactionType.Job ? jobCostCodeSearchExpression : serviceCostCodeSearchExpression}
                                            value={formData?.costcodeId.id}
                                            valueExpression="id"
                                        >
                                            <RequiredRule message={t('validation.costCodeRequired')} />
                                        </FormLookup>
                                    </FormItem>
                                    : null}
                                <FormItem>
                                    <Label text={`${t('time.payCode')} *`} />
                                    <FormLookup
                                        dataSource={laborPayCodes}
                                        displayExpression={getPayCodeDisplayExpr}
                                        dropDownCentered={false}
                                        dropDownHeight="250px"
                                        grouped={true}
                                        groupRender={renderPayCodeGroupHeader(t)}
                                        noDataText={t('time.noEmployeePayCodes')}
                                        isRequired={true}
                                        itemRender={PayCodeItem}
                                        onValueChanged={updateLookupField('paycodeId')}
                                        placeholder={t('time.selectPayCode')}
                                        searchEnabled={true}
                                        searchExpression={['paycodeName', 'paycodeDescription']}
                                        value={formData?.paycodeId.id}
                                        valueExpression="paycodeId"
                                    >
                                        <RequiredRule message={t('validation.paycodeRequired')} />
                                    </FormLookup>
                                </FormItem>
                                { settings.useShiftCode ?
                                    <FormItem>
                                        <Label text={t('time.shiftCode')} />
                                        <FormLookup
                                            dataSource={shiftCodes}
                                            displayExpression={getShiftCodeDisplayExpr}
                                            dropDownCentered={false}
                                            dropDownHeight="250px"
                                            grouped={false}
                                            noDataText={t('time.noShiftCodes')}
                                            isRequired={false}
                                            itemRender={ShiftCodeItem}
                                            onValueChanged={updateLookupField('shiftCodeId')}
                                            placeholder={t('time.selectShiftCode')}
                                            requireValidation={false}
                                            searchEnabled={true}
                                            searchExpression={['name', 'description']}
                                            showClearButton={true}
                                            value={formData?.shiftCodeId?.id}
                                            valueExpression="id"
                                        />
                                    </FormItem>
                                    : null
                                }
                                {
                                    useTimeInTimeOut &&
                                        <FormItem>
                                            <Label text={t('time.timeIn')} />
                                            <div className="time-in-out-container">
                                                <div className="time-in-out-picker">
                                                    <DateBox
                                                        min={minTimeInValue}
                                                        max={maxTimeInValue}
                                                        onValueChanged={handleTimeInOutUpdate('timeIn')}
                                                        pickerType="calendar"
                                                        showAnalogClock={false}
                                                        type="datetime"
                                                        value={formData.timeIn}
                                                        valueChangeEvent="keyup blur input change focusout"
                                                        width={225}
                                                    />
                                                </div>
                                            </div>
                                        </FormItem>
                                }
                                {
                                    useTimeInTimeOut &&
                                        <FormItem>
                                            <Label text={t('time.timeOut')} />
                                            <div className="time-in-out-container">
                                                <div className="time-in-out-picker">
                                                    <DateBox
                                                        min={minTimeOutValue}
                                                        onValueChanged={handleTimeInOutUpdate('timeOut')}
                                                        pickerType="calendar"
                                                        showAnalogClock={false}
                                                        type="datetime"
                                                        value={formData.timeOut}
                                                        valueChangeEvent="keyup blur input change focusout"
                                                        width={225}
                                                    />
                                                </div>
                                            </div>
                                        </FormItem>
                                }
                                <FormItem>
                                    <Label text={t('common.hours')} />
                                    <NumberBox
                                        format={numBoxFormat}
                                        max={24}
                                        min={-24}
                                        onValueChanged={onHourValueChanged}
                                        showSpinButtons={true}
                                        useLargeSpinButtons={devices.current().phone || devices.current().tablet}
                                        value={formData?.hours}
                                        valueChangeEvent="keyup change input"
                                    >
                                        <Validator>
                                            <RequiredRule message={t('validation.hoursRequired')} />
                                            <RangeRule
                                                min={-24}
                                                max={24}
                                            />
                                            <CustomRule
                                                validationCallback={validateTotalHoursForDay}
                                                reevaluate={true}
                                                message={t('validation.totalHoursExceed24')}
                                            />
                                            <CustomRule
                                                validationCallback={validateZeroHours}
                                                reevaluate={true}
                                                message={t('validation.hoursNotZero')}
                                            />
                                        </Validator>
                                    </NumberBox>
                                </FormItem>
                                <FormItem>
                                    <Label text={t('time.note')} />
                                    <TextArea
                                        height="100px"
                                        maxLength={250}
                                        onValueChanged={handleNoteChange}
                                        value={noteData?.noteText}
                                        valueChangeEvent="blur"
                                    />
                                </FormItem>
                            </Form>
                        </ScrollView>
                    </BoxItem>
                </Box>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    employeePayCodes:   state.employee.employeePayCodes,
    featureFlags:       state.core.featureFlags,
    jobList:            state.job.jobs,
    periodDays:         state.time.periodDays,
    settings:           state.time.settings,
    shiftCodes:         state.time.shiftCodes,
    unbilledActivities: state.activities.unbilledActivities,
    workOrderCostCodes: state.workOrder.costCodes,
    workOrderList:      state.workOrder.workOrders
});

const mapDispatchToProps = {
    ...JobActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeManagerEditForm);
