import React, { useCallback, useRef, useState } from 'react';
import { TextBox, List, Button } from 'devextreme-react';
import Validator, { EmailRule } from 'devextreme-react/validator';
import { useTranslation } from 'react-i18next';

import './admin-email-template.scss';

const AdminEmailAddressTemplate = ({
    emailAddresses,
    onDataChanged
}) => {
    const { t } = useTranslation();
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);
    const [emailValue, setEmailValue] = useState();
    const [emailList, setEmailList] = useState(emailAddresses?.length > 0 ? emailAddresses.split(',') : []);

    const emailTextBoxRef = useRef();

    const handleTextBoxChange = useCallback((e) => {
        setEmailValue(e.value);
        setAddButtonDisabled(!e.component.option('isValid'));
    }, [setEmailValue]);

    const handleTextBoxEnterKey = useCallback(e => {
        if (e.event) {
            const { component } = e;
            if (component.option('isValid')) {
                const item = emailList.find(email => email === emailValue);
                if (!item) {
                    addEmailToList(emailValue);
                }
                else {
                    setEmailValue();
                    component.focus();
                }
            }
        }
    }, [emailList, emailValue, setEmailValue]);

    const handleAddEmailClick = useCallback((e) => {
        if (emailTextBoxRef.current && emailTextBoxRef.current.instance.option('isValid')) {
            const item = emailList.find(email => email === emailValue);
            if (!item) {
                addEmailToList(emailValue);
            }
            else {
                setEmailValue();
                emailTextBoxRef.current.instance.focus();
            }
        }
    }, [emailList, emailValue, setEmailValue]);

    const addEmailToList = (emailAddress) => {
        const newList = [...emailList, emailAddress];
        setEmailList(newList);
        setEmailValue();
        setAddButtonDisabled(true);
        onDataChanged(newList.join(','));
        emailTextBoxRef.current.instance.focus();
    };

    const handleEmailDeletion = useCallback((e) => {
        const newList = emailList.join(',');
        onDataChanged(newList);
    }, [emailList]);

    return (
        <div className="email-list-container">
            <div className="email-text-box">
                <TextBox
                    ref={emailTextBoxRef}
                    onEnterKey={handleTextBoxEnterKey}
                    onValueChanged={handleTextBoxChange}
                    placeholder={t('common.emailAddress')}
                    value={emailValue}
                    valueChangeEvent="input"
                >
                    <Validator>
                        <EmailRule />
                    </Validator>
                </TextBox>
            </div>
            <div className="email-add-button">
                <Button
                    disabled={addButtonDisabled}
                    icon={'plus'}
                    onClick={handleAddEmailClick}
                />
            </div>
            <div className="email-list">
                <List
                    allowItemDeleting={true}
                    dataSource={emailList}
                    height={200}
                    itemDeleteMode="static"
                    onItemDeleted={handleEmailDeletion}
                />
            </div>
        </div>
    );

};

export default AdminEmailAddressTemplate;
