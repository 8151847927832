import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isNull } from 'lodash';
import { FormPopup } from '../utils/form-popup/FormPopup';
import DataGrid, { Button as GridButton, Column, Format, Toolbar, Item} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import { Tooltip } from 'devextreme-react/tooltip';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import TimeEntryForm from '../time-entry-form/TimeEntryForm';
import Icon from '@mdi/react';
import {
    mdiTimerStopOutline,
    mdiTrashCanOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { TransactionStatus } from '../../data/transactionStatuses';
import { TransactionType } from '../../data/transactionTypes';
import { t } from 'i18next';

import './TimeEntryEditList.scss';
import i18n from '../../localization/i18n';
import { differenceInMinutes, setSeconds, setMilliseconds, addMinutes } from 'date-fns';
import {
    toHoursAndMinutes ,
    defaultFormData,
    getStatusIcon,
    getStatusIconColor
} from '../../helpers/timeHelpers';
import { validateTimeOut } from '../time-entry-list/TimeEntryListHelper';
import OverflowTooltip from '../overflow-tooltip/OverflowTooltip';

let timedInTimer;

const renderStatusCell = cellData => {
    const cellId = `cell-${cellData.columnIndex}-${cellData.rowIndex}`;
    const hasError = cellData.data.status === TransactionStatus.Error || cellData.data.status === TransactionStatus.Rejected;
    const errorClassName = cellData.data.status === TransactionStatus.Error ? 'fa-solid fa-circle-exclamation' : 'fa-solid fa-triangle-exclamation' ;
    const tooltipHeaderMessage = cellData.data.status === TransactionStatus.Error ? t('time.errorInTimeEntry') : cellData.data.status === TransactionStatus.Rejected ? t('time.timeEntryRejected') : '';
    return (
        <>
            <div id={cellId} className="status-cell">
                <div className="status-icon">
                    <Icon
                        path={getStatusIcon(cellData.value)}
                        size={'20px'}
                        color={getStatusIconColor(cellData.value)}
                        spin={cellData.value === TransactionStatus.TimedIn}
                    />
                </div>
                <div className="status-value">{cellData.data.statusString}</div>
            </div>
            { hasError ?
                <Tooltip
                    target={`#${cellId}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="bottom"
                    hideOnOutsideClick={false}
                >
                    <div className="list-tooltip-error">
                        <div className="list-tooltip-error-header"><i className={errorClassName} style={{color: '#ff0000', paddingRight: '5px'}} />{tooltipHeaderMessage}</div>
                        <div className="list-tooltip-error-text">{cellData.data.statusComment}</div>
                    </div>
                </Tooltip>
                : null
            }
        </>
    );
};

const renderNoteCell = cellData => {
    const cellId = `note-cell-${cellData.columnIndex}-${cellData.rowIndex}`;
    const cellValue = cellData.value && cellData.value.length !== 0 ? cellData.value[0].noteText : null;
    return (
        <>
            <OverflowTooltip
                cellId={cellId}
                cellText={cellValue}
            />
        </>
    );
};


const renderTimeCell = useTimeInOut => cellData => {
    const { column, data } = cellData;
    const cellId = `${column.dataField}-${cellData.columnIndex}-${cellData.rowIndex}`;
    let tooltipValue;
    let displayValue = data[column.dataField];
    if (useTimeInOut && !isNull(data[column.dataField])) {
        tooltipValue = new Date(displayValue).toLocaleString(i18n.resolvedLanguage, {
            weekday: 'long',
            year:    'numeric',
            month:   'long',
            day:     'numeric'
        });
        const formattedTime = new Date(displayValue).toLocaleTimeString(i18n.resolvedLanguage);
        displayValue = formattedTime;
        tooltipValue = `${tooltipValue} ${formattedTime}`;
    }
    return (
        <>
            <div id={cellId}>{displayValue}</div>
            { tooltipValue &&
            <Tooltip
                target={`#${cellId}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position="bottom"
                hideOnOutsideClick={true}
            >
                <div className="time-tooltip">
                    <div className="time-tooltip-label">{column.dataField === 'timeIn' ? `${t('time.timeIn')}:` : `${t('time.timeOut')}:`}</div>
                    <div className="time-tooltip-label">{tooltipValue}</div>
                </div>
            </Tooltip>
            }
        </>
    );
};

const isEditingAllowed = e => {
    return e.row.data.status === TransactionStatus.Open || e.row.data.status === TransactionStatus.TimedIn || e.row.data.status === TransactionStatus.Rejected || e.row.data.status === TransactionStatus.Error;
};

const isDeleteAllowed = e => {
    return e.row.data.status === TransactionStatus.Open || e.row.data.status === TransactionStatus.TimedIn || e.row.data.status === TransactionStatus.Rejected || e.row.data.status === TransactionStatus.Error;
};

const isTimeOutAllowed = useTimeInTimeOut => e => {
    const isTimedIn = e.row.data.status === TransactionStatus.TimedIn;
    return useTimeInTimeOut && isTimedIn;
};

const TimeEntryEditList = ({
    activeEmployee,
    featureFlags,
    fetchJobCostCodes,
    formRef,
    fullTransactionList,
    gridInstance,
    handleEditListPopupHiding,
    handleTimeEntryDeletion,
    isFormDirtyStateRef,
    isResetForm,
    jobCostCodes,
    newTransactionId,
    onDataChanged,
    onFormDataChanged,
    onSave,
    resetEditList,
    selectedTransactionId,
    setEditListPopFormVisible,
    setEditRowKeysRef,
    setIsFormDirtyStateRef,
    setIsResetForm,
    setJobCostCodes,
    setNewTransactionId,
    setSelectedTransactionId,
    setPopupIsVisible,
    settings,
    transactions
}) => {
    const { t } = useTranslation();
    const [activityFieldLabel, setActivityFieldLabel] = useState('Job');
    const [editTransaction, setEditTransaction] = useState();
    const [showEditForm, setShowEditForm] = useState(false);
    const [isNewRecord, setIsNewRecord] = useState(false);
    const [useTimeInOut, setUseTimeInOut] = useState(false);

    useEffect(() => {
        setActivityFieldLabel(transactions.transactionType === 'Job' ? 'Job:' : transactions.transactionType === 'Service' ? 'Service Call:' : 'Unbilled Activity:');

        return (() => {
            clearTimeout(timedInTimer);
        });
    }, []);

    useEffect(() => {
        const timeInOutFeatureFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');

        if (timeInOutFeatureFlag && timeInOutFeatureFlag.featureFl && settings?.useTimeInTimeOut) {
            setUseTimeInOut(true);
        }
        else {
            setUseTimeInOut(false);
        }

    }, [featureFlags, settings.useTimeInTimeOut]);

    const dataGridSource = useMemo(() => {
        if (transactions.transactions && showEditForm && newTransactionId) {
            const newTrx = transactions.transactions.find(trx => trx.id === newTransactionId);
            if (newTrx) {
                setEditTransaction(newTrx);
                setNewTransactionId(null);
            }
        }
        else if (transactions.transactions && showEditForm && editTransaction) {
            const updatedTrx = cloneDeep(transactions.transactions).find(trx => trx.id === editTransaction.id);
            if (updatedTrx) {
                setEditTransaction(updatedTrx);
            }
        }

        const visibleTransactions = transactions.transactions?.filter(trx => !isNull(trx.id));

        const store = new ArrayStore({
            key:  'id',
            data: visibleTransactions
        });

        return store;
    }, [transactions.transactions]);

    const changeEditFormPopupVisibility = useCallback(data => {
        setEditListPopFormVisible(data);
        setShowEditForm(data);
    }, []);

    const handleEdit = useCallback(async e => {
        const { data } = e.row;
        if (data.transactionType === TransactionType.Job) {
            await fetchJobCostCodes(data.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }
        setIsNewRecord(false);
        setEditTransaction(data);
        setIsFormDirtyStateRef(false);
        setShowEditForm(true);
        setPopupIsVisible(true);
    }, []);

    const handleDelete = useCallback(e => {
        if (e.event && e.row.rowType === 'data') {
            setEditRowKeysRef({
                activityId:      e.row.data.activityId,
                costcodeId:      e.row.data.costcodeId,
                paycodeId:       e.row.data.paycodeId,
                equipmentId:     e.row.data.equipmentId,
                shiftCodeId:     e.row.data.shiftCodeId,
                employeeId:      e.row.data.employeeId,
                transactionType: e.row.data.transactionType,
                transactionDate: e.row.data.transactionFormattedDate,
            });
            handleTimeEntryDeletion(e.row.data);
        }
    }, []);

    const handleAddNewEntry = useCallback(async e => {
        if (transactions.transactionType === TransactionType.Job) {
            await fetchJobCostCodes(transactions.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }

        setIsNewRecord(true);
        const newFormData = cloneDeep(defaultFormData);
        newFormData.transactionDate = transactions.transactions[0].transactionDate;
        newFormData.transactionFormattedDate = transactions.transactions[0].transactionFormattedDate;
        newFormData.transactionType = transactions.transactionType;
        newFormData.activityId = transactions.activityId;
        newFormData.costcodeId = transactions.costcodeId;
        newFormData.paycodeId = transactions.paycodeId;
        newFormData.shiftCodeId = transactions.shiftCodeId;
        newFormData.employeeId = transactions.employeeId;
        newFormData.timesheetId = transactions.timesheetId;
        newFormData.hours = null;
        newFormData.id = null;
        newFormData.timeIn = null;
        newFormData.timeOut = null;
        newFormData.notes = [];

        await setEditTransaction(newFormData);
        setShowEditForm(true);
        setPopupIsVisible(true);
    }, [defaultFormData, transactions]);

    const handleFormReset = useCallback(async (transactionType, transactionDate) => {
        if (transactions.transactionType === TransactionType.Job) {
            await fetchJobCostCodes(transactions.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }

        setSelectedTransactionId();
        setIsFormDirtyStateRef(false);
        setIsNewRecord(true);

        const newFormData = cloneDeep(defaultFormData);
        newFormData.transactionDate = transactionDate;
        newFormData.transactionFormattedDate = transactions.transactionFormattedDate;
        newFormData.transactionType = transactionType;
        newFormData.activityId = {id: null};
        newFormData.costcodeId = {id: null};
        newFormData.paycodeId = transactions.paycodeId;
        newFormData.shiftCodeId = transactions.shiftCodeId;
        newFormData.employeeId = transactions.employeeId;
        newFormData.timesheetId = transactions.timesheetId;
        newFormData.hours = null;
        newFormData.id = null;

        await setEditTransaction(newFormData);

    }, [defaultFormData, transactions]);

    const handleTimeOut = useCallback(cellData => {
        const { row } = cellData;
        const { data } = row;

        const allowTimeOut = validateTimeOut(data, fullTransactionList, t, true);

        if (allowTimeOut) {
            let timeOutTime = setMilliseconds(setSeconds(new Date(), 0), 0);
            let timeDiff = differenceInMinutes(timeOutTime, new Date(data.timeIn));
            if (timeDiff > 1440) {
                timeOutTime = addMinutes(new Date(data.timeIn), 1440);
                timeDiff = 1440;
            }
            const newHours = Number(toHoursAndMinutes(timeDiff));

            const newData = cloneDeep(data);
            newData.timeOut = timeOutTime;
            newData.isTrxDirty = true;
            newData.hours = newHours;
            newData.status = TransactionStatus.Open;

            onDataChanged(newData);
            onSave();
        }
    }, [transactions, fullTransactionList]);

    const renderActivityCell = data => {
        const activityType = data.transactionType.toUpperCase();
        const iconClass = activityType === 'JOB' ? 'fa-regular fa-building' : activityType === 'SERVICE' ? 'fa-solid fa-hammer' : 'fa-regular fa-money-bill-1';
        const unbilledIconStyling = {
            paddingRight: '5px'
        };
        const jobIconStyling = {
            paddingRight: '5px'
        };
        const serviceIconStylng = {
            paddingRight: '5px'
        };

        return (
            <>
                { activityType !== 'UNBILLED' ?
                    <div style={{marginTop: '-10px'}}><i className={iconClass} style={activityType === 'JOB' ? jobIconStyling : serviceIconStylng} /> {`${data.activityName} - ${data.activityDescription}`}</div>
                    :
                    <div><i className={iconClass} style={unbilledIconStyling} />{data.activityName}</div>
                }
            </>
        );
    };

    return (
        <>
            <div className="dx-fieldset">
                <div className="dx-field-item-label">{activityFieldLabel}</div>
                <div className="dx-field-item field-label">{renderActivityCell(transactions)}</div>
                {transactions.activityClientName ?
                    <div className="dx-field-item-label">{`${transactions.activityClientName} - ${transactions.activitySiteName}`}</div>
                    : null}
            </div>
            {transactions.costcodeId ?
                <div className="dx-fieldset">
                    <div className="dx-field-item-label">Cost Code:</div>
                    <div className="dx-field-item field-label" style={{marginTop: '-10px'}}>{`${transactions.costcodeAlias} - ${transactions.costcodeDescription}`}</div>
                </div>
                : null}
            <div className="dx-fieldset">
                <div className="dx-field-item-label">Pay Code:</div>
                <div className="dx-field-item field-label" style={{marginTop: '-10px'}}>{`${transactions.paycodeName} - ${transactions.paycodeDescription}`}</div>
            </div>
            { settings.useShiftCode &&
                <div className="dx-fieldset">
                    <div className="dx-field-item-label">Shift Code:</div>
                    {transactions.shiftCodeName ?
                        <div className="dx-field-item field-label" style={{marginTop: '-10px'}}>{`${transactions.shiftCodeName} - ${transactions.shiftCodeDescription}`}</div>
                        : <div className="dx-field-item field-label" />}
                </div>
            }
            <DataGrid
                dataSource={dataGridSource}
                rowAlternationEnabled={true}
            >
                <Toolbar>
                    <Item location="before">
                        <div>{t('time.timeEntries')}</div>
                    </Item>
                    <Item location="after">
                        <Button
                            icon="fa-solid fa-plus"
                            onClick={handleAddNewEntry}
                        />
                    </Item>
                </Toolbar>
                <Column
                    alignment="center"
                    caption={t('time.hours')}
                    dataField="hours"
                    width={100}
                >
                    <Format type="fixedPoint" precision="2" />
                </Column>
                {
                    useTimeInOut &&
                    <Column
                        caption={t('time.timeIn')}
                        cellRender={renderTimeCell(useTimeInOut)}
                        dataField="timeIn"
                        width={100}
                    />
                }
                {
                    useTimeInOut &&
                    <Column
                        caption={t('time.timeOut')}
                        cellRender={renderTimeCell(useTimeInOut)}
                        dataField="timeOut"
                        width={100}
                    />
                }
                <Column
                    caption={t('time.status')}
                    cellRender={renderStatusCell}
                    dataField="status"
                    width={150}
                />
                <Column
                    caption={t('time.notes')}
                    cellRender={renderNoteCell}
                    dataField="notes"
                />
                <Column
                    name="commandColumn"
                    type="buttons"
                    width={100}
                    allowColumnResizing={false}
                    allowReordering={false}
                >
                    <GridButton
                        name="edit"
                        hint={t('time.editEntry')}
                        visible={isEditingAllowed}
                        onClick={handleEdit}
                    >
                        <Icon
                            className="grid-button"
                            path={mdiPencilOutline}
                            size={'20px'}

                        />
                    </GridButton>
                    <GridButton
                        name="delete"
                        hint={t('time.deleteEntry')}
                        visible={isDeleteAllowed}
                        onClick={handleDelete}
                    >
                        <Icon
                            className="grid-button"
                            path={mdiTrashCanOutline}
                            size={'20px'}
                        />
                    </GridButton>
                    <GridButton
                        name="timeOut"
                        hint={t('time.timeOut')}
                        visible={isTimeOutAllowed(useTimeInOut)}
                        onClick={handleTimeOut}
                    >
                        <Icon
                            className="grid-button"
                            path={mdiTimerStopOutline}
                            size={'20px'}
                            color={'red'}
                        />
                    </GridButton>
                </Column>
            </DataGrid>
            { showEditForm ?
                <FormPopup
                    cancelButtonText={t('common.cancel')}
                    isFormDirtyStateRef={isFormDirtyStateRef}
                    onResetForm={handleFormReset}
                    onHiding={handleEditListPopupHiding}
                    onSave={onSave}
                    resetEditList={resetEditList}
                    showMultiSaveButton={true}
                    setIsFormDirtyState={setIsFormDirtyStateRef}
                    setIsResetForm={setIsResetForm}
                    setVisible={changeEditFormPopupVisibility}
                    title={`${t('time.timeEntry')} - ${activeEmployee.firstName} ${activeEmployee.lastName} `}
                    visible={showEditForm}
                    width={600}
                    height="95vh"
                >
                    <TimeEntryForm
                        data={editTransaction}
                        formRef={formRef}
                        fullTransactionList={fullTransactionList}
                        gridInstance={gridInstance}
                        handleTimeEntryDeletion={handleTimeEntryDeletion}
                        isFormDirtyStateRef={isFormDirtyStateRef}
                        isResetForm={isResetForm}
                        isNewRecord={isNewRecord}
                        onFormDataChanged={onFormDataChanged}
                        onDataChanged={onDataChanged}
                        jobCostCodes={jobCostCodes}
                        setEditRowKeys={setEditRowKeysRef}
                        setIsFormDirtyState={setIsFormDirtyStateRef}
                        setJobCostCodes={setJobCostCodes}
                        selectedTransactionId={selectedTransactionId}
                        setSelectedTransactionId={setSelectedTransactionId}
                        transaction={editTransaction}
                    />
                </FormPopup>
                : null
            }
        </>
    );
};

export default TimeEntryEditList;
