import {
    SET_UNBILLED_ACTIVITIES
} from '../actions/actionTypes';

const initialState = {
    unbilledActivities: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_UNBILLED_ACTIVITIES:
            return {
                ...state,
                unbilledActivities: action.payload
            };
        default:
            return state;
    }
};
