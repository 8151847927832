/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from '../actions/actionTypes';

const initialState = {
    defaultCompany: null,
    email:          null,
    firstName:      null,
    id:             null,
    isSuperUser:    null,
    lastName:       null,
    phone:          null,
    role:           {},
    userName:       null,
    userTypes:      []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_CURRENT_USER:
            return {
                ...action.payload
            };
        case ActionTypes.FETCH_CURRENT_USER_ROLE:
            return {
                ...state,
                id:   action.payload.id,
                role: {...action.payload.role}
            };
        default:
            return state;
    }
};
