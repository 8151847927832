import React, { useEffect } from 'react';
import {
    Authenticator,
    useAuthenticator,
    View
} from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { SignInHeader } from './SignInHeader';
import { SignInFooter } from './SignInFooter';

import '../App.css';

const components = {
    Header,
    SignIn: {
        Header: SignInHeader,
        Footer: SignInFooter
    },
    Footer
};

export function Login() {
    // const { tokens } = useTheme();
    const { route } = useAuthenticator((context) => [context.route]);

    const location = useLocation();
    const navigate = useNavigate();

    let from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    return (
        <View className="auth-wrapper">
            <Authenticator components={components} hideSignUp />
        </View>
    );
}
