import Notify from 'devextreme/ui/notify';

export default function showToastMessage(message, type) {

    const position = 'bottom left';
    const direction = 'up-push';

    Notify({
        message:     message,
        height:      55,
        maxWidth:    'calc(100vw - 20px)',
        type:        type,
        displayTime: 5000,
        width:       'auto',
        animation:   {
            show: { 
                type:     'fade', 
                duration: 400, 
                from:     0, 
                to:       1
            } ,
            hide: { 
                type:     'fade', 
                duration: 40, 
                to:       0 
            }
        }
    }, {
        position,
        direction
    });
}
