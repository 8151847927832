export function userHasPermission(companyUser, item, permissions, featureFlags, settings, isSuperUser) {
    let hasPermission = false;
    const permissionList = permissions.split(',');

    permissionList.forEach(p => {
        if (hasPermission) {
            // Do not need to continue through loop if already has permission
            return true;
        }
        const employeeRecord = companyUser?.userTypes?.find(ut => ut.usertype.toLowerCase() === 'employee');

        if (employeeRecord || isSuperUser) {

            if (item.path === '/timeapproval' || item.path === '/administration/approversetup') {
                // manager approval feature flag
                const managerApprovalFlag = featureFlags.find(ff => ff.name === 'useTimeManagerApproval');
                if (managerApprovalFlag) {
                    hasPermission = isSuperUser ? isSuperUser : employeeRecord.userAttributes[p.trim()] ? employeeRecord.userAttributes[p.trim()] && managerApprovalFlag.featureFl : false;
                    hasPermission = hasPermission && settings.requireManagerApproval;
                }
                else {
                    hasPermission = false;
                }
            }
            else {
                hasPermission = isSuperUser ? isSuperUser : employeeRecord.userAttributes[p.trim()] ? employeeRecord.userAttributes[p.trim()] : false;
            }
        }
    });

    return hasPermission;
}
