import {
    FETCH_ANNOTATIONS,
} from '../actions/actionTypes';

const initialState = {
    annotations: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ANNOTATIONS:
            return {
                ...state,
                annotations: [...action.payload]
            };
        default:
            return state;
    }
};
