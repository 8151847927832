import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { List } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useTranslation } from 'react-i18next';
import devices from 'devextreme/core/devices';

const EmployeeSelection = ({
    employeeList,
    setSelectedEmployeeIds
}) => {
    const [listHeight, setListHeight] = useState(600);
    const { t } = useTranslation();

    useEffect(() => {
        if (devices.current().phone || devices.current().tablet) {
            setListHeight('80vh');
        }
        else {
            setListHeight('calc(100vh - 400px)');
        }
    }, []);

    const dataSource = useMemo(() => {
        const store = new ArrayStore({
            key:  'id',
            data: employeeList
        });

        return new DataSource({
            store:    store,
            paginate: false
        });

    }, [employeeList]);

    const onSelectedItemKeysChange = useCallback(e => {
        if (e.name === 'selectedItemKeys') {
            setSelectedEmployeeIds(e.value);
        }
    }, []);

    return (
        <List
            dataSource={dataSource}
            displayExpr="fullName"
            height={listHeight}
            keyExpr="id"
            onOptionChanged={onSelectedItemKeysChange}
            searchEditorOptions={{
                placeholder: t('common.search')
            }}
            searchExpr="fullName"
            searchEnabled={true}
            searchMode="contains"
            selectionMode="all"
            selectAllMode="allPages"
            selectAllText={t('report.selectAllEmployees')}
            selectByClick={true}
            showSelectionControls={true}
        />
    );
};

export default EmployeeSelection;
