import React, {useEffect, useRef, useState} from 'react';
import AwsConfig from '../../awsConfig';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../UserPanel/UserPanel';
import CompaniesPanel from '../companiesPanel/CompaniesPanel';
import WebBrowserPopup from '../utils/web-browser-popup/WebBrowserPopup';
// import { CompaniesPanel } from '../components';
import './Header.scss';
// import { Template } from 'devextreme-react/core/template';
import devices from 'devextreme/core/devices';

export default function Header({
    menuToggleEnabled,
    title,
    toggleMenu,
    currentOS,
    currentUser,
    isMobileApp
}) {
    const [pageTitle, setPageTitle] = useState(title);
    const [isHelpPopupVisible, setIsHelpPopupVisible] = useState(false);
    const toolbarRef = useRef();

    useEffect(() => {
        const env = process.env.REACT_APP_STAGE;
        const currentEnvironment = env === 'dev' ? 'Development' : env === 'stage' ? 'UAT' : null;

        if (currentEnvironment) {
            setPageTitle(`${title} - ${currentEnvironment}`);
        }
    }, []);

    const showHelp = () => {
        if (isMobileApp) {
            setIsHelpPopupVisible(true);
        }
        else {
            window.open(AwsConfig.key2act.helpUrl, '_blank');
        }
    };

    const renderTitle = () => {
        return (
            <div className="header-title-container">
                <div className="header-title-image">
                    <img
                        src={'/assets/images/Time-512x512.png'}
                        width={'24px'}
                        height={'24px'}
                        alt={'Signature Time'}
                    />
                </div>
                <div className="header-title-text">{pageTitle}</div>
            </div>
        );
    };

    return (
        <header className={'header-component'}>
            <Toolbar className={'header-toolbar'} ref={toolbarRef}>
                <Item
                    visible={menuToggleEnabled}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>
                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    render={renderTitle}
                    visible={!!title}
                />
                <Item
                    location="after"
                    locateInMenu={devices.current().phone || devices.current().tablet ? 'always' : 'auto'}
                >
                    <CompaniesPanel parentToolbar={toolbarRef} />
                </Item>

                <Item
                    location={'after'}
                    locateInMenu={devices.current().phone || devices.current().tablet ? 'always' : 'auto'}
                >
                    <UserPanel currentOS={currentOS} currentUser={currentUser} parentToolbar={toolbarRef} />
                </Item>
                <Item
                    location={'after'}
                    locateInMenu={devices.current().phone || devices.current().tablet ? 'always' : 'auto'}
                    widget="dxButton"
                    cssClass={'menu-button'}
                >
                    <Button icon="help" stylingMode="text" onClick={showHelp} />
                </Item>
            </Toolbar>
            <WebBrowserPopup
                title="Help"
                url={AwsConfig.key2act.helpUrl}
                showPopup={isHelpPopupVisible}
                popupHidden={() => {setIsHelpPopupVisible(false);}}
            />
        </header>
    );
}
