import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { addDays, addMinutes, differenceInMinutes, formatISO, isBefore, isAfter, format, parseISO, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';
import { cloneDeep, isNull, orderBy } from 'lodash';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import ScrollView from 'devextreme-react/scroll-view';
import List from 'devextreme-react/list';
import LoadPanel from 'devextreme-react/load-panel';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import ActionSheet from 'devextreme-react/action-sheet';
import { custom } from 'devextreme/ui/dialog';
import useScreenOrientation from 'use-screen-orientation';
import { useScreenSize } from '../../utils/media-query';
import { useTranslation } from 'react-i18next';
import AnnotationForm from '../annotations/AnnotationForm';
import PeriodSelector from '../PeriodSelector/PeriodSelector';
import { TimeEntryForm, FormPopup } from '../../components';
import ConfirmationDialog from '../confirmationDialog/confirmationDialog';
import { TransactionStatus } from '../../data/transactionStatuses';
import { TransactionType } from '../../data/transactionTypes';
import * as AnnotationTypes from '../../data/annotationTypes';
import parse from 'html-react-parser';
import devices from 'devextreme/core/devices';
import showToastMessage from '../../helpers/toastHelper.js';
import EmployeeSelection from '../employee-timesheet-report/EmployeeSelection.js';

import {
    EditActionItems,
    ViewItems,
    renderDayView,
    renderGroupView
} from './TimeEntryMobileListHelper';

import {
    defaultTransactionData,
    validateTimeOut
} from '../time-entry-list/TimeEntryListHelper.js';

import {
    toHoursAndMinutes,
    defaultFormData
} from '../../helpers/timeHelpers.js';

import * as AnnotationActions from '../../actions/annotationActions';
import * as ActivitiesActions from '../../actions/activitiesActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as JobActions from '../../actions/jobActions';
import * as TimeActions from '../../actions/timeActions';
import * as WorkOrderActions from '../../actions/workOrderActions';

import './TimeEntryMobileList.scss';
import { prepareTimeEntriesForSave, splitPayPeriodTransactions } from '../../helpers/timesheetHelper.js';

const employeeListAttr = {
    class: 'mobile-employee-list-select-box',
};

const mobileButtonAttr = {
    class: 'mobile-button',
};

const listSearchExpression = [
    'activityName',
    'costcodeAlias',
    'paycodeDescription',
    'activityClientName',
    'activitySiteName',
    'transactionDate',
    'hours',
];

const defaultNoteData = {
    id:             null,
    annotationType: 'TimeTransaction',
    isAttachemnt:   false,
    attachment:     null,
    subject:        null,
    noteText:       null
};

const fetchTimeSheetOptions = {
    includeexternalorigindetails: true,
    companyid:                    null,
    requesterid:                  null,
};

const TimeEntryMobileList = ({
    activeCompany,
    activeEmployee,
    createAnnotation,
    copyEmployeeTimesheet,
    currentWeekEndingDate,
    currentTimesheet,
    currentUser,
    deleteAnnotation,
    deleteTimeTransactions,
    employeePayCodes,
    featureFlags,
    fetchAnnotationById,
    fetchEmployeePayCodes,
    fetchJobList,
    fetchMobileEmployeeTimesheetList,
    fetchJobCostCodes,
    fetchWorkOrderList,
    fetchWorkOrderCostCodes,
    fetchUnbilledActivities,
    filteredEmployeeList,
    insertTimeSheetTransaction,
    printEmployeeTimesheetReport,
    settings,
    setActiveEmployee,
    shiftCodes,
    timesheetStartDate,
    timeUserSettings
}) => {
    const { t, i18n } = useTranslation();
    const { isXSmall, isSmall, isMedium } = useScreenSize();

    const [allowSubmitting, setAllowSubmitting] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [listDataSource, setListDataSource] = useState([]);
    const [dataSourceOptions, setDataSourceOptions] = useState({ group: ViewItems[0].group, sort: ViewItems[0].sort});
    const [isListGrouped, setIsListGrouped] = useState(false);
    const [selectedView, setSelectedView] = useState(0);
    const [actionSheetState, setActionSheetState] = useState({isVisible: false});
    const [timeEntryPopupVisible, setTimeEntryPopupVisible] = useState(false);
    const [timeEntryData, setTimeEntryData] = useState(defaultFormData);
    const [jobCostCodes, setJobCostCodes] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [totalHours, setTotalHours] = useState({
        regularHours: 0,
        otherHours:   0,
        totalHours:   0,
    });
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [isDeleteNoteConfirmationVisible, setIsDeleteNoteConfirmationVisible] = useState(false);
    const [deleteConfirmationTitle, setDeleteConfirmationTitle] = useState('Delete Transaction');
    const [deleteConfirmationData, setDeleteConfirmationData] = useState();
    const [deleteTransactions, setDeleteTransactions] = useState([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [allowdActionItems, setAllowedActionItems] = useState(EditActionItems);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('Default Message');
    const [noteToDelete, setNoteToDelete] = useState();
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [noteData, setNoteData] = useState(defaultNoteData);
    const [isNewNote, setIsNewNote] = useState(false);
    const [noteSectionLabel, setNoteSectionLabel] = useState('Note');
    const [isShowAnnotationForm, setIsShowAnnotationForm] = useState(false);
    const [isNoteReadOnly, setIsNoteReadOnly] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [annotationTarget, setAnnotationTarget] = useState(null);
    const [transactionsToSubmit, setTransactionsToSubmit] = useState([]);
    const [openTransactions, setOpenTransactions] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [listHeight, setListHeight] = useState('calc(100vh - 250px)');
    const [isAllExpanded, setIsAllExpanded] = useState(true);
    const [activityItemDataSource, setActivityItemDataSource] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [isMauiApp, setIsMauiApp] = useState(false);
    const [viewItemList,setViewItemList] = useState([]);
    const [orientation] = useScreenOrientation();
    const [useTimeInOut, setUseTimeInOut] = useState(false);
    const [hasTimedInEntry, setHasTimedInEntry] = useState(true);
    const [showEmployeeReportSelectionModal, setShowEmployeeReportSelectionModal] = useState(false);

    const entryListRef = useRef();
    const formRef = useRef();

    const printItems = [
        {
            id:   1,
            name: t('common.printselect'),
        }
    ];

    const timeEntryPopupVisibleRef = useRef(timeEntryPopupVisible);
    const setTimeEntryPopupVisibleRef = data => {
        timeEntryPopupVisibleRef.current = data;
        setTimeEntryPopupVisible(data);
    };

    const editRowKeysRef = useRef();
    const setEditRowKeysRef = data => {
        editRowKeysRef.current = data;
    };

    const isFormDirtyStateRef = useRef(false);
    const setIsFormDirtyState = data => {
        isFormDirtyStateRef.current = data;
    };

    const timeTransactionData = useRef();
    const setTimeTransactionData = (data) => {
        timeTransactionData.current = data;
    };

    const defaultPayCodeIdRef = useRef({id: null});
    const setDefaultPayCodeIdRef = data => {
        defaultPayCodeIdRef.current = data;
    };

    const defaultShiftCodeIdRef = useRef({id: null});
    const setDefaultShiftCodeIdRef = data => {
        defaultShiftCodeIdRef.current = data;
    };

    const activeEmployeeRef = useRef({id: null});
    const setActiveEmployeeRef = data => {
        activeEmployeeRef.current = {id: data.id};
        setActiveEmployee(data);
    };

    const currentTimesheetRef = useRef();
    const setCurrentTimesheetRef = data => {
        currentTimesheetRef.current = data;
    };

    const timesheetStartDateRef = useRef();
    const setTimesheetStartDateRef = data => {
        timesheetStartDateRef.current = data;
    };

    const newId = useRef(null);
    const setNewId = data => {
        newId.current = data;
    };

    const isNewRecordRef = useRef(false);
    const setIsNewRecordRef = data => {
        isNewRecordRef.current = data;
    };

    const employeeTimesheetReportListRef = useRef([]);
    const setEmployeeTimesheetReportListRef = data => {
        employeeTimesheetReportListRef.current = data;
    };

    const viewSelectToolbarRef = useRef();

    useEffect(() => {
        const isMaui = sessionStorage.getItem('isMauiApp');
        if (isMaui) {
            setIsMauiApp(true);
        }
        else {
            setIsMauiApp(false);
        }
        const newList = cloneDeep(ViewItems);
        newList.forEach(vi => vi.text = t(vi.text));
        setViewItemList(newList);
    }, []);

    useEffect(() => {
        const timeInOutFeatureFlag = featureFlags.find(ff => ff.name === 'useTimeInTimeOut');

        if (timeInOutFeatureFlag && timeInOutFeatureFlag.featureFl && settings?.useTimeInTimeOut) {
            setUseTimeInOut(true);
        }
        else {
            setUseTimeInOut(false);
        }
    }, [featureFlags, settings.useTimeInTimeOut]);

    useEffect(() => {
        const init = async () => {
            let heightCalc = 'calc(100% - 200px)';
            if (devices.current().phone) {
                switch (devices.orientation()) {
                    case 'portrait':
                        if (devices.current().ios) {
                            if (isMauiApp)
                                heightCalc = 'calc(100% - 205px)';
                            else
                                heightCalc = 'calc(100% - 290px)';
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 200px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 250px)';
                            }
                        }
                        break;
                    default:
                        if (devices.current().ios) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 265px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 265px)';
                            }
                        }
                        else if (devices.current().android) {
                            if (isMauiApp) {
                                heightCalc = 'calc(100% - 260px)';
                            }
                            else {
                                heightCalc = 'calc(100% - 260px)';
                            }
                        }
                        else {
                            heightCalc = 'calc(100% - 250px)';
                        }
                        break;
                }
            }
            else if (devices.current().tablet) {
                switch (devices.orientation()) {
                    case 'portrait':
                        if (devices.current().ios) {
                            if (isMauiApp)
                                heightCalc = 'calc(100% - 210px)';
                            else
                                heightCalc = 'calc(100% - 240px)';
                        }
                        else if (devices.current().android) {
                            if (isMauiApp)
                                heightCalc = 'calc(100% - 215px)';
                            else
                                heightCalc = 'calc(100% - 215px)';
                        }
                        break;
                    default:
                        if (devices.current().ios) {
                            if (isMauiApp)
                                heightCalc = 'calc(100% - 210px)';
                            else
                                heightCalc = 'calc(100% - 240px)';
                        }
                        else if (devices.current().android) {
                            if (isMauiApp)
                                heightCalc = 'calc(100% - 250px)';
                            else
                                heightCalc = 'calc(100% - 240px)';
                        }
                        break;
                }
            }
            else {
                heightCalc = 'calc(100vh - 305px)';
            }

            await setListHeight(heightCalc);
        };
        config({
            floatingActionButtonConfig: {
                shading:                 true,
                maxSpeedDialActionCount: 10
            }
        });
        repaintFloatingActionButton();
        init();
    }, [orientation, isMauiApp, isXSmall, isSmall, isMedium]);

    useEffect(() => {
        if (currentUser.id && filteredEmployeeList.length !== 0) {
            const empFound = filteredEmployeeList.find(
                (emp) => emp.userId === currentUser.id
            );
            if (empFound) {
                setSelectedEmployee(empFound.id);
                setActiveEmployeeRef(empFound);
            } else {
                setSelectedEmployee(filteredEmployeeList[0].id);
                setActiveEmployeeRef(filteredEmployeeList[0]);
            }
        }
    }, [currentUser.id, filteredEmployeeList]);

    useEffect(() => {
        const init = async () => {
            setTimeTransactionData(null);

            const store = new CustomStore({
                loadMode:  'raw',
                onLoading: (loadOptions) => {
                    fetchTimeSheetOptions.companyid = activeCompany.id;
                    fetchTimeSheetOptions.requesterid = currentUser.id;
                    loadOptions.userData = {
                        activeEmployee: activeEmployeeRef.current.id,
                        startDate:      timesheetStartDate,
                        isEditFormOpen: timeEntryPopupVisibleRef?.current,
                        editFormData:   editRowKeysRef?.current,
                        fetchOptions:   fetchTimeSheetOptions,
                        isNewRecord:    isNewRecordRef.current
                    };
                },
                load: (loadOptions) => {
                    return fetchMobileEmployeeTimesheetList(
                        [loadOptions.userData.activeEmployee],
                        loadOptions.userData.startDate,
                        loadOptions.userData.fetchOptions
                    );
                },
                onLoaded: (data, loadOptions) => {
                    let regularHours = 0;
                    let otherHours = 0;
                    let totalHours = 0;
                    const openTrx = [];
                    let editData = loadOptions.userData.editFormData;

                    const currentDate = new Date();
                    const showCopy = isBefore(currentDate, new Date(currentWeekEndingDate)) && isAfter(currentDate, new Date(timesheetStartDate));
                    if (showCopy && data.length === 0 ) {
                        const newList = cloneDeep(newActionItems);
                        newList.push(copyTimesheetAction);
                        setActivityItemDataSource(newList);
                    }
                    else {
                        setActivityItemDataSource(cloneDeep(newActionItems));
                    }

                    if (loadOptions.userData.isEditFormOpen && !loadOptions.userData.isNewRecord) {
                        let currentTrx = data.find(trx => newId.current === trx.id);
                        if (!currentTrx) {
                            currentTrx = data.find(trx => editData.id === trx.id);
                        }
                        if (currentTrx) {
                            setTimeEntryData(currentTrx);
                            setTimeTransactionData(currentTrx);
                        }
                    }

                    data.forEach((trx) => {
                        if (trx.items) {
                            trx.items.forEach(item => {
                                if (item.payCodeType === 1 || item.payCodeType === 2) {
                                    regularHours += item.hours;
                                    totalHours += item.hours;
                                } else {
                                    otherHours += item.hours;
                                    totalHours += item.hours;
                                }
                                if (
                                    item.status === TransactionStatus.Open ||
                                item.status === TransactionStatus.Error
                                ) {
                                    openTrx.push(item);
                                    setAllowSubmitting(true);
                                }
                            });
                        }
                        else {
                            if (trx.payCodeType === 1 || trx.payCodeType === 2) {
                                regularHours += trx.hours;
                                totalHours += trx.hours;
                            } else {
                                otherHours += trx.hours;
                                totalHours += trx.hours;
                            }
                            if (
                                trx.status === TransactionStatus.Open ||
                            trx.status === TransactionStatus.Error
                            ) {
                                openTrx.push(trx);
                                setAllowSubmitting(true);
                            }
                        }
                    });

                    setTotalHours({
                        regularHours: regularHours,
                        otherHours:   otherHours,
                        totalHours:   totalHours,
                    });
                    setOpenTransactions(openTrx);
                    setNewId(null);
                    setSelectedTransactionId(null);
                }
            });

            setListDataSource(new DataSource({
                store:    store,
                group:    dataSourceOptions.group,
                sort:     dataSourceOptions.sort,
                paginate: false
            }));
        };

        if (activeEmployee.id && timesheetStartDate) init();
    }, [activeEmployee.id, timesheetStartDate, currentWeekEndingDate, dataSourceOptions]);

    useEffect(() => {
        let formTrx = null;
        if (currentTimesheet && currentTimesheet.id && timeEntryPopupVisibleRef.current) {
            // Refresh form data if form popup is showing and currentTimesheet details have changed
            let formData = cloneDeep(timeEntryData);
            formData.timesheetId = currentTimesheet.id;
            const allTrx = cloneDeep(currentTimesheet.timeTransactions);

            if (formData.id) {
                formTrx = allTrx.find(trx => trx.id === formData.id);
            }
            setTimeEntryData(formTrx ? formTrx : formData);
        }
        if (currentTimesheet && currentTimesheet.timeTransactions) {
            const timedInCount = currentTimesheet.timeTransactions.filter(trx => trx.status === TransactionStatus.TimedIn).length;
            if (timedInCount !== 0 || formTrx?.status === TransactionStatus.TimedIn) {
                setHasTimedInEntry(true);
            }
            else {
                setHasTimedInEntry(false);
            }
        }
        else {
            setHasTimedInEntry(false);
        }
        setCurrentTimesheetRef(currentTimesheet);
    }, [currentTimesheet.id, currentTimesheet.timeTransactions]);

    useEffect(() => {
        if (employeePayCodes.filter(pc => pc.payType !== 5).length === 1) {
            setDefaultPayCodeIdRef({id: employeePayCodes[0].paycodeId});
            const shiftCodeLookup = shiftCodes?.find(sc => sc.id === employeePayCodes[0].shiftCodeId);
            if (shiftCodeLookup) {
                setDefaultShiftCodeIdRef({id: employeePayCodes[0].shiftCodeId});
            }
            else {
                setDefaultShiftCodeIdRef({id: null});
            }
        }
        else if (activeEmployee?.userId === currentUser.id && timeUserSettings && timeUserSettings.viewData && JSON.parse(timeUserSettings.viewData).defaultLaborPayCodeId) {
            const userSettings = JSON.parse(timeUserSettings.viewData);
            if (userSettings) {
                const foundPayCode = employeePayCodes.find(pc => pc.paycodeId === userSettings.defaultLaborPayCodeId);
                if (foundPayCode) {
                    setDefaultPayCodeIdRef({id: foundPayCode.paycodeId});
                    const shiftCodeLookup = shiftCodes?.find(sc => sc.id === foundPayCode.shiftCodeId);
                    if (shiftCodeLookup) {
                        setDefaultShiftCodeIdRef({id: foundPayCode.shiftCodeId});
                    }
                    else {
                        setDefaultShiftCodeIdRef({id: null});
                    }
                }
                else {
                    const foundPayCode = employeePayCodes.find(pc => pc.paycodeId === settings.defaultPaycodeId);
                    if (foundPayCode) {
                        setDefaultPayCodeIdRef({id: foundPayCode.paycodeId});
                        const shiftCodeLookup = shiftCodes?.find(sc => sc.id === foundPayCode.shiftCodeId);
                        if (shiftCodeLookup) {
                            setDefaultShiftCodeIdRef({id: foundPayCode.shiftCodeId});
                        }
                    }
                    else {
                        setDefaultPayCodeIdRef({id: null});
                        setDefaultShiftCodeIdRef({id: null});
                    }
                }
            }
        }
        else if (settings.defaultPaycodeId) {
            const foundPayCode = employeePayCodes.find(pc => pc.paycodeId === settings.defaultPaycodeId);
            if (foundPayCode) {
                setDefaultPayCodeIdRef({id: foundPayCode.paycodeId});
                const shiftCodeLookup = shiftCodes?.find(sc => sc.id === foundPayCode.shiftCodeId);
                if (shiftCodeLookup) {
                    setDefaultShiftCodeIdRef({id: foundPayCode.shiftCodeId});
                }
                else {
                    setDefaultShiftCodeIdRef({id: null});
                }
            }
            else {
                setDefaultPayCodeIdRef({id: null});
                setDefaultShiftCodeIdRef({id: null});
            }
        }
        else {
            setDefaultPayCodeIdRef({id: null});
            setDefaultShiftCodeIdRef({id: null});
        }
    }, [employeePayCodes, settings, timeUserSettings, shiftCodes]);

    useEffect(() => {
        setTimesheetStartDateRef(timesheetStartDate);
    }, [timesheetStartDate]);

    const handleRefresh = useCallback(async (e) => {
        const userSettings = sessionStorage.getItem('userSettings');
        let userSetting = {};
        if (userSettings) {
            userSetting = JSON.parse(userSettings);
        }

        if (entryListRef.current) {
            entryListRef.current.instance.reload();
        }
        const promises = [
            fetchJobList(activeCompany.id, userSetting?.jobDefaultDivisions ? userSetting.jobDefaultDivisions : [], {limit: 2000}),
            fetchWorkOrderList(activeCompany.id, userSetting?.serviceDefaultDivisions ? userSetting.serviceDefaultDivisions : [], {limit: 2000}),
            fetchWorkOrderCostCodes(),
            fetchUnbilledActivities()
        ];

        await Promise.all(promises.map(p => p));

    }, []);

    const handleViewChanged = useCallback((e) => {
        // change the list datasource for the selected view
        setDataSourceOptions({group: ViewItems[e.value].group, sort: ViewItems[e.value].sort});
        setIsListGrouped(e.value !== 0);

        setSelectedView(e.value);

        if (viewSelectToolbarRef.current?.instance) {
            // Hide View Selector in Toolbar
            viewSelectToolbarRef.current.instance.option('overflowMenuVisible', false);
        }
    }, [listDataSource]);

    const handleEmployeeChanged = useCallback((e) => {
        const employee = filteredEmployeeList.find(emp => emp.id === e.value);
        if (employee) {
            setActiveEmployeeRef(employee);
            setSelectedEmployee(e.value);
            fetchEmployeePayCodes(employee.id);
        }

    }, []);

    const handleSubmit = useCallback(async (e) => {
        await setIsDeleteConfirmationVisible(false);
        setDeleteTransactions([]);
        setDeleteConfirmationData();
        setIsDeleteConfirmation(false);
        await setShowConfirmationDialog(false);

        if (openTransactions.length !== 0) {
            setTransactionsToSubmit(cloneDeep(openTransactions));
            setConfirmationDialogMessage(t('time.submitEntriesConfirmation', {count: openTransactions.length}));
            setShowConfirmationDialog(true);
        }
    }, [openTransactions]);

    const handleExpandCollapseAll = useCallback((e) => {
        const groupCount = listDataSource._items?.length;
        if (entryListRef.current?.instance && groupCount > 0) {
            for (var i = 0; i < groupCount; i++) {
                if (isAllExpanded) {
                    entryListRef.current.instance.collapseGroup(i);
                }
                else {
                    entryListRef.current.instance.expandGroup(i);
                }
            }
        }

        setIsAllExpanded(currIsAllExpandedValue => !currIsAllExpandedValue);
    }, [entryListRef, isAllExpanded, listDataSource]);

    const changePopupVisibility = useCallback((isVisible) => {
        setTimeEntryPopupVisibleRef(isVisible);
    }, []);

    const onNewJobActivityClick = useCallback((e) => {
        onNewTransactionClick(TransactionType.Job);
    }, [currentTimesheet]);

    const onNewServiceActivityClick = useCallback((e) => {
        onNewTransactionClick(TransactionType.Service);
    }, [currentTimesheet]);

    const onNewUnbilledActivityClick = useCallback((e) => {
        onNewTransactionClick(TransactionType.Unbilled);
    }, [currentTimesheet]);

    const onCopyTimeSheetClick = useCallback(async (e) => {
        const confirmDialog = custom({
            title:       t('time.copyTimesheet'),
            messageHtml: t('time.copyTimesheetConfirmation'),
            buttons:     [{
                text:        t('common.yes'),
                stylingMode: 'text',
                onClick:     e => {
                    return true;
                }
            },
            {
                text:        t('common.no'),
                stylingMode: 'text',
                onClick:     e => {
                    return false;
                }
            }]
        });

        confirmDialog.show()
            .then(async dialogResult => {
                if (dialogResult) {
                    setShowLoading(true);
                    const previousTimeSheetStartDate = format(addDays(new Date(timesheetStartDateRef.current), -6), 'yyyy-MM-dd');
                    const copyResult = await copyEmployeeTimesheet(activeEmployee.id, previousTimeSheetStartDate, t);
                    if (copyResult) {
                        const listInstance = entryListRef.current?.instance;
                        if (listInstance) {
                            listInstance.reload();
                        }
                    }
                    setShowLoading(false);

                }
            });
    }, [activeEmployee.id, timesheetStartDateRef, entryListRef]);

    const onNewTransactionClick = useCallback((transactionType) => {
        // Determine Transaction Date from Timesheet Week
        setSelectedTransactionId();
        const today = new Date();
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        if (timesheetStartDateRef.current) {
            const startDateArr = timesheetStartDateRef.current.split('-');
            const startDate = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
            const dateDiff = Math.abs(startDate - todayDate)/(1000*60*60*24);
            if (dateDiff > 7) {
                // Previous Timesheet Week
                createNewTransaction(transactionType, format(startDate, 'yyyy-MM-dd'));
            }
            else {
                // Current Timesheet Week
                createNewTransaction(transactionType, format(today, 'yyyy-MM-dd'));
            }
        }
        else {
            createNewTransaction(transactionType, format(today, 'yyyy-MM-dd'));
        }
    }, [currentTimesheet, setSelectedTransactionId]);

    const createNewTransaction = useCallback(async (transactionType, transactionDate, existingData) => {
        setIsSaveDisabled(false);
        let formData = cloneDeep(defaultFormData);
        formData.transactionType = transactionType;
        formData.timesheetId = currentTimesheetRef.current?.id;
        formData.transactionDate = transactionDate;
        formData.transactionFormattedDate = transactionDate;
        formData.paycodeId = existingData && existingData.paycodeId ? existingData.paycodeId : defaultPayCodeIdRef.current;
        formData.employeeId = {id: activeEmployeeRef.current?.id};
        formData.shiftCodeId = existingData && existingData.shiftCodeId ? existingData.shiftCodeId : defaultShiftCodeIdRef.current;
        formData.hours = null;
        if (existingData) {
            formData.activityId = existingData.activityId;
            formData.costcodeId = existingData.costcodeId;
            if (formData.transactionType === TransactionType.Job && formData.activityId && formData.activityId.id) {
                await fetchJobCostCodes(formData.activityId.id).then(codes => {
                    setJobCostCodes(codes);
                });
            }
        }

        setNewId(null);
        setIsNewRecordRef(true);
        setIsFormDirtyState(false);
        setEditRowKeysRef({
            activityId:      formData.activityId,
            costcodeId:      formData.costcodeId,
            paycodeId:       formData.paycodeId,
            shiftCodeId:     formData.shiftCodeId,
            equipmentId:     formData.equipmentId,
            employeeId:      formData.employeeId,
            transactionDate: formData.transactionDate,
            id:              null
        });
        setTimeEntryData(formData);
        setTimeEntryPopupVisibleRef(true);
    }, [currentTimesheetRef, defaultPayCodeIdRef, defaultShiftCodeIdRef, defaultTransactionData, activeEmployeeRef]);

    const printTimesheet = async () => {
        await printEmployeeTimesheetReport(activeCompany.id, [activeEmployee.id], timesheetStartDate, true, t);
    };

    const handleSelectEmployeeTimesheetPrint = () => {
        setShowEmployeeReportSelectionModal(true);
    };

    const printSelectedEmployeesTimesheet = useCallback(async () => {
        if (employeeTimesheetReportListRef.current.length !== 0) {
            await printEmployeeTimesheetReport(activeCompany.id, employeeTimesheetReportListRef.current, timesheetStartDate, true, t);
        }
        setShowEmployeeReportSelectionModal(false);
    }, [activeCompany, employeeTimesheetReportListRef.current, timesheetStartDate]);

    const renderToolbars = () => {
        return (
            <>
                <Toolbar ref={viewSelectToolbarRef}>
                    <Item location="before">
                        <PeriodSelector />
                    </Item>
                    <Item
                        location="after"
                        locateInMenu="always"
                        widget="dxButton"
                        options={refreshOptions}
                    />
                    <Item
                        location="after"
                        locateInMenu="always"
                        widget="dxDropDownButton"
                        options={printOptions}
                    />
                    <Item
                        location="after"
                        locateInMenu="always"
                        widget="dxSelectBox"
                        options={viewItemOptions}
                    />
                </Toolbar>
                <Toolbar>
                    <Item location="before">
                        <SelectBox
                            dataSource={filteredEmployeeList}
                            displayExpr={'fullName'}
                            elementAttr={employeeListAttr}
                            label={t('time.timeEntryFor')}
                            labelMode="floating"
                            onValueChanged={handleEmployeeChanged}
                            placeholder={t('time.selectEmployee')}
                            stylingMode="outlined"
                            value={selectedEmployee}
                            valueExpr={'id'}
                        />
                    </Item>
                    <Item location="after">
                        <Button
                            elementAttr={mobileButtonAttr}
                            icon="check"
                            onClick={handleSubmit}
                            stylingMode="outlined"
                            text={t('common.submit')}
                            disabled={!allowSubmitting}
                        />
                    </Item>
                    <Item locateInMenu="never" location="after">
                        <Button
                            icon={isAllExpanded ? 'chevronup' : 'chevrondown'}
                            onClick={handleExpandCollapseAll}
                            stylingMode="contained"
                            type="default"
                            visible={selectedView !== 0}
                        />
                    </Item>
                </Toolbar>
            </>
        );
    };

    const printOptions = {
        displayExpr:      'name',
        dropDownOptions:  { minWidth: '175px' },
        elementAttr:      { id: 'mobile-print-button', class: 'mobile-print'},
        icon:             'fa-solid fa-file-pdf',
        items:            printItems,
        keyExpr:          'id',
        onButtonClick:    printTimesheet,
        onItemClick:      handleSelectEmployeeTimesheetPrint,
        splitButton:      true,
        text:             t('common.generate'),
        useSelectionMode: false,
    };

    const refreshOptions = {
        icon:        'refresh',
        onClick:     handleRefresh,
        stylingMode: 'outlined',
        text:        t('common.refresh')
    };

    const viewItemOptions = {
        dataSource:     viewItemList.filter(vi => vi.visible === true),
        defaultValue:   0,
        displayExpr:    'text',
        onValueChanged: handleViewChanged,
        stylingMode:    'outlined',
        valueExpr:      'id',
        value:          selectedView
    };

    const handleActionSheetItemClick = useCallback(e => {
        // handle the action
        const { itemData } = e;
        switch (itemData.id) {
            case 'editEntry':
                setIsSaveDisabled(false);
                setIsNewRecordRef(false);
                setIsFormDirtyState(false);
                setTimeEntryPopupVisibleRef(true);
                break;
            case 'viewEntry':
                setIsNewRecordRef(false);
                setIsFormDirtyState(false);
                setTimeEntryPopupVisibleRef(true);
                break;
            case 'deleteEntry':
                handleTimeEntryDeletion(selectedEntry);
                break;
            case 'addNote':
                handleAddNote(selectedEntry);
                break;
            case 'editNote':
                handleUpdateNote(selectedEntry);
                break;
            case 'deleteNote':
                handleDeleteNote(selectedEntry);
                break;
            case 'viewNote':
                handleViewNote(selectedEntry);
                break;
            case 'timeOut':
                handleTimeOut(selectedEntry);
                break;
            case 'timeInNewEntry':
                handleTimeInNewEntry(selectedEntry);
                break;
            default:
                break;
        }
        setActionSheetState({isVisible: false});
    },[selectedEntry, setActionSheetState]);

    const handleActionSheetCancelClick = useCallback(async e => {
        await setActionSheetState({isVisible: false});
    }, [setActionSheetState]);

    const handleItemClick = useCallback(async e => {
        let allowedActions = [];
        let entryActions = [];
        let noteActions = [];
        const { itemData } = e;

        await setSelectedEntry(itemData);
        const hasNotes = itemData.notes.length !== 0;

        // Find all transactions for the day related to this transaction
        const daysTransactions = currentTimesheet.timeTransactions.filter(
            tt => tt.activityId.id === itemData.activityId.id &&
            tt.costcodeId?.id === itemData.costcodeId?.id &&
            tt.paycodeId.id === itemData.paycodeId.id &&
            tt.shiftCodeId?.id === itemData.shiftCodeId?.id &&
            tt.equipmentId?.id === itemData.equipmentId?.id &&
            tt.employeeId.id === itemData.employeeId.id &&
            tt.transactionFormattedDate === itemData.transactionFormattedDate
        );

        const openTransactionCount = daysTransactions.filter(dt => dt.status === TransactionStatus.Open || dt.status === TransactionStatus.Rejected || dt.status === TransactionStatus.Error);
        setIsSaveDisabled(openTransactionCount.length === 0);

        itemData.transactionDate = itemData.transactionFormattedDate;
        itemData.transactions = daysTransactions;

        if (itemData.transactionType === TransactionType.Job) {
            // pull the job cost codes
            await fetchJobCostCodes(itemData.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }

        setTimeEntryData(itemData);
        setEditRowKeysRef({
            activityId:      itemData.activityId,
            costcodeId:      itemData.costcodeId,
            paycodeId:       itemData.paycodeId,
            shiftCodeId:     itemData.shiftCodeId,
            equipmentId:     itemData.equipmentId,
            employeeId:      itemData.employeeId,
            transactionDate: itemData.transactionDate,
            id:              itemData.id
        });

        entryActions = cloneDeep(EditActionItems.filter(eai => eai.allowedStatuses.includes(itemData.status) && eai.isEntryAction));
        if (!useTimeInOut || hasTimedInEntry) {
            entryActions = entryActions.filter(ea => ea.id !== 'timeInNewEntry');
        }
        noteActions = cloneDeep(EditActionItems.filter(eai => eai.allowedStatuses.includes(itemData.status) && eai.isNoteAction && eai.requiresNotes === hasNotes));
        entryActions.forEach(ea => allowedActions.push(ea));
        noteActions.forEach(na => allowedActions.push(na));
        allowedActions.forEach(aa => {
            aa.text = t(EditActionItems.find(a => a.id === aa.id).text);
        });
        setAllowedActionItems(orderBy(allowedActions, 'index', 'asc'));

        await setActionSheetState({isVisible: true});
    }, [
        currentTimesheet.timeTransactions,
        EditActionItems,
        hasTimedInEntry,
        setActionSheetState,
        setSelectedEntry,
        setTimeEntryData,
        setEditRowKeysRef
    ]);

    const handleTimeInNewEntry = async transaction => {
        const timeInValue = setMilliseconds(setSeconds(new Date(), 0), 0);

        let formData = cloneDeep(defaultFormData);
        formData.transactionType = transaction.transactionType;
        formData.transactionDate = format(timeInValue, 'yyyy-MM-dd');
        formData.transactionFormattedDate = format(timeInValue, 'yyyy-MM-dd');
        formData.activityId = transaction.activityId;
        formData.costcodeId = transaction.costcodeId;
        formData.paycodeId = transaction.paycodeId;
        formData.employeeId = {id: activeEmployeeRef.current?.id};
        formData.shiftCodeId = transaction.shiftCodeId;
        formData.hours = null;
        formData.timeIn = timeInValue;
        formData.timeOut = null;
        formData.status = TransactionStatus.TimedIn;
        formData.isTrxDirty = true;

        if (formData.transactionType === TransactionType.Job) {
            await fetchJobCostCodes(formData.activityId.id).then(codes => {
                setJobCostCodes(codes);
            });
        }

        setIsSaveDisabled(false);
        setIsNewRecordRef(true);
        setNewId(null);
        setSelectedTransactionId(null);
        setTimeEntryData(formData);
        setTimeTransactionData(formData);
        setIsFormDirtyState(true);
        setTimeEntryPopupVisibleRef(true);
    };

    const handleTimeOut = async (transaction) => {

        const allowTimeOut = validateTimeOut(transaction, currentTimesheet.timeTransactions, t, true);

        if (allowTimeOut) {
            let timeOutTime = setMilliseconds(setSeconds(new Date(), 0), 0);
            let timeDiff = differenceInMinutes(timeOutTime, new Date(transaction.timeIn));
            if (timeDiff > 1440) {
                timeOutTime = addMinutes(new Date(transaction.timeIn), 1440);
                timeDiff = 1440;
            }
            const newHours = toHoursAndMinutes(timeDiff);

            let newData = {
                ...transaction,
                ...{'timeOut': timeOutTime},
                ...{'hours': Number(newHours)},
                ...{'isTrxDirty': true},
                ...{'status': TransactionStatus.Open}
            };

            onDataChanged(newData);
            handleFormSave();
        }
    };

    const handleTimeEntryDeletion = async (transaction) => {
        if (transaction) {
            const trxId = transaction.id;
            setIsDeleteConfirmation(true);
            setDeleteConfirmationTitle(t('time.deleteTimeEntry'));
            setDeleteConfirmationData({type: 'single', data: transaction});
            setDeleteTransactions([{id: trxId, formattedDate: transaction.transactionFormattedDate}]);
            setShowConfirmationDialog(true);
        }
    };

    const onFormDataChanged = useCallback(async data => {
        await setTimeEntryData(data);
    }, [setTimeEntryData]);


    const onDataChanged = useCallback((data) => {
        setTimeTransactionData(data);
        setEditRowKeysRef({
            activityId:      data.activityId,
            costcodeId:      data.costcodeId,
            paycodeId:       data.paycodeId,
            shiftCodeId:     data.shiftCodeId,
            equipmentId:     data.equipmentId,
            employeeId:      data.employeeId,
            transactionDate: data.transactionFormattedDate,
            id:              data.id
        });
        setTimeEntryData(data);
    }, []);

    const handlePopupHiding = useCallback(async e => {
        const promptForClose = async () => {
            const customDialog = custom({
                title:       t('discardChanges'),
                messageHtml: t('time.discardChangesConfirmation'),
                buttons:     [{
                    text:        t('common.yes'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return true;
                    }
                },
                {
                    text:        t('common.no'),
                    stylingMode: 'text',
                    onClick:     e => {
                        return false;
                    }
                }]
            });

            const result = customDialog.show()
                .then(dialogResult => {
                    if (dialogResult) {
                        setSelectedTransactionId();
                        return true;
                    }
                    else {
                        return false;
                    }
                });
            return result;
        };
        if (isFormDirtyStateRef.current) {
            e.cancel = promptForClose();
        }
    }, [isFormDirtyStateRef, setSelectedTransactionId]);

    const handleFormSave = useCallback(async (e) => {
        const transactions = [cloneDeep(timeTransactionData.current)];

        const timesheetData = {
            timesheetId:        timeTransactionData.current.timesheetId,
            timesheetStartDate: timesheetStartDate,
            employeeId:         activeEmployee.id,
            transactions:       []
        };

        if (timeTransactionData.current) {
            const trx = cloneDeep(timeTransactionData.current);
            const periodEndDate = setHours(setMinutes(setSeconds(new Date(currentWeekEndingDate),59),59),23);
            if (useTimeInOut && !isNull(trx.timeOut) && isAfter(new Date(trx.timeOut), periodEndDate)) {
                const updatedTransactions = splitPayPeriodTransactions(trx);
                let futureResult = {success: false};
                if (updatedTransactions.currentTransaction) {
                    const offset = new Date().getTimezoneOffset();
                    const current = prepareTimeEntriesForSave([updatedTransactions.currentTransaction], activeEmployee);
                    const future = prepareTimeEntriesForSave([updatedTransactions.futureTransaction], activeEmployee);

                    // save the current timesheet
                    timesheetData.transactions = current;
                    const currentResult = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                    if (currentResult.success) {
                        setNewId(currentResult.id[0]);
                        setIsFormDirtyState(false);
                        setIsNewRecordRef(false);

                        const newTimesheetStartDate = formatISO(addMinutes(addDays(new Date(timesheetStartDate), 7), offset), {representation: 'date'});
                        timesheetData.timesheetId = null;
                        timesheetData.timesheetStartDate = newTimesheetStartDate;
                        timesheetData.employeeId = activeEmployee.id;
                        timesheetData.transactions = future;

                        futureResult = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                        if (futureResult.success) {
                            if (future[0].notes && future[0].notes.length !== 0) {
                                const futureNote = cloneDeep(future[0].notes[0]);
                                if (futureNote) {
                                    futureNote.id = null;
                                    futureNote.annotationType = AnnotationTypes.TIMETRANSACTION;
                                    futureNote.objectId = futureResult.id[0];
                                    await createAnnotation(activeCompany.id, futureNote, t);
                                }
                            }
                            showToastMessage(t('time.transactionCrossedPeriods'), 'warning');
                        }

                        const listInstance = entryListRef.current?.instance;
                        if (listInstance)  {
                            listInstance.reload();
                        }

                        return currentResult.success && futureResult.success;
                    }
                }
                else {
                    showToastMessage(t('time.noEntryToProcess', 'error'));
                }
            }
            else {
                const preparedTransactions = prepareTimeEntriesForSave(transactions, activeEmployee);
                timesheetData.transactions = preparedTransactions;

                const result = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                if (result.success) {
                    setNewId(result.id[0]);
                    setIsFormDirtyState(false);
                    setIsNewRecordRef(false);
                    const listInstance = entryListRef.current?.instance;
                    if (listInstance)  {
                        listInstance.reload();
                    }

                }
                return result.success;
            }
        }
        else {
            return true;
        }
    }, [
        timeTransactionData.current,
        timesheetStartDate,
        activeEmployee,
        currentWeekEndingDate,
        useTimeInOut
    ]);

    const handleFormReset = useCallback(async (transactionType, transactionDate) => {
        let formData = cloneDeep(defaultFormData);
        formData.transactionDate = transactionDate;
        formData.transactionFormattedDate = transactionDate;

        formData.transactionDate = editRowKeysRef.current ? editRowKeysRef.current.transactionDate : transactionDate;
        formData.transactionFormattedDate = editRowKeysRef.current ? editRowKeysRef.current.transactionDate : transactionDate;
        formData.activityId = editRowKeysRef.current ? editRowKeysRef.current.activityId : null;
        formData.costcodeId = editRowKeysRef.current ? editRowKeysRef.current.costcodeId : null;
        formData.paycodeId = editRowKeysRef.current ? editRowKeysRef.current.paycodeId : null;
        formData.shiftCodeId = editRowKeysRef.current ? editRowKeysRef.current.shiftCodeId : null;
        formData.transactionType = transactionType;

        createNewTransaction(transactionType, transactionDate, formData);
    }, [editRowKeysRef]);

    const isResetFormRef = useRef(false);
    const setIsResetFormRef = data => {
        isResetFormRef.current = data;
        setSelectedTransactionId();
    };

    const handleDeleteTimeConfirmation = async () => {
        if (deleteTransactions.length !== 0) {
            const promises = [];
            deleteTransactions.forEach(async trx => {
                promises.push(deleteTimeTransactions({id: trx.id, formattedDate: trx.formattedDate}, t));
            });

            await Promise.all(promises.map(p => p))
                .then(async result => {
                    const listInstance = entryListRef.current?.instance;
                    if (listInstance)  {
                        listInstance.reload();
                    }
                    setDeleteTransactions([]);
                    setDeleteConfirmationData();
                    setShowConfirmationDialog(false);
                    setTimeEntryPopupVisible(false);
                });
        }
        else {
            setDeleteTransactions([]);
            setDeleteConfirmationData();
            setShowConfirmationDialog(false);
            setTimeEntryPopupVisible(false);
        }
    };

    const handleSubmitConfirmation = async () => {
        setShowConfirmationDialog(false);
        let acknowledgementTimestamp = new Date();
        const listInstance = entryListRef.current?.instance;
        const timesheet = {
            timesheetId:                 currentTimesheetRef.current.id,
            timesheetStartDate:          currentTimesheetRef.current.timesheetStartDate ? currentTimesheetRef.current.timesheetStartDate : timesheetStartDateRef.current,
            timesheetFormattedStartDate: currentTimesheetRef.current.timesheetFormattedStartDate,
            employeeId:                  activeEmployeeRef.current?.id,
            transactions:                []
        };
        transactionsToSubmit.forEach(t => {
            t.activityId = t.activityId.id;
            t.costcodeId = t.costcodeId && t.costcodeId.id ? t.costcodeId.id : null;
            t.paycodeId = t.paycodeId.id;
            t.shiftCodeId = t.shiftCodeId.id;
            t.employeeId = t.employeeId.id;
            t.equipmentId = t.equipmentId && t.equipmentId.id ? t.equimentId.id : null;
            t.status = TransactionStatus.Submitted;
            t.acknowledgementTimestamp = settings.useTransactionAcknowledgement ? acknowledgementTimestamp : null;
        });
        timesheet.transactions = transactionsToSubmit;
        if (listInstance) {
            listInstance.beginUpdate();
        }
        await insertTimeSheetTransaction(activeEmployeeRef.current?.id, timesheet, t);

        setTransactionsToSubmit([]);
        setAllowSubmitting(false);

        if (listInstance) {
            listInstance.reload();
            listInstance.endUpdate();
        }
    };

    const handleDenyConfirmation = async () => {
        setShowConfirmationDialog(false);
    };

    const handleDeleteDeny = async () => {
        setShowConfirmationDialog(false);
        setIsDeleteNoteConfirmationVisible(false);
    };

    const renderTimeDeleteContent = (e) => {
        if (deleteConfirmationData) {
            const {data, column} = deleteConfirmationData.data;
            let transactions = [];
            let formattedDate;
            if (column) {
                transactions = data[column.caption + '_transactions'];
                formattedDate = column.caption;
            }
            else {
                formattedDate = deleteConfirmationData.data.transactionFormattedDate;
                transactions.push(deleteConfirmationData.data);
            }

            if (transactions.length === 1) {
                const hasNotes = transactions[0].notes.length !== 0;
                return (
                    <>
                        <div>
                            <div className="confirmation-dialog-header">
                                {
                                    transactions[0].hours ?
                                        t('time.deleteTimeConfirmation') :
                                        null
                                }
                            </div>
                            <div className="confirmation-costcode-value">
                                {
                                    transactions[0].hours ?
                                        t('time.deleteTimeEntryMessage', {transDate: parseISO(formattedDate), hours: transactions[0].hours}) :
                                        t('time.deleteTimedInEntry')
                                }
                            </div>
                            {
                                hasNotes && hasNotes.length !== 0 ?
                                    <div className="confirmation-costcode-value" style={{paddingTop: '10px'}}>
                                        {t('time.associatedNoteDeletion')}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                );
            }
        }
    };

    const handleAddNote = (entry) => {
        const transDate = parseISO(entry.transactionFormattedDate);
        const transactionId = entry.id;
        setSelectedTransactionId(transactionId);
        const noteLabel = t('time.noteLabel', {transDate: transDate, hourCount: entry.hours });
        setNoteSectionLabel(noteLabel);
        setIsNoteReadOnly(false);
        setIsNewNote(true);

        const transactionNoteData = {
            id:             null,
            annotationType: 'TimeTransaction',
            isAttachemnt:   false,
            attachment:     null,
            subject:        null,
            noteText:       null
        };

        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    };

    const handleUpdateNote = async (entry) => {
        const noteLabel = t('time.noteLabel', {transDate: parseISO(entry.transactionFormattedDate), hourCount: entry.hours});
        setNoteSectionLabel(noteLabel);
        const transactionId = entry.id;
        setSelectedTransactionId(transactionId);
        setIsNoteReadOnly(false);
        setIsNewNote(false);
        let transactionNoteData = {};
        if (entry.notes && entry.notes.length !== 0) {
            transactionNoteData.id = entry.notes[0].id;
            transactionNoteData.annotationType = 'TimeTransaction';
            transactionNoteData.isAttachment = false;
            transactionNoteData.subject = entry.notes[0].subject;
            transactionNoteData.noteText = entry.notes[0].noteText;
        }
        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    };

    const handleDeleteNote = async (entry) => {
        const noteData = entry.notes[0];
        setNoteToDelete(noteData);
        setSelectedTransactionId(entry.id);
        const title = `${t('common.delete')} ${t('time.noteLabel', {transDate: parseISO(entry.transactionFormattedDate), hourCount: entry.hours})}`;
        setDeleteConfirmationData(entry);
        setDeleteConfirmationTitle(title);
        setIsDeleteNoteConfirmationVisible(true);
    };

    const handleViewNote = async (entry) => {
        const noteLabel = t('time.noteLabel', {transDate: parseISO(entry.transactionFormattedDate), hourCount: entry.hours});
        setNoteSectionLabel(noteLabel);
        const transactionId = entry.id;
        setSelectedTransactionId(transactionId);
        setIsNoteReadOnly(true);
        setIsNewNote(false);

        let transactionNoteData = {};
        if (entry.notes && entry.notes.length !== 0) {
            transactionNoteData.id = entry.notes[0].id;
            transactionNoteData.annotationType = 'TimeTransaction';
            transactionNoteData.isAttachment = false;
            transactionNoteData.subject = entry.notes[0].subject;
            transactionNoteData.noteText = entry.notes[0].noteText;
        }
        setNoteData(transactionNoteData);
        setIsShowAnnotationForm(true);
    };

    const handleDeleteNoteConfirmation = useCallback(async () => {
        if (noteToDelete) {
            const listInstance = entryListRef.current?.instance;
            const deleted = await deleteAnnotation(activeCompany.id, noteToDelete.id, noteToDelete.isAttachment, t);
            if (deleted && listInstance) {
                if (selectedTransactionId) {
                    const trxToUpdate = currentTimesheet.timeTransactions.find(trx => trx.id === selectedTransactionId);
                    if (trxToUpdate && trxToUpdate.status === TransactionStatus.Rejected) {
                        trxToUpdate.status = TransactionStatus.Open;
                        trxToUpdate.statusComment = null;

                        trxToUpdate.employeeId = trxToUpdate.employeeId?.id;
                        trxToUpdate.activityId = trxToUpdate.activityId?.id;
                        trxToUpdate.paycodeId = trxToUpdate.paycodeId?.id;
                        trxToUpdate.shiftCodeId = trxToUpdate.shiftCode?.id;
                        trxToUpdate.equipmentId = trxToUpdate.equipmentId?.id;
                        if (trxToUpdate.costcodeId) {
                            trxToUpdate.costcodeId = trxToUpdate.costcodeId?.id;
                        }

                        const timesheetData = {
                            timesheetId:        trxToUpdate.timesheetId,
                            timesheetStartDate: timesheetStartDate,
                            employeeId:         trxToUpdate.employeeId,
                            transactions:       [trxToUpdate]
                        };
                        const result = await insertTimeSheetTransaction(timesheetData.employeeId, timesheetData, t);
                        if (result.success) {
                            listInstance.reload();
                        }
                    }
                    else {
                        listInstance.reload();
                    }
                }
                else {
                    listInstance.reload();
                }

            }
        }

        setShowConfirmationDialog(false);
        setIsDeleteNoteConfirmationVisible(false);
    }, [noteToDelete, selectedTransactionId, currentTimesheet, timesheetStartDate]);

    const renderConfirmationDialogContent = () => {
        return (
            <>
                <ScrollView
                    showScrollbar="always"
                    useNative={false}
                >
                    <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                        <div>
                            <div className="confirmation-dialog-header">
                                {confirmationDialogMessage}
                            </div>
                        </div>
                    </div>
                    {settings.useTransactionAcknowledgement ?
                        <div className="confirmation-acknowledgement-message">
                            {parse(settings.transactionAcknowledgementMessage)}
                        </div>
                        :
                        null
                    }
                </ScrollView>
            </>
        );
    };

    const renderNoteDeleteContent = () => {
        return (
            <>
                <div className="confirmation-dialog-message" style={{textAlign: 'top'}}>
                    <div>
                        <div className="confirmation-dialog-header">
                            {t('time.deleteNoteConfirmation')}
                        </div>
                        <div className="confirmation-note-message" style={{width: '100%'}}>
                            {t('time.deleteNoteConfirmationDate', {transDate: parseISO(deleteConfirmationData.transactionFormattedDate)})}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleAnnotationCancel = () => {
        setIsShowAnnotationForm(false);
        setIsNoteReadOnly(false);
    };

    const handleRefreshSavedNote = async (savedNote) => {
        const newAnnotation = await fetchAnnotationById(savedNote.id);
        if (newAnnotation) {
            const listInstance = entryListRef.current?.instance;
            if (listInstance)  {
                listInstance.reload();
            }
        }
    };

    const newActionItems = [
        {
            icon:    'fa-regular fa-building',
            label:   t('time.jobActivity'),
            index:   0,
            onClick: onNewJobActivityClick
        },
        {
            icon:    'fa-solid fa-hammer',
            label:   t('time.serviceActivity'),
            index:   1,
            onClick: onNewServiceActivityClick

        },
        {
            icon:    'fa-regular fa-money-bill-1',
            label:   t('time.unbilledActivity'),
            index:   2,
            onClick: onNewUnbilledActivityClick
        }
    ];

    const copyTimesheetAction = {
        icon:    'fa-regular fa-copy',
        label:   t('time.copyTimesheet'),
        index:   3,
        onClick: onCopyTimeSheetClick
    };

    return (
        <>
            <div className="mobile-wrapper">
                <div className="mobile-toolbar">{renderToolbars()}</div>
                <ScrollView
                    height={listHeight}
                >
                    <div className="mobile-list-view">
                        <List
                            ref={entryListRef}
                            collapsibleGroups={isListGrouped}
                            dataSource={listDataSource}
                            grouped={isListGrouped}
                            groupRender={renderGroupView(i18n.resolvedLanguage, t)}
                            height={listHeight}
                            itemRender={renderDayView(settings.useShiftCode, useTimeInOut, t, i18n.resolvedLanguage)}
                            pullRefreshEnabled={true}
                            searchExpr={listSearchExpression}
                            searchEnabled={false}
                            searchMode="contains"
                            onItemClick={handleItemClick}
                            noDataText={t('time.createNewTimeEntry')}
                        />
                        {
                            activityItemDataSource.map((item, index) =>
                                <SpeedDialAction
                                    icon={item.icon}
                                    label={item.label}
                                    index={item.index}
                                    onClick={item.onClick}
                                    key={index}
                                />)
                        }
                    </div>
                </ScrollView>
                <div className="total-hours-footer">
                    <div className="totals-container">
                        <div className="regular-hours-label">{`${t('time.regular')}:`}</div>
                        <div className="regular-hours">
                            {parseFloat(totalHours.regularHours).toFixed(2)}
                        </div>
                        <div className="other-hours-label">{`${t('time.other')}:`}</div>
                        <div className="other-hours">
                            {parseFloat(totalHours.otherHours).toFixed(2)}
                        </div>
                        <div className="totals-cell">{`${t('time.total')}: ${parseFloat(
                            totalHours.totalHours
                        ).toFixed(2)}`}</div>
                        <>
                            <div className="totals-top" />
                            <div className="totals-bottom" />
                        </>
                    </div>
                </div>
            </div>

            <ActionSheet
                cancelText={t('common.cancel')}
                dataSource={allowdActionItems}
                title={t('time.chooseAction')}
                showTitle={true}
                showCancelButton={true}
                visible={actionSheetState.isVisible}
                onItemClick={handleActionSheetItemClick}
                onCancelClick={handleActionSheetCancelClick}
            />
            { timeEntryPopupVisible ?
                <FormPopup
                    cancelButtonText={t('common.cancel')}
                    isFormDirtyStateRef={isFormDirtyStateRef}
                    isReadOnly={timeEntryData?.status === TransactionStatus.Approved || timeEntryData?.status === TransactionStatus.Submitted || timeEntryData?.status === TransactionStatus.Processed}
                    onHiding={handlePopupHiding}
                    onSave={handleFormSave}
                    onResetForm={handleFormReset}
                    showMultiSaveButton={true}
                    setIsFormDirtyState={setIsFormDirtyState}
                    setIsResetForm={setIsResetFormRef}
                    setVisible={changePopupVisibility}
                    title={`${t('time.timeEntry')} - ${activeEmployee.firstName} ${activeEmployee.lastName} `}
                    visible={timeEntryPopupVisible}
                    isSaveDisabled={isSaveDisabled}
                >
                    <TimeEntryForm
                        data={timeEntryData}
                        formRef={formRef}
                        fullTransactionList={currentTimesheet.timeTransactions}
                        isMobileView={true}
                        listInstance={entryListRef.current?.instance}
                        handleTimeEntryDeletion={handleTimeEntryDeletion}
                        isFormDirtyStateRef={isFormDirtyStateRef}
                        isNewRecord={isNewRecordRef.current}
                        isResetForm={isResetFormRef}
                        onFormDataChanged={onFormDataChanged}
                        onDataChanged={onDataChanged}
                        jobCostCodes={jobCostCodes}
                        selectedTransactionId={selectedTransactionId}
                        setSelectedTransactionId={setSelectedTransactionId}
                        setEditRowKeys={setEditRowKeysRef}
                        setIsFormDirtyState={setIsFormDirtyState}
                        setJobCostCodes={setJobCostCodes}
                    />
                </FormPopup>
                : null
            }
            { showConfirmationDialog ?
                <ConfirmationDialog
                    showDialog={showConfirmationDialog}
                    confirmationTitle={!isDeleteConfirmation ? t('time.submitTimeEntries') : deleteConfirmationTitle}
                    confirmButtonAction={!isDeleteConfirmation ? handleSubmitConfirmation : handleDeleteTimeConfirmation}
                    confirmButtonText={t('common.yes')}
                    denyButtonAction={!isDeleteConfirmation ? handleDenyConfirmation : handleDeleteDeny}
                    denyButtonText={t('common.no')}
                    dialogMessage={confirmationDialogMessage}
                    height="auto"
                    renderContent={isDeleteConfirmation ? renderTimeDeleteContent : renderConfirmationDialogContent}
                />
                : null
            }
            { isDeleteNoteConfirmationVisible ?
                <ConfirmationDialog
                    showDialog={isDeleteNoteConfirmationVisible}
                    confirmationTitle={deleteConfirmationTitle}
                    confirmButtonAction={handleDeleteNoteConfirmation}
                    confirmButtonText={t('common.yes')}
                    denyButtonAction={handleDeleteDeny}
                    denyButtonText={t('common.no')}
                    renderContent={renderNoteDeleteContent}
                    height={300}
                    width={'calc(100% - 10px)'}
                />
                : null
            }
            { isShowAnnotationForm ?
                <AnnotationForm
                    activeEmployee={activeEmployee}
                    annotationType={AnnotationTypes.TIMETRANSACTION}
                    cancelButtonAction={handleAnnotationCancel}
                    handleAddNote={handleAddNote}
                    isNoteReadOnly={isNoteReadOnly}
                    isNewNote={isNewNote}
                    noteData={noteData}
                    noteSectionLabel={noteSectionLabel}
                    objectId={selectedTransactionId}
                    popupWidth={isXSmall ? 'calc(100vw - 20px)' : '400px'}
                    setIsShowAnnotationForm={setIsShowAnnotationForm}
                    showAnnotationForm={isShowAnnotationForm}
                    showAttachmentForm={false}
                    showNoteForm={true}
                    target={annotationTarget}
                    title={t('time.annotations')}
                    useTimeEntryForm={true}
                    listInstance={entryListRef.current?.instance}
                    handleRefreshSavedNote={handleRefreshSavedNote}
                />
                : null}
            {
                showEmployeeReportSelectionModal ?
                    <FormPopup
                        cancelButtonText={t('common.cancel')}
                        onSave={printSelectedEmployeesTimesheet}
                        setVisible={setShowEmployeeReportSelectionModal}
                        saveButtonIcon={'fa-solid fa-file-pdf'}
                        saveButtonText={t('common.generate')}
                        title={t('report.selectemployees')}
                        visible={showEmployeeReportSelectionModal}
                    >
                        <EmployeeSelection
                            employeeList={filteredEmployeeList}
                            setSelectedEmployeeIds={setEmployeeTimesheetReportListRef}
                        />
                    </FormPopup>
                    : null
            }
            <LoadPanel
                visible={showLoading}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    activeCompany:         state.time.activeCompany,
    activeEmployee:        state.employee.activeEmployee,
    currentOS:             state.core.currentOS,
    currentTimesheet:      state.time.currentTimesheet,
    currentUser:           state.currentUser,
    currentWeekEndingDate: state.time.currentWeekEndingDate,
    employeePayCodes:      state.employee.employeePayCodes,
    featureFlags:          state.core.featureFlags,
    filteredEmployeeList:  state.employee.filteredEmployeeList,
    isInRefresh:           state.time.isInRefresh,
    isLoadingTimesheet:    state.time.isLoadingTimesheet,
    periodDays:            state.time.periodDays,
    settings:              state.time.settings,
    shiftCodes:            state.time.shiftCodes,
    subTotals:             state.time.currentTimesheet.subTotals,
    timesheetStartDate:    state.time.timesheetStartDate,
    timeUserSettings:      state.configurations.timeUserSettings
});

const mapDispatchToProps = {
    ...AnnotationActions,
    ...ActivitiesActions,
    ...EmployeeActions,
    ...JobActions,
    ...TimeActions,
    ...WorkOrderActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryMobileList);
