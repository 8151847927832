import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { get } from 'lodash';

import template from 'url-template';

import {
    FETCH_CURRENT_USER_ROLE,
    SET_CURRENT_USER,
    USER_LOGOUT
} from '../actions/actionTypes';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';
import showToastMessage from '../helpers/toastHelper';

const CurrentUserRoleUrl = template.parse('/companies/{companyId}/accounts{?page,limit}');

const DEFAULT_PAGE_LIMIT = 100;

const defaultCurrentUserPayload = {
    defaultCompany: null,
    email:          null,
    firstName:      null,
    lastName:       null,
    phone:          null,
    id:             null,
    userName:       null,
    isSuperUser:    false
};

export const initUser = () => async (dispatch, getState) => {
    try {
        await fetchAuthSession()
            .then(authSession => {
                const { tokens } = authSession;
                let payload = getState().currentUser;

                if (tokens && tokens.idToken && tokens.idToken.payload) {
                    payload = {
                        defaultCompany: tokens.idToken.payload['custom:diDefaultCompanyId'],
                        email:          tokens.idToken.payload.email,
                        firstName:      tokens.idToken.payload.given_name,
                        lastName:       tokens.idToken.payload.family_name,
                        phone:          tokens.idToken.payload.phone_number,
                        id:             tokens.idToken.payload['custom:diAccountId'],
                        userName:       `${tokens.idToken.payload.given_name} ${tokens.idToken.payload.family_name}`,
                        isSuperUser:    payload.isSuperUser ? payload.isSuperUser : false
                    };
                }
                else {
                    payload = defaultCurrentUserPayload;
                }
                dispatch({
                    type: SET_CURRENT_USER,
                    payload
                });
            })
            .catch(error => {
                /* eslint no-console: ["error", { allow: ["warn"] }] */
                console.warn('Authorization Error: ', error);
                sessionStorage.removeItem('returnUrl');
                signOut({global: true})
                    .catch(err => {
                        signOut();
                    });
                dispatch({
                    type: USER_LOGOUT
                });
            });
    }
    catch (err) {
        showToastMessage(err, 'error');
        dispatch({
            type:    SET_CURRENT_USER,
            payload: defaultCurrentUserPayload
        });
    }
};

export const fetchCurrentUserRole = (companyId, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();
    const currentUser = getState().currentUser;

    let totalPayload = [];

    let user = {
        id:   currentUser.id,
        role: {...currentUser.role}
    };

    try {
        const getPayload = async (page = 1) => {
            options.limit = DEFAULT_PAGE_LIMIT;
            options.page = page;

            const response = await wennsoftBaseApi.get(
                CurrentUserRoleUrl.expand({
                    companyId,
                    ...options
                }),
                header
            );

            const payload = get(response, 'data.data', []);
            const totalCount = get(response, 'data.meta.totalCount', 0);

            totalPayload = [...totalPayload, ...payload];

            page++;

            if (payload.length > 0 && totalPayload.length < totalCount) {
                return await getPayload(page);
            }

            return;
        };

        await getPayload();

        const activeUser = totalPayload.find(({ email }) => email === currentUser.email);
        //let emailList = totalPayload.map((user) => { return user.email; });

        if (activeUser) {
            user = {
                id:   activeUser.id,
                role: activeUser.role
            };
        }

        // set emails for validation on save configuration modal
        //dispatch(setEmails(emailList));

        dispatch({
            type:    FETCH_CURRENT_USER_ROLE,
            payload: user
        });
    } catch (error) {
        showToastMessage(error, 'error');
        //dispatch(handleNetworkError(error));
    }
};

export const setCurrentUser = payload => {
    return {
        type: SET_CURRENT_USER,
        payload
    };
};
