import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Drawer from 'devextreme-react/drawer';
import { Header } from '../../components';

import './AccessDenied.scss';

const AccessDenied = ({
    activeCompany,
    currentOS,
    currentUser,
    isMobileApp
}) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (activeCompany.id) {
            setSearchParams({companyId: activeCompany.id});
        }
    }, [activeCompany.id]);

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header
                menuToggleEnabled
                toggleMenu={() => {}}
                title={'Signature Time'}
                currentOS={currentOS}
                currentUser={currentUser}
                isMobileApp={isMobileApp}
            />
            <Drawer
                position={'before'}
                opened={false}
            >
                <div className="page-container">
                    <div className="image-container">
                        <img
                            alt="logo"
                            src="/assets/images/WennSoft+BLUE+3200x850.png?format=1500w"
                            width={448}
                            height={119}
                        />
                    </div>
                    <div className="message-container">
                        <h2>Access Denied</h2>
                        <p>We are sorry the page you are trying to access has restricted access.<br />
                            Please contact your system administrator if you have any questions.</p>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AccessDenied;
