import { combineReducers } from 'redux';

import activitiesReducers from './activitiesReducer';
import annotationReducers from './annotationReducer';
import authReducers from './authReducers';
import companyInfoReducers from './companyInfoReducers';
import companyUserReducers from './companyUserReducers';
import configurationReducers from './configurationReducer';
import coreReducers from './coreReducers';
import currentUserReducers from './currentUserReducers';
import employeeReducers from './employeeReducers';
import jobReducers from './jobReducers';
import workOrderReducers from './workOrderReducers';
import timeReducers from './timeReducers';
import websocketReducers from './websocketReducers';

const appReducer = combineReducers({
    activities:     activitiesReducers,
    annotations:    annotationReducers,
    auth:           authReducers,
    companyInfo:    companyInfoReducers,
    companyUser:    companyUserReducers,
    configurations: configurationReducers,
    core:           coreReducers,
    currentUser:    currentUserReducers,
    employee:       employeeReducers,
    job:            jobReducers,
    workOrder:      workOrderReducers,
    time:           timeReducers,
    websocket:      websocketReducers
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
