import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import TimeEntryHeader from '../../components/timeEntryHeader/TimeEntryHeader';
import TimeEntryTable from '../../components/timeEntryTable/TimeEntryTable';
import TimeEntryList from '../../components/time-entry-list/TimeEntryList';
import TimeEntryMobileList from '../../components/time-entry-mobile-list/TimeEntryMobileList';
import devices from 'devextreme/core/devices';
import { useScreenSize } from '../../utils/media-query';

import './TimeEntry.scss';

const TimeEntry = ({
    activeCompany,
    featureFlags,
    timeCompanies
}) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams({});
    // eslint-disable-next-line no-unused-vars
    const [selectedCompany, setSelectedCompany] = useState();
    // eslint-disable-next-line no-unused-vars
    const [companyList, setCompanyList] = useState([]);
    const [useTimeEntryForm, setUseTimeEntryForm] = useState(false);
    const [useTimeEntryMobileList, setUseTimeEntryMobileList] = useState(false);

    const {isXSmall, isSmall, isMedium } = useScreenSize();

    useEffect(() => {
        setSearchParams({companyId: activeCompany.id});
    }, [activeCompany.id]);

    useEffect(() => {
        const featureFlagForm = featureFlags.find(ff => ff.name === 'useTimeEntryForm');
        const featureFlagList = featureFlags.find(ff => ff.name === 'useTimeEntryMobileList');
        if (featureFlagForm) {
            setUseTimeEntryForm(featureFlagForm.featureFl);
        }
        if (featureFlagList) {
            setUseTimeEntryMobileList(featureFlagList.featureFl);
        }
    }, [featureFlags]);

    useEffect(() => {
        if (!isEmpty(timeCompanies)) {
            const list = timeCompanies.map(c => c.company);
            setSelectedCompany(list[0].id);
            setCompanyList(list);
        }
    }, [timeCompanies]);

    return (
        <>
            <div className={ isXSmall || isSmall || isMedium ? 'mobile-list-block' : 'content-block'}>
                {!useTimeEntryForm ?
                    <>
                        <TimeEntryHeader />
                        <div className={'time-entry-table'}>
                            <TimeEntryTable />
                        </div>
                    </>
                    :
                    (useTimeEntryForm && useTimeEntryMobileList) ?
                        (devices.current().phone || devices.current().tablet ?  <TimeEntryMobileList /> : <TimeEntryList />)
                        :
                        <TimeEntryList />
                }
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    activeCompany:  state.time.activeCompany,
    activeEmployee: state.employee.activeEmployee,
    companies:      state.core.companies,
    companyInfo:    state.companyInfo,
    currentUser:    state.currentUser,
    featureFlags:   state.core.featureFlags,
    instanceId:     state.auth.instanceId,
    session:        state.auth.session,
    timeCompanies:  state.core.timeCompanies
});

export default connect(mapStateToProps, null)(TimeEntry);
