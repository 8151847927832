import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { cloneDeep, isEmpty } from 'lodash';
import PeriodSelector from '../PeriodSelector/PeriodSelector';
import ConfirationDialog from '../confirmationDialog/confirmationDialog';

import * as ActivitiesActions from '../../actions/activitiesActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as JobActions from '../../actions/jobActions';
import * as TimeActions from '../../actions/timeActions';
import * as WorkOrderActions from '../../actions/workOrderActions';

import './TimeEntryHeader.scss';
import { useTranslation } from 'react-i18next';
import { TransactionStatus } from '../../data/transactionStatuses';

const TimeEntryHeader = ({
    activeCompany,
    activeEmployee,
    currentUser,
    currentTimesheet,
    employeeList,
    featureFlags,
    fetchEmployeePayCodes,
    fetchEmployeeTimesheet,
    fetchJobList,
    fetchUnbilledActivities,
    fetchWorkOrderList,
    fetchWorkOrderCostCodes,
    insertTimeSheetTransaction,
    isInEditMode,
    isSaveValid,
    setActiveEmployee,
    setIsInRefresh,
    timesheetData,
    timesheetGridInstance,
    timesheetStartDate
}) => {
    const { t } = useTranslation();
    const [selectedEmployee, setSelectedEmployee] = useState(activeEmployee?.id);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('Default Message');
    const [showEmployeeChangeDialog, setShowEmployeeChangeDialog] = useState(false);
    const [employeeChangeId, setEmployeeChangeId] = useState();
    const [allowSubmitting, setAllowSubmitting] = useState(true);
    const [transactionsToSubmit, setTransactionsToSubmit] = useState([]);
    const [showCompanyChangeDialog, setShowCompanyChangeDialog] = useState(false);
    const [useTimeEntryForm, setUseTimeEntryForm] = useState(false);

    useEffect(() => {
        const featureFlag = featureFlags.find(ff => ff.name === 'useTimeEntryForm');
        if (featureFlag) {
            setUseTimeEntryForm(featureFlag.featureFl);
        }
    }, [featureFlags]);

    useEffect(() => {
        if (activeEmployee && activeEmployee.id) {
            setSelectedEmployee(activeEmployee.id);
            fetchEmployeePayCodes(activeEmployee.id);
        }
    }, [activeEmployee.id]);

    useEffect(()=> {
        if (activeCompany.id && currentUser.id && employeeList.length !== 0) {
            const employee = employeeList.find(emp => emp.userId === currentUser.id);
            if (employee) {
                setActiveEmployee(employee);
            }
        }
    }, [employeeList]);

    useEffect(() => {
        const openTransactions = timesheetData.filter(trx => trx.status === TransactionStatus.Open);
        if (openTransactions.length !== 0) {
            setAllowSubmitting(true);
        }
        else {
            setAllowSubmitting(false);
        }
    }, [timesheetData]);

    const handleEmployeeChanged = async e =>  {
        const employee = employeeList.find(emp => emp.id === e.value);
        const gridRefInstance = timesheetGridInstance.current.instance;
        if (gridRefInstance) {
            if (isInEditMode) {
                await setConfirmationDialogMessage('You have unsaved transactions. Would you like to save?');
                await setEmployeeChangeId(e.value);
                await setShowEmployeeChangeDialog(true);
            }
            else {
                gridRefInstance.cancelEditData();
                setActiveEmployee(employee);
                fetchTimesheet(employee.id);
            }
        }
        else {
            setActiveEmployee(employee);
            fetchTimesheet(employee.id);
        }
    };

    const handleEmployeeChangeCofirmation = async () => {
        const gridRefInstance = timesheetGridInstance.current.instance;
        const employee = employeeList.find(emp => emp.id === employeeChangeId);
        if (gridRefInstance) {
            if (gridRefInstance.hasEditData()) {
                await gridRefInstance.saveEditData();
                if (isSaveValid) {
                    setActiveEmployee(employee);
                    fetchTimesheet(employee.id);
                }
            }
        }
        setShowEmployeeChangeDialog(false);
    };

    const handleEmployeeChangeDeny = () => {
        const gridRefInstance = timesheetGridInstance.current.instance;
        if (gridRefInstance) {
            if (gridRefInstance.hasEditData()) {
                gridRefInstance.cancelEditData();
            }
        }
        const employee = employeeList.find(emp => emp.id === employeeChangeId);
        setActiveEmployee(employee);
        setShowEmployeeChangeDialog(false);
        fetchTimesheet(employee.id);
    };

    const fetchTimesheet = async employeeId => {
        fetchEmployeePayCodes(employeeId);
        const options = {
            includeexternalorigindetails: true,
            companyid:                    activeCompany.id,
            requesterid:                  currentUser.id
        };

        if (timesheetStartDate) {
            if (!useTimeEntryForm) {
                await fetchEmployeeTimesheet([employeeId], timesheetStartDate, options);
            }
        }
    };

    const handleSubmit = async e => {
        const openTransactions = currentTimesheet.timeTransactions.filter(trx => trx.status === TransactionStatus.Open);

        if (openTransactions.length !== 0) {
            setTransactionsToSubmit(cloneDeep(openTransactions));
            setConfirmationDialogMessage(`Are you sure you want to submit ${openTransactions.length} transactions?`);
            setShowConfirmationDialog(true);
        }
    };

    const handleCofirmation = async () => {
        setShowConfirmationDialog(false);
        const timesheet = {
            timesheetId:                 currentTimesheet.id,
            timesheetStartDate:          currentTimesheet.timesheetStartDate ? currentTimesheet.timesheetStartDate : timesheetStartDate,
            timesheetFormattedStartDate: currentTimesheet.timesheetFormattedStartDate,
            employeeId:                  currentTimesheet.employeeId,
            transactions:                []
        };
        transactionsToSubmit.forEach(t => {
            t.activityId = t.activityId.id;
            t.costcodeId = t.costcodeId && t.costcodeId.id ? t.costcodeId.id : null;
            t.paycodeId = t.paycodeId.id;
            t.employeeId = t.employeeId.id;
            t.equipmentId = t.equipmentId && t.equipmentId.id ? t.equimentId.id : null;
            t.status = 'Submitted';
        });
        timesheet.transactions = transactionsToSubmit;
        await insertTimeSheetTransaction(activeEmployee.id, timesheet, t);
        setTransactionsToSubmit([]);
    };

    const handleDeny = () => {
        setShowConfirmationDialog(false);
        setTransactionsToSubmit([]);
    };

    const handleRefreshClick = useCallback((e) => {
        const init = async() => {
            const userSettings = sessionStorage.getItem('userSettings');
            let userSetting = {};
            if (userSettings) {
                userSetting = JSON.parse(userSettings);
            }

            const options = {
                includeexternalorigindetails: true,
                companyid:                    activeCompany.id,
                requesterId:                  currentUser.id
            };

            const gridRefInstance = timesheetGridInstance?.current?.instance;
            if (gridRefInstance) {
                if (gridRefInstance.hasEditData()) {
                    setShowCompanyChangeDialog(true);
                    return;
                }
                else {
                    gridRefInstance.cancelEditData();
                    setIsInRefresh(true);
                    setShowCompanyChangeDialog(false);
                    fetchEmployeePayCodes(activeEmployee.id);
                    fetchEmployeeTimesheet([activeEmployee.id], timesheetStartDate, options);
                }
            }
            else {
                setIsInRefresh(true);
                setShowCompanyChangeDialog(false);
                fetchEmployeePayCodes(activeEmployee.id);
                fetchEmployeeTimesheet([activeEmployee.id], timesheetStartDate, options);
            }
            const promises = [
                fetchJobList(activeCompany.id, userSetting?.jobDefaultDivisions ? userSetting.jobDefaultDivisions : [], {limit: 2000}),
                fetchWorkOrderList(activeCompany.id, userSetting?.serviceDefaultDivisions ? userSetting.serviceDefaultDivisions : [], {limit: 2000}),
                fetchWorkOrderCostCodes(),
                fetchUnbilledActivities()
            ];

            await Promise.all(promises.map(p => p));
            setIsInRefresh(false);
        };

        init();
    }, [activeEmployee.id, currentUser.id, timesheetStartDate, timesheetGridInstance]);

    const RefreshButton = () => {
        return (
            <div className="toolbar-button-wrapper">
                <Button
                    name="refreshButton"
                    icon="fa-solid fa-rotate"
                    height={32}
                    onClick={handleRefreshClick}
                    hint={t('common.refresh')}
                />
            </div>
        );
    };

    const SubmitButton = () => {
        return (
            <div className="toolbar-button-wrapper">
                <Button
                    name="submitButton"
                    stylingMode={'outlined'}
                    icon="fa-regular fa-circle-check"
                    text={t('time.submitTime')}
                    height={32}
                    hint={t('time.submitTime')}
                    onClick={handleSubmit}
                    disabled={!allowSubmitting}
                />
            </div>
        );
    };

    const EmployeeSelectBox = () => {
        return (
            <SelectBox
                dataSource={employeeList}
                value={selectedEmployee}
                valueExpr={'id'}
                displayExpr={'fullName'}
                searchExpr={'fullName'}
                searchEnabled={true}
                searchTimeout={200}
                onValueChanged={handleEmployeeChanged}
                placeholder={t('time.selectEmployee')}
            />);
    };

    const employeeDisplay = () => {
        const className = isEmpty(employeeList) || employeeList.length === 1 ? 'employee-name' : 'employee-list';
        return (
            <div className={'employee-wrapper'}>
                <div className={'employee'}>Time Entry for:</div>
                <div className={className}>{isEmpty(employeeList) || employeeList.length === 1 ? `${activeEmployee?.firstName ? activeEmployee.firstName : ''} ${activeEmployee?.lastName ? activeEmployee.lastName : ''}` : <EmployeeSelectBox />}</div>
            </div>
        );
    };

    const displayToolbar = () => {
        return (
            <Toolbar>
                <Item location="before">
                    {employeeDisplay()}
                </Item>
                <Item location="after" locateInMenu="auto">
                    <PeriodSelector />
                </Item>
                <Item location="after" locateInMenu="auto">
                    <SubmitButton />
                </Item>
                <Item location="after" locateInMenu="auto">
                    <RefreshButton />
                </Item>
            </Toolbar>
        );
    };

    return (
        <>
            <div className={'time-entry-header-wrapper'}>
                <div className={'toolbar-row'}>
                    {displayToolbar()}
                </div>
            </div>
            {
                showConfirmationDialog ?
                    <ConfirationDialog
                        showDialog={showConfirmationDialog}
                        confirmationTitle="Submit Transactions"
                        confirmButtonAction={handleCofirmation}
                        confirmButtonText="Yes"
                        denyButtonAction={handleDeny}
                        denyButtonText="No"
                        dialogMessage={confirmationDialogMessage}
                    />
                    : null
            }
            {
                showEmployeeChangeDialog ?
                    <ConfirationDialog
                        showDialog={showEmployeeChangeDialog}
                        confirmationTitle="Save Transactions"
                        confirmButtonAction={handleEmployeeChangeCofirmation}
                        confirmButtonText="Yes"
                        denyButtonAction={handleEmployeeChangeDeny}
                        denyButtonText="No"
                        dialogMessage={confirmationDialogMessage}
                    />
                    : null
            }
            {
                showCompanyChangeDialog ?
                    <ConfirationDialog
                        showDialog={showCompanyChangeDialog}
                        confirmationTitle="Record in Edit"
                        denyButtonAction={() => {setShowCompanyChangeDialog(false);}}
                        denyButtonText="Close"
                        dialogMessage={'You are currently editing a time record. Please complete the edit before changing companies.'}
                        showConfirmButton={false}
                        defaultButton={'cancel'}
                    />
                    :
                    null
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        activeCompany:         state.time.activeCompany,
        activeEmployee:        state.employee.activeEmployee,
        companyUser:           state.companyUser.companyUser,
        currentUser:           state.currentUser,
        currentTimesheet:      state.time.currentTimesheet,
        employeeList:          state.employee.employeeList,
        featureFlags:          state.core.featureFlags,
        isInEditMode:          state.time.isInEditMode,
        isSaveValid:           state.time.isSaveValid,
        timesheetData:         state.time.timesheetData,
        timesheetGridInstance: state.time.timesheetGridInstance,
        timesheetStartDate:    state.time.timesheetStartDate
    };

};

const mapDispatchToProps = {
    ...ActivitiesActions,
    ...EmployeeActions,
    ...JobActions,
    ...TimeActions,
    ...WorkOrderActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryHeader);
